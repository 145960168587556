import moment from "moment";
import {
  GET_PARTIES,
  ADD_PARTY,
  UPDATE_PARTY,
  REMOVE_PARTY,
  PARTY_CHAT_TIMESTAMP,
  LOGOUT,
} from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTIES: {
      return action.payload || [];
    }

    case UPDATE_PARTY:
    case ADD_PARTY: {
      let party = action.payload;
      const index = state.findIndex((p) => p.id === party.id);
      if (index === -1) return [...state, party];
      const newState = [...state];
      newState[index] = party;
      return newState;
    }

    case REMOVE_PARTY: {
      return state.filter((p) => p.id !== action.payload);
    }

    case PARTY_CHAT_TIMESTAMP: {
      const { partyId } = action.payload;
      const index = state.findIndex((chat) => chat.shortId === partyId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index].seen = moment().toISOString();
      return newState;
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
