import React from "react";
import { Container, CssBaseline, Typography } from "@mui/material";
import { AppBar, NavMenu } from "components";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import { Link } from "@mui/material";

const PageNotFound = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const { t } = useTranslation();

  return (
    // <Container>
    //   <CssBaseline />
    //   {/* <AppBar
    //     open={menuOpen}
    //     onOpen={() => setMenuOpen(true)}
    //     title={t("general.notFoundTitle")}
    //   /> */}
    //   <NavMenu
    //     items={props.menuItems}
    //     open={menuOpen}
    //     onClose={() => setMenuOpen(false)}
    //   />
    //   <main className={classes.main}>
    //     <Typography variant="h5">{t("general.notFound")}</Typography>
    //   </main>
    // </Container>
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <img
          className={classes.logoSmall}
          src="https://prowin.net/files/prowin-data/img/logos/prowin/prowin-blume-farbig.svg"
          alt="logo proWin"
        />
        <img
          className={classes.logoLarge}
          src="https://prowin.net/files/prowin-data/img/logos/prowin/prowin-blume-farbig.svg"
          alt="logo proWin"
        />
      </div>

      <div className={classes.right}>
        <div className={classes.textBox}>
          <MoodBadIcon style={{ color: "#919191" }} className={classes.emoji} />
          <Typography className={classes.text1}>
            Oops...this page wasn't find.
          </Typography>
          <Typography className={classes.text2}>
            Please try another link or return <Link to={"/parties"}>home</Link>.
          </Typography>
        </div>
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    // flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // flex: 1,
    height: "100vh",
    background: "#F3F9FC",
  },
  left: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    marginLeft: 150,
  },
  logoSmall: {
    width: "260px",
    height: "340px",
  },
  logoLarge: {
    width: "100px",
    height: "120px",
  },
  right: {
    display: "flex",
    width: "50%",
  },
  textBox: {
    width: 550,
    height: 300,
    background: "white",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  text1: {
    fontSize: 30,
  },
  text2: {
    fontSize: 20,
  },
  emoji: {
    width: 70,
    height: 70,
    margin: 30,
  },
}));
export default PageNotFound;
