import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/it";

const JoinChatDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [chatType, setChatType] = React.useState("");
  const [chatURL, setChatURL] = React.useState("");

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  React.useImperativeHandle(ref, () => ({
    open: (chatType) => {
      setOpen(true);
      setChatType(chatType);
    },
  }));
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(chatURL);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {chatType === "party"
            ? t("chats.joinParty")
            : chatType === "direct"
            ? t("chats.joinChat")
            : chatType === "group"
            ? t("chats.joinGroup")
            : ""}
        </DialogTitle>
        <DialogContent>
          <div className={classes.search}>
            <InputBase
              className={classes.inputBase}
              placeholder={t("chats.pasteLink")}
              value={chatURL}
              onChange={(q) => setChatURL(q.target.value)}
              style={{
                fontSize: "13px",
                color: "#000",
                width: "100%",
              }}
            />
          </div>
        </DialogContent>
        <div className={classes.spacer} />
        <div className={classes.buttonWrapper}>
          <DialogActions>
            <Button
              className={classes.buttonCancel}
              onClick={handleClose}
              color="inherit"
            >
              {t("general.cancel")}
            </Button>
            <Button
              className={classes.buttonCreate}
              type="submit"
              color="primary"
              variant="contained"
            >
              {t("chats.join")}
            </Button>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  search: {
    background: "#F3F9FC",
    borderRadius: 5,
    height: 40,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  inputBase: {
    margin: "10px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  buttonCancel: {
    margin: 10,
    width: 270,
  },
  buttonCreate: {
    margin: 10,
    width: 270,
    background: "#1E92CD",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(160,160,160,0.2)",
    },
  },
}));

export default React.forwardRef(JoinChatDialog);
