import {
  Button,
  Checkbox,
  Collapse,
  Fade,
  FormControlLabel,
  InputBase,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/styles";
import React from "react";
import { useActions } from "actions";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const GuestAccount = (props) => {
  const { verificationCodeSent, guestEmail } = props;
  const { classes } = useStyles();
  const [email, setEmail] = React.useState("");
  const [accepted, setAccepted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { GuestLoginWithEmail } = useActions();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2);

  const handleGuestLoginWithEmail = async (e) => {
    e.preventDefault();
    const { error } = await GuestLoginWithEmail(email, lang);
    if (error) {
      const errorArray = Object.keys(error).map((key) => [
        Number(key),
        error[key],
      ]);
      const errorMessage = (errorArray[1][1]?.response)
        .split(":")[1]
        .replace("}", "")
        .replace(/"/g, "");
      setErrorMessage(errorMessage);
    } else {
      verificationCodeSent();
    }
  };

  const checkEmailInput = (e) => {
    setEmail(e.target.value);
    guestEmail(email);
  };

  return (
    <form onSubmit={handleGuestLoginWithEmail} className={classes.form}>
      <div className={classes.inputSendCode}>
        <InputBase
          placeholder={t("login.email")}
          value={email}
          onChange={checkEmailInput}
          style={{
            fontSize: "13px",
            color: "#000",
          }}
        />
      </div>
      <Collapse in={!!email.trim()}>
        <Fade in={!!email.trim()}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={accepted}
                onChange={(e) => setAccepted(e.target.checked)}
              />
            }
            label={
              <Trans i18nKey="login.privacyNotice">
                <Link to="/privacy" target="_blank"></Link>
              </Trans>
            }
            style={{ marginTop: 10 }}
          />
        </Fade>
      </Collapse>
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      <div className={classes.buttonSendCode}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={!email.trim() || !accepted}
          type="submit"
        >
          {t("login.login")}
        </Button>
      </div>
    </form>
  );
};

const useStyles = makeStyles()((theme) => ({
  form: {
    width: "100%",
    maxWidth: 380,
    marginTop: 10,
    marginBottom: 20,
  },
  inputSendCode: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    height: 30,
    padding: "8px 5px 5px 20px",
  },
  buttonSendCode: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-end",
    fontFamily: theme.typography.h1.fontFamily,
  },
  errorMessage: {
    fontSize: 15,
    color: theme.palette.error.main,
    margin: "10px 0 0 25px",
    width: "100%",
    maxWidth: 350,
  },
  button: {
    textTransform: "none",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4) !important",
    fontFamily: theme.typography.h1.fontFamily,
  },
}));

export default GuestAccount;
