import {
  LOGIN,
  LOGOUT,
  INFORM,
  GET_USER,
  GET_GUEST,
  GET_PROFILES,
  ADD_PROFILE,
  UPDATE_PROFILE,
  GUEST_PROFILE_UPDATE,
  DELETE_PROFILE,
  SELECT_PROFILE,
  SWITCH_COMPANY_MODE,
  HANDLE_VIEW_RELEASE_NOTES,
  SET_CALLBACK_LOCATION,
  GET_MEDIA,
  ADD_MEDIA,
  CONVERT_MEDIA,
  REMOVE_MEDIA,
  MEDIA_TRASH,
  RESTORE_MEDIA,
  GET_PARTIES,
  ADD_PARTY,
  UPDATE_PARTY,
  REMOVE_PARTY,
  PARTY_INFO,
  PARTY_GUESTS,
  CHAT_GUESTS,
  JOIN_PARTY,
  CHANGE_NAME,
  PARTY_HOST,
  SUBSCRIBE,
  LATEST_CHAT,
  SEND_MESSAGE,
  TRY_SEND_MESSAGE,
  REMOVE_TEMP_MESSAGE,
  HANDLE_MESSAGE,
  HANDLE_GUESTS,
  HANDLE_ONLINE,
  HANDLE_SEEN,
  REMOVE_GUEST,
  HANDLE_GUEST_UPDATED,
  HANDLE_GUEST_REMOVED,
  HANDLE_UPDATED_MESSAGE,
  HANDLE_UPDATED_SEEN,
  HANDLE_NEW_HOST,
  LEAVE_CHAT,
  APPLY_TEMPLATE,
  GET_PROFILE,
  GET_DIRECT_PROFILE,
  IMPORT_START,
  IMPORT_FILE,
  IMPORT_MEDIA,
  IMPORT_MESSAGE,
  IMPORT_PARTY,
  DISMISS_BANNER,
  //Apply Template Actions
  START_TEMPLATE,
  CONTINUE_TEMPLATE,
  CLOSE_TEMPLATE,
  CHANGE_TEMPLATE_POSITION,
  HIDE_TEMPLATE_MESSAGE,
  REMOVE_APPLIED_TEMPLATE,
  GET_TEMPLATES,
  GET_TEMPLATE,
  ADD_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
  TEMPLATE_TRASH,
  RESTORE_TEMPLATE,
  ADD_TEMPLATE_MESSAGE,
  EDIT_TEMPLATE_MESSAGE,
  DELETE_TEMPLATE_MESSAGE,
  DELETE_TEMPLATE_MESSAGES,
  MOVE_TEMPLATE_MESSAGE,
  TIMESTAMP_CHAT,
  CLAIM_ACCOUNT,
  VERIFIED_GUEST_LOGIN,
  // Direct chats
  LATEST_DIRECT_CHAT,
  GET_CHATS,
  SEND_CHAT_MESSAGE,
  TRY_SEND_CHAT_MESSAGE,
  HANDLE_CHAT_MESSAGE,
  ADD_CHAT,
  ARCHIVE_CHAT,
  UNARCHIVE_CHAT,
  DIRECT_CHAT_TIMESTAMP,
  HANDLE_UPDATED_DIRECT_MESSAGE,

  //GroupChats
  ADD_GROUP_CHATS,
  GET_GROUP_CHATS,
  GET_GROUP_CHAT,
  UPDATE_GROUP_CHATS,
  JOIN_GROUP_CHATS,
  LATEST_GROUP_CHAT,
  GET_OLDER_MESSAGES,
  GET_NEWER_MESSAGES,
  TRY_SEND_GROUP_MESSAGE,
  SEND_GROUP_MESSAGE,
  HANDLE_GROUP_MESSAGE,
  GET_GUESTS,
  REMOVE_TEMP_GROUP_MESSAGE,
  HANDLE_UPDATED_GROUP_MESSAGE,
  HANDLE_UPDATED_GROUP_SEEN,
  GROUP_MEDIA,
  MUTE_GROUP,
  UNMUTE_GROUP,
  HANDLE_GROUP_RESTRICTED,
  LOAD_INITIAL_GROUP_MESSAGE,
  REMOVE_GROUP_CHAT,
  MUTE_CHAT,
  UNMUTE_CHAT,
  MUTE_PARTY,
  UNMUTE_PARTY,

  // Moderation
  BLOCKED_LIST,
  BLOCK,
  UNBLOCK,
  GET_USER_PROFILE,
  CHAT_LIST,
  GROUP_CHAT_TIMESTAMP,
  PARTY_CHAT_TIMESTAMP,
  HANDLE_PARTY_RESTRICTED,
} from "./types";
import { createAction } from "redux-actions";
import * as API from "./api";
import idx from "idx";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { v4 as uuid } from "uuid";

const loginAction = createAction(LOGIN);
const logoutAction = createAction(LOGOUT);
const informAction = createAction(INFORM);

//GUESTS
const getGuestAction = createAction(GET_GUEST);
const verifiedGuestLoginAction = createAction(VERIFIED_GUEST_LOGIN);
const claimAccount = createAction(CLAIM_ACCOUNT);

const getUserAction = createAction(GET_USER);
const getProfilesAction = createAction(GET_PROFILES);
const updateProfileAction = createAction(GUEST_PROFILE_UPDATE);
const updateGuestProfileAction = createAction(UPDATE_PROFILE);
const addProfileAction = createAction(ADD_PROFILE);
const deleteProfileAction = createAction(DELETE_PROFILE);
const selectProfileAction = createAction(SELECT_PROFILE);
const switchCompanyModeAction = createAction(SWITCH_COMPANY_MODE);
const handleViewReleaseNotesAction = createAction(HANDLE_VIEW_RELEASE_NOTES);
const setCallbackLocationAction = createAction(SET_CALLBACK_LOCATION);
const dismissBannerAction = createAction(DISMISS_BANNER);

const getMediaAction = createAction(GET_MEDIA);
const addMediaAction = createAction(ADD_MEDIA);
const convertMediaAction = createAction(CONVERT_MEDIA);
const removeMediaAction = createAction(REMOVE_MEDIA);
const mediaTrashAction = createAction(MEDIA_TRASH);
const restoreMediaAction = createAction(RESTORE_MEDIA);

const getPartiesAction = createAction(GET_PARTIES);
const addPartyAction = createAction(ADD_PARTY);
const updatePartyAction = createAction(UPDATE_PARTY);
const removePartyAction = createAction(REMOVE_PARTY);
const partyRestrictedAction = createAction(HANDLE_PARTY_RESTRICTED);

const partyInfoAction = createAction(PARTY_INFO);
const partyGuestsAction = createAction(PARTY_GUESTS);
const chatGuestsAction = createAction(CHAT_GUESTS);
const joinPartyAction = createAction(JOIN_PARTY);
const changeNameAction = createAction(CHANGE_NAME);
const partyHostAction = createAction(PARTY_HOST);
const subscribeAction = createAction(SUBSCRIBE);

const latestChatAction = createAction(LATEST_CHAT);
const trySendMessageAction = createAction(TRY_SEND_MESSAGE);
const removeTempMessageAction = createAction(REMOVE_TEMP_MESSAGE);
const sendMessageAction = createAction(SEND_MESSAGE);
const handleMessageAction = createAction(HANDLE_MESSAGE);
const handleGuestsAction = createAction(HANDLE_GUESTS);
const handleOnlineAction = createAction(HANDLE_ONLINE);
const handleSeenAction = createAction(HANDLE_SEEN);
const removeGuestAction = createAction(REMOVE_GUEST);
const leaveChatAction = createAction(LEAVE_CHAT);
const updatedMessageAction = createAction(HANDLE_UPDATED_MESSAGE);
const updatedSeenAction = createAction(HANDLE_UPDATED_SEEN);
const handleNewHostAction = createAction(HANDLE_NEW_HOST);
const applyTemplateAction = createAction(APPLY_TEMPLATE);
const getProfileAction = createAction(GET_PROFILE);
const getDirectProfileAction = createAction(GET_DIRECT_PROFILE);
const handleEnteredTimestampAction = createAction(TIMESTAMP_CHAT);

const handleGuestUpdatedAction = createAction(HANDLE_GUEST_UPDATED);
const handleGuestRemovedAction = createAction(HANDLE_GUEST_REMOVED);

const getTemplatesAction = createAction(GET_TEMPLATES);
const getTemplateAction = createAction(GET_TEMPLATE);
const addTemplateAction = createAction(ADD_TEMPLATE);
const editTemplateAction = createAction(EDIT_TEMPLATE);
const deleteTemplateAction = createAction(DELETE_TEMPLATE);
const templateTrashAction = createAction(TEMPLATE_TRASH);
const restoreTemplateAction = createAction(RESTORE_TEMPLATE);
const addTemplateMessageAction = createAction(ADD_TEMPLATE_MESSAGE);
const editTemplateMessageAction = createAction(EDIT_TEMPLATE_MESSAGE);
const deleteTemplateMessageAction = createAction(DELETE_TEMPLATE_MESSAGE);
const deleteTemplateMessagesAction = createAction(DELETE_TEMPLATE_MESSAGES);
const moveTemplateMessageAction = createAction(MOVE_TEMPLATE_MESSAGE);

const startTemplateAction = createAction(START_TEMPLATE);
const continueTemplateAction = createAction(CONTINUE_TEMPLATE);
const closeTemplateAction = createAction(CLOSE_TEMPLATE);
const changeTemplatePostionAction = createAction(CHANGE_TEMPLATE_POSITION);
const hideTemplateMessageAction = createAction(HIDE_TEMPLATE_MESSAGE);
const removeAppliedTemplateAction = createAction(REMOVE_APPLIED_TEMPLATE);

// General chat

const muteChatAction = createAction(MUTE_CHAT);
const unmuteChatAction = createAction(UNMUTE_CHAT);
const mutePartyAction = createAction(MUTE_PARTY);
const unmutePartyAction = createAction(UNMUTE_PARTY);

//Group Chat
const getGroupChatsAction = createAction(GET_GROUP_CHATS);
const getGroupChatAction = createAction(GET_GROUP_CHAT);
const addGroupChatAction = createAction(ADD_GROUP_CHATS);
const updateGroupChatAction = createAction(UPDATE_GROUP_CHATS);
const joinGroupChatAction = createAction(JOIN_GROUP_CHATS);

const latestGroupChatAction = createAction(LATEST_GROUP_CHAT);
const getOlderMessagesAction = createAction(GET_OLDER_MESSAGES);
const getNewerMessagesAction = createAction(GET_NEWER_MESSAGES);
const trySendGroupMessageAction = createAction(TRY_SEND_GROUP_MESSAGE);
const sendGroupMessageAction = createAction(SEND_GROUP_MESSAGE);
const handleGroupMessageAction = createAction(HANDLE_GROUP_MESSAGE);
const handleUpdatedGroupMessage = createAction(HANDLE_UPDATED_GROUP_MESSAGE);
const updatedGroupSeenAction = createAction(HANDLE_UPDATED_GROUP_SEEN);
const removeGroupChatAction = createAction(REMOVE_GROUP_CHAT);

const getGroupGuestAction = createAction(GET_GUESTS);
const removeTempGroupMessageAction = createAction(REMOVE_TEMP_GROUP_MESSAGE);
const groupMediaAction = createAction(GROUP_MEDIA);
const muteGroupChatAction = createAction(MUTE_GROUP);
const unmuteGroupChatAction = createAction(UNMUTE_GROUP);
const groupRestrictedAction = createAction(HANDLE_GROUP_RESTRICTED);
const loadInitialGroupMessageAction = createAction(LOAD_INITIAL_GROUP_MESSAGE);

const blockedListAction = createAction(BLOCKED_LIST);
const blockAction = createAction(BLOCK);
const unblockAction = createAction(UNBLOCK);
const getUserProfileAction = createAction(GET_USER_PROFILE);
const chatListAction = createAction(CHAT_LIST);

//Direct Chat
const latestDirectChatAction = createAction(LATEST_DIRECT_CHAT);
const getChatsAction = createAction(GET_CHATS);
const sendChatMessageAction = createAction(SEND_CHAT_MESSAGE);
const trySendChatMessageAction = createAction(TRY_SEND_CHAT_MESSAGE);
const handleDirectMessageAction = createAction(HANDLE_CHAT_MESSAGE);
const handleUpdatedDirectMessageAction = createAction(
  HANDLE_UPDATED_DIRECT_MESSAGE
);
const addChatAction = createAction(ADD_CHAT);
const archiveChatAction = createAction(ARCHIVE_CHAT);
const unarchiveChatAction = createAction(UNARCHIVE_CHAT);
const directChatTimestampAction = createAction(DIRECT_CHAT_TIMESTAMP);
const groupChatTimestampAction = createAction(GROUP_CHAT_TIMESTAMP);
const partyChatTimestampAction = createAction(PARTY_CHAT_TIMESTAMP);

//Imports

const importStartAction = createAction(IMPORT_START);
const importFileAction = createAction(IMPORT_FILE);
const importMediaAction = createAction(IMPORT_MEDIA);
const importMessageAction = createAction(IMPORT_MESSAGE);
const importPartyAction = createAction(IMPORT_PARTY);

export const useActions = () => {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);

  const refresh = async () => {
    if (!session.refreshToken) return session;
    if (moment().isBefore(moment.unix(session.expiresAt))) return session;
    try {
      let response = await API.Auth.refresh(session.refreshToken);
      dispatch(loginAction(response.data));
      return response.data;
    } catch {
      dispatch(logoutAction());
      return {};
    }
  };

  return {
    Login: async (authCode) => {
      let error;
      try {
        let response = await API.Auth.token(authCode);
        dispatch(loginAction(response.data));
      } catch (e) {
        error = e;
      }
      return { error };
    },
    GetGuest: async (userId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Guest.get(session, userId);
        dispatch(getGuestAction(response?.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GuestLogin: async (firstName, lastName) => {
      let response, error;
      try {
        response = await API.Guest.login(firstName, lastName);
        dispatch(loginAction(response?.data.Item));
        dispatch(getGuestAction(response?.data.Item));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ClaimAccount: async (email, lang) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Auth.claim(session, email, lang);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    VerifyAccountClaim: async (verificationCode) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Auth.verifyAccountClaim(session, verificationCode);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GuestLoginWithEmail: async (email, lang) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Auth.guestLogin(session, email, lang);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    VerifyGuestLogin: async (email, verificationCode) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Auth.verifyGuestLogin(
          session,
          email,
          verificationCode
        );
        dispatch(loginAction(response.data));
        dispatch(verifiedGuestLoginAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UpdateGuestProfile: async (profile, profilePic) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Guest.updateProfile(session, profile, profilePic);
        dispatch(updateGuestProfileAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    Logout: () => {
      API.setAccessToken();
      API.setRefreshToken();
      API.setUserType();
      API.setGuestCredentials();
      dispatch(logoutAction());
    },
    HandleInformed: () => {
      dispatch(informAction());
    },
    GetUser: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.getUser(session);
        dispatch(getUserAction(response.data));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    GetProfiles: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.getProfiles(session);
        dispatch(getProfilesAction(response.data));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    UpdateProfile: async (profileId, profile, profilePic) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.update(
          session,
          profileId,
          profile,
          profilePic
        );
        dispatch(updateProfileAction(response.data.profile));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    AddProfile: async (profile, profilePic) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.add(session, profile, profilePic);
        dispatch(addProfileAction(response.data.profile));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    DeleteProfile: async (profileId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.delete(session, profileId);
        dispatch(deleteProfileAction({ profileId }));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    SetMainProfile: async (profileId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.setMain(session, profileId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    SelectProfile: (profileId) =>
      dispatch(selectProfileAction({ userId: session.userId, profileId })),
    SwitchCompanyMode: (companyMode) =>
      dispatch(
        switchCompanyModeAction({ userId: session.userId, companyMode })
      ),
    HandleViewReleaseNotes: (version) =>
      dispatch(handleViewReleaseNotesAction(version)),
    SetCallbackLocation: (location) =>
      dispatch(setCallbackLocationAction(location)),
    DismissBanner: () => dispatch(dismissBannerAction()),

    GetMedia: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.get(session);
        dispatch(getMediaAction(response.data));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    AddMedia: async (
      contentType,
      file,
      title,
      path,
      mediaType,
      mediaExtension,
      onProgress
    ) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.add(
          session,
          contentType,
          file,
          title,
          path,
          mediaType,
          mediaExtension,
          onProgress
        );
        dispatch(addMediaAction(response));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    ConvertMedia: async (mediaId, isPortrait, noAudio) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.convert(
          session,
          mediaId,
          isPortrait,
          noAudio
        );
        dispatch(convertMediaAction(mediaId));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    GetConversionStatus: async (mediaId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.getConversionStatus(session, mediaId);
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    RemoveMedia: async (mediaId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.remove(session, mediaId);
        dispatch(removeMediaAction(mediaId));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    MediaTrash: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.trash(session);
        dispatch(mediaTrashAction(response.data));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    RestoreMedia: async (mediaId) => {
      let response, error;
      try {
        dispatch(restoreMediaAction({ mediaId }));
        const session = await refresh();
        response = await API.Media.restore(session, mediaId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    EditMedia: async (mediaId, name) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.edit(session, mediaId, name);
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    CreateFolder: async (title, mediaType, path) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.createFolder(
          session,
          title,
          mediaType,
          path
        );
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    CopyToFolder: async (origin, type, path, title) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.duplicate(
          session,
          origin,
          type,
          path,
          title
        );
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    AddToFolder: async (mediaId, path) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Media.addToFolder(session, mediaId, path);
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },

    GetParties: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.get(session);
        dispatch(getPartiesAction(response.data));
      } catch (e) {
        // const status = idx(e, (_) => _.response.status);
        // if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    AddParty: async (
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging,
      image
    ) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.add(
          session,
          title,
          start,
          expiration,
          host,
          manager,
          password,
          restrictedMessaging,
          image
        );
        dispatch(addPartyAction(response.data));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    UpdateParty: async (
      partyId,
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging,
      image,
      deleteProfilePic
    ) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.update(
          session,
          partyId,
          title,
          start,
          expiration,
          host,
          manager,
          password,
          restrictedMessaging,
          image,
          deleteProfilePic
        );
        dispatch(updatePartyAction(response.data));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    RemoveParty: async (partyId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.remove(session, partyId);
        dispatch(removePartyAction(partyId));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },

    PartyInfo: async (partyId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.info(session, partyId);
        dispatch(partyInfoAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    HandlePartyRestricted: (partyId, restricted) =>
      dispatch(partyRestrictedAction({ partyId, restricted })),
    PartyGuests: async (partyId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.guests(session, guestId, partyId);
        dispatch(partyGuestsAction({ partyId, guests: response.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    JoinPartyPreflight: async (partyId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.joinPreflight(session, partyId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    JoinParty: async (partyId, firstName, lastName, password) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.join(
          session,
          partyId,
          firstName,
          lastName,
          password
        );
        dispatch(joinPartyAction({ ...response.data, partyId }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ChangeName: async (partyId, id, firstName, lastName, email) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.changeName(
          session,
          partyId,
          id,
          firstName,
          lastName
        );
        dispatch(changeNameAction({ partyId, id, firstName, lastName }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    PartyHost: async (partyId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.host(session, partyId, guestId);
        dispatch(partyHostAction({ partyId, guestId }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    Subscribe: async (partyId, id, token) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.subscribe(session, partyId, id, token);
        dispatch(subscribeAction({ partyId, id, token }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveGuest: async (partyId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.removeGuest(session, partyId, guestId);
        dispatch(removeGuestAction({ partyId, guestId }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },

    LatestChat: async (guestId, partyId, from) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.getChat(session, guestId, partyId, from);
        dispatch(latestChatAction({ partyId, from, messages: response.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    SendMessage: async (
      guestId,
      partyId,
      fromTemplate,
      replyingTo,
      sender,
      messageType,
      message,
      mediaAttachment,
      mediaAttachmentType,
      mediaExtension,
      contentType,
      mediaAttachmentOwner,
      description
    ) => {
      let response, error;
      const tempId = uuid();
      try {
        dispatch(
          trySendMessageAction({
            guestId,
            partyId,
            message: {
              id: tempId,
              tempId,
              fromTemplate,
              replyingTo,
              sender,
              messageType,
              message,
              mediaAttachment,
              mediaAttachmentType,
              mediaExtension,
              contentType,
              mediaAttachmentOwner,
              description,
            },
            tempId,
          })
        );
        const session = await refresh();
        response = await API.Parties.sendChat(
          session,
          guestId,
          partyId,
          fromTemplate,
          replyingTo,
          sender,
          messageType,
          message,
          mediaAttachment,
          mediaAttachmentType,
          mediaExtension,
          contentType,
          mediaAttachmentOwner,
          description
        );
        dispatch(
          sendMessageAction({ partyId, message: response.data, tempId })
        );
      } catch (e) {
        dispatch(removeTempMessageAction({ partyId, tempId }));
        error = e;
      }
      return { response, error };
    },
    SendMessageAgain: async ({
      guestId,
      partyId,
      tempId,
      fromTemplate,
      replyingTo,
      sender,
      messageType,
      message,
      mediaAttachment,
      mediaAttachmentType,
      mediaExtension,
      contentType,
      mediaAttachmentOwner,
    }) => {
      let response, error;
      try {
        dispatch(
          trySendMessageAction({
            guestId,
            partyId,
            message: {
              id: tempId,
              tempId,
              fromTemplate,
              replyingTo,
              sender,
              messageType,
              message,
              mediaAttachment,
              mediaAttachmentType,
              mediaExtension,
              contentType,
              mediaAttachmentOwner,
            },
            tempId,
          })
        );
        const session = await refresh();
        response = await API.Parties.sendChat(
          session,
          guestId,
          partyId,
          fromTemplate,
          replyingTo,
          sender,
          messageType,
          message,
          mediaAttachment,
          mediaAttachmentType,
          mediaExtension,
          contentType,
          mediaAttachmentOwner
        );
        dispatch(
          sendMessageAction({ partyId, message: response.data, tempId })
        );
      } catch (e) {
        dispatch(removeTempMessageAction({ partyId, tempId }));
        error = e;
      }
      return { response, error };
    },
    LeaveChat: (partyId) => dispatch(leaveChatAction({ partyId })),

    GetProfile: async (partyId, profileId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.getProfile(session, profileId);
        dispatch(getProfileAction({ partyId, profile: response.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetDirectProfile: async (profileId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Profile.getProfile(session, profileId);
        dispatch(getDirectProfileAction({ profile: response.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    EditAppliedTemplate: async (partyId, messages) => {
      let response, error;
      try {
        const session = await refresh();
        await API.Parties.editTemplate(session, partyId, messages);
        dispatch(applyTemplateAction({ partyId, messages }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetAppliedTemplate: async (partyId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.getTemplate(session, partyId);
        dispatch(
          applyTemplateAction({ partyId, messages: response.data.messages })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    SendFullTemplateToTemplate: async (
      templateId,
      otherTemplateId,
      beforeMessageId
    ) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.sendTemplate(
          session,
          templateId,
          otherTemplateId,
          beforeMessageId
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ChatTimestamp: async (partyId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.chatTimestamp(session, partyId);
        dispatch(partyChatTimestampAction({ partyId }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    HandleMessage: (partyId, message) =>
      dispatch(handleMessageAction({ partyId, message })),
    HandleGuests: (partyId, guests) =>
      dispatch(handleGuestsAction({ partyId, guests })),
    HandleOnline: (partyId, guests, online) =>
      dispatch(handleOnlineAction({ partyId, guests, online })),
    HandleSeen: (partyId) => dispatch(handleSeenAction({ partyId })),
    HandleUpdatedMessage: (partyId, message) =>
      dispatch(updatedMessageAction({ partyId, message })),
    HandleUpdatedSeen: (partyId, { guestId, timestamp }) =>
      dispatch(updatedSeenAction({ partyId, guestId, timestamp })),
    HandleNewHost: (partyId, guestId) =>
      dispatch(handleNewHostAction({ partyId, guestId })),
    HandleGuestUpdated: (partyId, guest) =>
      dispatch(handleGuestUpdatedAction({ partyId, guest })),
    HandleGuestRemoved: (partyId, guestId) =>
      dispatch(handleGuestRemovedAction({ partyId, guestId })),

    GetTemplates: async () => {
      let response, error;
      try {
        const session = await refresh();
        const { data: myTemplates } = await API.Templates.list(session);
        const { data: shares } = await API.Templates.shares(session);
        dispatch(getTemplatesAction([...myTemplates, ...shares]));
        response = { data: [...myTemplates, ...shares] };
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    GetTemplate: async (templateId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.get(session, templateId);
        dispatch(getTemplateAction(response.data));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    AddTemplate: async (title) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.add(session, title);
        dispatch(addTemplateAction(response));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    EditTemplate: async (templateId, name) => {
      let response, error;
      try {
        dispatch(editTemplateAction({ templateId, name }));
        const session = await refresh();
        response = await API.Templates.edit(session, templateId, name);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveTemplate: async (templateId) => {
      let response, error;
      try {
        dispatch(deleteTemplateAction({ templateId }));
        const session = await refresh();
        response = await API.Templates.remove(session, templateId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    TemplateTrash: async () => {
      let response, error;
      try {
        const session = await refresh();
        const { data: trash } = await API.Templates.trash(session);
        const { data: shareTrash } = await API.Templates.shareTrash(session);
        dispatch(templateTrashAction([...trash, ...shareTrash]));
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    RestoreTemplate: async (templateId) => {
      let response, error;
      try {
        dispatch(restoreTemplateAction({ templateId }));
        const session = await refresh();
        response = await API.Templates.restore(session, templateId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    CreateTemplateFolder: async (title, path) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.createFolder(session, title, path);
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    TemplateAddToFolder: async (templateId, path) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.addToFolder(session, templateId, path);
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    SharedTemplateAddToFolder: async (shareToken, path) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.addShareToFolder(
          session,
          shareToken,
          path
        );
      } catch (e) {
        const status = idx(e, (_) => _.response.status);
        if (status === 401) dispatch(logoutAction());
        error = e;
      }
      return { response, error };
    },
    AddTemplateMessage: async (templateId, message, contentType) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.addMessage(
          session,
          templateId,
          message,
          contentType
        );
        const msg = response.data.message;
        dispatch(
          addTemplateMessageAction({
            templateId,
            message: msg,
            beforeMessageId: message.beforeMessageId,
          })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    EditTemplateMessage: async (templateId, message) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.editMessage(
          session,
          templateId,
          message
        );
        dispatch(editTemplateMessageAction({ templateId, message }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    DeleteTemplateMessage: async (templateId, messageId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.deleteMessage(
          session,
          templateId,
          messageId
        );
        dispatch(deleteTemplateMessageAction({ templateId, messageId }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    DeleteTemplateMessages: async (templateId, messageIds) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.deleteMessages(
          session,
          templateId,
          messageIds
        );
        dispatch(deleteTemplateMessagesAction({ templateId, messageIds }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    DuplicateFile: async (origin, type, saveInLibrary, path, title) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.duplicateFile(
          session,
          origin,
          type,
          saveInLibrary,
          path,
          title
        );
        if (saveInLibrary) {
          dispatch(addMediaAction(response.data));
        }
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    MoveTemplateMessage: async (templateId, messageId, newNextId) => {
      let response, error;
      try {
        const session = await refresh();
        dispatch(
          moveTemplateMessageAction({ templateId, messageId, newNextId })
        );
        response = await API.Templates.moveMessage(
          session,
          templateId,
          messageId,
          newNextId
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    MoveTemplateMessages: async (templateId, messageIds, newNextId) => {
      let response, error;
      try {
        const session = await refresh();
        await API.Templates.moveMessages(
          session,
          templateId,
          messageIds,
          newNextId
        );
        response = await API.Templates.get(session, templateId);
        dispatch(getTemplateAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveSharedTemplate: async (shareToken) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.removeShare(session, shareToken);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RestoreSharedTemplate: async (shareToken) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.restoreShare(session, shareToken);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    AcceptSharePreflight: async (shareToken) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.acceptSharePreflight(
          session,
          shareToken
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    AcceptShare: async (shareToken) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.acceptShare(session, shareToken);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ShareTemplate: async (templateId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.share(session, templateId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RevokeSharedTemplate: async (templateId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.revokeShare(session, templateId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },

    StartTemplate: (partyId, templateId) =>
      dispatch(startTemplateAction({ partyId, templateId })),
    ContinueTemplate: (partyId, templateId) =>
      dispatch(continueTemplateAction({ partyId, templateId })),
    CloseTemplate: (partyId) => dispatch(closeTemplateAction({ partyId })),
    ChangeTemplatePostion: (partyId, position) =>
      dispatch(changeTemplatePostionAction({ partyId, position })),
    HideTemplateMessage: (partyId, messageId) =>
      dispatch(hideTemplateMessageAction({ partyId, messageId })),
    RemoveAppliedTemplate: (partyId) =>
      dispatch(removeAppliedTemplateAction({ partyId })),

    ApplyTemplate: async (templateId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Templates.apply(session, templateId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    SendFullTemplate: async (partyId, templateId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.sendFullTemplate(
          session,
          partyId,
          templateId
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveMessage: async (guestId, partyId, messageId, fullDelete) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.removeMessage(
          session,
          guestId,
          partyId,
          messageId,
          fullDelete
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveMessages: async (guestId, partyId, messageIds) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.removeMessages(
          session,
          guestId,
          partyId,
          messageIds
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },

    EditMessage: async (guestId, partyId, messageId, message) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.editMessage(
          session,
          guestId,
          partyId,
          messageId,
          message
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UpdateSeen: async (partyId, guestId, timestamp) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Parties.updateSeen(
          session,
          guestId,
          partyId,
          timestamp
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ImportStart: async (token) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Import.start(session, token);
        dispatch(importStartAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },

    ImportFile: async (file) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Import.file(session, file);
        dispatch(importFileAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },

    ImportMedia: async (mediaId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Import.media(session, mediaId);
        dispatch(importMediaAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ImportMessage: async (id, partyId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Import.message(session, id, partyId);
        dispatch(importMessageAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ImportParty: async (partyId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Import.party(session, partyId);
        dispatch(importPartyAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    //groupchats
    GetGroupChats: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.get(session);
        dispatch(getGroupChatsAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetGroupChat: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.getGroupChat(session, groupId);
        dispatch(getGroupChatAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    AddGroupChat: async (title, restrictedMessaging, image) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.add(
          session,
          title,
          restrictedMessaging,
          image
        );
        dispatch(addGroupChatAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UpdateGroupChat: async (
      groupId,
      title,
      restrictedMessaging,
      image,
      deleteGroupPicture
    ) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.update(
          session,
          groupId,
          title,
          restrictedMessaging,
          image,
          deleteGroupPicture
        );
        dispatch(updateGroupChatAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    LoadInitialGroupMessage: async (groupId, limit) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.initMessage(session, groupId, limit);
        dispatch(
          loadInitialGroupMessageAction({ groupId, messages: response.data })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    JoinGroupChatPreflight: async (token) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.joinPreflight(session, token);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    JoinGroupChat: async (token) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.join(session, token);
        dispatch(joinGroupChatAction(...response.data, token));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    LatestGroupChat: async (groupId, timestamp) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.getChat(session, groupId, timestamp);
        dispatch(
          latestGroupChatAction({ groupId, timestamp, messages: response.data })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetOlderMessages: async (groupId, timestamp) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.getOlderMessages(
          session,
          groupId,
          timestamp
        );
        dispatch(
          getOlderMessagesAction({
            groupId,
            timestamp,
            messages: response.data,
          })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetNewerMessages: async (groupId, timestamp) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.getNewerMessages(
          session,
          groupId,
          timestamp
        );
        dispatch(
          getNewerMessagesAction({
            groupId,
            timestamp,
            messages: response.data,
          })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    HandleGroupMessage: (groupId, message) =>
      dispatch(handleGroupMessageAction({ groupId, message })),
    HandleUpdatedGroupMessage: (groupId, message) =>
      dispatch(handleUpdatedGroupMessage({ groupId, message })),
    SendGroupMessage: async (
      groupId,
      fromTemplate,
      replyingTo,
      sender,
      profileId,
      messageType,
      message,
      mediaAttachment,
      mediaAttachmentType,
      mediaExtension,
      contentType,
      mediaAttachmentOwner,
      description
    ) => {
      let response, error;
      const tempId = uuid();
      try {
        dispatch(
          trySendGroupMessageAction({
            groupId,
            message: {
              id: tempId,
              tempId,
              fromTemplate,
              replyingTo,
              sender,
              profileId,
              messageType,
              message,
              mediaAttachment,
              mediaAttachmentType,
              mediaExtension,
              contentType,
              mediaAttachmentOwner,
              description,
            },
            tempId,
          })
        );
        const session = await refresh();
        response = await API.groupChats.sendChat(
          session,
          groupId,
          fromTemplate,
          replyingTo,
          sender,
          profileId,
          messageType,
          message,
          mediaAttachment,
          mediaAttachmentType,
          mediaExtension,
          contentType,
          mediaAttachmentOwner,
          description
        );
        dispatch(
          sendGroupMessageAction({ groupId, message: response.data, tempId })
        );
      } catch (e) {
        dispatch(removeTempGroupMessageAction({ groupId, tempId }));
        error = e;
      }
      return { response, error };
    },
    RemoveGroupMessage: async (groupId, messageId, timestamp) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.removeMessage(
          session,
          groupId,
          messageId,
          timestamp
        );
        dispatch(
          handleUpdatedGroupMessage({
            groupId,
            message: response.data,
          })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveGroupMessages: async (groupId, messageIds, timestamps) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.removeMessages(
          session,
          groupId,
          messageIds,
          timestamps
        );
        for (const message of response.data) {
          dispatch(
            handleUpdatedGroupMessage({
              groupId,
              message,
            })
          );
        }
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    SearchGroupMessage: async (groupId, text) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.searchMessage(session, groupId, text);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    EditGroupMessage: async (groupId, messageId, message, timestamp) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.editMessage(
          session,
          groupId,
          messageId,
          message,
          timestamp
        );
        dispatch(
          handleUpdatedGroupMessage({
            groupId,
            message: response.data,
          })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveGroupChatGuest: async (groupId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.removeGuest(session, groupId, guestId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    LeaveGroup: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.leave(session, groupId);
        dispatch(removeGroupChatAction(groupId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetGuests: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.getGuests(session, groupId);
        dispatch(getGroupGuestAction({ groupId, guests: response.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveGroupChat: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.removeGroupChat(session, groupId);
        dispatch(removeGroupChatAction(groupId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    MakeAdmin: async (groupId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.makeAdmin(session, groupId, guestId);
        // dispatch(getGroupGuestAction({groupId, guests: response.data}));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveAdmin: async (groupId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.removeAdmin(session, groupId, guestId);
        // dispatch(getGroupGuestAction({groupId, guests: response.data}));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    MakeMod: async (groupId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.makeMod(session, groupId, guestId);
        // dispatch(getGroupGuestAction({groupId, guests: response.data}));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveMod: async (groupId, guestId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.removeMod(session, groupId, guestId);
        // dispatch(getGroupGuestAction({groupId, guests: response.data}));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GroupTimestamp: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.groupTimestamp(session, groupId);
        dispatch(groupChatTimestampAction({ groupId }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UpdateGroupSeen: async (groupId, guestId, timestamp) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.updateSeen(session, groupId);
        dispatch(updatedGroupSeenAction({ groupId, guestId, timestamp }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    IsTester: async (userId) => {
      const session = await refresh();
      let response = await API.groupChats.isTester(session, userId);
      return { response };
    },
    InviteUser: async (otherUserId, groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.inviteUser(
          session,
          otherUserId,
          groupId
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    InviteVIP: async (prowinId, groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.inviteVIP(session, prowinId, groupId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ShareGroupChat: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.share(session, groupId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    EndShareGroupChat: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.endShare(session, groupId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GroupMedia: async (groupId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.groupChats.getMedia(session, groupId);
        dispatch(groupMediaAction({ groupId, media: response.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    HandleGroupRestricted: async (groupId, restricted) =>
      dispatch(groupRestrictedAction({ groupId, restricted })),
    MuteChat: async (chatId) => {
      let response, error;
      try {
        response = await API.Chat.mute(session, chatId);
        dispatch(muteChatAction(chatId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UnMuteChat: async (chatId) => {
      let response, error;
      try {
        response = await API.Chat.unmute(session, chatId);
        dispatch(unmuteChatAction(chatId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    MuteGroupChat: async (groupId) => {
      let response, error;
      try {
        response = await API.groupChats.mute(session, groupId);
        dispatch(muteGroupChatAction(groupId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UnMuteGroupChat: async (groupId) => {
      let response, error;
      try {
        response = await API.groupChats.unmute(session, groupId);
        dispatch(unmuteGroupChatAction(groupId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    MuteParty: async (shortId, partyId) => {
      let response, error;
      try {
        response = await API.Parties.mute(session, shortId);
        dispatch(mutePartyAction(partyId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UnMuteParty: async (shortId, partyId) => {
      let response, error;
      try {
        response = await API.Parties.unmute(session, shortId);
        dispatch(unmutePartyAction(partyId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    Report: async (contentType, id, description) => {
      let error;
      try {
        const session = await refresh();
        await API.Moderation.report(session, contentType, id, description);
      } catch (e) {
        error = e;
      }
      return { error };
    },
    BlockedList: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Moderation.blocked(session);
        dispatch(blockedListAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    Block: async (userId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Moderation.block(session, userId);
        dispatch(blockAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    Unblock: async (userId) => {
      let response, error;
      try {
        const session = await refresh();
        await API.Moderation.unblock(session, userId);
        dispatch(unblockAction(userId));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetUserProfile: async (userId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Guest.get(session, userId);
        dispatch(getUserProfileAction({ userId, profile: response.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetChatList: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.All.chatList(session);
        dispatch(chatListAction(response?.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    // Direct chat
    LatestDirectChat: async (chatId, from) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Chat.getChat(session, chatId, from);
        dispatch(
          latestDirectChatAction({ chatId, from, messages: response.data })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    GetChats: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Chat.get(session);
        dispatch(getChatsAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    SendDirectMessage: async (
      chatId,
      fromTemplate,
      replyingTo,
      sender,
      messageType,
      message,
      mediaAttachment,
      mediaAttachmentType,
      mediaExtension,
      contentType,
      mediaAttachmentOwner,
      description
    ) => {
      let response, error;
      const tempId = uuid();
      try {
        dispatch(
          trySendChatMessageAction({
            chatId,
            message: {
              id: tempId,
              tempId,
              fromTemplate,
              replyingTo,
              sender,
              messageType,
              message,
              mediaAttachment,
              mediaAttachmentType,
              mediaExtension,
              contentType,
              mediaAttachmentOwner,
              description,
            },
            tempId,
          })
        );
        const session = await refresh();
        response = await API.Chat.sendDirectChat(
          session,
          chatId,
          fromTemplate,
          replyingTo,
          sender,
          messageType,
          message,
          mediaAttachment,
          mediaAttachmentType,
          mediaExtension,
          contentType,
          mediaAttachmentOwner,
          description
        );
        dispatch(
          sendChatMessageAction({ chatId, message: response.data, tempId })
        );
      } catch (e) {
        dispatch(removeTempMessageAction({ chatId, tempId }));
        error = e;
      }
      return { response, error };
    },
    RemoveDirectMessage: async (chatId, messageId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Chat.removeDirectMessage(
          session,
          chatId,
          messageId
        );
        dispatch(
          handleUpdatedDirectMessageAction({ chatId, message: response?.data })
        );
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    RemoveDirectMessages: async (chatId, messageIds) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Chat.removeDirectMessages(
          session,
          chatId,
          messageIds
        );
        dispatch(updatedMessageAction({ chatId, message: response?.data }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    DirectChatTimestamp: async (chatId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Chat.chatTimestamp(session, chatId);
        dispatch(directChatTimestampAction({ chatId }));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ChatSuggestions: async () => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Chat.suggestions(session);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    AddChat: async (otherUserId) => {
      let response, error;
      try {
        const session = await refresh();
        response = await API.Chat.add(session, otherUserId);
        dispatch(addChatAction(response.data));
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    ArchiveChat: async (chatId) => {
      let response, error;
      try {
        dispatch(archiveChatAction({ chatId }));
        const session = await refresh();
        response = await API.Chat.archive(session, chatId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    UnarchiveChat: async (chatId) => {
      let response, error;
      try {
        dispatch(unarchiveChatAction({ chatId }));
        const session = await refresh();
        response = await API.Chat.unarchive(session, chatId);
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    HandleDirectMessage: (chatId, message) =>
      dispatch(handleDirectMessageAction({ chatId, message })),
    HandleUpdatedDirectMessage: (chatId, message) =>
      dispatch(handleUpdatedDirectMessageAction({ chatId, message })),
  };
};
