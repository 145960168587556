import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useActions } from "actions";
import { PartyLoading } from "components";
import { useSelector } from "react-redux";

const Callback = (props) => {
  const { search } = useLocation();

  const { callbackLocation } = useSelector((state) => state.settings);
  const { Login } = useActions();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const location = useLocation();

  const handleLogin = async (authCode) => {
    await Login(authCode);
    setLoggedIn(true);
  };

  React.useEffect(() => {
    let paramsArray = search
      .substring(1)
      .split("&")
      .map((s) => s.split("="));
    let params = {};
    for (let param of paramsArray)
      params[decodeURIComponent(param[0])] = decodeURIComponent(param[1]);
    handleLogin(params.code);
  }, []);

  if (loggedIn) {
    if (!callbackLocation || callbackLocation === location.pathname)
      return <Redirect to="/" />;
    return <Redirect to={callbackLocation} />;
  }
  return <PartyLoading />;
};

export default Callback;
