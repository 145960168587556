import { MoreVert } from "@mui/icons-material";
import { Avatar, Badge, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const Guest = React.memo(
  ({ id, initials, name, role, src, srcSet, online, menuItems }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const handleMenuButton = (e) => {
      setAnchorEl(e.target);
      setMenuOpen(true);
    };
    const handleMenuItem = (index) => {
      setMenuOpen(false);
      if (menuItems[index].action) menuItems[index].action(id);
    };

    return (
      <>
        <div className={classes.wrapper}>
          <Badge
            variant="dot"
            overlap="circular"
            color={online ? "success" : undefined}
            className={classes.badge}
          >
            <Avatar className={classes.avatar} src={src} srcSet={srcSet}>
              {initials}
            </Avatar>
          </Badge>
          <div className={classes.name}>
            {name}
            {!!role && ` (${role})`}
          </div>
          {!!menuItems?.length && (
            <IconButton onClick={handleMenuButton}>
              <MoreVert />
            </IconButton>
          )}
        </div>
        {!!menuItems?.length && (
          <Menu
            anchorEl={anchorEl}
            keepMounted={true}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index.toString()}
                onClick={() => handleMenuItem(index)}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        )}
      </>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 5,
    paddingLeft: 15,
    display: "flex",
    alignItems: "center",
  },
  badge: {
    "& .MuiBadge-dot": {
      height: 12,
      minWidth: 12,
      borderRadius: 6,
    },
  },
  avatar: {
    fontWeight: "bold",
    fontSize: "1em",
    border: "0.5px solid rgba(160,160,160,0.6)",
  },
  name: {
    flex: 1,
    marginLeft: 10,
    fontWeight: "500",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default Guest;
