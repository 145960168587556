import {
  // CHAT_INFO,
  CHAT_GUESTS,
  LATEST_DIRECT_CHAT,
  HANDLE_CHAT_MESSAGE,
  HANDLE_UPDATED_MESSAGE,
  HANDLE_UPDATED_SEEN,
  TRY_SEND_CHAT_MESSAGE,
  LOAD_INITIAL_DIRECT_MESSAGE,
  HANDLE_UPDATED_DIRECT_MESSAGE,

  //Direct Chat
  SEND_CHAT_MESSAGE,
  LOGOUT,
} from "../types";
import moment from "moment";

export const sortMessages = (a, b) => {
  if (a && b) {
    if (moment(a.timestamp).isBefore(b.timestamp)) return -1;
    if (moment(b.timestamp).isBefore(a.timestamp)) return 1;
    return 0;
  }
};

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LATEST_DIRECT_CHAT: {
      let { chatId, from, messages } = action.payload;
      if (!state[chatId]) state[chatId] = {};

      if (!from) {
        state[chatId].messages = messages.sort(sortMessages);
      } else {
        if (!state[chatId].messages) state[chatId].messages = [];
        let newMessages = messages.filter(
          (m) => !state[chatId].messages.find((o) => o.id === m.id)
        );
        state[chatId].messages = [
          ...state[chatId].messages,
          ...newMessages,
        ].sort(sortMessages);
      }
      return state;
    }

    case CHAT_GUESTS: {
      let { partyId, guests } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      state[partyId].guests = guests;
      return state;
    }

    case HANDLE_CHAT_MESSAGE: {
      let { chatId, message } = action.payload;
      if (!state[chatId]) state[chatId] = {};
      if (!state[chatId].messages) state[chatId].messages = [];
      state[chatId].messages = [
        ...state[chatId].messages.filter((m) => m.id !== message.id),
        message,
      ].sort(sortMessages);
      return state;
    }

    case TRY_SEND_CHAT_MESSAGE: {
      let { chatId, message, tempId } = action.payload;
      if (!state[chatId]) state[chatId] = {};
      if (!state[chatId].messages) state[chatId].messages = [];
      let msg = state[chatId].messages.find((m) => m.tempId === tempId);
      if (msg) {
        delete msg.failed;
        state[chatId].messages = [...state[chatId].messages].sort(sortMessages);
      } else {
        state[chatId].messages = [...state[chatId].messages, message].sort(
          sortMessages
        );
      }
      return state;
    }

    case SEND_CHAT_MESSAGE: {
      let { chatId, message, tempId } = action.payload;
      if (!state[chatId]) state[chatId] = {};
      if (!state[chatId].messages) state[chatId].messages = [];
      state[chatId].messages = [
        ...state[chatId].messages.filter((m) => m.tempId !== tempId),
        message,
      ].sort(sortMessages);
      return state;
    }

    case HANDLE_UPDATED_MESSAGE: {
      let { chatId, message } = action.payload;
      if (!state[chatId]) state[chatId] = {};
      if (!state[chatId].messages) state[chatId].messages = [];
      state[chatId].messages = state[chatId].messages.filter(
        (m) => m.id !== message.id
      );
      if (message.message !== "2bgd418k-a72n-532s-hu07-z582p3581d46")
        state[chatId].messages.push(message);
      return JSON.parse(JSON.stringify(state));
    }

    case HANDLE_UPDATED_DIRECT_MESSAGE: {
      let { chatId, message } = action.payload;
      if (!state[chatId]) state[chatId] = {};
      if (!state[chatId].messages) state[chatId].messages = [];
      const existingMessageIndex = state[chatId].messages.findIndex(
        (m) => m.id === message.id
      );
      if (existingMessageIndex !== -1)
        state[chatId].messages[existingMessageIndex] = message;
      return JSON.parse(JSON.stringify(state));
    }

    case HANDLE_UPDATED_SEEN: {
      let { chatId, guestId, timestamp } = action.payload;
      if (!state[chatId]) state[chatId] = {};
      if (!state[chatId].guests) state[chatId].guests = [];
      const guest = state[chatId].guests.find((g) => g.id === guestId);
      if (!guest) return state;
      guest.lastSeen = timestamp;
      state[chatId].guests = [...state[chatId].guests];
      return state;
    }

    case LOAD_INITIAL_DIRECT_MESSAGE: {
      let { chatId, messages } = action.payload;
      if (!state[chatId]) state[chatId] = {};
      if (!state[chatId].messages) state[chatId].messages = [];
      state[chatId].messages = messages.sort(sortMessages);
      return state;
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
