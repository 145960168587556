import React from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import {
  Container,
  Paper,
  Typography,
  Grid,
  CssBaseline,
  Button,
  Menu,
  MenuItem,
  Avatar,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "assets/logo-prowin-messenger.svg";
import { useHistory } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { PartyLoading } from "src/components";
import moment from "moment";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const JoinParty = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { JoinPartyPreflight, JoinParty, GetChatList } = useActions();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2).toLowerCase();
  const langButton = React.useRef();
  const [langOpen, setLangOpen] = React.useState(false);
  const [passwordRequired, setPasswordRequired] = React.useState(false);
  const [partyChatInfo, setPartyChatInfo] = React.useState();
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [joining, setJoining] = React.useState(false);
  const [error, setError] = React.useState(false);

  const history = useHistory();

  const session = useSelector((state) => state.session);

  const selectLang = async (lang) => {
    await i18n.changeLanguage(lang);
    setLangOpen(false);
  };

  const loadPartyChatInfo = async () => {
    setLoading(true);
    let { response, error } = await JoinPartyPreflight(id);
    if (response?.data?.passwordProtected) {
      setPasswordRequired(true);
    }
    if (!error) setPartyChatInfo(response?.data);
    else {
      setPartyChatInfo({ status: error?.response?.data?.error });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadPartyChatInfo();
  }, [id]);

  const joinGuest = async () => {
    setJoining(true);
    const { error } = await JoinParty(
      id,
      session.firstName,
      session.lastName,
      password
    );
    if (!error) {
      await GetChatList();
      history.push(`/chats/p/${id}`);
      return;
    } else {
      setJoining(false);
      setError(true);
    }
  };

  const { src, srcSet } = generateImageSourceSet(
    partyChatInfo?.id,
    partyChatInfo?.partyPic,
    120
  );

  if (loading) return <PartyLoading />;

  if (partyChatInfo?.status === "alreadyJoined")
    return <Redirect to={`/chats/p/${id}`} />;

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Grid item xs={3}>
            <Link to="/">
              <img src={logo} alt="Prowin Messenger" />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="inherit"
              onClick={() => setLangOpen(!langOpen)}
              ref={langButton}
              className={classes.langButton}
            >
              {lang === "en"
                ? "English 🇬🇧"
                : lang === "nl"
                ? "Nederlands 🇳🇱"
                : lang === "fr"
                ? "Français 🇫🇷"
                : "Deutsch 🇩🇪"}
            </Button>
            <Menu
              anchorEl={langButton.current}
              keepMounted={true}
              open={langOpen}
              onClose={() => setLangOpen(false)}
            >
              <MenuItem onClick={() => selectLang("de")}>Deutsch 🇩🇪</MenuItem>
              <MenuItem onClick={() => selectLang("en")}>English 🇬🇧</MenuItem>
              <MenuItem onClick={() => selectLang("nl")}>
                Nederlands 🇳🇱
              </MenuItem>
              <MenuItem onClick={() => selectLang("fr")}>Français 🇫🇷</MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Container maxWidth="sm">
          <Paper className={classes.profileCard} justifyContent="center">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Avatar src={src} srcSet={srcSet} className={classes.avatar}>
                  <ShoppingCartIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography align="center" variant="h5">
                  {partyChatInfo?.title +
                    (partyChatInfo?.host ? ` ${partyChatInfo?.host}` : "") +
                    (partyChatInfo?.start
                      ? ` ${moment(partyChatInfo?.start).format(
                          t("general.dateFormat")
                        )}`
                      : "")}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Typography variant="h6" align="center">
            {partyChatInfo?.status === "This party doesn't exist."
              ? t("party.partyNotFound")
              : partyChatInfo?.status === "This party is over."
              ? t("party.partyExpired")
              : partyChatInfo?.status === "notYourParty"
              ? t("chats.notYourPartyDescription")
              : t("chats.joinPartyDescription")}
          </Typography>
          {!!passwordRequired && (
            <TextField
              margin="dense"
              label={t("party.password")}
              type="text"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.field}
              required
              disabled={joining}
              error={error}
              helperText={error ? t("party.invalidPassword") : ""}
            />
          )}
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.buttonCancel}
              color="inherit"
              onClick={() => history.goBack()}
            >
              {t("general.back")}
            </Button>
            {!partyChatInfo?.status && (
              <Button
                className={classes.buttonCreate}
                type="submit"
                color="primary"
                variant="contained"
                style={{ textTransform: "lowecase" }}
                onClick={() => joinGuest()}
                disabled={
                  !partyChatInfo?.title ||
                  joining ||
                  (!!passwordRequired && !password.trim())
                }
              >
                {t("chats.joinParty")}
              </Button>
            )}
          </div>
        </Container>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  langButton: {
    textTransform: "none",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  profileCard: {
    marginBottom: theme.spacing(4),
  },
  cardContent: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonCancel: {
    margin: 10,
    width: 270,
  },
  buttonCreate: {
    width: 270,
    background: "#1E92CD",
  },
}));

export default JoinParty;
