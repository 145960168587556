import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ForbiddenToJoin = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const history = useHistory();

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     history.push("/");
  //   }, 5000);
  // });

  return (
    <div className={classes.screen}>
      <Typography variant="h5" color="primary">
        {t("party.forbiddenToJoinTitle")}
      </Typography>
      <div style={{ height: 20 }} />
      <Typography variant="body1">{t("party.forbiddenToJoinText")}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    marginTop: 30,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default ForbiddenToJoin;
