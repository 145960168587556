import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { AppBar, ActionBar, NavMenu, HeaderBar } from "components";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import de from "./de.json";
import en from "./en.json";
import nl from "./nl.json";
import fr from "./fr.json";
import it from "./it.json";

const getLanguageData = (lang) => {
  const langShort = lang.split("-")[0];
  switch (langShort) {
    case "en":
      return en;
    case "nl":
      return nl;
    case "fr":
      return fr;
    case "it":
      return it;
    default:
      return de;
  }
};

const ReleaseNotes = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const { t, i18n } = useTranslation();
  const releaseNotesData = getLanguageData(i18n.language);

  const releaseNotes = useSelector((state) => state.releaseNotes);
  const { HandleViewReleaseNotes } = useActions();

  React.useEffect(() => {
    document.title = `${t("general.releaseNotes")} - proWIN Messenger`;
    if (releaseNotes.lastViewedVersion >= process.env.REACT_APP_VERSION) return;
    HandleViewReleaseNotes(process.env.REACT_APP_VERSION);
  }, []);

  const renderSection = (index, title, description) => {
    return (
      <React.Fragment key={index}>
        <h2 className={classes.subHeading}>{title}</h2>
        <p className={classes.paragraph}>{description}</p>
      </React.Fragment>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="releaseNotes"
      />
      <div className={classes.screen}>
        <CssBaseline />
        <HeaderBar title={t("general.releaseNotes")} />
        <main className={classes.main}>
          {releaseNotesData.map((version) => (
            <>
              <h1 className={classes.title}>
                {version.version} - {version.releaseDate}
              </h1>
              {!!version.description && (
                <p className={classes.paragraph}>{version.description}</p>
              )}
              {version.features.map(({ title, description }, index) =>
                renderSection(index, title, description)
              )}
              {!!version.bugfixTitle && (
                <>
                  <h2 className={classes.subHeading}>{version.bugfixTitle}</h2>
                  <ul>
                    {version.bugfixes.map((bugfix, index) => (
                      <li key={index}>{bugfix}</li>
                    ))}
                  </ul>
                </>
              )}
            </>
          ))}
        </main>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0 ",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  main: {
    marginTop: -10,
    padding: 20,
    paddingTop: 10,
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
  title: {
    marginBottom: 0,
  },
  subHeading: {
    marginBottom: 0,
  },
  paragraph: {
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: "pre-wrap",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default ReleaseNotes;
