import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import createSvgIcon from "@mui/icons-material/utils/createSvgIcon";
import { copyTextToClipboard } from "src/screens/chats/helpers";

const CopyIcon = createSvgIcon(
  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />,
  "ContentCopy"
);

const ShareLinkDialog = (props, ref) => {
  const classes = useStyles();

  const { title, description, onRevoke, revokeLabel } = props;
  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState("");

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: (link) => {
      setOpen(true);
      setLink(link);
    },
  }));

  const handleClose = (e) => {
    if (e) e.preventDefault();
    setOpen(false);
  };

  const handleRevoke = (e) => {
    setOpen(false);
    onRevoke();
  };

  const textField = React.useRef();
  const [linkCopied, setLinkCopied] = React.useState(false);
  const copyLink = async () => {
    textField.current.focus();
    await copyTextToClipboard(link);
    setLinkCopied(true);
    setTimeout(setLinkCopied, 3000, false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          <TextField
            inputRef={textField}
            onFocus={() =>
              textField?.current?.setSelectionRange?.(0, link.length)
            }
            onSelect={() =>
              textField?.current?.setSelectionRange?.(0, link.length)
            }
            margin="dense"
            type="text"
            fullWidth
            value={link}
            className={classes.field}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={copyLink}
                    edge="end"
                    disabled={linkCopied}
                    size="large"
                  >
                    {!linkCopied && <CopyIcon />}
                    {linkCopied && <CheckIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          {onRevoke && (
            <Button onClick={handleRevoke} color="inherit">
              {revokeLabel}
            </Button>
          )}
          <Button type="submit" color="primary" variant="contained">
            {t("general.close")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
    maxWidth: "calc(100vw - 120px)",
  },
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(ShareLinkDialog);
