import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const HeaderButton = ({ label, icon, onClick = () => {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainButton}>
      <Button
        className={classes.button}
        variant="contained"
        onClick={onClick}
        startIcon={icon}
      >
        {label}
      </Button>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  mainButton: {
    marginLeft: 10,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  button: {
    borderRadius: "5px",
    height: "30px",
    margin: "0 10px 0 0",
    paddingTop: 8,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    fontSize: "15px",
    textTransform: "none",
    color: "rgba(30, 146, 205, 100)",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4) !important",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgb(250,250,250)",
    },

    "& .MuiButton-startIcon": {
      marginTop: -2,
    },
  },
}));
export default HeaderButton;
