import { combineReducers } from "redux";
import session from "./session";
import profile from "./profile";
import media from "./media";
import parties from "./parties";
import joinedParties from "./joinedParties";
import joinedChats from "./joinedChats";
import groupChats from "./groupChats";
import chats from "./chats";
import joinedGroupChats from "./joinedGroupChats";
import me from "./me";
import templates from "./templates";
import appliedTemplates from "./appliedTemplates";
import releaseNotes from "./releaseNotes";
import settings from "./settings";
import banner from "./banner";
import templateMessages from "./templateMessages";
import enteredTimestamp from "./enteredTimestamp";
import templateTrash from "./templateTrash";
import mediaTrash from "./mediaTrash";
import allChatTypes from "./allChatTypes";

export default combineReducers({
  session,
  profile,
  chats,
  media,
  parties,
  joinedParties,
  joinedChats,
  groupChats,
  joinedGroupChats,
  me,
  templates,
  appliedTemplates,
  releaseNotes,
  settings,
  banner,
  templateMessages,
  enteredTimestamp,
  templateTrash,
  mediaTrash,
  allChatTypes,
});
