import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Amplify from "aws-amplify";
import {
  Party,
  Callback,
  NotFound,
  Privacy,
  Impress,
  TemplateDetails,
  Profile,
  AcceptShare,
  FAQEntry,
  LandingPage,
  AddVIP,
  LoginPage,
  Claim,
  GuestProfile,
  Chats,
  TemplateNew,
  JoinParty,
} from "screens";
import { routes, guestRoutes } from "./allRoutes";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import AddGroup from "src/screens/addGroup";
import { SocketProvider } from "src/context/socket";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "eu-central-1",
    userPoolId: "eu-central-1_EnzFuLCAo",
    userPoolWebClientId: "74nqom1k7fc8o193lcld7uf15f",
  },
});

const Router = (props) => {
  const session = useSelector((state) => state.session);
  const theme = useTheme();

  const menuRoutes = session.secretKey ? guestRoutes : routes;
  const { t, i18n } = useTranslation();
  document.documentElement.setAttribute("lang", i18n.language.substring(0, 2));

  const location = useLocation();

  const [initialURL, setInitialURL] = React.useState("");

  React.useEffect(() => {
    if (!session.accessToken && !session.secretKey) {
      setInitialURL(location.pathname);
    }
  }, []);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t("general.dismissCookieNotice")}
        style={{
          background: theme.palette.primary.main,
          fontFamily: "DIN NEXT LT Pro, sans-serif",
          zIndex: 1500,
        }}
        buttonStyle={{
          background: "transparent",
          fontFamily: "DIN NEXT LT Pro, sans-serif",
          fontWeight: "500",
          color: "white",
          fontSize: "13px",
        }}
      >
        {t("general.cookieNotice")}
      </CookieConsent>
      <Switch>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route exact path="/impress">
          <Impress />
        </Route>
        <Route exact path="/login">
          <LoginPage target={initialURL} />
        </Route>
        <Route exact path="/callback">
          <Callback />
        </Route>
        {!!session.secretKey && (
          <Route exact path="/claim">
            <Claim />
          </Route>
        )}
        {(!!session.accessToken || !!session.secretKey) && (
          <Route exact path="/">
            <Redirect to={menuRoutes[0].link} />
          </Route>
        )}
        <Route exact path="/addTemplate/:shareToken">
          {!!session.accessToken && <AcceptShare menuItems={routes} />}
          {!session.accessToken && <LoginPage />}
        </Route>

        <Route exact path="/addVIP/:id">
          {!!session.accessToken || !!session.secretKey ? (
            <AddVIP />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route exact path="/addGroup/:id">
          {!!session.accessToken || !!session.secretKey ? (
            <AddGroup />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route exact path="/joinParty/:id">
          {!!session.accessToken || !!session.secretKey ? (
            <JoinParty />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route exact path="/faq/entry/:slug">
          {(!!session.accessToken || !!session.secretKey) && (
            <FAQEntry menuItems={menuRoutes} />
          )}
        </Route>
        {!!session.accessToken && (
          <Route exact path="/templates/:templateId">
            <TemplateDetails menuItems={routes} />
          </Route>
        )}
        {!!session.accessToken && (
          <Route exact path="/profiles/:profileId">
            <Profile menuItems={menuRoutes} />
          </Route>
        )}

        {menuRoutes.map((r) => (
          <Route path={r.path} key={r.id}>
            {!!session.accessToken || !!session.secretKey ? ( // VIP session.userId
              <r.screen menuItems={menuRoutes} />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        ))}

        <Route exact path="/de">
          <LandingPage lang="de" />
        </Route>
        <Route exact path="/en">
          <LandingPage lang="en" />
        </Route>
        <Route exact path="/fr">
          <LandingPage lang="fr" />
        </Route>
        <Route exact path="/it">
          <LandingPage lang="it" />
        </Route>
        <Route exact path="/nl">
          <LandingPage lang="nl" />
        </Route>
        <Route path="/templateNew">
          <TemplateNew />
        </Route>
        <Redirect exact from="/:partyId" to="/joinParty/:partyId" />
        {(!session.accessToken || !session.secretKey) && (
          <Route path="*">
            <LandingPage />
          </Route>
        )}
        <Route path="*">
          <NotFound menuItems={menuRoutes} />
        </Route>
      </Switch>
    </>
  );
};

export default Router;
