import React from "react";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const ClaimPrompt = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { hide } = props;

  return (
    <div className={classes.wrapper}>
      <span>
        {t("login.claimAccount")} &#8212;{" "}
        <Link
          style={{
            color: "inherit",
          }}
          to="/claim"
        >
          {t("login.claimBtn")}
        </Link>
      </span>
      <IconButton onClick={hide}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    fontSize: "calc(10px + (16 - 10) * ((100vw - 300px) / (1600 - 300)))",
    backgroundColor: "#F7920B",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

export default ClaimPrompt;
