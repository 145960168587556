import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const LanguageDialog = (props, ref) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const { t, i18n } = useTranslation();

  const languages = [
    {
      name: "English",
      symbol: "en",
      id: 1,
      flag: "🇬🇧",
    },
    {
      name: "Deutsch",
      symbol: "de",
      id: 2,
      flag: "🇩🇪",
    },
    {
      name: "Nederlands",
      symbol: "nl",
      id: 3,
      flag: "🇳🇱",
    },
    {
      name: "Français",
      symbol: "fr",
      id: 4,
      flag: "🇫🇷",
    },
    {
      name: "Italiano",
      symbol: "it",
      id: 5,
      flag: "🇮🇹",
    },
  ];

  React.useImperativeHandle(ref, () => ({
    open: async () => {
      setOpen(true);
    },
  }));

  const selectLang = async (lang) => {
    await i18n.changeLanguage(lang);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("general.selectLanguage")}</DialogTitle>
      <DialogContent>
        {languages.map((lang) => (
          <div
            key={lang.id}
            className={classes.langRow}
            onClick={() => selectLang(lang.symbol)}
          >
            <span>{lang.name}</span>
            <span>{lang.flag}</span>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  langRow: {
    cursor: "pointer",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
}));

export default React.forwardRef(LanguageDialog);
