const generateVersion = (image, imageCrop, size, scale) => {
  let w, h;
  if (typeof size === "number") ({ w, h } = { w: size, h: size });
  else ({ w, h } = size);
  return {
    path: `${image}?auto=compress,format&w=${w * scale}&h=${
      h * scale
    }&bg=white&fit=crop${imageCrop}`,
    scale: `${scale}x`,
  };
};

const generateImageSet = (folder, file, size, isPdf) => {
  if (!folder || !file) return;
  const image = `${process.env.REACT_APP_IMGIX_URL}/${folder}/${file}`;
  const imageCrop = isPdf ? "&crop=top,left&page=1" : "";
  const imageSet = [
    generateVersion(image, imageCrop, size, 1),
    generateVersion(image, imageCrop, size, 2),
    generateVersion(image, imageCrop, size, 3),
  ];
  return imageSet;
};

export const generateImageSourceSet = (folder, file, size, isPdf) => {
  const imageSet = generateImageSet(folder, file, size, isPdf);
  if (!imageSet) return {};
  return {
    src: imageSet[0].path,
    srcSet: imageSet.map((image) => `${image.path} ${image.scale}`).join(", "),
  };
};

export const generateFullImageSource = (folder, file, isPdf, pdfThumbnail) => {
  if (!folder || !file) return;
  const compression = isPdf ? "" : "?auto=compress,format";
  const pagePreview = !pdfThumbnail ? "" : "&page=1&bg=white";
  return `${process.env.REACT_APP_IMGIX_URL}/${folder}/${file}${compression}${pagePreview}`;
};
