import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/it";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const NewChatDialog = ({ selectedProfile, onCopy, onSubmit }, ref) => {
  const classes = useStyles();
  const [guests, setGuests] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(guests);

  const { ChatSuggestions } = useActions();

  const directChats = useSelector((state) => state.chats || []);

  React.useEffect(async () => {
    const { response } = await ChatSuggestions();
    setGuests(response?.data || []);
    if (query) changeQuery(query, [...(response?.data || []), ...directChats]);
  }, []);

  const [open, setOpen] = React.useState(false);

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const handleCopy = () => {
    setOpen(false);
    onCopy();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const applySearch = React.useCallback((item, q) => {
    const searchVal = q.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^.*" + term + ".*$");
      result = result && searchTerm.test(item.title?.toLowerCase() || "");
    }
    return result;
  }, []);

  const changeQuery = (q, list = [...guests]) => {
    setQuery(q);
    if (!q.trim()) {
      setSearchResults(list);
      return;
    }
    const results = list.filter((m) => applySearch(m, q));
    setSearchResults(results);
  };

  const goToChat = (item) => {
    setOpen(false);
    onSubmit(item.id);
  };

  const renderItem = (item, index) => {
    const { src, srcSet } = generateImageSourceSet(
      item.otherUserId || item.id,
      item.profilePic,
      40
    );
    return (
      <ListItem
        className={classes.listItem}
        onClick={() => goToChat(item)}
        key={index}
      >
        <ListItemAvatar>
          <Avatar src={src} srcSet={srcSet} />
        </ListItemAvatar>
        <ListItemText primary={item?.title} />
      </ListItem>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("chats.startNewChat")}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.search}>
          <InputBase
            className={classes.inputBase}
            placeholder={t("chats.searchGuest")}
            value={query}
            onChange={(q) => changeQuery(q.target.value)}
            style={{
              fontSize: "13px",
              color: "#000",
              width: 300,
            }}
          />
        </div>
        <List className={classes.list}>
          {!query && guests.map(renderItem)}
          {query && searchResults.map(renderItem)}
        </List>
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        {selectedProfile && onCopy && (
          <Button
            className={classes.buttonCreate}
            type="submit"
            color="primary"
            variant="contained"
            style={{ textTransform: "lowecase" }}
            onClick={handleCopy}
          >
            {t("chats.createLink")}
          </Button>
        )}
        <Button
          className={classes.buttonCancel}
          onClick={handleClose}
          color="inherit"
        >
          {t("general.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  search: {
    background: "#F3F9FC",
    borderRadius: 5,
    width: "100%",
    height: 40,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    marginBottom: 10,
  },
  inputBase: {
    margin: "10px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttonCancel: {
    margin: 10,
    width: 270,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(160,160,160,0.2)",
    },
  },
  buttonCreate: {
    width: 270,
    background: "#1E92CD",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
  },
  list: {
    padding: 0,
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
}));

export default React.forwardRef(NewChatDialog);
