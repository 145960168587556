import React from "react";
import {
  CssBaseline,
  Snackbar,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material";
import { Alert } from "@mui/material";
import {
  NavMenu,
  EditMediaDialog,
  DeleteDialog,
  FolderDialog,
  MoveToDialog,
  PhotoDialog,
  HeaderBar,
  MediaBreadCrumbs,
} from "components";
import UploadIcon from "@mui/icons-material/CloudUpload";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import MediaInfo from "mediainfo.js";
import idx from "idx";
import MediaList from "./mediaList";
import FolderList from "./folderList";
import { useTranslation } from "react-i18next";
import mime from "mime-types";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import _ from "lodash";
import short from "short-uuid";
import { useHistory } from "react-router-dom";
const translator = short();

const Media = (props) => {
  const media = useSelector((state) => state.media);
  const session = useSelector((state) => state.session);
  const mediaTrash = useSelector((state) => state.mediaTrash);

  const {
    GetUser,
    GetProfiles,
    SelectProfile,
    GetMedia,
    AddMedia,
    EditMedia,
    RemoveMedia,
    MediaTrash,
    RestoreMedia,
    ConvertMedia,
    CreateFolder,
    AddToFolder,
    CopyToFolder,
  } = useActions();
  const [path, setPath] = React.useState(
    !window.location.hash
      ? []
      : window.location.hash
          .substr(1)
          .split("/")
          .map((p) => (p === "trash" ? p : translator.toUUID(p)))
  );

  const [visibleFiles, setVisibleFiles] = React.useState();
  const [visibleFolders, setVisibleFolders] = React.useState();

  const { t } = useTranslation();
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const init = async () => {
    // await GetUser();
    if (session.accessToken) {
      await GetProfiles();
      await GetMedia();
      await MediaTrash();
    }
  };

  // const profileState = useSelector((state) => state.profile);
  // const [profiles, setProfiles] = React.useState();
  // React.useEffect(() => {
  //   setProfiles(profileState.profiles || []);
  // }, [profileState]);
  // const settings = useSelector((state) => state.settings);
  // const selectProfileDialog = React.useRef();
  // React.useEffect(() => {
  //   if (
  //     settings?.[session.userId]?.selectedProfile ||
  //     !profiles ||
  //     profiles.length === 0
  //   )
  //     return;
  //   if (profiles.length === 1) {
  //     SelectProfile(profiles[0].id);
  //     return;
  //   }
  //   selectProfileDialog.current.open();
  // }, [profiles, settings, session.userId]);
  // const submitSelectProfileDialog = (profileId) => {
  //   SelectProfile(profileId);
  // };

  const { acceptedFiles, getRootProps, getInputProps, isDragReject } =
    useDropzone({
      noDragEventsBubbling: true,
      accept: ["image/*", "video/*", "application/pdf", "audio/*"],
      maxFiles: 20,
    });

  React.useEffect(() => {
    init();
    document.title = `${t("media.library")} - proWIN Messenger`;
  }, []);

  const [showUpload, setShowUpload] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadDescription, setUploadDescription] = React.useState("");
  const [showFilesCountError, setShowFilesCountError] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState();
  const [hovered, setHovered] = React.useState(false);
  const [draggingItem, setDraggingItem] = React.useState(false);

  /* selecting files from hard drive */
  React.useEffect(() => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = ["image/*", "video/*", "application/pdf", "audio/*"];
    input.onchange = ({ target }) => {
      const { files } = target;
      let filesResult = Object.keys(files).map((key) => files[key]);
      setSelectedFiles(filesResult);
    };
    input.id = "fileSelector";
    input.style.display = "none";
    document.body.append(input);
    return () => {
      document.body.removeChild(input);
    };
  }, []);
  const selectFiles = () => {
    document.getElementById("fileSelector").click();
  };
  const cancelPhotoPreview = () => {
    document.getElementById("fileSelector").value = "";
    setSelectedFiles();
  };

  React.useEffect(() => {
    setHovered(false);
    if (acceptedFiles.length > 0) setSelectedFiles(acceptedFiles);
  }, [acceptedFiles]);

  React.useEffect(() => {
    if (!selectedFiles) return;
    if (selectedFiles.length <= 20) showUploadDialog();
    else setShowFilesCountError(true);
  }, [selectedFiles]);

  const uploadDialog = React.useRef();
  const showUploadDialog = () => {
    uploadDialog.current.open();
  };
  const submitUploadDialog = async ({ files }) => {
    let len = files.length;
    setShowUpload(true);
    for (var i = 0; i < len; i++) {
      let mediaType = files[i].type.split("/")[0];
      let mediaName = files[i].name.split(".").slice(0, -1).join(".");
      let mediaExtension = /(?:\.([^.]+))?$/.exec(files[i].name)[1];
      setUploadProgress(0);
      setUploadDescription(`${mediaName} ${t("media.uploading")}`);
      let isPortrait = false;
      let noAudio;
      let videoLength = 0;
      if (mediaType === "video" || mediaType === "image")
        mediaExtension = undefined;
      if (mediaType === "video") {
        let mediaInfo = await MediaInfo();
        const getSize = () => files[i].size;
        const readChunk = (chunkSize, offset) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              if (event.target.error) reject(event.target.error);
              resolve(new Uint8Array(event.target.result));
            };
            reader.readAsArrayBuffer(
              files[i].slice(offset, offset + chunkSize)
            );
          });
        let info = await mediaInfo.analyzeData(getSize, readChunk);
        noAudio =
          (idx(info, (_) => _.media.track) || []).filter(
            (t) => t["@type"] === "Audio"
          ).length < 1;
        let general = (idx(info, (_) => _.media.track) || []).filter(
          (t) => t["@type"] === "General"
        )[0] || { Duration: "0" };
        videoLength = parseInt(general.Duration);
        let videoTrack = (idx(info, (_) => _.media.track) || []).filter(
          (t) => t["@type"] === "Video"
        )[0] || { Width: "0", Height: "0" };
        isPortrait = parseInt(videoTrack.Height) > parseInt(videoTrack.Width);
        if (
          parseInt(videoTrack.Rotation) === 90 ||
          parseInt(videoTrack.Rotation) === 270
        )
          isPortrait = !isPortrait;
      }

      try {
        let mediaId = await new Promise((resolve, reject) => {
          let fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const { response, error } = await AddMedia(
              files[i].type,
              e.target.result,
              mediaName,
              path.join("/"),
              mediaType,
              mediaExtension,
              (e) => setUploadProgress((100 / e.total) * e.loaded)
            );
            error ? reject(error) : resolve(response);
          };
          fileReader.readAsArrayBuffer(files[i]);
        });
        if (mediaType === "video" || mediaType === "application") {
          await ConvertMedia(mediaId, isPortrait, noAudio);
          await new Promise((resolve) =>
            setTimeout(resolve, videoLength > 0 ? videoLength * 500 : 1000)
          );
        }
        setUploadDescription(`${mediaName} ${t("media.uploadSuccessful")}`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
      } catch (e) {
        setUploadDescription(`${mediaName}: ${t("media.uploadFailed")}`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
      }
    }
    await GetMedia();
  };
  const deleteDialog = React.useRef();
  const [deleteItem, setDeleteItem] = React.useState();
  const removeMedia = (e, m) => {
    e.stopPropagation();
    setDeleteItem(m);
    deleteDialog.current.open();
  };
  const submitRemove = async () => {
    if (Array.isArray(deleteItem)) {
      for (let i = 0; i < deleteItem.length; i++) {
        if (deleteItem[i]) await RemoveMedia(deleteItem[i].id);
      }
    } else {
      if (deleteItem) await RemoveMedia(deleteItem.id);
    }
    await MediaTrash();
  };

  const handleRestoreMedia = async (mediaId) => {
    await RestoreMedia(mediaId);
    await GetMedia();
  };

  const convertMedia = async (e, m) => {
    e.stopPropagation();
    setShowUpload(true);
    setUploadProgress(100);
    await ConvertMedia(m.id);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    window.location.reload();
  };

  const editMediaDialog = React.useRef();
  const [editItem, setEditItem] = React.useState();
  const editMedia = (e, m) => {
    e.stopPropagation();
    setEditItem(m);
    let name = m.title;
    editMediaDialog.current.open(name);
  };
  const submitEditDialog = async (name) => {
    await EditMedia(editItem.id, name);
    await GetMedia();
  };

  const downloadMedia = async (e, m) => {
    e.stopPropagation();
    let name = m.title;
    let uri = `${process.env.REACT_APP_BUCKET_URL}/${session.userId}/${m.id}`;
    downloadURI(uri, name);
  };
  const downloadURI = (uri, name) => {
    fetch(uri)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        let ext = mime.extension(blob.type);
        if (!ext && blob.type === "image/jpeg") ext = "jpg";
        if (!ext && blob.type === "image/jpg") ext = "jpg";
        if (!ext && blob.type === "audio/mpeg") ext = "mp3";
        if (blob.type === "audio/mpeg" && ext === "mpga") ext = "mp3";
        const filename = !ext ? name : `${name}.${ext}`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const [view, setView] = React.useState(false);

  const newFolderDialog = React.useRef();
  const showNewFolderDialog = () => {
    newFolderDialog.current.open();
  };
  const submitNewFolderDialog = async (title) => {
    await CreateFolder(title, "folder", path.join("/"));
    await GetMedia();
  };

  /* recursively move files and folders */
  const moveFileOrFolder = async (mediaId, newPath) => {
    const m = media.find((m) => m.id === mediaId);
    if (m.mediaType !== "folder") {
      // files can just be moved and that's it
      await AddToFolder(mediaId, newPath);
      return;
    }
    const oldPath = !m.path ? mediaId : `${m.path}/${mediaId}`;
    const folderContents = media.filter((m) => m.path === oldPath);
    // move the folder itself
    const { error } = await AddToFolder(mediaId, newPath);
    if (error) return; // catch if moving was prevented for whatever reason
    for (const folderItem of folderContents) {
      // move all folder contents
      await moveFileOrFolder(
        folderItem.id,
        !newPath ? mediaId : `${newPath}/${mediaId}`
      );
    }
  };

  /* recursively copy files and folders */
  const copyFiles = async (mediaId, newPath) => {
    const m = media.find((m) => m.id === mediaId);
    const oldPath = !m.path ? mediaId : `${m.path}/${mediaId}`;
    if (m.mediaType !== "folder") {
      // files can just be moved and that's it
      await CopyToFolder(
        `${session.userId}/${m.id}`,
        m.mediaType,
        newPath,
        undefined
      );
      return;
    }
    const folderContents = media.filter((m) => m.path === oldPath);
    // move the folder itself
    const { error } = await CopyToFolder(
      `${session.userId}/${m.id}`,
      m.mediaType,
      newPath,
      undefined
    );
    if (error) return; // catch if moving was prevented for whatever reason
    for (const folderItem of folderContents) {
      // move all folder contents
      await copyFiles(
        folderItem.id,
        !newPath ? mediaId : `${newPath}/${mediaId}`
      );
    }
  };

  const moveToDialog = React.useRef();
  const showMoveToDialog = (mediaId) => {
    moveToDialog.current.open(media, mediaId);
  };
  const submitMoveToDialog = async (mediaId, folderId) => {
    let newPath = [];
    if (folderId !== "home") {
      const folder = media.find((m) => m.id === folderId);
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
    }

    if (Array.isArray(mediaId)) {
      for (let i = 0; i < mediaId.length; i++) {
        await moveFileOrFolder(mediaId[i], newPath.join("/"));
      }
    } else {
      await moveFileOrFolder(mediaId, newPath.join("/"));
    }
    await GetMedia();
  };

  const copyToDialog = React.useRef();
  const showCopyToDialog = (mediaId) => {
    copyToDialog.current.open(media, mediaId);
  };
  const submitCopyToDialog = async (mediaId, folderId) => {
    let newPath = [];
    if (folderId !== "home") {
      const folder = media.find((m) => m.id === folderId);
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
    }

    if (Array.isArray(mediaId)) {
      for (let i = 0; i < mediaId.length; i++) {
        await copyFiles(mediaId[i], newPath.join("/"));
      }
    } else {
      await copyFiles(mediaId, newPath.join("/"));
    }
    await GetMedia();
  };

  const drop = async (e, folderId) => {
    e.preventDefault();
    var data = e.dataTransfer.getData("text");
    let newPath = [];
    if (folderId !== "home") {
      const folder = media.find((m) => m.id === folderId);
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
    }

    const files = data.split(",");
    for (let i = 0; i < files.length; i++) {
      await moveFileOrFolder(files[i], newPath.join("/"));
    }

    await GetMedia();
  };
  const drag = (e, multiIds) => {
    setDraggingItem(true);
    if (multiIds && multiIds.includes(e.target.id)) {
      e.dataTransfer.setData("text", multiIds);
    } else {
      e.dataTransfer.setData("text", e.target.id);
    }
  };
  const dragEnd = () => {
    setDraggingItem(false);
  };

  const openFolder = (folder) => {
    window.location.hash = [
      ...(folder.path ? folder.path.split("/") : []),
      folder.id,
    ]
      .map((p) => translator.fromUUID(p))
      .join("/");
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };
  const openTrash = () => {
    window.location.hash = "trash";
    setPath(["trash"]);
    MediaTrash();
  };

  const getMediaInPath = () => {
    if (!path || path.length === 0) {
      setVisibleFolders(
        media.filter((m) => m.mediaType === "folder" && !m.path)
      );
      setVisibleFiles(media.filter((m) => m.mediaType !== "folder" && !m.path));
    } else {
      if (path[0] === "trash") {
        const onlyFolders = mediaTrash.filter((m) => m.mediaType === "folder");
        const sortedTrashFolders = onlyFolders.sort(
          (a, b) => b.trashedAt - a.trashedAt
        );
        setVisibleFolders(sortedTrashFolders);

        const onlyFiles = mediaTrash.filter((m) => m.mediaType !== "folder");
        const sortedTrashFiles = onlyFiles.sort(
          (a, b) => b.trashedAt - a.trashedAt
        );
        setVisibleFiles(sortedTrashFiles);
      } else {
        setVisibleFolders(
          media.filter(
            (m) => m.mediaType === "folder" && m.path === path.join("/")
          )
        );
        setVisibleFiles(
          media.filter(
            (m) => m.mediaType !== "folder" && m.path === path.join("/")
          )
        );
      }
    }
  };
  React.useEffect(() => {
    getMediaInPath();
  }, [path, media]);

  const history = useHistory();

  React.useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH" || history.action === "POP") {
        setPath(
          location.hash
            ? location.hash
                .substring(1)
                .split("/")
                .map((p) => translator.toUUID(p))
            : []
        );
      }
    });
  }, []);

  const handleSetPath = (folderId) => {
    setSearch();
    if (folderId === "home" || !folderId) {
      history.replace(window.location.pathname);
      setPath([]);
      return;
    }
    const folder = media.find((m) => m.id === folderId);
    window.location.hash = [
      ...(folder.path ? folder.path.split("/") : []),
      folder.id,
    ]
      .map((p) => translator.fromUUID(p))
      .join("/");
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };

  // Search functionality
  const [search, setSearch] = React.useState("");
  const applySearch = (item) => {
    const searchVal = search.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result = result && searchTerm.test(item.title.toLowerCase());
    }
    return result;
  };
  React.useEffect(() => {
    if (!search) {
      getMediaInPath();
      return;
    }
    setVisibleFiles(
      media.filter((m) => m.mediaType !== "folder").filter(applySearch)
    );
    setVisibleFolders(
      media.filter((m) => m.mediaType === "folder").filter(applySearch)
    );
  }, [search]);

  const orderByFolders = () => {
    if (search && visibleFiles) {
      let files = _.cloneDeep(visibleFiles);
      for (let f of files) {
        if (!f.path) {
          f.path = "home";
        }
        if (f.path.includes("/")) {
          f.path = f.path.slice(f.path.lastIndexOf("/") + 1);
        }
      }
      let orderedSearch = _.groupBy(files, "path");

      return Object.keys(orderedSearch).map((key) => {
        if (!key || key === null) {
          orderedSearch["home"] = orderedSearch[key];
          delete orderedSearch[key];
        }
        let file = media.find((m) => m.id === key);
        return (
          <div>
            <Button
              className={classes.pathButton}
              size="small"
              onClick={() => handleSetPath(key)}
            >
              {(file && file.title) || t("media.homeTitle")}
            </Button>
            <MediaList
              media={orderedSearch[key]}
              session={session}
              removeMedia={removeMedia}
              editMedia={editMedia}
              downloadMedia={downloadMedia}
              moveTo={showMoveToDialog}
              drag={drag}
              dragEnd={dragEnd}
              convertMedia={convertMedia}
            />
          </div>
        );
      });
    }
  };

  const headerButtons = [
    {
      label: `${t("general.upload")}`,
      icon: <UploadIcon />,
      onClick: selectFiles,
    },
    {
      label: `${t("media.folderDialogTitle")}`,
      icon: <CreateNewFolderIcon />,
      onClick: showNewFolderDialog,
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="media"
        setPath={setPath}
      />
      <div
        className={clsx(classes.screen, { [classes.screenHovered]: hovered })}
        onDragEnter={draggingItem ? undefined : () => setHovered(true)}
      >
        <CssBaseline />
        {hovered && (
          <div
            {...getRootProps({
              className: clsx("dropzone", classes.dropzone, {
                [classes.dropzoneReject]: isDragReject,
              }),
              onDragLeave: () => setHovered(false),
            })}
          >
            <UploadIcon />
            <span>{t("general.uploadDrop")}</span>
            <input {...getInputProps()} />
          </div>
        )}
        <HeaderBar
          title={t("media.library")}
          buttons={headerButtons}
          searchChange={setSearch}
          search={search}
        />
        <MediaBreadCrumbs
          path={path}
          media={media}
          setNewPath={handleSetPath}
          drop={drop}
          pathHomeLabel={t("media.homeTitle")}
        />

        {/* {!folderOpened && */}
        <div className={classes.main}>
          {!!visibleFolders && visibleFolders.length > 0 && (
            <FolderList
              session={session}
              media={visibleFolders}
              removeMedia={removeMedia}
              editMedia={editMedia}
              moveTo={showMoveToDialog}
              drop={drop}
              drag={drag}
              dragEnd={dragEnd}
              openFolder={openFolder}
              openTrash={path.length === 0 ? openTrash : undefined}
              isTrash={path?.[0] === "trash"}
              restoreMedia={handleRestoreMedia}
            />
          )}
          {!!visibleFiles && !search && (
            <MediaList
              media={visibleFiles}
              session={session}
              removeMedia={removeMedia}
              editMedia={editMedia}
              downloadMedia={downloadMedia}
              moveTo={showMoveToDialog}
              copyTo={showCopyToDialog}
              drag={drag}
              dragEnd={dragEnd}
              convertMedia={convertMedia}
              isTrash={path?.[0] === "trash"}
              restoreMedia={handleRestoreMedia}
            />
          )}
          {orderByFolders()}
        </div>
        <PhotoDialog
          ref={uploadDialog}
          onSubmit={submitUploadDialog}
          onCancel={cancelPhotoPreview}
          selectedFiles={selectedFiles}
          upload={true}
        />
        <EditMediaDialog ref={editMediaDialog} onSubmit={submitEditDialog} />
        <DeleteDialog ref={deleteDialog} type="media" onSubmit={submitRemove} />
        <FolderDialog ref={newFolderDialog} onSubmit={submitNewFolderDialog} />
        <MoveToDialog ref={moveToDialog} onSubmit={submitMoveToDialog} />
        <MoveToDialog ref={copyToDialog} onSubmit={submitCopyToDialog} />
        {/* <SelectProfileDialog
          ref={selectProfileDialog}
          onSubmit={submitSelectProfileDialog}
          profiles={profiles}
        /> */}
        <Snackbar
          open={showUpload}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert severity={uploadProgress === 100 ? "success" : "info"}>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              color="primary"
              className={classes.uploadProgress}
            />
            <Typography>{uploadDescription}</Typography>
          </Alert>
        </Snackbar>
        <Snackbar
          open={showFilesCountError}
          autoHideDuration={5000}
          onClose={() => setShowFilesCountError(false)}
        >
          <Alert
            onClose={() => setShowFilesCountError(false)}
            severity="warning"
          >
            <Typography>{t("media.filesUploadLimit")}</Typography>
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0 ",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  screenHovered: {
    ["& *"]: {
      pointerEvents: "none",
    },
  },
  main: {
    padding: 20,
    paddingTop: 10,
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
  heading: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: 18,
    marginTop: 15,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  uploadProgress: {
    width: 300,
    height: 20,
    borderRadius: 5,
  },
  actionBar: {
    display: "flex",
    justifyContent: "flex-start",
  },
  dropzone: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "dashed",
    alignItems: "center",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    opacity: "80%",
    zIndex: 10000,
    pointerEvents: "auto",
  },
  dropzoneReject: {
    backgroundColor: "rgb(255, 180, 180)",
    borderColor: "red",
  },
  pathButton: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "#333",
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 30,
    "& span": {
      pointerEvents: "none",
    },
    justifyContent: "flex-start",
    margin: `${theme.spacing(1)} 0`,
  },
}));

export default Media;
