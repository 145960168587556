import moment from "moment";
import { useTranslation } from "react-i18next";

export const useHandleTimestamp = () => {
  const { t } = useTranslation();
  return (timestamp) => {
    if (timestamp === undefined) return null;
    if (moment(timestamp).isSame(moment(), "d")) {
      return moment(timestamp).format("HH:mm");
    }
    if (
      moment(timestamp).isSame(moment().subtract(1, "days").startOf("day"), "d")
    ) {
      return `${t("general.yesterday")}`;
    }
    if (
      moment(timestamp).isSameOrBefore(
        moment().subtract(1, "years").startOf("day"),
        "d"
      )
    ) {
      return moment(timestamp).format(t("general.dateFormat"));
    }
    return moment(timestamp).format(t("general.dateFormatShort"));
  };
};
