import * as React from "react";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@mui/styles";
import { ListItemText } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";

const TemplateContent = ({ name, handleClick, key }) => {
  const classes = useStyles();

  return (
    <div>
      <ListItem
        key={key}
        className={classes.contentWrapper}
        onClick={handleClick}
      >
        <FolderOpenIcon
          style={{ margin: "10px 10px 10px 0", color: "#333E48" }}
        />
        <div>
          <ListItemText
            key={key}
            className={classes.maintemplateContent}
            primary={name}
          />
        </div>
      </ListItem>
      <ListItem
        key={key}
        className={classes.contentWrapper}
        onClick={handleClick}
      >
        <ScatterPlotIcon
          style={{ margin: "10px 10px 10px 0", color: "#333E48" }}
        />
        <div>
          <ListItemText
            key={key}
            className={classes.maintemplateContent}
            primary={name}
          />
        </div>
      </ListItem>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    margin: 10,
    width: 330,
    background: "white",
    padding: "0 12px 0 16px !important",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(160,160,160,0.2)",
    },
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },

  maintemplateContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export default TemplateContent;
