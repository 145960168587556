/* eslint-disable no-useless-computed-key */
/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { isIOS, isAndroid } from "react-device-detect";
import {
  IconButton,
  Card,
  Button,
  useMediaQuery,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  Alert,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/CloudUpload";
import SendingIcon from "@mui/icons-material/Schedule";
import FailedIcon from "@mui/icons-material/ErrorOutline";
import {
  VoiceDialog,
  PhotoDialog,
  MediaDialog,
  SendProfileDialog,
  PartyLoading,
  MoveToDialog,
  MoveToChatDialog,
  RemoveMessageDialog,
  ForwardMessagesDialog,
  ChatBar,
  UploadProgress,
  NewMessageButton,
  ImageCarousel,
  MediaPreview,
  MobilePopUp,
  preventSimultaneousPlayback,
  ChatListInfo,
  MoveToTemplateDialog,
} from "components";
import { insertFormatting } from "src/utils/insertFormatting";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import clsx from "clsx";
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";
import Linkify from "react-linkify";
import LinkifyIt from "linkify-it";
import tlds from "tlds";
import { ReactTinyLink } from "react-tiny-link";
import { subscribeUser } from "./notifications";
import EmojiPicker from "emoji-picker-react";
import "draft-js/dist/Draft.css";
import { usePageVisibility } from "react-page-visibility";
import Ellipsis from "react-lines-ellipsis";
import MediaInfo from "mediainfo.js";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import Snackbar from "@mui/material/Snackbar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Checkbox from "@mui/material/Checkbox";

import {
  useEmit,
  useGuestRemoved,
  useIAmOnline,
  useNewMessage,
  useReloadMessages,
  useSocket,
  useUpdatedMessage,
  useUserJoined,
  useUserLeft,
} from "./../../context/socket";
import { useHandleTimestamp } from "src/utils/handleTimestamp.util";
import useForwardMessages from "./useForwardMessage";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const DirectChatView = (props) => {
  const { t, i18n } = useTranslation();
  const {
    showChatInfo,
    setShowChatInfo,
    headerBackButton,
    refreshList,
    unreadMessages,
  } = props;
  const classes = useStyles();
  const isVisible = usePageVisibility();

  const { partyId } = useParams();

  const handleTimestamp = useHandleTimestamp();

  const {
    GetMedia,
    AddMedia,
    ConvertMedia,
    CopyToFolder,
    PartyGuests,
    Subscribe,
    SendMessage,
    SendMessageAgain,
    RemoveDirectMessages,
    RemoveDirectMessage,
    HandleDirectMessage,
    DirectChatTimestamp,
    LatestDirectChat,
    SendDirectMessage,
    ArchiveChat,
    UnarchiveChat,
    GetChatList,
    HandleUpdatedDirectMessage,
  } = useActions();

  //Selectors

  const session = useSelector((state) => state.session);

  const settings = useSelector((state) => state.settings);
  const [profile, setProfile] = React.useState({});

  const currentProfile = useSelector((state) => state.profile);

  const allChatTypes = useSelector((state) => state.allChatTypes);

  const chat = allChatTypes.find((c) => c.chatId === partyId) || {};

  const me = useSelector(
    (state) => state.session?.id || state.session?.userId || ""
  );

  const media = useSelector((state) => state.media || []);

  const userId = useSelector((state) =>
    state.session.secretKey ? state.session.id : state.session.userId
  );

  const userType = useSelector((state) =>
    state.session.secretKey ? "guest" : "user"
  );

  const templates = useSelector((state) => state.templates);

  const chats = useSelector((state) => state.chats || {});

  const history = useHistory();

  const socketEmit = useEmit();

  const sortedMessages = useSelector(
    (state) => state.joinedChats[partyId]?.messages || []
  );

  const guests = useSelector(
    (state) => state.joinedChats[partyId]?.guests || []
  );

  const mediaMessages = useSelector(
    (state) =>
      state.joinedChats[partyId]?.messages?.filter(
        (m) =>
          m?.messageType === "photo" ||
          (m.mediaAttachmentType?.trim() && m.mediaAttachmentType !== "audio")
      ) || []
  );

  const [loading, setLoading] = React.useState(!chat?.title);
  React.useEffect(async () => {
    if (!loading) return;
    const { response } = await GetChatList();
    const thisChat = response?.data?.find((c) => c.chatId === partyId);
    if (!thisChat) history.push("/chats");
    setLoading(false);
  }, [loading, partyId]);

  //Chat info
  const [chatInfo, setChatInfo] = React.useState();
  const handleOpenChatInfo = () => {
    setShowChatInfo(true);
  };
  const handleCloseChatInfo = () => {
    setShowChatInfo(false);
  };

  preventSimultaneousPlayback(); //For Audio files

  const [appDeclined, setAppDeclined] = React.useState(false);

  const [showEmojis, setShowEmojis] = React.useState(false);
  const onEmojiClick = (event, { emoji }) => {
    let {
      selectionStart,
      selectionEnd,
      value: oldMessage,
    } = document.getElementById("chatInput");
    var newMessage =
      oldMessage.slice(0, selectionStart) +
      emoji +
      oldMessage.slice(selectionEnd);
    inputRef.current.setMessage(newMessage);
    setTimeout(() => {
      inputRef.current.setSelectionRange(
        selectionStart + emoji.length,
        selectionStart + emoji.length
      );
    }, 100);
  };
  const { acceptedFiles, getRootProps, getInputProps, isDragReject } =
    useDropzone({
      noDragEventsBubbling: true,
      accept:
        me === chatInfo?.userId
          ? ["image/*", "video/*", "audio/*", "application/pdf"]
          : ["image/*"],
      maxFiles: 10,
    });

  const inputRef = React.useRef();
  /* load party on start */
  const [messagesLoading, setMessagesLoading] = React.useState(true);
  const [isChatArchived, setIsChatArchived] = React.useState(false);

  React.useEffect(() => {
    if (!chats?.length) return;
    setChatInfo(chats?.find((c) => c.chatId === partyId));
  }, [partyId, chats]);

  const loadDirectChat = async () => {
    setMessagesLoading(true);
    let { response, error } = await LatestDirectChat(partyId);
    if (response?.data?.length > 0) setShowMessageIndicator(true);
    setMessagesLoading(false);
    DirectChatTimestamp(partyId);
    await initDirectChat(response?.data);
    handleMessageIndicator();
    checkChatStatus();
  };

  const updateDirectChat = async () => {
    const lastMessageTimestamp = _.last(sortedMessages)?.timestamp;
    await LatestDirectChat(partyId, lastMessageTimestamp);
  };

  const checkChatStatus = async () => {
    for (const item of chats) {
      if (item.archived && item.chatId === partyId) {
        setIsChatArchived(true);
      }
    }
  };

  React.useEffect(() => {
    loadDirectChat();
    setShowEmojis(false);
  }, [partyId]);

  React.useEffect(() => {
    if (!currentProfile) return;
    const selectedProfile =
      currentProfile?.profiles?.find(
        (p) => p.id === settings[session.userId].selectedProfile
      ) || {};
    setProfile(selectedProfile);
  }, []);

  /* new message indicator */
  const messageList = React.useRef();
  let dividerRef = React.useRef(null);
  const [showMessageIndicator, setShowMessageIndicator] = React.useState(false);
  const handleMessageIndicator = (message = null) => {
    if (!me) return;
    if (!messageList.current) return;
    if (dividerRef.current) dividerRef.current.scrollIntoView();
    else {
      messageList.current.scrollTo({
        top: messageList.current.scrollHeight,
        behaviour: "smooth",
      });
      setShowMessageIndicator(false);
    }
  };

  /* bulk menu */
  const [globalAnchorEl, setGlobalAnchorEl] = React.useState();
  const [globalChatMenuOpen, setGlobalChatMenuOpen] = React.useState(false);
  const [bulkDelete, setBulkDelete] = React.useState(false);
  const [bulkForward, setBulkForward] = React.useState(false);
  const [selectMode, setSelectMode] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [mediaSelected, setMediaSelected] = React.useState(false);
  const [forwardStatus, setForwardStatus] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  /* message context menu */
  const [anchorEl, setAnchorEl] = React.useState();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedMessage, setSelectedMessage] = React.useState();
  const [selectedReply, setSelectedReply] = React.useState();
  const [sendingMedia, setSendingMedia] = React.useState();
  const [selectedFiles, setSelectedFiles] = React.useState();
  const [hovered, setHovered] = React.useState(false);

  /* selecting files from hard drive */
  React.useEffect(() => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept =
      session?.userId === chatInfo?.userId
        ? ["image/*", "video/*", "audio/*", "application/pdf"]
        : ["image/*"];
    input.oninput = ({ target }) => {
      const { files } = target;
      let filesResult = Object.keys(files).map(
        (key, index) => files[(key, index)]
      );
      setSelectedFiles(filesResult);
    };
    input.id = "directFileSelector";
    input.style.display = "none";
    document.body.append(input);
    return () => {
      document.body.removeChild(input);
    };
  }, []);

  const selectFiles = () => {
    setShowEmojis(false);
    document.getElementById("directFileSelector").click();
  };

  const cancelPhotoPreview = () => {
    document.getElementById("directFileSelector").value = "";
    setSelectedFiles();
  };

  React.useEffect(() => {
    setHovered(false);
    if (acceptedFiles.length > 0) setSelectedFiles(acceptedFiles);
  }, [acceptedFiles]);

  React.useEffect(() => {
    if (selectedFiles) {
      showPhotoDialog();
    }
  }, [selectedFiles]);

  const handleMenuOpen = (event, message) => {
    if (!message?.id) return;
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setSelectedMessage(message);
  };
  const handleMenuClose = () => {
    setAnchorEl();
    setMenuOpen(false);
    setSelectedMessage();
  };

  const startReplyMessage = () => {
    if (!selectedMessage) return;
    setSelectedReply(selectedMessage);
    setMenuOpen(false);
  };
  const cancelReply = () => {
    if (sending || !me) return;
    setSelectedReply();
  };
  const cancelMediaSending = () => {
    if (sending || !me) return;
    setSendingMedia();
  };

  /* bulk select operations*/

  const handleGlobalChatMenuOpen = (event) => {
    setShowEmojis(false);
    setGlobalAnchorEl(event.currentTarget);
    setGlobalChatMenuOpen(true);
  };
  const handleGlobalChatMenuClose = () => {
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleForwardMessage = () => {
    setBulkForward(true);
    setSelectMode(true);
    setSelectedIds([selectedMessage?.id]);
    setSelectedMessages([selectedMessage]);
    handleMenuClose();
  };

  const handleBulkDelete = () => {
    setBulkDelete(true);
    setSelectMode(true);
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleBulkForward = () => {
    setBulkForward(true);
    setSelectMode(true);
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleCancelBulk = () => {
    setBulkDelete(false);
    setBulkForward(false);
    setSelectMode(false);
    setSelectedIds([]);
    setSelectedMessages([]);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const [selectedMessages, setSelectedMessages] = React.useState([]);

  const onMessageSelect = (item, e) => {
    if (selectedIds.includes(item.id))
      setSelectedIds((selectedIds) =>
        selectedIds.filter((id) => id !== item.id)
      );
    else setSelectedIds((selectedIds) => [...selectedIds, item.id]);

    if (selectedMessages.includes(item)) {
      setSelectedMessages((selectedMessages) =>
        selectedMessages.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      setSelectedMessages((selectedMessages) => [...selectedMessages, item]);
    }
  };

  /* forward messages */
  const forwardMessagesDialog = React.useRef();
  const showForwardMessagesDialog = () => {
    let nonMediaMessages = [];
    for (const message of selectedMessages) {
      if (
        !message.mediaAttachmentType &&
        message.messageType !== "photo" &&
        message.messageType !== "video" &&
        message.messageType !== "audio"
      ) {
        nonMediaMessages.push(message);
      }
    }
    if (nonMediaMessages.length > 0) {
      setMediaSelected(false);
    } else {
      setMediaSelected(true);
    }
    setMenuOpen(false);
    forwardMessagesDialog.current.open();
  };

  const submitMoveToLibrary = async () => {
    showCopyToDialog(selectedMessages);
  };
  const submitMoveToTemplate = async () => {
    showCopyToTemplateDialog(selectedMessages);
  };
  const submitMoveToChat = async () => {
    showCopyToChatDialog(selectedMessages);
  };

  // Dialog to select a CHAT where a message should be copied
  // TODO: move into a cutstom hook?
  const copyToChatDialog = React.useRef();
  const showCopyToChatDialog = (mediaItems) => {
    copyToChatDialog.current.open(allChatTypes, mediaItems);
  };

  const forwardMessages = useForwardMessages();

  const submitCopyToChatDialog = async (mediaItems, selectedChat, chatType) => {
    await forwardMessages(mediaItems, selectedChat, chatType);
    refreshList();
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setSelectMode(false);
  };

  // Dialog to select a TEMPLATE where a message should be copied
  const copyToTemplateDialog = React.useRef();
  const showCopyToTemplateDialog = (mediaItems) => {
    copyToTemplateDialog.current.open(templates, mediaItems);
  };
  const submitCopyToTemplateDialog = () => {
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setSelectMode(false);
  };

  // Dialog to selected a MEDIA FOLDER where an attachment should be copied
  const copyToDialog = React.useRef();
  const showCopyToDialog = (mediaItems) => {
    copyToDialog.current.open(media, mediaItems);
  };
  const submitCopyToDialog = async (mediaItems, folderId) => {
    let newPath = [];
    const folder = media.find((m) => m.id === folderId);
    for (let i = 0; i < mediaItems.length; i++) {
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
      await copyFiles(mediaItems[i], newPath.join("/"));
    }
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setSelectMode(false);
  };

  const copyFiles = async (mediaItem, newPath) => {
    let { response, error } = await CopyToFolder(
      mediaItem.fromTemplate
        ? `${session.userId}/${mediaItem.mediaAttachment}`
        : `${mediaItem.partyId}/${mediaItem.message}`,
      mediaItem.mediaAttachmentType
        ? mediaItem.mediaAttachmentType
        : mediaItem.messageType,
      newPath,
      mediaItem.title ? mediaItem.title : null
    );
    if (!error) {
      setForwardStatus("Success!");
      setSnackBarOpen(true);
    }
  };

  /* delete message */
  const removeMessageDialog = React.useRef();
  const showRemoveMessageDialog = () => {
    setMenuOpen(false);
    removeMessageDialog.current.open();
  };
  const submitRemoveMessageDialog = async () => {
    if (bulkDelete) {
      let { response, error } = await RemoveDirectMessages(
        partyId,
        selectedIds
      );
      if (!error)
        for (const msg of response.data) {
          socketEmit("updateMessage", { partyId, message: msg });
        }
      setBulkDelete(false);
      setSelectMode(false);
      setSelectedMessages([]);
      setSelectedIds([]);
    } else {
      if (!selectedMessage?.id) return;
      let { response, error } = await RemoveDirectMessage(
        partyId,
        selectedMessage.id
      );
      if (!error)
        socketEmit("updateMessage", { partyId, message: response.data });
    }
    handleMenuClose();
  };

  /* initialize party */
  const [removed, setRemoved] = React.useState(false);

  const initDirectChat = async (party) => {
    if (!party) return;
    if (messageList.current)
      messageList.current.scrollTo({
        top: messageList.current.scrollHeight,
        behaviour: "auto",
      });
    if (session && session.userId) {
      let userIsSender = false;
      for (const item of party) {
        if (item.sender === session.userId) {
          userIsSender = true;
        }
      }
      if (userIsSender) {
        await GetMedia();
        await PartyGuests(partyId, null);
      }
    }
  };

  /* Play sound when new message arrives and not in focus */
  const [newMessages, dispatchNewMessages] = React.useReducer(
    (state, action) => (action.type === "inc" ? state + 1 : 0),
    0
  );
  React.useEffect(() => {
    if (!isVisible) return;
    if (newMessages > 0) DirectChatTimestamp(partyId);
    dispatchNewMessages({ type: "reset" });
    updateDirectChat();
  }, [isVisible, newMessages]);

  React.useEffect(() => {
    if (!chatInfo?.title) return;
    document.title =
      (unreadMessages > 0 ? `(${unreadMessages}) ` : "") + chatInfo.title;
  }, [unreadMessages, chatInfo?.title]);

  /* socket functions */
  const [onlineUsers, setOnlineUsers] = React.useState({});
  const registerPush = async () => {
    const subscription = await subscribeUser();
    if (subscription) await Subscribe(partyId, me, subscription);
  };
  React.useEffect(() => {
    registerPush();
  }, [me]);

  const socket = useSocket();

  React.useEffect(() => {
    if (!socket?.connected) return;
    socketEmit("join", {
      partyId,
      guestId: me,
    });
  }, [socket?.connected]);

  useUserJoined(({ partyId: pId, guestId, socketId }) => {
    if (partyId !== pId) return;
    setOnlineUsers((onlineUsers) => {
      if (!Array.isArray(onlineUsers[guestId])) onlineUsers[guestId] = [];
      onlineUsers[guestId].push(socketId);
      return { ...onlineUsers };
    });
    socketEmit("iAmOnline", {
      recipientId: socketId,
      partyId,
      guestId: me,
    });
  });

  useIAmOnline(({ partyId: pId, guestId, socketId }) => {
    if (pId !== partyId) return;
    setOnlineUsers((onlineUsers) => {
      if (!Array.isArray(onlineUsers[guestId])) onlineUsers[guestId] = [];
      onlineUsers[guestId].push(socketId);
      return { ...onlineUsers };
    });
  });

  useUserLeft(({ partyId: pId, socketId }) => {
    if (pId !== partyId) return;
    setOnlineUsers((onlineUsers) => {
      for (const guestId in onlineUsers) {
        if (!Array.isArray(onlineUsers[guestId])) onlineUsers[guestId] = [];
        onlineUsers[guestId] = onlineUsers[guestId].filter(
          (sid) => sid !== socketId
        );
      }
      return { ...onlineUsers };
    });
  });

  const updateSeen = React.useCallback(() => {
    if (isVisible) DirectChatTimestamp(partyId);
  }, [isVisible]);

  useNewMessage((message) => {
    if (message?.partyId !== partyId) return;
    refreshList().then(updateSeen);
    // TODO: handle notification
    const myMessage = message.messageInfo.sender === me;
    const showIndicator =
      !myMessage &&
      messageList?.current?.scrollTop <
        messageList?.current?.scrollHeight - messageList?.current?.clientHeight;
    HandleDirectMessage(partyId, message.messageInfo);
    if (showIndicator) setShowMessageIndicator(true);
    else if (!myMessage) setTimeout(() => handleMessageIndicator(message), 200);
    if (!isVisible) {
      dispatchNewMessages({ type: "inc" });
    }
  });

  useUpdatedMessage((message) => {
    if (message?.partyId !== partyId) return;
    HandleUpdatedDirectMessage(partyId, message);
  });

  useReloadMessages(({ partyId: pId }) => {
    if (pId !== partyId) return;
    LatestDirectChat(partyId);
  });

  useGuestRemoved(({ partyId: pId, guestId }) => {
    if (pId !== partyId) return;
    if (guestId === me) setRemoved(true);
  });

  const allAudio = (mediaArray) => {
    for (let i = 0; i < mediaArray.length; i++) {
      if (mediaArray[i].mediaType !== "audio") return false;
    }
    return true;
  };

  const handleMediaDescriptions = async (mediaArray) => {
    if (allAudio(mediaArray)) await submitMediaDialog(mediaArray);
    else setSendingMedia(mediaArray);
  };

  const handleArchiveChat = async () => {
    //TODO add chat archived confirmation
    await ArchiveChat(partyId);
    socketEmit("guestUpdate", { partyId });
    history.push("/chats");
  };

  const handleUnarchiveChat = async () => {
    await UnarchiveChat(partyId);
    socketEmit("guestUpdate", { partyId });
    setGlobalChatMenuOpen(false);
    setIsChatArchived(false);
  };

  /* voice message dialog */
  const voiceDialog = React.useRef();
  const showVoiceDialog = () => {
    setShowEmojis(false);
    voiceDialog.current.open();
  };
  const submitVoiceDialog = async (audioBlob) => {
    if (sending || !me) return;
    setSending(true);
    let replyingTo;
    if (selectedReply) replyingTo = selectedReply.id;
    let { response, error } = await SendDirectMessage(
      partyId,
      false,
      replyingTo,
      me,
      "voice",
      audioBlob,
      undefined,
      undefined,
      undefined,
      audioBlob.type
    );
    if (error) {
      setSending(false);
      return;
    }
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
    setSending(false);
    setSelectedReply();
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  /* photo message dialog */
  const photoDialog = React.useRef();
  const showPhotoDialog = () => {
    photoDialog.current.open();
  };
  const submitPhotoDialogChoice = (submission) => {
    if (submission.saveToLibrary) submitUploadDialog(submission.files);
    else submitPhotoDialog(submission.files);
  };

  const submitPhotoDialog = async (files) => {
    if (sending || !me) return;
    setSending(true);
    for (const file of files) {
      if (me && !file.type.startsWith("image")) {
        setShowUpload(true);
        setUploadProgress(-1);
        setUploadDescription(`${file.name} ist kein Bild.`);
        setTimeout(() => {
          setShowUpload(false);
          setUploadProgress(0);
          setUploadDescription("");
        }, 3000);
        continue;
      }
      let replyingTo;
      if (selectedReply) replyingTo = selectedReply.id;
      let { response, error } = await SendDirectMessage(
        partyId,
        false,
        replyingTo,
        session.id,
        file.type.startsWith("audio") ? "voice" : "photo",
        file,
        file.type
      );
      if (error) {
        setSending(false);
        return;
      }
      socketEmit("sendMessage", {
        partyId,
        message: { messageInfo: response.data, senderName: me.firstName },
      });
    }
    setSending(false);
    setSelectedReply();
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  const [showUpload, setShowUpload] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadDescription, setUploadDescription] = React.useState("");

  const submitUploadDialog = async (files) => {
    let len = files.length;
    let mediaFiles = [];
    setShowUpload(true);
    for (var i = 0; i < len; i++) {
      let mediaType = files[i].type.split("/")[0];
      let mediaName = files[i].name.split(".").slice(0, -1).join(".");
      let mediaExtension = /(?:\.([^.]+))?$/.exec(files[i].name)[1];
      setUploadProgress(0);
      setUploadDescription(`${mediaName} wird hochgeladen...`);
      let isPortrait = false;
      let noAudio;
      let videoLength = 0;
      if (mediaType === "video" || mediaType === "image")
        mediaExtension = undefined;
      if (mediaType === "video") {
        let mediaInfo = await MediaInfo();
        const getSize = () => files[i].size;
        const readChunk = (chunkSize, offset) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              if (event.target.error) reject(event.target.error);
              resolve(new Uint8Array(event.target.result));
            };
            reader.readAsArrayBuffer(
              files[i].slice(offset, offset + chunkSize)
            );
          });
        let info = await mediaInfo.analyzeData(getSize, readChunk);
        noAudio =
          (info?.media?.track || []).filter((t) => t["@type"] === "Audio")
            .length < 1;
        let general = (info?.media?.track || []).filter(
          (t) => t["@type"] === "General"
        )[0] || { Duration: "0" };
        videoLength = parseInt(general.Duration);
        let videoTrack = (info?.media?.track || []).filter(
          (t) => t["@type"] === "Video"
        )[0] || { Width: "0", Height: "0" };
        isPortrait = parseInt(videoTrack.Height) > parseInt(videoTrack.Width);
        if (
          parseInt(videoTrack.Rotation) === 90 ||
          parseInt(videoTrack.Rotation) === 270
        )
          isPortrait = !isPortrait;
      }
      try {
        let mediaId = await new Promise((resolve, reject) => {
          let fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const { response, error } = await AddMedia(
              files[i].type,
              e.target.result,
              mediaName,
              null,
              mediaType,
              mediaExtension,
              (e) => setUploadProgress((100 / e.total) * e.loaded)
            );
            error ? reject(error) : resolve(response);
          };
          fileReader.readAsArrayBuffer(files[i]);
        });
        if (mediaType === "video" || mediaType === "application") {
          setUploadDescription(`${mediaName} wird konvertiert...`);
          await ConvertMedia(mediaId, isPortrait, noAudio);
          await new Promise((resolve) =>
            setTimeout(resolve, videoLength > 0 ? videoLength * 500 : 1000)
          );
        }
        setUploadDescription(`${mediaName} erfolgreich hochgeladen!`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
        let m = {
          id: mediaId,
          title: mediaName,
          mediaType: mediaType,
          mediaExtension: mediaExtension,
        };
        mediaFiles = [...(mediaFiles || []), m];
      } catch (e) {
        setUploadDescription(`${mediaName}: Hochladen fehlgeschlagen.`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
      }
    }
    handleMediaDescriptions(mediaFiles);
  };

  /* media message dialog */
  const mediaDialog = React.useRef();
  const showMediaDialog = async () => {
    setShowEmojis(false);
    await GetMedia();
    mediaDialog.current.open();
  };
  const submitMediaDialog = async (mediaArray) => {
    if (sending || !me) return;
    setSending(true);
    for (let file of mediaArray) {
      let replyingTo;
      if (selectedReply) replyingTo = selectedReply.id;
      let { response, error } = await SendDirectMessage(
        partyId,
        false,
        replyingTo,
        session.id,
        "text",
        file.title,
        file.id,
        file.mediaType,
        file.mediaExtension,
        undefined,
        undefined,
        file.description
      );
      if (error) {
        setSending(false);
        return;
      }
      socketEmit("sendMessage", {
        partyId,
        message: { messageInfo: response.data, senderName: me.firstName },
      });
    }
    setSending(false);
    setSelectedReply();
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);

  /* send text message */
  const [sending, setSending] = React.useState(false);
  const sendTextMessage = async (message) => {
    if (sending || !me || !message) return;
    let replyingTo;
    if (selectedReply) replyingTo = selectedReply.id;
    inputRef.current.setMessage("");
    if (selectedReply) setSelectedReply();
    if (showEmojis) setShowEmojis(false);
    inputRef.current.focus();
    let { response, error } = await SendDirectMessage(
      partyId,
      false,
      replyingTo,
      session.id || session.userId,
      "text",
      message
    );
    if (error) return;
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: session.firstName },
    });
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  const sendAgain = async (message) => {
    let { response, error } = await SendMessageAgain({ ...message, partyId });
    if (error) return;
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  /* chat input behaviour */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMessageSubmit = (e, message) => {
    if (e.which === 13 && !e.shiftKey && !isMobile) {
      e.preventDefault();
      sendTextMessage(message);
    } else if (e.which == 13 && !e.shiftKey && !isMobile) {
      e.preventDefault();
      // finishEditing(message);
    }
  };

  const getImageSourceSet = (item, isReply) => {
    const folder = item.messageType === "photo" ? partyId : item.sender;
    const file =
      item.messageType === "photo"
        ? item.message
        : item.mediaAttachmentType === "video"
        ? `${item.mediaAttachment}.0000001.jpg`
        : item.mediaAttachment;
    const size = isReply ? 120 : 300;
    return generateImageSourceSet(
      folder,
      file,
      size,
      item.mediaAttachmentType === "application"
    );
  };

  const renderReply = (item, isPreview, fromTemplate) => {
    const myMessage = item.sender === me;
    const senderName = fromTemplate
      ? t("party.nextFromTemplate")
      : myMessage
      ? t("party.me")
      : `${guests.find((g) => g.id === item.sender)?.firstName || ""} ${
          guests.find((g) => g.id === item.sender)?.lastName || ""
        }`;
    const goToMessage = () => {
      document.getElementById(item.id).scrollIntoView();
    };

    const { src, srcSet } = getImageSourceSet(item, true);

    return (
      <div
        className={
          isPreview
            ? classes.replyPreview
            : clsx(classes.replyQuote, { [classes.myReplyQuote]: myMessage })
        }
        onClick={isPreview ? null : goToMessage}
      >
        <strong>
          {senderName}
          <br />
        </strong>
        {item?.messageType.startsWith("heading") && (
          <Ellipsis
            lines={1}
            text={`${t("party.heading")}: ${item.message}`}
            basedOn="letters"
          />
        )}
        {item?.messageType === "profile" && (
          <Ellipsis
            lines={1}
            text={`${profile.firstName} ${profile.lastName}`}
            basedOn="letters"
          />
        )}
        {item?.messageType === "text" && !item.mediaAttachment && (
          <Ellipsis
            lines={1}
            style={item.deleted && { fontStyle: "italic" }}
            text={item.deleted ? t("party.deletedPlaceholder") : item.message}
            basedOn="letters"
          />
        )}
        {(item?.messageType === "voice" ||
          item.mediaAttachmentType === "audio") && (
          <span style={{ fontStyle: "italic" }}>{t("party.voiceMessage")}</span>
        )}
        {(item?.messageType === "photo" ||
          (!!item.mediaAttachment &&
            item?.messageType === "text" &&
            item.mediaAttachmentType !== "audio")) && (
          <img
            src={src}
            srcSet={srcSet}
            className={classes.replyPhotoMessage}
          />
        )}
        {item.mediaAttachmentType === "video" && (
          <PlayIcon className={classes.replyIcon} />
        )}
        {item.mediaAttachmentType === "application" && (
          <PdfIcon className={classes.replyIcon} />
        )}
      </div>
    );
  };

  const renderMessage = (item) => {
    if (item.message === "2bgd418k-a72n-532s-hu07-z582p3581d46") return;
    let myMessage =
      item.sender === me &&
      item.message !== "59953f60-7f65-4119-9374-34ab02e43a9d";
    let deleted = item.message === "8b3c857c-b40f-471b-bc66-a134f5924a83";
    if (deleted) item.deleted = true;
    if (!item) return;
    let senderName = myMessage ? t("party.me") : chatInfo?.title;
    if (senderName?.includes("undefined")) {
      senderName = `[${t("party.guestRemoved")}]`;
    }
    if (item.message === "59953f60-7f65-4119-9374-34ab02e43a9d") {
      return (
        <div className={classes.newUserMessage} key={item.id} id={item.id}>
          <span>{t("party.newGuest", { name: senderName })}</span>
        </div>
      );
    }

    const { src, srcSet } = getImageSourceSet(item);

    let messageLines;
    if (item.mediaAttachment && item.mediaAttachmentType === "application") {
      const viewFile = () => {
        let i = mediaMessages.findIndex((p) => p.id === item.id);
        if (i !== -1) {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }
      };

      messageLines = (
        <div>
          <div className={classes.pdfMessageWrapper}>
            <img
              src={src}
              srcSet={srcSet}
              className={classes.photoMessage}
              onClick={viewFile}
            />
            <div className={classes.pdfTitleWrapper}>
              <Typography className={classes.pdfTitle}>
                <PdfIcon style={{ marginBottom: -6 }} />
                &nbsp;{item.message}
              </Typography>
            </div>
          </div>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target="_blank">
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }

    if (item.mediaAttachmentType === "video") {
      const viewVideo = () => {
        let i = mediaMessages.findIndex((p) => p.id === item.id);
        if (i !== -1) {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }
      };
      messageLines = (
        <div>
          <div className={classes.pdfMessageWrapper}>
            <img
              src={src}
              srcSet={srcSet}
              className={classes.photoMessage}
              onClick={viewVideo}
            />
            <div className={classes.iconWrapper}>
              <PlayIcon className={classes.videoMessagePlay} />
            </div>
          </div>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target="_blank">
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }
    if (item?.messageType === "text" && !item.mediaAttachment) {
      const links = linkify.match(item.message);

      messageLines = (
        <>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a style={{ color: "#fff" }} href={href} key={key} target="blank">
                {text}
              </a>
            )}
          >
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                fontStyle: deleted ? "italic" : undefined,
              }}
            >
              {deleted
                ? t("party.deletedPlaceholder")
                : insertFormatting(item.message)}
            </div>
          </Linkify>
          {links && links[0] && (
            <>
              <div style={{ height: 5 }} />
              <ReactTinyLink
                cardSize="small"
                showGraphic={true}
                maxLine={2}
                minLine={1}
                url={links[0].url}
              />
              <div style={{ height: 5 }} />
            </>
          )}
        </>
      );
    }
    if (
      item?.messageType === "voice" ||
      item.mediaAttachmentType === "voice" ||
      item.mediaAttachmentType === "audio"
    )
      messageLines = (
        <div className={classes.voiceMessage}>
          <audio controls>
            <source
              src={
                item?.messageType === "voice"
                  ? `${process.env.REACT_APP_STATIC_URL}/${partyId}/${item.message}`
                  : `${process.env.REACT_APP_STATIC_URL}/${
                      item.mediaAttachmentOwner || item.sender
                    }/${item.mediaAttachment}`
              }
            />
          </audio>
        </div>
      );
    if (item?.messageType === "photo" || item.mediaAttachmentType === "image") {
      const viewPhoto = () => {
        let i = mediaMessages.findIndex((p) => p.id === item.id);
        if (i !== -1) {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }
      };
      messageLines = (
        <div>
          <img
            src={src}
            srcSet={srcSet}
            className={classes.photoMessage}
            onClick={viewPhoto}
          />
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target="_blank">
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }
    const replyMessage = !item.replyingTo
      ? undefined
      : sortedMessages.find(
          (m) => m.id === (item.replyingTo?.id || item.replyingTo)
        );
    return (
      <div
        className={clsx(classes.primaryMessageWrapper)}
        key={item.id}
        id={item.id}
      >
        {selectMode && (myMessage || bulkForward) && (
          <div className={clsx(classes.messageCheckbox)}>
            <Checkbox
              checked={selectedIds.includes(item.id)}
              onChange={() => onMessageSelect(item)}
            />
          </div>
        )}
        <div
          className={clsx(
            myMessage ? classes.messageWrapperOut : classes.messageWrapperIn,
            !selectMode
              ? classes.fullWidthMessage
              : classes.noneFullWidthMessage
          )}
        >
          <Card
            elevation={0}
            className={clsx(classes.chatMessage, {
              [classes.chatMessageOut]: myMessage,
            })}
          >
            <div className={classes.messageHeader}>
              {!!item.tempId && (
                <span
                  style={{
                    transform: "translateX(-50px)",
                    color: item.failed ? "red" : "rgb(160,160,160)",
                    width: 0,
                    height: 0,
                    cursor: item.failed && "pointer",
                  }}
                  onClick={item.failed && (() => sendAgain(item))}
                >
                  {item.failed ? (
                    <FailedIcon size="small" />
                  ) : (
                    <SendingIcon size="small" />
                  )}
                </span>
              )}
              <span className={classes.chatSender}>{senderName}</span>
              <span className={classes.grow} />
              <span className={classes.chatTimestamp}>
                {handleTimestamp(item.timestamp)}
              </span>
              {(session?.userId === chatInfo?.userId || !deleted) && (
                <IconButton
                  className={classes.iconMessageSendDirectChatView}
                  size="small"
                  style={{ marginTop: -7, marginRight: -5 }}
                  onClick={(event) => handleMenuOpen(event, item)}
                  disabled={isChatArchived || !!item.tempId}
                >
                  <MoreHorizIcon
                    className={
                      myMessage
                        ? classes.iconMessageSendDirectChatView
                        : classes.otherIconMessageGroupChatView
                    }
                  />
                </IconButton>
              )}
            </div>
            {!!replyMessage && !item.deleted && renderReply(replyMessage)}
            {messageLines}
          </Card>
        </div>
      </div>
    );
  };

  const { src: avatarSrc, srcSet: avatarSrcSet } = generateImageSourceSet(
    chatInfo?.otherUserId,
    chatInfo?.profilePic,
    40
  );

  if (
    (isIOS || isAndroid) &&
    !appDeclined &&
    session.userId !== chatInfo?.userId &&
    !me
  )
    return <MobilePopUp setAppDeclined={setAppDeclined} />;
  if (loading || messagesLoading) return <PartyLoading />;

  if (chatInfo?.blockByOther) {
    return <p>You've been blocked!</p>;
  }

  return (
    <div
      className={classes.screen}
      // onDragEnter={() => setHovered(true)}
    >
      <audio className="audio-element">
        <source src={require("assets/message-alert.mp3")}></source>
      </audio>
      {hovered && (
        <div
          {...getRootProps({
            className: clsx("dropzone", classes.dropzone, {
              [classes.dropzoneReject]: isDragReject,
            }),
            onDragLeave: () => setHovered(false),
          })}
        >
          <UploadIcon />
          <span>{t("general.uploadDrop")}</span>
          <input {...getInputProps()} />
        </div>
      )}

      <div className={classes.chat}>
        <div
          className={classes.chatHeader}
          onClick={() => handleOpenChatInfo()}
        >
          <Avatar
            className={classes.headerAvatar}
            src={avatarSrc}
            srcSet={avatarSrcSet}
          >
            {chatInfo?.initials?.toUpperCase()}
          </Avatar>
          <div style={{ flex: 1 }}>{chatInfo?.title}</div>
          <div>
            {selectMode && (
              <Button
                variant="text"
                size="small"
                disabled={selectedIds.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  if (bulkDelete && !bulkForward) showRemoveMessageDialog();
                  else if (!bulkDelete && bulkForward)
                    showForwardMessagesDialog();
                }}
              >
                {bulkDelete && !bulkForward
                  ? t("party.massDeleteMessageLabel")
                  : bulkForward && !bulkDelete
                  ? t("party.massForwardMessageLabel")
                  : null}
              </Button>
            )}
            {!selectMode && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleGlobalChatMenuOpen(event);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            {selectMode && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancelBulk();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.chatView} ref={messageList}>
          <div className={classes.allMessages}>
            {sortedMessages.map(renderMessage)}
          </div>
        </div>
        {showEmojis && (
          <div className={classes.emojiKeyboard}>
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              groupNames={{
                smileys_people: t("emojis.smileys_people"),
                animals_nature: t("emojis.animals_nature"),
                food_drink: t("emojis.food_drink"),
                travel_places: t("emojis.travel_places"),
                activities: t("emojis.activities"),
                objects: t("emojis.objects"),
                symbols: t("emojis.symbols"),
                flags: t("emojis.flags"),
                recently_used: t("emojis.recently_used"),
              }}
              disableSearchBar={true}
            />
          </div>
        )}
        {!!selectedReply && (
          <div className={classes.reply}>
            <IconButton onClick={cancelReply} disabled={sending}>
              <CloseIcon />
            </IconButton>
            {renderReply(selectedReply, true)}
          </div>
        )}
        {Array.isArray(sendingMedia) && sendingMedia.length > 0 && (
          <MediaPreview
            submitMediaDialog={submitMediaDialog}
            sendingMedia={sendingMedia}
            setSendingMedia={setSendingMedia}
            userId={chatInfo?.userId}
          />
        )}
        {!bulkDelete && !bulkForward && (
          <ChatBar
            isManager={!!session.accessToken}
            sending={sending}
            autoFocus={!!me}
            showEmojiKeyboard={() => {
              setShowEmojis(!showEmojis);
            }}
            showMediaDialog={showMediaDialog}
            selectFiles={selectFiles}
            showVoiceDialog={showVoiceDialog}
            onEnter={handleMessageSubmit}
            onSendText={sendTextMessage}
            ref={inputRef}
          />
        )}
      </div>

      {showChatInfo && headerBackButton && (
        <ChatListInfo
          hideChatInfo={() => handleCloseChatInfo()}
          party={chatInfo}
          guests={guests}
          onlineUsers={onlineUsers}
          session={session}
          expired={isChatArchived}
          messageList={messageList}
          media={mediaMessages}
          chatType="direct"
        />
      )}
      <Menu
        anchorEl={globalAnchorEl}
        keepMounted={true}
        open={globalChatMenuOpen}
        onClose={handleGlobalChatMenuClose}
      >
        <MenuItem onClick={handleBulkDelete}>
          {t("party.massDeleteMessageLabel")}
        </MenuItem>
        {userType === "user" && (
          <MenuItem onClick={handleBulkForward}>
            {t("party.massForwardMessageLabel")}
          </MenuItem>
        )}
        {/* {
            <MenuItem onClick={() => Block(chatInfo?.otherUserId)}>
              {"Block"}
            </MenuItem>
          } */}
        {
          <MenuItem
            onClick={isChatArchived ? handleUnarchiveChat : handleArchiveChat}
          >
            {isChatArchived ? t("chats.unarchiveChat") : t("chats.archiveChat")}
          </MenuItem>
        }
      </Menu>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {userType === "user" && (
          <MenuItem onClick={handleForwardMessage}>
            {t("general.forward")}
          </MenuItem>
        )}
        {me === selectedMessage?.sender && me === chatInfo?.userId && (
          <MenuItem onClick={showRemoveMessageDialog}>
            {t("party.deleteMessageLabel")}
          </MenuItem>
        )}
        {!selectedMessage?.deleted && (
          <MenuItem onClick={startReplyMessage}>
            {t("party.replyMessageLabel")}
          </MenuItem>
        )}
      </Menu>
      <RemoveMessageDialog
        ref={removeMessageDialog}
        onSubmit={submitRemoveMessageDialog}
        bulk={bulkDelete}
      />
      <ForwardMessagesDialog
        ref={forwardMessagesDialog}
        onMoveToLibrary={submitMoveToLibrary}
        onMoveToTemplate={submitMoveToTemplate}
        onMoveToChat={submitMoveToChat}
        isMedia={mediaSelected}
      />
      <MoveToDialog ref={copyToDialog} onSubmit={submitCopyToDialog} />
      <MoveToTemplateDialog
        ref={copyToTemplateDialog}
        onSubmit={submitCopyToTemplateDialog}
      />
      <MoveToChatDialog
        ref={copyToChatDialog}
        onSubmit={submitCopyToChatDialog}
      />
      <VoiceDialog ref={voiceDialog} onSubmit={submitVoiceDialog} />
      <PhotoDialog
        ref={photoDialog}
        onSubmit={submitPhotoDialogChoice}
        onCancel={cancelPhotoPreview}
        selectedFiles={selectedFiles}
        libraryEnabled={session?.userId === chatInfo?.userId}
      />
      <MediaDialog
        ref={mediaDialog}
        onSubmit={handleMediaDescriptions}
        media={media}
        userId={session.userId}
      />
      <ImageCarousel
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        media={mediaMessages}
        currentIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        party={chatInfo}
        chatType="direct"
      />
      <NewMessageButton
        show={showMessageIndicator}
        handleClick={handleMessageIndicator}
        handleClose={() => setShowMessageIndicator(false)}
      />
      <UploadProgress
        showUpload={showUpload}
        progress={uploadProgress}
        description={uploadDescription}
      />
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
        anchorOriginTopRight
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {forwardStatus}
        </Alert>
      </Snackbar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    padding: 0,
    height: "100%",
  },
  title: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    flex: 1,
    textOverflow: "ellipsis",
  },
  copyLinkButton: {
    color: "#000",
    textTransform: "none",
    fontWeight: "bold",
  },
  mobileDotMenu: {
    justifyContent: "right",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tabs: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tab: {
    textTransform: "capitalize",
    minWidth: 0,
    marginRight: 30,
    fontWeight: "bold",
    flex: 1,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  chat: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    maxHeight: "100%",
  },
  chatView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
    scrollBehavior: "smooth",
    padding: "10px 0",
    margin: "0 10px",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  allMessages: {
    display: "block",
    // paddingBottom: 10,
  },
  primaryMessageWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  messageWrapperIn: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "8px",
    alignItems: "flex-start",
  },
  messageWrapperOut: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  fullWidthMessage: {
    width: "100%",
  },
  noneFullWidthMessage: {
    width: "90%",
  },
  messageCheckbox: {
    display: "flex",
    alignItems: "center",
    marginLeft: "5rem",
    width: "10%",
  },
  messageWrapperReply: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  headingMessage: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingTop: 30,
    paddingBottom: 10,
    flexShrink: 0,
    fontWeight: "bold",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    "& span": {
      maxWidth: "calc(100% - 20px)",
    },
  },
  heading: {
    fontSize: "1.5em",
    borderColor: "rgba(160,160,160,0.6)",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 0.5,
  },
  heading2: {
    fontSize: "1.4em",
  },
  heading3: {
    fontWeight: "500",
    fontSize: "1.3em",
    paddingTop: 20,
  },
  newUserMessage: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingTop: 15,
    paddingBottom: 10,
    fontSize: "1em",
    color: "rgba(160,160,160,0.6)",
    flexShrink: 0,
  },
  previewHeading: {
    display: "flex",
    width: "78%",
    justifyContent: "center",
    fontWeight: "bold",
    borderColor: "rgba(160,160,160,0.6)",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 0.5,
    flexShrink: 0,

    fontSize: 20,
    fontWeight: 10,
    marginTop: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderLeft: "solid",
    borderWidth: 1,
    backgroundColor: "rgb(240,240,240)",
  },
  emojiKeyboard: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 8,
    marginTop: -328,
    zIndex: 1500,
  },
  voiceMessage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "italic",
    paddingRight: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  photoMessage: {
    width: 300,
    maxWidth: "calc(100% + 10px)",
    aspectRatio: 1,
    margin: -5,
    marginTop: 5,
    borderRadius: 3,
    cursor: "pointer",
  },
  pdfMessageWrapper: {
    paddingBottom: 5,
  },
  pdfTitleWrapper: {
    height: 50,
    margin: -5,
    marginBottom: -45,
    transform: "translateY(-45px)",
    backgroundColor: "rgba(0,0,0,0.5)",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 300,
  },
  pdfTitle: {
    flex: 1,
    color: "white",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  iconWrapper: {
    width: 300,
    height: 300,
    margin: -5,
    marginTop: -305,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoMessagePlay: {
    fontSize: 60,
    pointerEvents: "none",
    color: "white",
  },
  onlineIcon: {
    paddingRight: 10,
  },

  managerVoiceMessageIcon: {
    color: "white",
  },
  moreIcon: {
    display: "none",
  },
  chatMessage: {
    display: "block",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "20%",
    maxWidth: "70%",
    backgroundColor: "rgb(240,240,240)",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    overflow: "visible",
    backgroundColor: "#fff",
    color: "#4E4E4E",
  },
  mediaDescription: {
    marginTop: 5,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    maxWidth: 280,
  },
  readReceipt: {
    marginTop: 5,
    fontSize: "0.9em",
    color: "rgba(160,160,160,0.6)",
    textAlign: "right",
    cursor: "pointer",
  },
  replyMessage: {
    display: "block",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "20%",
    maxWidth: "100%",
    opacity: 0.8,
    border: "solid",
    borderWidth: 1,
    backgroundColor: "rgb(240,240,240)",
  },
  reply: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    paddingLeft: 5,
  },
  replyPreview: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    marginLeft: 5,
    padding: 10,
    borderLeft: "solid",
    borderWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    backgroundColor: "#eee",
  },
  replyQuote: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    marginLeft: -5,
    marginRight: -5,
    marginBottom: 5,
    padding: 10,
    borderLeft: "solid",
    borderWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 3,
    cursor: "pointer",
  },
  myReplyQuote: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  replyPhotoMessage: {
    width: 120,
    height: 120,
  },
  replyIcon: {
    fontSize: 40,
    color: "white",
    marginTop: -80,
    marginBottom: 40,
    marginLeft: 40,
  },
  // eslint-disable-next-line no-dupe-keys
  replyMessage: {
    fontSize: 20,
    fontWeight: 10,
  },
  chatMessageOut: {
    backgroundColor: theme.palette.darkGrey.main,
    color: "#fff",
    borderRadius: 12,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    // width: "50%",
    "&:a": { color: "#fff" },
  },
  messageHeader: {
    display: "flex",
    flexDirection: "row",
  },
  chatSender: {
    fontSize: ".9em",
    fontWeight: "bold",
  },
  chatTimestamp: {
    fontSize: ".9em",
    opacity: 0.7,
  },
  mediaContent: {
    width: "100%",
    flex: 1,
    marginBottom: 20,
  },
  contentWrapper: {
    display: "none",
    paddingTop: 0,
    [theme.breakpoints.up("md")]: {
      display: "block",
      flex: 1,
      padding: 10,
      paddingBottom: 20,
    },
  },
  chatHeader: {
    boxShadow: "0px 0.5px 1px rgba(160,160,160, 0.4)",
    height: 50,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    fontWeight: "bold",
    backgroundColor: "#fff",
    borderRadius: 5,
    alignItems: "center",
    cursor: "pointer",
  },
  mobileButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  tabletButton: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
    minWidth: 10,
  },
  messageIndicator: {
    borderRadius: theme.spacing(3),
    fontWeight: "bold",
    textTransform: "none",
  },

  allTemplateMessages: {
    display: "block",
    paddingBottom: 10,
  },
  dropzone: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "dashed",
    alignItems: "center",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    opacity: "80%",
    zIndex: 10000,
    pointerEvents: "auto",
  },
  dropzoneReject: {
    backgroundColor: "rgb(255, 180, 180)",
    borderColor: "red",
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  name: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: "bold",
  },
  profileMessage: {
    fontSize: 15,
    marginLeft: 20,
    marginRight: 20,
  },
  headerAvatar: {
    marginRight: 20,
  },
  initials: {
    marginRight: 10,
    width: 38,
    height: 38,
    color: "#fff",
    backgroundColor: "#bdbdbd",
    borderRadius: "50%",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconMessageSendDirectChatView: {
    color: "white",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  otherIconMessageGroupChatView: {
    color: theme.palette.darkGrey.main,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
}));

export default DirectChatView;
