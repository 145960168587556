import React from "react";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const Privacy = (props) => {
  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <Typography variant="h4" component="h1">
        Datenschutzerklärung
      </Typography>
      <Typography variant="h5" component="h2" className={styles.h2}>
        1. Datenschutz auf einen Blick
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Allgemeine Hinweise
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Die folgenden Hinweise geben einen einfachen &Uuml;berblick
        dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
        diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
        denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
        Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
        unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Datenerfassung auf dieser Website
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        <strong>
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
        </strong>
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum
        dieser Website entnehmen.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        <strong>Wie erfassen wir Ihre Daten?</strong>
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Ihre Daten werden dadurch erhoben, dass Sie uns diese mitteilen. Dies
        geschieht über die Eingabe im Anmeldeformular sowie durch das
        Chat-System.
        <br />
        Weitere technische Daten (z.B. Internetbrowser oder Betriebssystem)
        werden nicht erfasst.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        <strong>Wof&uuml;r nutzen wir Ihre Daten?</strong>
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Die erfassten Daten werden lediglich verwendet, um Sie anderen Benutzern
        im Rahmen des Chatsystems darzustellen.
        <br />
        Es erfolgt keine Analyse Ihres Nutzerverhaltens oder eine anderweitige
        Nutzung der Daten.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        <strong>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</strong>
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
        Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen
        Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung
        oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung
        zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung
        jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
        Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren
        steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
        Aufsichtsbeh&ouml;rde zu.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
        sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
      </Typography>
      <Typography variant="h5" component="h2" className={styles.h2}>
        2. Hosting und Content Delivery Networks (CDN)
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Externes Hosting
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Die personenbezogenen Daten, die auf dieser Website erfasst werden,
        werden auf den Servern des Hosters gespeichert.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung
        gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
        1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
        effizienten Bereitstellung unseres Online-Angebots durch einen
        professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
        Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen.
      </Typography>
      <Typography variant="h5" component="h2" className={styles.h2}>
        3. Allgemeine Hinweise und Pflicht&shy;informationen
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Hinweis zur verantwortlichen Stelle
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
        Website ist:
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        MOVA Deutschland GmbH
        <br />
        Südring 25
        <br />
        44787 Bochum
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Telefon: +49 234 9784 9049
        <br />
        E-Mail: messenger@prowin.net
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person,
        die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel
        der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
        E-Mail-Adressen o. &Auml;.) entscheidet.
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
        ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
        bereits erteilte Einwilligung jederzeit widerrufen. Die
        Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
        sowie gegen Direktwerbung (Art. 21 DSGVO)
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH
        AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R
        EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN
        WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
        SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
        F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
        FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER
        GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON
        RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
        SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen
        ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem
        Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes
        oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das
        Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
        oder gerichtlicher Rechtsbehelfe.
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Recht auf Daten&shy;&uuml;bertrag&shy;barkeit
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich
        oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
        aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der
        Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        SSL- bzw. TLS-Verschl&uuml;sselung
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
        &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
        oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen
        Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo;
        auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
        Browserzeile.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
        die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen
        werden.
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Auskunft, L&ouml;schung und Berichtigung
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck
        der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
        L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im
        Impressum angegebenen Adresse an uns wenden.
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Recht auf Einschr&auml;nkung der Verarbeitung
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
        jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das
        Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden
        F&auml;llen:
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
            Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
            Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
            verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
            sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
        eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
        Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
        Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
        nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
        wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder
        eines Mitgliedstaats verarbeitet werden.
      </Typography>
      <Typography variant="h5" component="h2" className={styles.h2}>
        4. Datenerfassung auf dieser Website
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        Cookies
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
        Cookies sind kleine Textdateien und richten auf Ihrem Endger&auml;t
        keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die
        Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
        Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden
        nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
        bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst
        l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren
        Webbrowser erfolgt.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Die von uns erfassten Cookies sind technisch notwendig, da bestimmte
        Websitefunktionen ohne diese nicht funktionieren w&uuml;rden (z.B.
        Teilnahme am Chat, Medien- und Party-Verwaltung).
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Cookies, die zur Durchf&uuml;hrung des elektronischen
        Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
        bestimmter, von Ihnen erw&uuml;nschter Funktionen (funktionale Cookies)
        oder zur Optimierung der Website erforderlich sind, werden auf Grundlage
        von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
        Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
        Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die
        Speicherung der betreffenden Cookies ausschlie&szlig;lich auf Grundlage
        dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
        jederzeit widerrufbar.
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
        Setzen von Cookies informiert werden und Cookies nur im Einzelfall
        erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder
        generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
        Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der
        Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
        eingeschr&auml;nkt sein.
      </Typography>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  h2: {
    marginTop: 20,
  },
  h3: {
    marginTop: 10,
    paddingLeft: 20,
  },
  p: {
    fontSize: "0.9em",
    paddingLeft: 20,
    marginBottom: 5,
  },
}));

export default Privacy;
