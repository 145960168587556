export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const INFORM = "INFORM";

export const GET_USER = "GET_USER";
export const GET_GUEST = "GET_GUEST";
export const VERIFIED_GUEST_LOGIN = "VERIFIED_GUEST_LOGIN";
export const CLAIM_ACCOUNT = "CLAIM_ACCOUNT";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GUEST_PROFILE_UPDATE = "GUEST_PROFILE_UPDATE";
export const ADD_PROFILE = "ADD_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const SELECT_PROFILE = "SELECT_PROFILE";
export const SWITCH_COMPANY_MODE = "SWITCH_COMPANY_MODE";
export const HANDLE_VIEW_RELEASE_NOTES = "HANDLE_VIEW_RELEASE_NOTES";
export const SET_CALLBACK_LOCATION = "SET_CALLBACK_LOCATION";
export const DISMISS_BANNER = "DISMISS_BANNER";

export const GET_MEDIA = "GET_MEDIA";
export const ADD_MEDIA = "ADD_MEDIA";
export const CONVERT_MEDIA = "CONVERT_MEDIA";
export const REMOVE_MEDIA = "REMOVE_MEDIA";
export const MEDIA_TRASH = "MEDIA_TRASH";
export const RESTORE_MEDIA = "RESTORE_MEDIA";
export const EDIT_MEDIA = "EDIT_MEDIA";
export const CREATE_FOLDER = "CREATE_FOLDER";

export const GET_PARTIES = "GET_PARTIES";
export const ADD_PARTY = "ADD_PARTY";
export const UPDATE_PARTY = "UPDATE_PARTY";
export const REMOVE_PARTY = "REMOVE_PARTY";
export const PARTY_INFO = "PARTY_INFO";
export const PARTY_GUESTS = "PARTY_GUESTS";
export const CHAT_GUESTS = "CHAT_GUESTS";
export const JOIN_PARTY = "JOIN_PARTY";
export const CHANGE_NAME = "CHANGE_NAME";
export const PARTY_HOST = "PARTY_HOST";
export const SUBSCRIBE = "SUBSCRIBE";
export const LATEST_CHAT = "LATEST_CHAT";
export const TRY_SEND_MESSAGE = "TRY_SEND_MESSAGE";
export const REMOVE_TEMP_MESSAGE = "REMOVE_TEMP_MESSAGE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const HANDLE_MESSAGE = "HANDLE_MESSAGE";
export const HANDLE_GUESTS = "HANDLE_GUESTS";
export const HANDLE_GUEST_UPDATED = "HANDLE_GUEST_UPDATED";
export const HANDLE_GUEST_REMOVED = "HANDLE_GUEST_REMOVED";
export const HANDLE_ONLINE = "HANDLE_ONLINE";
export const HANDLE_SEEN = "HANDLE_SEEN";
export const REMOVE_GUEST = "REMOVE_GUEST";
export const LEAVE_CHAT = "LEAVE_CHAT";
export const TIMESTAMP_CHAT = "TIMESTAMP_CHAT";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const HANDLE_UPDATED_MESSAGE = "HANDLE_UPDATED_MESSAGE";
export const HANDLE_UPDATED_SEEN = "HANDLE_UPDATED_SEEN";
export const HANDLE_NEW_HOST = "HANDLE_NEW_HOST";
export const APPLY_TEMPLATE = "APPLY_TEMPLATE";
export const GET_PROFILE = "GET_PROFILE";
export const GET_DIRECT_PROFILE = "GET_DIRECT_PROFILE";
export const PARTY_CHAT_TIMESTAMP = "PARTY_CHAT_TIMESTAMP";
export const HANDLE_PARTY_RESTRICTED = "HANDLE_PARTY_RESTRICTED";

//Group Chats

export const ADD_GROUP_CHATS = "ADD_GROUP_CHATS";
export const GET_GROUP_CHATS = "GET_GROUP_CHATS";
export const GET_GROUP_CHAT = "GET_GROUP_CHAT";
export const UPDATE_GROUP_CHATS = "UPDATE_GROUP_CHATS";
export const JOIN_GROUP_CHATS = "JOIN_GROUP_CHATS";
export const LATEST_GROUP_CHAT = "LATEST_GROUP_CHAT";
export const GET_OLDER_MESSAGES = "GET_OLDER_MESSAGES";
export const GET_NEWER_MESSAGES = "GET_NEWER_MESSAGES";
export const TRY_SEND_GROUP_MESSAGE = "TRY_SEND_GROUP_MESSAGE";
export const SEND_GROUP_MESSAGE = "SEND_GROUP_MESSAGE";
export const GET_GUESTS = "GET_GUESTS";
export const REMOVE_TEMP_GROUP_MESSAGE = "REMOVE_TEMP_GROUP_MESSAGE";
export const HANDLE_UPDATED_GROUP_MESSAGE = "HANDLE_UPDATED_GROUP_MESSAGE";
export const HANDLE_UPDATED_GROUP_SEEN = "HANDLE_UPDATED_GROUP_SEEN";
export const GROUP_MEDIA = "GROUP_MEDIA";
export const MUTE_GROUP = "MUTE_GROUP";
export const UNMUTE_GROUP = "UNMUTE_GROUP";
export const HANDLE_GROUP_RESTRICTED = "HANDLE_GROUP_RESTRICTED";
export const LOAD_INITIAL_GROUP_MESSAGE = "LOAD_INITIAL_GROUP_MESSAGE";
export const REMOVE_GROUP_CHAT = "REMOVE_GROUP_CHAT";
export const HANDLE_GROUP_MESSAGE = "HANDLE_GROUP_MESSAGE";
export const BLOCKED_LIST = "BLOCKED_LIST";
export const BLOCK = "BLOCK";
export const UNBLOCK = "UNBLOCK";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const CHAT_LIST = "CHAT_LIST";
export const MUTE_CHAT = "MUTE_CHAT";
export const UNMUTE_CHAT = "UNMUTE_CHAT";
export const MUTE_PARTY = "MUTE_PARTY";
export const UNMUTE_PARTY = "UNMUTE_PARTY";
export const GROUP_CHAT_TIMESTAMP = "GROUP_CHAT_TIMESTAMP";

// Direct chats
export const LATEST_DIRECT_CHAT = "LATEST_DIRECT_CHAT";
export const GET_CHATS = "GET_CHATS";
export const ADD_CHAT = "ADD_CHAT";
export const SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE";
export const TRY_SEND_CHAT_MESSAGE = "TRY_SEND_CHAT_MESSAGE";
export const HANDLE_CHAT_MESSAGE = "HANDLE_CHAT_MESSAGE";
export const ARCHIVE_CHAT = "ARCHIVE_CHAT";
export const UNARCHIVE_CHAT = "UNARCHIVE_CHAT";
export const LOAD_INITIAL_DIRECT_MESSAGE = "LOAD_INITIAL_DIRECT_MESSAGE";
export const DIRECT_CHAT_TIMESTAMP = "DIRECT_CHAT_TIMESTAMP";
export const HANDLE_UPDATED_DIRECT_MESSAGE = "HANDLE_UPDATED_DIRECT_MESSAGE";

// Templates

export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const TEMPLATE_TRASH = "TEMPLATE_TRASH";
export const RESTORE_TEMPLATE = "RESTORE_TEMPLATE";

export const ADD_TEMPLATE_MESSAGE = "ADD_TEMPLATE_MESSAGE";
export const EDIT_TEMPLATE_MESSAGE = "EDIT_TEMPLATE_MESSAGE";
export const DELETE_TEMPLATE_MESSAGE = "DELETE_TEMPLATE_MESSAGE";
export const DELETE_TEMPLATE_MESSAGES = "DELETE_TEMPLATE_MESSAGES";
export const MOVE_TEMPLATE_MESSAGE = "MOVE_TEMPLATE_MESSAGE";

export const START_TEMPLATE = "START_TEMPLATE";
export const CONTINUE_TEMPLATE = "CONTINUE_TEMPLATE";
export const CLOSE_TEMPLATE = "CLOSE_TEMPLATE";
export const CHANGE_TEMPLATE_POSITION = "CHANGE_TEMPLATE_POSITION";
export const HIDE_TEMPLATE_MESSAGE = "HIDE_TEMPLATE_MESSAGE";
export const REMOVE_APPLIED_TEMPLATE = "REMOVE_APPLIED_TEMPLATE";

//Import

export const IMPORT_START = "IMPORT_START";
export const IMPORT_FILE = "IMPORT_FILE";
export const IMPORT_MEDIA = "IMPORT_MEDIA";
export const IMPORT_MESSAGE = "IMPORT_MESSAGE";
export const IMPORT_PARTY = "IMPORT_PARTY";
