import React from "react";
import {
  CssBaseline,
  Button,
  Avatar,
  ListItemText,
  Divider,
  Skeleton,
  Box,
  ListItemAvatar,
  ListItem,
  TextField,
} from "@mui/material";
import { HeaderBar, NavMenu, PartyDialog } from "components";
import PartyChatView from "../chats/partyChatView";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupsIcon from "@mui/icons-material/Groups";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import {
  useEmit,
  useGuestRemoved,
  useNewMessage,
  usePartyDeleted,
  useReloadMessages,
  useSocketInitialized,
  useUpdatedMessage,
} from "./../../context/socket";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { Switch, Route } from "react-router-dom";
import { lastMessageWithSender, lastMessage } from "../chats/latestMessage";
import { useHandleTimestamp } from "src/utils/handleTimestamp.util";
import moment from "moment";
import { LocalizationProvider, StaticDatePicker, PickersDay } from "@mui/lab";
import clsx from "clsx";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const sortByLatestMessage = (a, b) => {
  if (!a.latestMessage?.timestamp) return 1;
  if (!b.latestMessage?.timestamp) return -1;
  return (
    new Date(b.latestMessage.timestamp) - new Date(a.latestMessage.timestamp)
  );
};

const sortByStartDate = (a, b) => {
  if (!a?.start) return 1;
  if (!b?.start) return -1;
  return new Date(b.start) - new Date(a.start);
};

const sortByExpiration = (a, b) => {
  if (!a?.expiration) return 1;
  if (!b?.expiration) return -1;
  return new Date(b.expiration) - new Date(a.expiration);
};

const Parties = (props) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [calendarParties, setCalendarParties] = React.useState([]);
  const [activeParties, setActiveParties] = React.useState([]);
  const [plannedParties, setPlannedParties] = React.useState([]);
  const [expiredParties, setExpiredParties] = React.useState([]);
  const [chatListLoading, setChatListLoading] = React.useState(true);
  const [markedDates, setMarkedDates] = React.useState({});
  const [selectedDate, setSelectedDate] = React.useState(
    moment().startOf("day")
  );
  const changeDate = (date) => {
    setSelectedDate(date);
    setCalendarParties(
      allParties.filter(
        (p) => p.start && moment(p.start).startOf("day").isSame(date)
      )
    );
  };

  React.useEffect(() => {
    if (selectedChip === 0) handleSearch(searchQuery, selectedChip);
  }, [selectedDate]);

  const handleTimestamp = useHandleTimestamp();

  const session = useSelector((state) => state.session);

  const userId = session.secretKey ? session.id : session.userId;

  const allParties = useSelector((state) => state.parties || []);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState();

  const { partyId: selectedId } = useParams();

  const history = useHistory();

  const socketInitialized = useSocketInitialized();
  const socketEmit = useEmit();

  const { t } = useTranslation();

  const { AddParty, GetParties } = useActions();

  const chips = [
    {
      title: t("parties.calendar"),
      list: calendarParties,
    },
    {
      title: t("parties.active"),
      list: activeParties,
    },
    {
      title: t("parties.planned"),
      list: plannedParties,
    },
    {
      title: t("parties.expired"),
      list: expiredParties,
    },
  ];

  const applySearch = (item, query) => {
    const searchVal = query.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result =
        result &&
        item?.title &&
        searchTerm.test(item.title?.toLowerCase() || item.title);
    }
    return result;
  };

  const handleSearch = (query, selectedChip) => {
    setSearchQuery(query);
    if (!query.trim()) {
      return;
    }
    setSearchResults(
      chips[selectedChip].list.filter((item) => applySearch(item, query))
    );
  };

  const handlePartySelect = async (party) => {
    if (!party) return;
    history.push(`/parties/${party.shortId}`);
  };

  const [selectedChip, setSelectedChip] = React.useState(0);
  const changeChip = (chip) => {
    setSelectedChip(chip);
    handleSearch(searchQuery, chip);
  };

  const [unreadMessages, setUnreadMessages] = React.useState(0);

  const loadChatList = async () => {
    setChatListLoading(true);
    await GetParties();
    setChatListLoading(false);
  };
  React.useEffect(() => {
    if (!allParties) return;
    setCalendarParties(
      allParties.filter((p) => p.start && moment(p.start).isSame(selectedDate))
    );
    setActiveParties(
      allParties
        .filter(
          (p) =>
            (!p.expiration || moment(p.expiration).isAfter()) &&
            (!p.start || moment(p.start).isSameOrBefore())
        )
        .sort(sortByLatestMessage)
    );
    setPlannedParties(
      allParties
        .filter(
          (p) =>
            (!p.expiration || moment(p.expiration).isAfter()) &&
            p.start &&
            moment(p.start).isAfter()
        )
        .sort(sortByStartDate)
    );
    setExpiredParties(
      allParties
        .filter((p) => p.expiration && moment(p.expiration).isSameOrBefore())
        .sort(sortByExpiration)
    );
    setMarkedDates(
      allParties.reduce((m, party) => {
        if (!party.start) return m;
        const day = moment(party.start).format("YYYY-MM-DD");
        if (!m[day]) m[day] = 0;
        m[day]++;
        return m;
      }, {})
    );
    let unreadMessages = 0;
    if (allParties)
      unreadMessages = allParties.filter((item) => !isSeen(item)).length;
    setUnreadMessages(unreadMessages);
    if (!selectedId)
      document.title =
        (unreadMessages > 0 ? `(${unreadMessages}) ` : "") +
        `${t("parties.title")} - proWIN Messenger`;
  }, [allParties]);

  React.useEffect(() => {
    if (selectedId) return;
    loadChatList();
    document.title =
      (unreadMessages > 0 ? `(${unreadMessages}) ` : "") +
      `${t("parties.title")} - proWIN Messenger`;
    setShowChatInfo(false);
  }, [selectedId]);

  React.useEffect(() => {
    if (!allParties || !socketInitialized) return;
    for (const item of allParties) {
      socketEmit("join", {
        partyId: item.shortId,
        guestId: userId,
      });
    }
  }, [allParties, socketInitialized]);

  const partyDialog = React.useRef();

  const [showChatInfo, setShowChatInfo] = React.useState(false);
  const handleBack = () => setShowChatInfo(false);

  const handleAdd = () => {
    partyDialog.current.open();
  };

  const submitPartyDialog = async (
    title,
    start,
    expiration,
    host,
    manager,
    password,
    restrictedMessaging,
    selectedFile
  ) => {
    const { response, error } = await AddParty(
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging,
      selectedFile
    );
    if (error) return;
    history.push(`/parties/${response?.data.shortId}`);
    loadChatList();
  };

  useNewMessage((message) => {
    loadChatList();
    if (message?.messageInfo?.sender !== userId) {
      const audioEl = document.getElementsByClassName("audio-element")?.[0];
      if (audioEl?.play) audioEl.play();
    }
  });

  useUpdatedMessage(() => {
    loadChatList();
  });

  useReloadMessages(() => {
    loadChatList();
  });

  usePartyDeleted(() => {
    loadChatList();
  });

  useGuestRemoved(() => {
    loadChatList();
  });

  const isSeen = (item) => {
    if (!item.latestMessage?.timestamp) return true;
    if (item.latestMessage.sender === userId) return true;
    if (!item.seen) return false;
    return moment(item.latestMessage.timestamp).isSameOrBefore(item.seen);
  };

  const renderItem = (item, index, list) => {
    const isExpired =
      !!item.expiration && moment().isSameOrAfter(item.expiration);
    const isPlanned =
      selectedChip !== 0 && !!item.start && moment().isSameOrBefore(item.start);
    const seen = isSeen(item);
    const TextWrapper = seen
      ? ({ children, ...props }) => <span {...props}>{children}</span>
      : ({ children, ...props }) => <strong {...props}>{children}</strong>;

    const { src, srcSet } = generateImageSourceSet(item.id, item.partyPic, 45);
    return (
      <div key={index}>
        <ListItem
          className={
            !!selectedId &&
            (item.shortId === selectedId ||
              item.id === selectedId ||
              item.chatId === selectedId)
              ? classes.listItemSelected
              : classes.listItem
          }
          onClick={() => handlePartySelect(item)}
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar} src={src} srcSet={srcSet}>
              <ShoppingCartIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            key={index}
            className={classes.mainListItemText}
            primary={<TextWrapper>{item?.title}</TextWrapper>}
            secondary={
              <TextWrapper>
                {item.shortId
                  ? lastMessageWithSender(
                      item,
                      session,
                      t,
                      item?.latestMessage?.senderName
                    )
                  : !item.chatId && item.latestMessage?.senderName
                  ? lastMessageWithSender(
                      item,
                      session,
                      t,
                      item?.latestMessage?.senderName
                    )
                  : lastMessage(item, session, t)}
              </TextWrapper>
            }
          />
          <div className={classes.timestamp}>
            <span style={{ whiteSpace: "nowrap" }}>
              <TextWrapper>
                {handleTimestamp(item.latestMessage?.timestamp)}
              </TextWrapper>
              {!seen && <span className={classes.unreadDot} />}
            </span>
            <div
              className={classes.chatType}
              style={{
                backgroundColor: isPlanned
                  ? "#cce2cb"
                  : isExpired
                  ? "#ffcaca"
                  : "transparent",
              }}
            >
              {isPlanned
                ? moment(item.start).format(t("general.dateFormatShort"))
                : isExpired
                ? t("parties.expired")
                : " "}
            </div>
          </div>
        </ListItem>
        {index < list.length - 1 && (
          <Divider
            style={{
              width: "calc(100% - 80px)",
              marginLeft: 70,
            }}
          />
        )}
      </div>
    );
  };

  const chatList = chips[selectedChip].list;

  const headerButtons = [
    {
      label: t("chats.newParty"),
      icon: <CalendarMonthIcon />,
      onClick: handleAdd,
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="parties"
      />
      <div className={classes.screen}>
        <HeaderBar
          title={t("parties.title")}
          buttons={headerButtons}
          onBack={handleBack}
          showBackButton={showChatInfo}
        />
        <CssBaseline />
        <div style={{ minHeight: 0, flex: 1, display: "flex" }}>
          {!showChatInfo && (
            <div className={classes.chatList}>
              <div className={classes.wrapperSearch}>
                <div className={classes.search}>
                  <InputBase
                    className={classes.inputBase}
                    placeholder={"Party suchen"}
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value, selectedChip)}
                    style={{
                      fontSize: "13px",
                      color: "#000",
                      width: 280,
                    }}
                  />
                  <IconButton
                    className={classes.searchBtn}
                    type="submit"
                    aria-label="search"
                    disableRipple
                    color="primary"
                  >
                    <div>
                      {searchQuery.length === 0 ? (
                        <SearchIcon />
                      ) : (
                        <CloseIcon onClick={() => setSearchQuery(() => "")} />
                      )}
                    </div>
                  </IconButton>
                </div>
              </div>
              {/* Chat tabs */}
              <div className={classes.chatTab}>
                {chips.map((chip, index) => (
                  <Button
                    key={index.toString()}
                    className={classes.chip}
                    onClick={() => changeChip(index)}
                    style={
                      selectedChip === index
                        ? { backgroundColor: "#333E48", color: "#fff" }
                        : null
                    }
                  >
                    {chip.title}
                  </Button>
                ))}
              </div>
              {selectedChip === 0 && (
                <div className={classes.calendar}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={selectedDate}
                    onChange={changeDate}
                    views={["day"]}
                    renderDay={(
                      date,
                      _selectedDates,
                      { key, ...pickersDayProps }
                    ) => {
                      return (
                        <div className={classes.calendarDay} key={key}>
                          <PickersDay {...pickersDayProps} />
                          {!pickersDayProps.outsideCurrentMonth && (
                            <div
                              className={clsx(classes.calendarDots, {
                                [classes.calendarDotsActive]:
                                  selectedDate.isSame(date),
                              })}
                            >
                              {markedDates[date.format("YYYY-MM-DD")] > 0 && (
                                <div className="dot" />
                              )}
                              {markedDates[date.format("YYYY-MM-DD")] > 1 && (
                                <div className="dot" />
                              )}
                              {markedDates[date.format("YYYY-MM-DD")] > 2 && (
                                <div className="dot" />
                              )}
                            </div>
                          )}
                        </div>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              )}
              <div className={classes.sideMenu}>
                {/* Search input */}
                {!chatList?.length && chatListLoading ? (
                  <>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                  </>
                ) : !chatList?.length ? (
                  <p
                    style={{
                      margin: "20px auto",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    {t("chats.noChats")}
                  </p>
                ) : !searchQuery?.length ? (
                  chatList.map(renderItem)
                ) : searchResults.length ? (
                  searchResults.map(renderItem)
                ) : (
                  <p
                    style={{
                      margin: "20px auto",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    {t("chats.noResults")}
                  </p>
                )}
              </div>
            </div>
          )}

          {/* Chat view */}

          <main className={classes.main}>
            <Switch>
              <Route exact path="/parties/:partyId">
                <PartyChatView
                  chatType="party"
                  showChatInfo={showChatInfo}
                  setShowChatInfo={setShowChatInfo}
                  headerBackButton={showChatInfo}
                  refreshList={loadChatList}
                  unreadMessages={unreadMessages}
                />
              </Route>
              <Route path="*">
                <div className={classes.noChats}>
                  <img src="/chats.svg" />
                  <p>{t("chats.selectParty")}</p>
                </div>
              </Route>
            </Switch>
          </main>
        </div>
        <PartyDialog
          ref={partyDialog}
          onSubmit={submitPartyDialog}
          selectedDate={selectedChip === 0 ? selectedDate : null}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  main: {
    height: "calc(100% - 20px)",
    margin: "0px 20px 20px 20px",
    flex: 2,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  chatList: {
    flex: 1,
    minHeight: 0,
    height: "calc(100% - 20px)",
    display: "flex",
    flexDirection: "column",
    maxWidth: 350,
    minWidth: 324,
    marginLeft: 30,
    marginBottom: 20,
    marginRight: -10,
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
    overflow: "hidden",
  },
  sideMenu: {
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  search: {
    background: "#F3F9FC",
    borderRadius: 10,
    margin: "20px 10px",
    width: "100%",
    height: 30,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  wrapperSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: "0 10px",
    maxWidth: "97%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  inputBase: {
    marginLeft: "10px",
  },
  searchBtn: {
    position: "relative",
    top: 3,
    fontSize: "1.2rem",
  },
  chatTab: {
    display: "flex",
    padding: 10,
    paddingTop: 0,
    flexWrap: "no-wrap",
    flexDirection: "row",
    overflowX: "scroll",
    overflowY: "hidden",
    ...theme.scrollbars,
  },
  chip: {
    marginRight: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    padding: "5px 10px",
    color: "#333E48",
    backgroundColor: "#fff",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    textTransform: "none",
    fontSize: "12px",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(250,250,250)",
      transition: "all 0.1s easy",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 12px 0 16px",
    height: 80,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(160,160,160,0.2)",
    },
  },
  listItemSelected: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 12px 0 16px",
    height: 80,
    backgroundColor: "#E8E8E8",
    cursor: "pointer",
  },
  mainListItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "20px 0",
    overflow: "hidden",
    wordWrap: "normal",
    width: "100%",

    "& .MuiListItemText-primary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },

    "& .MuiListItemText-secondary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  avatar: {
    width: 45,
    height: 45,
    fontWeight: "bold",
  },
  skeletonWrapper: {
    display: "flex",
    padding: "0 12px 0 16px",
    height: 80,
    marginTop: 20,
  },
  chatInfoIcon: {
    color: "#333E48",
    "&:hover": {
      cursor: "pointer",
      color: "#BEBEBE",
    },
  },
  initials: {
    marginRight: 20,
    width: 40,
    height: 40,
    color: "#fff",
    backgroundColor: "#bdbdbd",
    borderRadius: "50%",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timestamp: {
    fontSize: "0.8rem",
    color: "#bdbdbd",
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  chatType: {
    paddingLeft: 7,
    paddingRight: 7,
    height: 20,
    borderRadius: 10,
    justifyContent: "center",
    color: "#333",
    fontSize: 12,
    fontWeight: "500",
    paddingTop: 1,
  },
  noChats: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: "50%",
    },

    "& p": {
      marginTop: 0,
      color: "rgb(120,120,120)",
    },
  },
  calendar: {
    "& .PrivatePickersSlideTransition-root": {
      minHeight: 230,

      "& div[role = cell]": {
        flex: 1,
        display: "flex",
        alignItems: "center",
        flexBase: 36,
      },

      "& .MuiPickersDay-root": {
        flex: 1,
        maxWidth: 36,
      },
    },

    "& .MuiTypography-caption": {
      width: "unset !important",
      flex: 1,
    },
  },
  calendarDay: {
    flex: 1,
    height: 36,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  calendarDots: {
    display: "flex",
    justifyContent: "center",
    height: 4,
    marginTop: -4,
    zIndex: 2,
    transform: "translateY(-5px)",

    "& .dot": {
      width: 4,
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.palette.primary.main,
      marginLeft: 1,
      marginRight: 1,
    },
  },
  calendarDotsActive: {
    "& .dot": {
      backgroundColor: "white",
    },
  },
  unreadDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    display: "inline-block",
    marginLeft: 5,
    marginRight: -15,
  },
}));

export default Parties;
