import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { HeaderBar, DeleteDialog, PartyDialog, TemplateList } from "components";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import _ from "lodash";
import { Switch, Route } from "react-router-dom";
import TemplateCreate from "src/components/templateList/TemplateCreate";
import short from "short-uuid";
import {
  AppBar,
  ActionBar,
  NavMenu,
  TemplateDialog,
  ShareTemplateDialog,
  FolderDialog,
  MoveToDialog,
  EditMediaDialog,
  // DeleteDialog,
  // HeaderBar,
} from "components";
import TemplateTable from "../templates/table";

const translator = short();

const TemplateNew = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const session = useSelector((state) => state.session);
  const templates = useSelector((state) => state.templates);
  const templateTrash = useSelector((state) => state.templateTrash);
  const {
    GetTemplate,
    GetTemplates,
    AddTemplate,
    ShareTemplate,
    RevokeSharedTemplate,
    CreateTemplateFolder,
    TemplateAddToFolder,
    RemoveTemplate,
    EditTemplate,
    TemplateTrash,
    RestoreTemplate,
    SharedTemplateAddToFolder,
    RemoveSharedTemplate,
    RestoreSharedTemplate,
  } = useActions();

  const [path, setPath] = React.useState(
    !window.location.hash
      ? []
      : window.location.hash
          .substr(1)
          .split("/")
          .map((p) => (p === "trash" ? p : translator.toUUID(p)))
  );

  const [visible, setVisible] = React.useState();

  const newFolderDialog = React.useRef();
  const showNewFolderDialog = () => {
    newFolderDialog.current.open();
  };
  const submitNewFolderDialog = async (title) => {
    await CreateTemplateFolder(title, path.join("/"));
    await GetTemplates();
  };

  /* recursively move files and folders */
  const moveFileOrFolder = async (templateId, newPath) => {
    const m = templates.find((m) => m.id === templateId);
    if (!m.isFolder) {
      if (m.sharedBy) await SharedTemplateAddToFolder(m.shareToken, newPath);
      else await TemplateAddToFolder(templateId, newPath);
      return;
    }
    const oldPath = !m.path ? templateId : `${m.path}/${templateId}`;
    const folderContents = templates.filter((m) => m.path === oldPath);
    // move the folder itself
    const { error } = await TemplateAddToFolder(templateId, newPath);
    if (error) return; // catch if moving was prevented for whatever reason
    for (const folderItem of folderContents) {
      // move all folder contents
      await moveFileOrFolder(
        folderItem.id,
        !newPath ? templateId : `${newPath}/${templateId}`
      );
    }
  };

  const moveToDialog = React.useRef();
  const showMoveToDialog = (templateId) => {
    moveToDialog.current.open(templates, templateId);
  };
  const submitMoveToDialog = async (templateId, folderId) => {
    let newPath = [];
    if (folderId !== "home") {
      const folder = templates.find((m) => m.id === folderId);
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
    }

    if (Array.isArray(templateId)) {
      for (let i = 0; i < templateId.length; i++) {
        await moveFileOrFolder(templateId[i], newPath.join("/"));
      }
    } else {
      await moveFileOrFolder(templateId, newPath.join("/"));
    }
    await GetTemplates();
  };

  const openFolder = (folder) => {
    window.location.hash = [
      ...(folder.path ? folder.path.split("/") : []),
      folder.id,
    ]
      .map((p) => translator.fromUUID(p))
      .join("/");
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };
  const openTrash = () => {
    window.location.hash = "trash";
    setPath(["trash"]);
    TemplateTrash();
  };

  const editFolderDialog = React.useRef();
  const [editItem, setEditItem] = React.useState();
  const editFolder = (m) => {
    setEditItem(m);
    let name = m.title;
    editFolderDialog.current.open(name);
  };
  const submitEditDialog = async (name) => {
    await EditTemplate(editItem.id, name);
    await GetTemplates();
  };

  const deleteDialog = React.useRef();
  const [deleteItem, setDeleteItem] = React.useState();
  const removeFolder = (m) => {
    setDeleteItem(m);
    deleteDialog.current.open();
  };

  const submitRemove = async () => {
    if (Array.isArray(deleteItem)) {
      for (let i = 0; i < deleteItem.length; i++) {
        if (deleteItem[i]) {
          if (deleteItem[i].userId === session?.userId) {
            if (deleteItem[i].shareToken)
              await RevokeSharedTemplate(deleteItem[i].id);
            await RemoveTemplate(deleteItem[i].id);
          } else await RemoveSharedTemplate(deleteItem[i].shareToken);
        }
      }
    } else {
      if (deleteItem) {
        if (deleteItem.userId === session?.userId) {
          if (deleteItem.shareToken) await RevokeSharedTemplate(deleteItem.id);
          await RemoveTemplate(deleteItem.id);
        } else await RemoveSharedTemplate(deleteItem.shareToken);
      }
    }
    await TemplateTrash();
    await GetTemplates();
  };

  const handleRestoreTemplate = async (template) => {
    if (template.userId !== session?.userId) {
      if (template.shareToken) await RestoreSharedTemplate(template.shareToken);
    } else {
      await RestoreTemplate(template.id);
    }
    await TemplateTrash();
    await GetTemplates();
  };

  // const { t } = useTranslation();

  const init = async () => {
    await GetTemplates();
    await TemplateTrash();
  };
  React.useEffect(() => {
    init();
    document.title = `${t("templates.title")} - proWIN Messenger`;
  }, []);

  const templateDialog = React.useRef();
  const handleAdd = () => {
    templateDialog.current.open();
  };

  const submitTemplateDialog = async (title) => {
    let { response } = await AddTemplate(title);
    if (path.length > 0 && response?.data)
      await TemplateAddToFolder(response.data.id, path.join("/"));
    await GetTemplates();
    if (response && response.data)
      history.push(`/templates/${response.data.id}`);
  };

  const shareTemplateDialog = React.useRef();
  const handleShare = async (shareTemplate) => {
    const templateId = shareTemplate.id;
    const { response, error } = await ShareTemplate(templateId);
    if (error) {
      alert(t("general.tryAgainLater"));
      return;
    }
    const { shareToken } = response.data;
    const template = templates.find((t) => t.id === templateId);
    shareTemplateDialog.current.open(template.title, shareToken, templateId);
    GetTemplates();
  };
  const revokeTemplateSharing = async (templateId) => {
    await RevokeSharedTemplate(templateId);
    await GetTemplate(templateId);
  };

  const getTemplatesInPath = () => {
    if (!path || path.length === 0) {
      setVisible(templates.filter((m) => !m.path));
    } else {
      if (path[0] === "trash") setVisible(templateTrash);
      else setVisible(templates.filter((m) => m.path === path.join("/")));
    }
  };
  React.useEffect(() => {
    getTemplatesInPath();
  }, [path, templates]);

  const handleSetPath = (folderId) => {
    setSearch();
    if (folderId === "home" || !folderId) {
      history.replace(window.location.pathname);
      setPath([]);
      return;
    }
    const folder = templates.find((m) => m.id === folderId);
    window.location.hash = [
      ...(folder.path ? folder.path.split("/") : []),
      folder.id,
    ]
      .map((p) => translator.fromUUID(p))
      .join("/");
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };

  const [search, setSearch] = React.useState("");

  const applySearch = (item) => {
    const searchVal = search.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result = result && searchTerm.test(item.title.toLowerCase());
    }
    return result;
  };

  React.useEffect(() => {
    if (!search) {
      getTemplatesInPath();
      return;
    }
    setVisible(templates.filter((t) => !t.isFolder).filter(applySearch));
  }, [search]);

  const { GetParties, AddParty, UpdateParty, RemoveParty } = useActions();

  const partyDialog = React.useRef();
  const [showChatInfo, setShowChatInfo] = React.useState(false);
  const handleBack = () => setShowChatInfo(false);

  const submitPartyDialog = async (
    title,
    start,
    expiration,
    host,
    manager,
    password,
    restrictedMessaging
  ) => {
    if (editItem) {
      await UpdateParty(
        editItem.id,
        title,
        start,
        expiration,
        host,
        manager,
        password,
        restrictedMessaging
      );

      const partyId = editItem.shortId;
      let socket = io(process.env.REACT_APP_API_URL, {
        transports: ["websocket"],
        reconnection: true, // whether to reconnect automatically
        reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
        reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
        reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
        randomizationFactor: 0.5,
      });
      socket.emit("updateRestriction", {
        partyId,
        restrictedMessaging,
      });
      socket.off();
    } else {
      await AddParty(
        title,
        start,
        expiration,
        host,
        manager,
        password,
        restrictedMessaging
      );
    }
    await GetParties();
  };
  const handleRemove = (e, p) => {
    e.stopPropagation();
    setDeleteItem(p);
    deleteDialog.current.open();
  };

  const headerButtons = [
    {
      label: "New Template",
      icon: <ViewQuiltIcon />,
      onClick: handleAdd,
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="templates"
      />
      <Container className={classes.screen} data-testid="screen">
        <HeaderBar
          title="Template"
          buttons={headerButtons}
          onBack={handleBack}
          showBackButton={showChatInfo}
        />
        <CssBaseline />
        <div style={{ display: "flex" }}>
          <div className={classes.sideMenu}>
            <TemplateList />
          </div>

          {/* Chat view */}
          <main className={classes.main}>
            {!!visible && (
              <TemplateCreate
                templates={visible}
                searchValue={search}
                onShare={handleShare}
                onMoveTo={showMoveToDialog}
                removeFolder={removeFolder}
                editFolder={editFolder}
                openFolder={openFolder}
                openTrash={path.length === 0 ? openTrash : undefined}
                isTrash={path?.[0] === "trash"}
                restoreTemplate={handleRestoreTemplate}
              />
            )}

            <TemplateDialog
              ref={templateDialog}
              onSubmit={submitTemplateDialog}
            />
            <EditMediaDialog
              ref={editFolderDialog}
              onSubmit={submitEditDialog}
            />
            <DeleteDialog
              ref={deleteDialog}
              type="media"
              onSubmit={submitRemove}
            />
            <FolderDialog
              ref={newFolderDialog}
              onSubmit={submitNewFolderDialog}
            />
            <MoveToDialog ref={moveToDialog} onSubmit={submitMoveToDialog} />
            <MoveToDialog
              ref={moveToDialog}
              onSubmit={submitMoveToDialog}
              pathHomeLabel={t("templates.homeTitle")}
            />
            <ShareTemplateDialog
              ref={shareTemplateDialog}
              onRevoke={revokeTemplateSharing}
            />
          </main>
        </div>
        <PartyDialog ref={partyDialog} onSubmit={submitPartyDialog} />
        <DeleteDialog ref={deleteDialog} type="party" onSubmit={submitRemove} />
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    height: "97vh",
    margin: "20px 0 ",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: "50px 50px",
    borderBottomLeftRadius: "50px 50px",
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
  },
  main: {
    margin: "10px 20px 20px 30px",
    width: "100%",
    height: "97%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 12,
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
    backgroundColor: "#fff",
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
}));

export default TemplateNew;
