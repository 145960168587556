import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

function Search() {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");

  const { t } = useTranslation();

  return (
    <div className={classes.rootBlue}>
      <InputBase
        className={classes.inputBase}
        placeholder={t("chats.searchChat")}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        style={{
          fontSize: "13px",
          color: "#000",
        }}
      />
      <IconButton
        className={classes.searchBtn}
        type="submit"
        aria-label="search"
        disableRipple
        color="primary"
      >
        <div>
          {searchValue.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon onClick={() => setSearchValue(() => "")} />
          )}
        </div>
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  rootBlue: {
    background: "#F3F9FC",
    borderRadius: 15,
    width: 280,
    height: 30,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  inputBase: {
    marginLeft: "10px",
  },
  searchBtn: {
    position: "relative",
    top: 3,
    fontSize: "1.2rem",
  },
}));

export default Search;
