const { useSelector } = require("react-redux");
const { useActions } = require("src/redux/actions");

const useForwardMessages = () => {
  const { DuplicateFile, SendMessage, SendGroupMessage, SendDirectMessage } =
    useActions();

  const session = useSelector((state) => state.session || {});
  const userId = session.userId || session.id;
  const guestId = session.id;

  const selectedProfile = useSelector(
    (state) => state.settings?.[state.session?.userId]?.selectedProfile
  );

  return async (mediaItems, selectedChat, chatType) => {
    for (const msg of mediaItems) {
      let newFile = {};
      const isMedia =
        msg.mediaAttachmentType ||
        (msg.messageType !== "text" && !msg.messageType?.startsWith("heading"));
      if (isMedia) {
        let origin, type;
        if (msg.messageType === "text" && msg.mediaAttachment) {
          origin = `${msg.mediaAttachmentOwner || msg.sender}/${
            msg.mediaAttachment
          }`;
          type = msg.mediaAttachmentType;
        }
        if (msg.messageType === "photo") {
          origin = `${msg.groupId || msg.partyId || msg.sender}/${msg.message}`;
          type = "image";
        }
        if (msg.messageType === "voice") {
          origin = `${msg.groupId || msg.partyId}/${msg.message}`;
          type = "audio";
        }

        let { response, error } = await DuplicateFile(origin, type, false);

        newFile = {
          mediaAttachment: response?.data.id,
          mediaAttachmentType: response?.data.mediaType,
        };
      }
      // if forwarding to another GROUP chat
      if (chatType === "group") {
        if (isMedia) {
          await SendGroupMessage(
            selectedChat,
            false,
            null,
            userId,
            selectedProfile,
            "text",
            msg.message,
            newFile.mediaAttachment,
            newFile.mediaAttachmentType,
            msg.mediaExtension,
            undefined,
            userId,
            msg.description
          );
        } else {
          await SendGroupMessage(
            selectedChat,
            false,
            null,
            userId,
            selectedProfile,
            msg.messageType,
            msg.message,
            undefined
          );
        }
        // if forwarding to a PARTY chat
      } else if (chatType === "party") {
        if (isMedia) {
          await SendMessage(
            guestId,
            selectedChat,
            false,
            null,
            userId,
            "text",
            msg.message,
            newFile.mediaAttachment,
            newFile.mediaAttachmentType,
            msg.mediaExtension,
            undefined,
            userId,
            msg.description
          );
        } else {
          await SendMessage(
            guestId,
            selectedChat,
            false,
            null,
            userId,
            msg.messageType,
            msg.message
          );
        }
        // if forwarding to a DIRECT chat
      } else {
        if (isMedia) {
          await SendDirectMessage(
            selectedChat,
            false,
            null,
            userId,
            "text",
            msg.title || "",
            newFile.mediaAttachment,
            newFile.mediaAttachmentType,
            msg.mediaExtension,
            undefined,
            userId,
            msg.description
          );
        } else {
          await SendDirectMessage(
            selectedChat,
            false,
            null,
            userId,
            "text",
            msg.message || ""
          );
        }
      }
    }
  };
};

export default useForwardMessages;
