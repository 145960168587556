import axios from "axios";
import idx from "idx";
import base64 from "base-64";

export const setAccessToken = async (token) => {
  if (!token) await localStorage.removeItem("accessToken");
  else await localStorage.setItem("accessToken", token);
};

export const getAccessToken = async () => {
  return await localStorage.getItem("accessToken");
};

export const setRefreshToken = async (token) => {
  if (!token) await localStorage.removeItem("refreshToken");
  else await localStorage.setItem("refreshToken", token);
};

export const setUserType = async (type) => {
  if (!type) await localStorage.removeItem("userType");
  else await localStorage.setItem("userType", type);
};

export const getUserType = async () => {
  return await localStorage.getItem("userType");
};

export const setGuestCredentials = async (userId, secretKey) => {
  if (!userId) await localStorage.removeItem("guestCredentials");
  else
    await localStorage.setItem(
      "guestCredentials",
      base64.encode(`${userId}:${secretKey}`)
    );
};

export const getGuestCredentials = async () => {
  return await localStorage.getItem("guestCredentials");
};

const baseURL = process.env.REACT_APP_API_URL;

const baseAPI = axios.create({
  baseURL,
});

baseAPI.interceptors.request.use(async (request) => {
  const userType = await getUserType();
  if (userType === "guest") {
    const guestCredentials = await getGuestCredentials();
    if (guestCredentials) {
      request.headers["Authorization"] = `Basic ${guestCredentials}`;
    }
    return request;
  }
  const accessToken = await getAccessToken();
  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return request;
});

const api = async (
  session,
  guestId,
  endpoint,
  method = "get",
  body,
  contentType
) => {
  const headers = {
    ...(await createAuthorizationHeader(session, guestId)),
    "content-type": contentType || "application/json",
  };
  if (method === "get" || method === "delete")
    return await baseAPI[method](endpoint, { headers });
  return await baseAPI[method](endpoint, body ? JSON.stringify(body) : body, {
    headers,
  });
};

const createAuthorizationHeader = async (session, guestId) => {
  if (guestId)
    return {
      Authorization: `Basic ${base64.encode(`${guestId}:`)}`,
    };
  if (!session || !session.accessToken) return {};
  return { Authorization: `Bearer ${session.accessToken}` };
};

export const Auth = {
  token: async (authCode) =>
    await api(null, null, "/auth/token", "post", {
      code: authCode,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    }),
  refresh: async (refreshToken) =>
    await api(null, null, "/auth/refresh", "post", { refreshToken }),
  claim: async (session, email, languageCode) =>
    await api(session, session.id, "/auth/claim", "post", {
      email,
      languageCode,
    }),
  verifyAccountClaim: async (session, verificationCode) =>
    await api(session, session.id, `/auth/claim/${verificationCode}`, "put"),
  guestLogin: async (session, email, languageCode) =>
    await api(session, null, "/auth/login", "post", {
      email,
      languageCode,
    }),
  verifyGuestLogin: async (session, email, verificationCode) => {
    const response = await api(session, null, "/auth/verify", "post", {
      email,
      verificationCode,
    });
    const { id, secretKey } = response?.data || {};
    setAccessToken();
    setRefreshToken();
    setUserType("guest");
    setGuestCredentials(id, secretKey);
    return response;
  },
};

export const Import = {
  start: async (session, token) =>
    await api(session, null, "/import/start", "post", { token }),
  file: async (session, file) =>
    await api(session, null, "/import/file", "post", { file }),
  media: async (session, mediaId) =>
    await api(session, null, "/import/media", "post", { mediaId }),
  message: async (session, id, partyId) =>
    await api(session, null, "/import/message", "post", { id, partyId }),
  party: async (session, partyId) =>
    await api(session, null, "/import/party", "post", { partyId }),
};

export const Guest = {
  login: async (firstName, lastName) => {
    const response = await api(null, null, "/guests/register", "post", {
      firstName,
      lastName,
    });
    const { id, secretKey } = response?.data?.Item || {};
    setAccessToken();
    setRefreshToken();
    setUserType("guest");
    setGuestCredentials(id, secretKey);
    return response;
  },
  updateProfile: async (session, profile, profilePic) => {
    let response = await api(session, null, "/guests/profile", "put", profile);
    let uploadUrl = response?.data?.uploadUrl;
    if (uploadUrl && profilePic) {
      await axios
        .put(uploadUrl, profilePic, {
          headers: {
            "Content-Type": profile.newProfilePicType,
          },
        })
        .catch((error) => {
          console.log("Error uploading", error);
        });
    }
    return response;
  },
  get: async (session, userId) => await api(session, null, `/guests/${userId}`),
};

export const Profile = {
  getProfiles: async (session) => await api(session, null, "/profile"),
  getUser: async (session) => await api(session, null, "/profile/user"),
  getProfile: async (session, profileId) =>
    await api(session, null, `/profile/${profileId}`),
  update: async (session, profileId, profile, profilePic) => {
    let response = await api(
      session,
      null,
      `/profile/${profileId}`,
      "put",
      profile
    );
    let uploadUrl = response?.data?.uploadUrl;
    if (uploadUrl && profilePic) {
      await axios.put(
        uploadUrl,
        new Blob([profilePic], { type: profile.newProfilePicType }),
        {
          headers: {
            "Content-Type": profile.newProfilePicType,
          },
        }
      );
    }
    return response;
  },
  add: async (session, profile, profilePic) => {
    let response = await api(session, null, `/profile`, "post", profile);
    let uploadUrl = response?.data?.uploadUrl;
    if (uploadUrl && profilePic) {
      await axios.put(
        uploadUrl,
        new Blob([profilePic], { type: profile.newProfilePicType }),
        {
          headers: {
            "Content-Type": profile.newProfilePicType,
          },
        }
      );
    }
    return response;
  },
  delete: async (session, profileId) =>
    await api(session, null, `/profile/${profileId}`, "delete"),
  setMain: async (session, profileId) =>
    await api(session, null, `/profile/setMain/${profileId}`, "post"),
};

export const Media = {
  get: async (session) => await api(session, null, "/media"),
  add: async (
    session,
    contentType,
    file,
    title,
    path,
    mediaType,
    mediaExtension,
    onProgress
  ) => {
    let data = await api(session, null, "/media", "post", {
      title,
      path,
      mediaType,
      mediaExtension,
      contentType,
    });
    let uploadUrl = data.data.uploadUrl;
    await axios
      .put(uploadUrl, new Blob([file], { type: contentType }), {
        onUploadProgress: onProgress,
        headers: {
          "Content-Type": contentType,
        },
      })
      .catch((error) => {
        if (error.response) {
          //do something
        } else if (error.request) {
          //do something else
        } else if (error.message) {
          //do something other than the other two
        }
      });

    return data.data.id;
  },
  convert: async (session, mediaId, isPortrait, noAudio) =>
    await api(session, null, `/media/convert/${mediaId}`, "put", {
      isPortrait,
      noAudio,
    }),
  getConversionStatus: async (session, mediaId) =>
    await api(session, null, `/media/convert/${mediaId}`),
  remove: async (session, mediaId) =>
    await api(session, null, `/media/${mediaId}`, "delete"),
  trash: async (session) => await api(session, null, "/media/trash"),
  restore: async (session, mediaId) =>
    await api(session, null, `/media/${mediaId}/restore`, "put"),
  edit: async (session, mediaId, name) =>
    await api(session, null, `/media/${mediaId}`, "put", { name }),
  createFolder: async (session, title, mediaType, path) =>
    await api(session, null, `/media/folder`, "post", {
      title,
      mediaType,
      path,
    }),
  addToFolder: async (session, mediaId, path) =>
    await api(session, null, `/media/folder`, "put", { mediaId, path }),
  duplicate: async (session, origin, type, path, title) =>
    await api(session, null, `/media/duplicate`, "post", {
      origin,
      type,
      saveInLibrary: true,
      path,
      title,
    }),
};

export const Templates = {
  list: async (session) => await api(session, null, "/templates"),
  shares: async (session) => await api(session, null, "/templates/shares"),
  shareTrash: async (session) =>
    await api(session, null, "/templates/shareTrash"),
  get: async (session, templateId) =>
    await api(session, null, `/templates/${templateId}`),
  add: async (session, title) =>
    await api(session, null, "/templates/create", "post", { title }),
  edit: async (session, templateId, name) =>
    await api(session, null, `/templates/${templateId}`, "put", {
      name,
    }),
  createFolder: async (session, title, path) =>
    await api(session, null, `/templates/folder`, "post", {
      title,
      path,
    }),
  addToFolder: async (session, templateId, path) =>
    await api(session, null, `/templates/folder`, "put", { templateId, path }),
  addShareToFolder: async (session, shareToken, path) => {
    return await api(session, null, `/templates/folderShare`, "put", {
      shareToken,
      path,
    });
  },
  remove: async (session, templateId) =>
    await api(session, null, `/templates/${templateId}`, "delete"),
  trash: async (session) => await api(session, null, "/templates/trash"),
  restore: async (session, templateId) =>
    await api(session, null, `/templates/${templateId}/restore`, "put"),
  removeShare: async (session, shareToken) =>
    await api(session, null, `/templates/trashShare/${shareToken}`, "put"),
  restoreShare: async (session, shareToken) =>
    await api(session, null, `/templates/restoreShare/${shareToken}`, "put"),
  apply: async (session, templateId) =>
    await api(session, null, `/templates/${templateId}/apply`),
  // Template new functions
  addMessage: async (session, templateId, message, contentType) => {
    const response = await api(
      session,
      null,
      `/templates/${templateId}/sendMessage`,
      "post",
      message
    );
    let uploadUrl = response?.data?.uploadUrl;
    if (
      (message.messageType === "voice" || message.messageType === "photo") &&
      uploadUrl
    ) {
      await axios.put(uploadUrl, message.message, {
        headers: {
          "Content-Type": contentType,
        },
      });
    }
    return response;
  },

  editMessage: async (session, templateId, message) =>
    await api(
      session,
      null,
      `/templates/${templateId}/editMessage/${message.id}`,
      "put",
      message
    ),
  deleteMessage: async (session, templateId, messageId) =>
    await api(
      session,
      null,
      `/templates/${templateId}/deleteMessage/${messageId}`,
      "delete"
    ),
  deleteMessages: async (session, templateId, messageIds) =>
    await api(session, null, `/templates/${templateId}/deleteMessages`, "put", {
      messageIds,
    }),
  moveMessage: async (session, templateId, messageId, newNextId) =>
    await api(
      session,
      null,
      `/templates/${templateId}/moveMessage/${messageId}`,
      "put",
      { newNextId }
    ),
  moveMessages: async (session, templateId, messageIds, newNextId) =>
    await api(session, null, `/templates/${templateId}/moveMessages`, "put", {
      messageIds,
      newNextId,
    }),
  sendTemplate: async (session, templateId, otherTemplateId, beforeMessageId) =>
    await api(
      session,
      null,
      `/templates/${templateId}/sendFullTemplate`,
      "post",
      {
        templateId: otherTemplateId,
        beforeMessageId,
      }
    ),
  acceptSharePreflight: async (session, shareToken) =>
    await api(session, null, `/templates/share/${shareToken}`),
  acceptShare: async (session, shareToken) =>
    await api(session, null, `templates/share/${shareToken}`, "post"),
  share: async (session, templateId) =>
    await api(session, null, `/templates/${templateId}/share`, "put"),
  revokeShare: async (session, templateId) =>
    await api(session, null, `/templates/${templateId}/revokeShare`, "put"),
};

export const Parties = {
  get: async (session) => await api(session, null, "/parties"),
  add: async (
    session,
    title,
    start,
    expiration,
    host,
    manager,
    password,
    restrictedMessaging,
    image
  ) => {
    const response = await api(session, null, "/parties", "post", {
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging,
      newPartyPictureType: image?.type,
    });

    let uploadUrl = response?.data?.uploadUrl;
    if (uploadUrl && image) {
      await axios.put(uploadUrl, new Blob([image], { type: image.type }), {
        headers: {
          "Content-Type": image.type,
        },
      });
    }

    return response;
  },
  update: async (
    session,
    partyId,
    title,
    start,
    expiration,
    host,
    manager,
    password,
    restrictedMessaging,
    image,
    deletePartyPicture
  ) => {
    const response = await api(session, null, `/parties/${partyId}`, "put", {
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging,
      newPartyPictureType: image?.type,
      deletePartyPicture,
    });

    let uploadUrl = response?.data?.uploadUrl;
    if (uploadUrl && image) {
      await axios.put(uploadUrl, new Blob([image], { type: image.type }), {
        headers: {
          "Content-Type": image.type,
        },
      });
    }

    return response;
  },
  remove: async (session, partyId) =>
    await api(session, null, `/parties/${partyId}`, "delete"),
  guests: async (session, guestId, partyId) =>
    await api(session, guestId, `/parties/${partyId}/guests`),
  removeGuest: async (session, partyId, guestId) =>
    await api(
      session,
      session?.accessToken ? null : guestId,
      `/parties/${partyId}/removeGuest/${guestId}`,
      "delete"
    ),
  removeMessage: async (session, guestId, partyId, messageId, fullDelete) =>
    await api(session, guestId, `/parties/${partyId}/deleteMessage`, "put", {
      messageId,
      fullDelete,
    }),
  removeMessages: async (session, guestId, partyId, messageIds) =>
    await api(session, guestId, `/parties/${partyId}/deleteMessages`, "put", {
      messageIds,
    }),
  duplicateFile: async (session, origin, type, saveInLibrary, path, title) =>
    await api(session, null, "/media/duplicate", "post", {
      origin,
      type,
      saveInLibrary,
      path,
      title,
    }),
  editMessage: async (session, guestId, partyId, messageId, message) =>
    await api(session, guestId, `/parties/${partyId}/editMessage`, "put", {
      messageId,
      message,
    }),
  updateSeen: async (session, guestId, partyId, timestamp) =>
    await api(session, guestId, `/parties/${partyId}/updateSeen`, "put", {
      guestId,
      timestamp,
    }),
  info: async (session, partyId) =>
    await api(session, null, `/parties/${partyId}`),
  joinPreflight: async (session, partyId) =>
    await api(session, null, `/parties/${partyId}/joinPreflight`),
  join: async (session, partyId, firstName, lastName, password) =>
    await api(session, null, `/parties/${partyId}/join`, "put", {
      firstName,
      lastName,
      password,
    }),
  changeName: async (session, partyId, id, firstName, lastName) =>
    await api(session, id, `/parties/${partyId}/changeName`, "put", {
      id,
      firstName,
      lastName,
    }),
  host: async (session, partyId, guestId) =>
    await api(session, null, `/parties/${partyId}/host`, "put", { guestId }),
  subscribe: async (session, partyId, id, pushToken) =>
    await api(session, id, `/parties/${partyId}/subscribe`, "put", {
      id,
      pushToken,
    }),
  getChat: async (session, guestId, partyId, from) =>
    await api(
      session,
      guestId,
      `/parties/${partyId}/chat${from ? `?from=${from}` : ""}`
    ),
  sendChat: async (
    session,
    guestId,
    partyId,
    fromTemplate,
    replyingTo,
    sender,
    messageType,
    message,
    mediaAttachment,
    mediaAttachmentType,
    mediaExtension,
    contentType,
    mediaAttachmentOwner,
    description
  ) => {
    const response = await api(
      session,
      guestId,
      `/parties/${partyId}/chat`,
      "post",
      {
        fromTemplate,
        replyingTo,
        sender,
        messageType,
        message,
        mediaAttachment,
        mediaAttachmentType,
        mediaExtension,
        mediaAttachmentOwner,
        description,
      },
      contentType
    );
    let uploadUrl = idx(response, (_) => _.data.uploadUrl);
    if ((messageType === "voice" || messageType === "photo") && uploadUrl) {
      await fetch(uploadUrl, {
        method: "PUT",
        body: message,
      });
    }
    return response;
  },
  applyTemplate: async (session, partyId, templateId) =>
    await api(session, null, `/parties/${partyId}/template`, "post", {
      templateId,
    }),
  editTemplate: async (session, partyId, messages) =>
    await api(session, null, `/parties/${partyId}/template`, "put", {
      messages,
    }),
  getTemplate: async (session, partyId) =>
    await api(session, null, `/parties/${partyId}/template`),
  sendFullTemplate: async (session, partyId, templateId) =>
    await api(session, null, `/parties/${partyId}/sendFullTemplate`, "post", {
      templateId,
    }),
  chatTimestamp: async (session, partyId) =>
    await api(session, null, `/parties/${partyId}/chatTimestamp`),
};

//GroupChat
export const groupChats = {
  get: async (session) => await api(session, null, "/groupchats"),
  add: async (session, title, restrictedMessaging, image) => {
    const response = await api(session, null, "/groupchats", "post", {
      title,
      restrictedMessaging,
      imageType: image?.type,
    });

    let uploadUrl = response?.data?.uploadUrl;
    if (uploadUrl && image) {
      await axios.put(uploadUrl, new Blob([image], { type: image.type }), {
        headers: {
          "Content-Type": image.type,
        },
      });
    }

    return response;
  },
  initMessage: async (session, groupId, limit) =>
    await api(
      session,
      `/groupchats/${groupId}/chat/initialLoadMessage?groupId=${groupId}&limit=${limit}`
    ),

  update: async (
    session,
    groupId,
    title,
    restrictedMessaging,
    image,
    deleteGroupPicture
  ) => {
    const response = await api(session, null, `/groupchats/${groupId}`, "put", {
      title,
      restrictedMessaging,
      newImageType: image?.type,
      deleteGroupPicture,
    });

    let uploadUrl = response?.data?.uploadUrl;
    if (uploadUrl && image) {
      await axios.put(uploadUrl, new Blob([image], { type: image.type }), {
        headers: {
          "Content-Type": image.type,
        },
      });
    }

    return response;
  },
  joinPreflight: async (session, token) =>
    await api(session, null, `/groupchats/joinPreflight/${token}`),
  join: async (session, token) =>
    await api(session, null, `/groupchats/join/${token}`),
  removeMessage: async (session, groupId, messageId, timestamp) =>
    await api(session, null, `/groupchats/${groupId}/deleteMessage`, "put", {
      messageId,
      timestamp,
    }),
  removeMessages: async (session, groupId, messageIds, timestamps) =>
    await api(session, null, `/groupchats/${groupId}/deleteMessages`, "put", {
      messageIds,
      timestamps,
    }),
  searchMessage: async (session, groupId, text) =>
    await api(session, null, `/groupchats/${groupId}/searchMessage`, "post", {
      text,
    }),
  editMessage: async (session, groupId, messageId, message, timestamp) =>
    await api(session, null, `/groupchats/${groupId}/editMessage`, "put", {
      messageId,
      message,
      timestamp,
    }),
  sendChat: async (
    session,
    groupId,
    fromTemplate,
    replyingTo,
    sender,
    profileId,
    messageType,
    message,
    mediaAttachment,
    mediaAttachmentType,
    mediaExtension,
    contentType,
    mediaAttachmentOwner,
    description
  ) => {
    const response = await api(
      session,
      null,
      `/groupchats/${groupId}/chat`,
      "post",
      {
        fromTemplate,
        replyingTo: replyingTo?.id,
        sender,
        profileId,
        messageType,
        message,
        mediaAttachment,
        mediaAttachmentType,
        mediaExtension,
        mediaAttachmentOwner,
        description,
      }
    );
    let uploadUrl = idx(response, (_) => _.data.uploadUrl);
    if ((messageType === "voice" || messageType === "photo") && uploadUrl) {
      await axios.put(uploadUrl, message, {
        // onUploadProgress: onProgress,
        headers: {
          "Content-Type": contentType,
        },
      });
    }
    return response;
  },
  getChat: async (session, groupId, timestamp) =>
    await api(session, null, `/groupchats/${groupId}/chat/${timestamp}`),
  getOlderMessages: async (session, groupId, timestamp) =>
    await api(
      session,
      null,
      `/groupchats/${groupId}/getOlderMessages/${timestamp}`
    ),
  getNewerMessages: async (session, groupId, timestamp) =>
    await api(
      session,
      null,
      `/groupchats/${groupId}/getNewerMessages/${timestamp}`
    ),
  getGroupChat: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}`),
  getGuests: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}/guests`),
  removeGuest: async (session, groupId, guestId) =>
    await api(
      session,
      null,
      `/groupchats/${groupId}/removeGuest/${guestId}`,
      "delete"
    ),
  leave: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}/leave`, "delete"),
  removeGroupChat: async (session, groupId) => {
    await api(session, null, `/groupchats/${groupId}`, "delete");
  },
  groupTimestamp: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}/chatTimestamp`),
  makeAdmin: async (session, groupId, guestId) =>
    await api(session, null, `/groupchats/${groupId}/admin/${guestId}`, "put"),
  removeAdmin: async (session, groupId, guestId) =>
    await api(
      session,
      null,
      `/groupchats/${groupId}/removeAdmin/${guestId}`,
      "put"
    ),
  makeMod: async (session, groupId, guestId) =>
    await api(session, null, `/groupchats/${groupId}/mod/${guestId}`, "put"),
  removeMod: async (session, groupId, guestId) =>
    await api(
      session,
      null,
      `/groupchats/${groupId}/removeMod/${guestId}`,
      "put"
    ),
  updateSeen: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}/updateSeen`, "put"),
  isTester: async (session, userId) =>
    await api(session, null, `/groupchats/${userId}/isTester`),
  inviteUser: async (session, otherUserId, groupId) =>
    await api(session, null, `/groupchats/inviteUser`, "post", {
      otherUserId,
      groupId,
    }),
  inviteVIP: async (session, prowinId, groupId) =>
    await api(session, null, `/groupchats/inviteVIP`, "post", {
      prowinId,
      groupId,
    }),
  share: async (session, groupId) =>
    await api(session, null, `/groupchats/share`, "post", {
      groupId,
    }),
  endShare: async (session, groupId) =>
    await api(session, null, `/groupchats/endShare`, "post", {
      groupId,
    }),
  getMedia: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}/media`),
  mute: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}/mute`),
  unmute: async (session, groupId) =>
    await api(session, null, `/groupchats/${groupId}/unmute`),
};

export const All = {
  chatList: async (session) => await api(session, null, "/chat/all"),
};

//Direct Chat
export const Chat = {
  get: async (session) => await api(session, null, "/chat"),
  suggestions: async (session) => await api(session, null, "/chat/suggestions"),
  add: async (session, otherUserId) =>
    await api(session, null, "/chat", "post", { otherUserId }),
  getChat: async (session, chatId, from) =>
    await api(session, null, `/chat/${chatId}${from ? `?from=${from}` : ""}`),
  initMessage: async (session, chatId, limit) =>
    await api(
      session,
      `/chat/${chatId}/initialLoadMessage?chatId=${chatId}&limit=${limit}`
    ),

  searchMessage: async (session, chatId, text) =>
    await api(session, null, `/chat/${chatId}/searchMessage`, "post", {
      text,
    }),
  removeDirectMessage: async (session, chatId, messageId) =>
    await api(session, null, `/chat/${chatId}/${messageId}`, "put"),
  removeDirectMessages: async (session, chatId, messageIds) =>
    await api(session, null, `/chat/${chatId}/deleteMessages`, "put", {
      messageIds,
    }),
  chatTimestamp: async (session, chatId) =>
    await api(session, null, `/chat/${chatId}/chatTimestamp`, "get"),
  sendDirectChat: async (
    session,
    chatId,
    fromTemplate,
    replyingTo,
    sender,
    messageType,
    message,
    mediaAttachment,
    mediaAttachmentType,
    mediaExtension,
    contentType,
    mediaAttachmentOwner,
    description
  ) => {
    const response = await api(session, null, `/chat/${chatId}`, "post", {
      fromTemplate,
      replyingTo,
      sender,
      messageType,
      message,
      mediaAttachment,
      mediaAttachmentType,
      mediaExtension,
      mediaAttachmentOwner,
      description,
    });
    let uploadUrl = idx(response, (_) => _.data.uploadUrl);
    if ((messageType === "voice" || messageType === "photo") && uploadUrl) {
      await axios.put(uploadUrl, message, {
        headers: {
          "Content-Type": contentType,
        },
      });
    }
    return response;
  },
  archive: async (session, chatId) =>
    await api(session, null, `/chat/${chatId}/archive`, "put"),
  unarchive: async (session, chatId) =>
    await api(session, null, `/chat/${chatId}/unarchive`, "put"),
  mute: async (session, chatId) =>
    await api(session, null, `/chat/${chatId}/mute`),
  unmute: async (session, chatId) =>
    await api(session, null, `/chat/${chatId}/unmute`),
};

export const Moderation = {
  report: async (session, contentType, id, description) =>
    await api(session, null, "/moderation/report", "post", {
      contentType,
      id,
      description,
    }),
  block: async (session, userId) =>
    await api(session, null, "/moderation/block", "post", {
      userId,
    }),
  unblock: async (session, userId) =>
    await api(session, null, "/moderation/unblock", "post", {
      userId,
    }),
  blocked: async (session) => await api(session, null, "/moderation/blocked"),
};
