import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";

const TableOfContents = ({ headings, messageList }) => {
  const classes = useStyles();

  const renderToc = (item) => {
    return (
      <div
        className={clsx([classes.tocHeading, classes[item.messageType]])}
        key={item.id}
      >
        <ChevronRightIcon />
        <a className={classes.tocLink} onClick={() => scrollToMessage(item.id)}>
          {item.message}
        </a>
      </div>
    );
  };

  const scrollToMessage = (messageId) => {
    const messageItem = document.getElementById(messageId);
    const messagePosition =
      messageItem.offsetTop - messageList.current.offsetTop;
    messageList.current.scrollTo({
      top: messagePosition,
      behaviour: "smooth",
    });
  };

  return <div className={classes.cardContent}>{headings.map(renderToc)}</div>;
};

const useStyles = makeStyles((theme) => ({
  tocHeading: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    paddingLeft: 15,
  },
  heading: {
    // maxWidth: "calc(100% - 15px)",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  heading2: {
    // maxWidth: "calc(100% - 30px)",
    marginLeft: 15,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  heading3: {
    // maxWidth: "calc(100% - 45px)",
    marginLeft: 30,
    fontWeight: "500",
    fontSize: "1em",
  },
  tocLink: {
    flex: 1,
    textDecoration: "none",
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 9,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.2em",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  cardTitle: {
    height: 50,
    paddingLeft: 20,
    display: "none",
    fontWeight: "bold",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    borderBottom: "1px solid #D8D8D8",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  cardContent: {
    flex: 1,
    paddingRight: 10,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
}));

export default TableOfContents;
