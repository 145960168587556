import React from "react";
import { makeStyles } from "@mui/styles";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import { ImageCarousel } from "components";
import _ from "lodash";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const MediaList = ({ media, party }) => {
  const classes = useStyles();

  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);

  const chatType = party.shortId ? "party" : party.chatId ? "direct" : "group";

  const renderPhotos = (item, index) => {
    const i = media.length - index - 1;

    const { src, srcSet } = generateImageSourceSet(
      item.messageType !== "photo"
        ? item.mediaAttachmentOwner || item.sender
        : chatType === "direct"
        ? party.chatId
        : party.id,
      item.messageType === "photo"
        ? item.message
        : item.mediaAttachmentType === "video"
        ? `${item.mediaAttachment}.0000001.jpg`
        : item.mediaAttachment,
      124,
      item.mediaAttachmentType === "application"
    );
    return (
      <div
        key={item.id}
        onClick={() => {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }}
        className={classes.item}
      >
        <img src={src} srcSet={srcSet} className={classes.thumbnail} />
        {item.mediaAttachmentType === "video" && (
          <PlayIcon className={classes.icon} />
        )}
        {item.mediaAttachmentType === "application" && (
          <PdfIcon className={classes.icon} />
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classes.mediaList}>
        {_.reverse([...media]).map(renderPhotos)}
      </div>
      <ImageCarousel
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        media={media}
        currentIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        party={party}
        chatType={chatType}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mediaList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: 10,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
  item: {
    height: 124,
    width: 124,
    marginRight: 10,
    marginBottom: 10,
    overflow: "hidden",
  },
  thumbnail: {
    cursor: "pointer",
    width: "100%",
    height: "100%",
  },
  icon: {
    cursor: "pointer",
    color: "white",
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    height: "100%",
    padding: "25%",
    position: "relative",
    top: "calc(-100% - 8px)",
  },
}));

export default MediaList;
