import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const InviteGuestDialog = ({ groupGuests = [], onSubmit }, ref) => {
  const classes = useStyles();
  const [guests, setGuests] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(guests);

  const [chatSuggestions, setChatSuggestions] = React.useState([]);
  const directChats = useSelector(
    (state) => state.allChatTypes?.filter((c) => !!c.chatId) || []
  );

  const { ChatSuggestions, GetChatList } = useActions();

  const [selected, setSelected] = React.useState([]);

  React.useEffect(async () => {
    const { response } = await ChatSuggestions();
    setChatSuggestions(response?.data || []);
  }, []);

  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setGuests(
        [...directChats, ...chatSuggestions].filter(
          (g) =>
            !groupGuests.find((guest) => guest.id === (g.otherUserId || g.id))
        )
      );
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setSelected([]);
  };

  const applySearch = React.useCallback((item, q) => {
    const searchVal = q.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^.*" + term + ".*$");
      result = result && searchTerm.test(item.title?.toLowerCase() || "");
    }
    return result;
  }, []);

  const changeQuery = (q, list = [...guests]) => {
    setQuery(q);
    if (!q.trim()) {
      setSearchResults(list);
      return;
    }
    const results = list.filter((m) => applySearch(m, q));
    setSearchResults(results);
  };

  const handleInvite = async () => {
    setOpen(false);
    await onSubmit(selected);
    setSelected([]);
    const { response: chatListResponse } = await GetChatList();
    const directChats = chatListResponse?.data?.filter((c) => !!c.chatId) || [];
    const { response } = await ChatSuggestions();
    setGuests(
      [...directChats, ...(response?.data || [])].filter(
        (g) =>
          !groupGuests.find((guest) => guest.id === (g.otherUserId || g.id))
      )
    );
  };

  const renderItem = (item, index) => {
    const isSelected = selected.includes(item.otherUserId || item.id);

    const { src, srcSet } = generateImageSourceSet(
      item.otherUserId || item.id,
      item.profilePic,
      40
    );
    return (
      <ListItem
        key={index}
        className={clsx(classes.listItem, {
          [classes.listItemSelected]: isSelected,
        })}
        onClick={() =>
          setSelected((selected) =>
            isSelected
              ? selected.filter((id) => id !== (item.otherUserId || item.id))
              : [...selected, item.otherUserId || item.id]
          )
        }
      >
        <ListItemAvatar>
          <Avatar src={src} srcSet={srcSet} />
        </ListItemAvatar>
        <ListItemText primary={item?.title} />
      </ListItem>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("chats.inviteToGroup")}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.search}>
          <InputBase
            className={classes.inputBase}
            placeholder={t("chats.searchGuest")}
            value={query}
            onChange={(q) => changeQuery(q.target.value)}
            style={{
              fontSize: "13px",
              color: "#000",
              width: 300,
            }}
          />
        </div>
        <List className={classes.list}>
          {!query && guests.map(renderItem)}
          {query && searchResults.map(renderItem)}
        </List>
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        <Button
          className={classes.buttonCreate}
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleInvite}
          disabled={selected.length < 1}
        >
          {t("chats.invite")}
        </Button>
        <Button
          className={classes.buttonCancel}
          onClick={handleClose}
          color="inherit"
        >
          {t("general.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  search: {
    background: "#F3F9FC",
    borderRadius: 5,
    width: "100%",
    height: 40,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    marginBottom: 10,
  },
  inputBase: {
    margin: "10px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  buttonCancel: {
    margin: 10,
    width: 270,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(160,160,160,0.2)",
    },
  },
  listItemSelected: {
    backgroundColor: "#E8E8E8",
  },
  buttonCreate: {
    width: 270,
    background: "#1E92CD",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
  },
  list: {
    padding: 0,
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
}));

export default React.forwardRef(InviteGuestDialog);
