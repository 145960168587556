import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";

const PhotoDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit, onCancel, selectedFiles, upload, libraryEnabled } = props;
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState();
  const [previews, setPreviews] = React.useState([]);
  const [saveToLibrary, setSaveToLibrary] = React.useState(false);
  const [disableCheckBox, setDisableCheckBox] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      setFiles(selectedFiles);
      setPreviews(
        selectedFiles.map((file, index) =>
          file.type.split("/")[0] === "image" ? URL.createObjectURL(file) : ""
        )
      );
    },
  }));

  React.useEffect(() => {
    if (!libraryEnabled || !files) return;
    if (files.find((f) => !f.type.startsWith("image"))) {
      setSaveToLibrary(true);
      setDisableCheckBox(true);
    }
  }, [files]);

  const handleClose = () => {
    setOpen(false);
    setSaveToLibrary(false);
    setDisableCheckBox(false);
    onCancel();
  };

  const handleSaveToLibrary = () => {
    setSaveToLibrary(!saveToLibrary);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    setSaveToLibrary(false);
    setDisableCheckBox(false);
    for (const preview of previews) {
      if (!!preview) URL.revokeObjectURL(preview);
    }
    onSubmit({ files, saveToLibrary });
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classes.wrapper}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {upload ? t("media.uploadTitle") : t("party.sendPhotoTitle")}:
        </DialogTitle>
        <DialogContent className={classes.content}>
          {files &&
            files.map((file, index) => (
              <div className={classes.zoneSize}>
                {file.type.split("/")[0] === "image" && (
                  <div className={classes.displayWrapper}>
                    <div className={classes.imageDisplay}>
                      <img
                        width="auto"
                        height="100"
                        src={previews[index]}
                        alt="preview"
                        className={classes.image}
                      ></img>
                    </div>
                    <span className={classes.title}>{file.name}</span>
                  </div>
                )}
                {file.type.split("/")[0] === "video" && (
                  <div className={classes.displayWrapper}>
                    <div className={classes.display}>
                      <PlayIcon />
                    </div>
                    <span className={classes.title}>{file.name}</span>
                  </div>
                )}
                {file.type.split("/")[0] === "audio" && (
                  <div className={classes.displayWrapper}>
                    <div className={classes.display}>
                      <GraphicEqIcon />
                    </div>
                    <span className={classes.title}>{file.name}</span>
                  </div>
                )}
                {file.type.split("/")[0] === "application" && (
                  <div className={classes.displayWrapper}>
                    <div className={classes.display}>
                      <PdfIcon />
                    </div>
                    <span className={classes.title}>{file.name}</span>
                  </div>
                )}
              </div>
            ))}
        </DialogContent>
        {libraryEnabled && (
          <FormControlLabel
            control={
              <Checkbox
                checked={saveToLibrary}
                onChange={handleSaveToLibrary}
                name="checkedB"
                color="primary"
                disabled={disableCheckBox}
              />
            }
            label={t("media.saveToLibrary")}
            className={classes.label}
          />
        )}
        {/* <div className={classes.spacer} /> */}
        <DialogActions>
          <Button
            style={{ width: 300, background: "#D8D8D8" }}
            onClick={handleClose}
            color="inherit"
          >
            {t("general.cancel")}
          </Button>
          <Button
            style={{ width: 300, background: "#1E92CD" }}
            type="submit"
            color="primary"
            variant="contained"
          >
            {upload ? t("general.upload") : t("general.send")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
  wrapper: {
    padding: 20,
  },
  preview: {
    maxWidth: "100%",
  },
  title: {
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "center",
  },
  displayWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // width: 550,
  },
  display: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 100,
    border: "solid",
    borderColor: "rgba(160, 160, 160, 0.6)",
    marginRight: 10,
  },
  imageDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 460,
    height: "100%",
    marginBottom: 10,
  },
  zoneSize: {
    // margin: 10,
    // width: 500,
  },
  content: {
    // display: "flex",
    // flexDirection: "row",
    width: 500,
    // padding: 30,
  },
  image: {
    margin: 10,
    width: 350,
    height: "100%",
  },
  label: {
    marginLeft: 30,
  },
}));

export default React.forwardRef(PhotoDialog);
