import React from "react";
import clsx from "clsx";
import { Button, Divider, IconButton } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import ChevronRight from "@mui/icons-material/ChevronRight";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

const MediaBreadCrumbs = ({
  path,
  media = [],
  setNewPath,
  drop,
  pathHomeLabel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [activePathHover, setActivePathHover] = React.useState();

  const handleSetPath = (id) => {
    setNewPath(id);
  };

  const handleDrop = (e, p) => {
    drop(e, p);
    setActivePathHover();
  };

  return (
    <>
      <div className={classes.subHeader}>
        <Button
          size="small"
          classes={{
            root: clsx(classes.pathButton, {
              [classes.pathButtonActive]: activePathHover === "home",
            }),
            label: classes.pathButtonLabel,
          }}
          onDrop={(e) => handleDrop(e, "home")}
          onClick={() => handleSetPath("home")}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={() => setActivePathHover("home")}
          onDragLeave={() => setActivePathHover()}
        >
          {pathHomeLabel}
        </Button>
        {path.length > 0 && (
          <ChevronRight fontSize="small" className={classes.pathSeparator} />
        )}
        {path.length > 2 && (
          <>
            <Button
              size="small"
              classes={{
                root: clsx(classes.pathButton, {
                  [classes.pathButtonActive]: activePathHover === "home",
                }),
                label: classes.pathButtonLabel,
              }}
              disabled
            >
              ...
            </Button>
            <ChevronRight fontSize="small" className={classes.pathSeparator} />
          </>
        )}
        {path.slice(-2).map((p, index) => (
          <React.Fragment key={p}>
            <Button
              size="small"
              classes={{
                root: clsx(classes.pathButton, {
                  [classes.pathButtonActive]: activePathHover === p,
                }),
                label: classes.pathButtonLabel,
              }}
              onDrop={p === "trash" ? () => {} : (e) => handleDrop(e, p)}
              onClick={p === "trash" ? () => {} : () => handleSetPath(p)}
              onDragOver={p === "trash" ? () => {} : (e) => e.preventDefault()}
              onDragEnter={
                p === "trash" ? () => {} : () => setActivePathHover(p)
              }
              onDragLeave={
                p === "trash" ? () => {} : () => setActivePathHover()
              }
            >
              {p === "trash"
                ? t("templates.trash")
                : media.find((m) => m.id === p)?.title}
            </Button>
            {index < path.slice(-2).length - 1 && (
              <ChevronRight
                fontSize="small"
                className={classes.pathSeparator}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <Divider style={{ color: "#BEBEBE" }} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  subHeader: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 20,
    paddingRight: 10,
    marginTop: -40,
    backgroundColor: "#F3F9FC",
  },
  pathButton: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "#333",
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 30,
    "& span": {
      pointerEvents: "none",
    },
    justifyContent: "flex-start",
    margin: `${theme.spacing(1)} 0`,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  pathButtonActive: {
    backgroundColor: "#ddd",
  },
  pathButtonLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  pathSeparator: {
    color: "#999",
    marginLeft: -5,
    marginRight: -5,
  },
}));

export default MediaBreadCrumbs;
