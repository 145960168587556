import React from "react";
import { Button, InputBase } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import { makeStyles } from "tss-react/mui";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const ClaimAccount = (props) => {
  const { emailSubmitted } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const [email, setEmail] = React.useState("");
  const { t, i18n } = useTranslation();
  const { ClaimAccount } = useActions();
  const lang = i18n.language.substring(0, 2);

  const session = useSelector((state) => state.session || {});
  console.log(session);

  const history = useHistory();

  const handleAccountClaim = async (e) => {
    e.preventDefault();
    const { error } = await ClaimAccount(email, lang);
    if (error) console.log(error);
    if (!error) {
      emailSubmitted();
    }
  };

  return (
    <div className={classes.loginContent}>
      <p className={classes.title}>
        {session.accountStatus === "claimed"
          ? `${t("general.currentEmail")}: ${session.accountEmail}\n\n${t(
              "general.changeEmail"
            )}:`
          : t("login.claimTitle")}
      </p>
      <div className={classes.inputSendCode}>
        <InputBase
          placeholder={t("login.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            width: 350,
            fontSize: "13px",
            color: "#000",
            marginTop: 3,
            marginLeft: 5,
          }}
        />
      </div>
      <div className={classes.buttonSendCode}>
        <div className={classes.undelineButtons}>
          <Button
            style={{
              textTransform: "none",
            }}
            onClick={() => history.goBack()}
          >
            {t("general.back")}
          </Button>
        </div>
        <Button
          style={{
            textTransform: "none",
            background: theme.palette.primary.main,
            color: "#FFF",
            boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
            padding: "3px 20px 0 20px",
            fontSize: 17,
            fontFamily: "DIN NEXT LT Pro",
          }}
          onClick={handleAccountClaim}
        >
          {session.accountStatus === "claimed"
            ? t("general.send")
            : t("login.claimBtn")}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    height: "100vh",
    backgroundColor: theme.palette.lightBlue.main,
    flex: 1,
    overflow: "hidden",
  },
  loginContent: {
    flex: 1,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  inputSendCode: {
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    height: 30,
    margin: "10px 20px",
    padding: 5,
  },
  buttonSendCode: {
    marginTop: 10,
    width: "100%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButtonSkip: {
    fontSize: 10,
    display: "flex",
  },
  undelineButtons: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.darkGrey.main,
    margin: 0,
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: 350,
    whiteSpace: "pre-wrap",
  },
}));

export default ClaimAccount;
