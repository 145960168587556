import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  FormControl,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import EmojiPicker from "emoji-picker-react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

const HeadingDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [messageType, setMessageType] = React.useState("heading");
  const [heading, setHeading] = React.useState("");
  const [showEmojis, setShowEmojis] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      setHeading("");
      setMessageType("heading");
    },
  }));

  const handleClose = () => {
    setShowEmojis(false);
    setOpen(false);
    setHeading("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowEmojis(false);
    setOpen(false);
    setHeading("");
    setMessageType("heading");
    onSubmit(heading, messageType);
  };

  const showEmojiKeyboard = () => {
    setShowEmojis(!showEmojis);
  };

  const onEmojiClick = (event, { emoji }) => {
    let {
      selectionStart,
      selectionEnd,
      value: oldMessage,
    } = document.getElementById("headingInput");
    var newMessage =
      oldMessage.slice(0, selectionStart) +
      emoji +
      oldMessage.slice(selectionEnd);
    setHeading(newMessage);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.sendHeadingTitle")}:</DialogTitle>
        <DialogContent style={{ padding: "0 24px " }}>
          <DialogContentText className={classes.sendHeadingText}>
            {t("party.sendHeading")}
          </DialogContentText>
          <FormControl>
            <Select
              className={classes.sendHeadingMenuItem}
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
            >
              <MenuItem value="heading">{t("party.heading")} 1</MenuItem>
              <MenuItem value="heading2">{t("party.heading")} 2</MenuItem>
              <MenuItem value="heading3">{t("party.heading")} 3</MenuItem>
            </Select>
          </FormControl>
          {showEmojis && (
            <div className={classes.emojiKeyboard}>
              <EmojiPicker
                onEmojiClick={onEmojiClick}
                groupNames={{
                  smileys_people: t("emojis.smileys_people"),
                  animals_nature: t("emojis.animals_nature"),
                  food_drink: t("emojis.food_drink"),
                  travel_places: t("emojis.travel_places"),
                  activities: t("emojis.activities"),
                  objects: t("emojis.objects"),
                  symbols: t("emojis.symbols"),
                  flags: t("emojis.flags"),
                  recently_used: t("emojis.recently_used"),
                }}
                disableSearchBar={true}
              />
            </div>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="headingInput"
              autoFocus
              margin="dense"
              label={t("party.heading")}
              type="text"
              fullWidth
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              className={classes.field}
              required
            />
            <IconButton
              onClick={showEmojiKeyboard}
              className={classes.emojiButton}
              size="small"
            >
              <EmojiEmotionsIcon />
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ width: 300, background: "#D8D8D8" }}
            onClick={handleClose}
            color="inherit"
          >
            {t("general.cancel")}
          </Button>
          <Button
            style={{ width: 300, background: "#1E92CD" }}
            type="submit"
            color="primary"
            variant="contained"
          >
            {t("general.send")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(2),
  },
  sendHeadingText: {
    marginBottom: theme.spacing(2),
  },
  sendHeadingMenuItem: {
    marginBottom: theme.spacing(2),
  },
  emojiKeyboard: {
    position: "fixed",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 8,
    marginTop: -328,
    zIndex: 1500,
  },
}));

export default React.forwardRef(HeadingDialog);
