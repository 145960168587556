import * as React from "react";
import Search from "./Search";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import ListIcon from "@mui/icons-material/List";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import {
  GuestList,
  TableOfContents,
  MediaList,
  DirectChatProfileInfo,
} from "components";
import clsx from "clsx";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChatListInfo = ({
  party,
  hideChatInfo,
  guests,
  onlineUsers,
  headings,
  messageList,
  media,
  chatType,
  determineRole,
  menuItems,
  filterMenuItems,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(
    chatType === "direct" ? "about" : "guests"
  );
  const [guestSearchResults, setGuestSearchResults] = React.useState();

  return (
    <div className={classes.wrapperList}>
      <div className={classes.mainChatList}>
        <div className={classes.search}>
          {activeTab === "guests" && (
            <Search guestList={guests} guestResults={setGuestSearchResults} />
          )}
          <IconButton onClick={hideChatInfo}>
            <CloseIcon />
          </IconButton>
        </div>
        {activeTab === "guests" && (
          <GuestList
            guests={guests}
            onlineUsers={onlineUsers}
            guestSearch={guestSearchResults}
            determineRole={determineRole}
            menuItems={menuItems}
            filterMenuItems={filterMenuItems}
          />
        )}
        {activeTab === "content" && (
          <TableOfContents headings={headings} messageList={messageList} />
        )}
        {activeTab === "about" && <DirectChatProfileInfo party={party} />}
        {activeTab === "media" && <MediaList media={media} party={party} />}
      </div>
      <div className={classes.tabSwitcher}>
        {chatType === "direct" && (
          <div
            className={clsx(classes.tabButton, {
              [classes.tabButtonActive]: activeTab === "about",
            })}
            onClick={() => setActiveTab("about")}
          >
            <div className="leftCorner" />
            <div className="content">
              <PersonIcon />
              <span className={classes.tabButtonLabel}>{t("chats.about")}</span>
            </div>
            <div className="rightCorner" />
          </div>
        )}
        {chatType !== "direct" && (
          <div
            className={clsx(classes.tabButton, {
              [classes.tabButtonActive]: activeTab === "guests",
            })}
            onClick={() => setActiveTab("guests")}
          >
            <div className="leftCorner" />
            <div className="content">
              <PeopleIcon />
              <span className={classes.tabButtonLabel}>
                {`${t("party.guests")} (${guests.length})`}
              </span>
            </div>
            <div className="rightCorner" />
          </div>
        )}
        {chatType === "party" && (
          <div
            className={clsx(classes.tabButton, {
              [classes.tabButtonActive]: activeTab === "content",
            })}
            onClick={() => setActiveTab("content")}
          >
            <div className="leftCorner" />
            <div className="content">
              <ListIcon />
              <span className={classes.tabButtonLabel}>
                {t("party.tocShort")}
              </span>
            </div>
            <div className="rightCorner" />
          </div>
        )}
        <div
          className={clsx(classes.tabButton, {
            [classes.tabButtonActive]: activeTab === "media",
          })}
          onClick={() => setActiveTab("media")}
        >
          <div className="leftCorner" />
          <div className="content">
            <PermMediaIcon />
            <span className={classes.tabButtonLabel}>{`${t("chats.media")} (${
              media?.length || 0
            })`}</span>
          </div>
          <div className="rightCorner" />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperList: {
    padding: 0,
  },
  mainChatList: {
    borderRadius: "10px 10px 0 0",
    backgroundColor: "white",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    background: "linear-gradient(#fff, #f6f6f6)",
    height: "calc(100% - 50px)",
    marginLeft: 10,
    width: 420,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    display: "flex",
    flexDirection: "column",
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    margin: 8,
  },
  ButtonChatList: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  bottomNav: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "stretch",
    marginLeft: 10,
    borderRadius: "0  0 12px 12px",
    flexDirection: "column",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    overflow: "hidden",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  buttonNav: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    background: "#fff",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    height: 35,
    "&:hover": {
      cursor: "pointer",
      width: "100%",
      backgroundColor: "#333E48",
      color: "#fff",
      fontSize: 10,
      height: 35,
    },
  },
  colorbkg: {
    background: "linear-gradient(#fff, #f6f6f6)",
  },
  tabSwitcher: {
    marginLeft: 10,
    display: "flex",
    background: "#fff",
    paddingBottom: 10,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  tabButton: {
    flex: 1,
    display: "flex",
    cursor: "pointer",

    "& div": {
      transition: "all 0.15s ease-in-out",
    },

    "& .leftCorner": {
      backgroundColor: "#fff",
      height: 40,
      width: 10,
      "&:after": {
        display: "block",
        content: '""',
        color: "transparent",
        height: 40,
        width: 10,
        backgroundColor: "#fff",
        borderTopRightRadius: 10,
      },
    },

    "& .rightCorner": {
      backgroundColor: "#fff",
      height: 40,
      width: 10,
      "&:after": {
        display: "block",
        content: '""',
        color: "transparent",
        height: 40,
        width: 10,
        backgroundColor: "#fff",
        borderTopLeftRadius: 10,
      },
    },

    "& .content": {
      flex: 1,
      height: 40,
      backgroundColor: "#fff",
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#555",
    },

    "&:hover": {
      "& .leftCorner": {
        backgroundColor: "#fafafa",
      },
      "& .rightCorner": {
        backgroundColor: "#fafafa",
      },
      "& .content": {
        backgroundColor: "#fafafa",
      },
    },
  },
  tabButtonActive: {
    "& .leftCorner": {
      backgroundColor: "#f6f6f6 !important",
    },
    "& .rightCorner": {
      backgroundColor: "#f6f6f6 !important",
    },
    "& .content": {
      backgroundColor: "#f6f6f6 !important",
      color: theme.palette.primary.main,
    },
  },
  tabButtonLabel: {
    marginLeft: 5,
    paddingTop: 2,
    fontSize: 15,
  },
}));

export default ChatListInfo;
