import {
  Button,
  Checkbox,
  Collapse,
  Fade,
  FormControlLabel,
  InputBase,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/styles";
import React from "react";
import { useActions } from "actions";
import { Trans, useTranslation } from "react-i18next";
import { routes } from "./../../router/allRoutes";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const RegisterGuest = (props) => {
  const { target } = props;
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [accepted, setAccepted] = React.useState(false);
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { GuestLogin } = useActions();

  const session = useSelector((state) => state.session);

  const handleGuestRegistration = async (e) => {
    e.preventDefault();
    await GuestLogin(firstName.trim(), lastName.trim());
  };

  return (
    <form onSubmit={handleGuestRegistration} className={classes.form}>
      <div className={classes.inputRegisterFirst}>
        <InputBase
          placeholder={t("party.firstName")}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          style={{
            fontSize: "13px",
            color: "#000",
          }}
        />
      </div>
      <div className={classes.inputRegisterLast}>
        <InputBase
          placeholder={t("party.lastName")}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          style={{
            fontSize: "13px",
            color: "#000",
          }}
        />
      </div>
      <Collapse in={!!firstName.trim() && !!lastName.trim()}>
        <Fade in={!!firstName.trim() && !!lastName.trim()}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={accepted}
                onChange={(e) => setAccepted(e.target.checked)}
              />
            }
            label={
              <Trans i18nKey="login.privacyNotice">
                <Link to="/privacy" target="_blank"></Link>
              </Trans>
            }
            style={{ marginTop: 10 }}
          />
        </Fade>
      </Collapse>
      <div className={classes.buttonRegister}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          type="submit"
          disabled={!firstName.trim() || !lastName.trim() || !accepted}
        >
          {t("login.register")}
        </Button>
      </div>
      {session?.secretKey && <Redirect to={target ? target : routes[0].path} />}
    </form>
  );
};

const useStyles = makeStyles()((theme) => ({
  form: {
    width: "100%",
    maxWidth: 380,
    marginTop: 10,
    marginBottom: 20,
  },
  inputRegisterFirst: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 30,
    padding: "8px 5px 5px 20px",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  inputRegisterLast: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 30,
    marginTop: 10,
    padding: "8px 5px 5px 20px",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  buttonRegister: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  button: {
    textTransform: "none",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4) !important",
    fontFamily: theme.typography.h1.fontFamily,
  },
}));
export default RegisterGuest;
