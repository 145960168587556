import React from "react";
import { IconButton, MenuItem, Menu } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { useHistory, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";

const ProfileListItem = ({ item, handleMainProfileChange, handleSelect }) => {
  const { profileId } = useParams();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const selectedProfile = useSelector(
    (state) => state.settings?.[state.session?.userId]?.selectedProfile || ""
  );

  const handleEdit = () => {
    history.push(`/profiles/${item.id}`);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {!item.main && (
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              handleMainProfileChange(item.id);
            }}
          >
            {t("profiles.mainProfileChange")}
          </MenuItem>
        )}
        {item.id !== selectedProfile && (
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              handleSelect(item.id);
            }}
          >
            {t("profiles.activeProfileChange")}
          </MenuItem>
        )}
      </Menu>
      <div
        className={classes.profileRow}
        key={item.id}
        onClick={handleEdit}
        style={item.id === profileId ? { backgroundColor: "#eee" } : null}
      >
        <p
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: item.id === selectedProfile ? "500" : "400",
          }}
        >
          {item.firstName} {item.lastName}
          {item.main && (
            <LocalPoliceIcon
              style={{ color: "#aaa", fontSize: 20, marginLeft: 5 }}
            />
          )}
        </p>
        {(!item.main || item.id !== selectedProfile) && (
          <IconButton size="small" onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  profileWrapper: {
    margin: "10px auto 0",
    [theme.breakpoints.down("xs")]: {
      marginTop: 120,
    },
    maxWidth: 600,
    // background: "pink",
  },
  companySwitch: {
    marginBottom: 20,
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 10,
  },
  addAction: {
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "rgba(51,62,72,0.7)",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#fff",
    marginTop: "-1rem",
    zIndex: "2",
  },
  deleteAction: {
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "rgba(51,62,72,0.7)",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#fff",
    marginLeft: "4rem",
    marginTop: "-1.45rem",
    zIndex: "2",
  },
  avatar: {
    width: 100,
    height: 100,
    zIndex: "1",
  },
  profilesList: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  profileThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  selected: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#00BDF2",
  },
  title: {
    marginBottom: 0,
  },
  fieldGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginTop: "20px",
    "& > :first-child": {
      marginRight: 10,
    },
    "& > :last-child": {
      marginRight: "0 !important",
    },
  },
  field: {
    flex: 1,
    fontSize: "13px",
    color: "#000",
    height: 40,
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    "& input": {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  textFieldInput: {
    color: "#000",
    width: "48%",
    // marginBottom: 20,
    height: 40,
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  bioField: {
    width: "100%",
    fontSize: "13px",
    color: "#000",
    // marginBottom: 20,
    marginTop: 20,
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  buttonRegister: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  dateField: {
    outline: "none",
  },
  sideMenu: {
    width: 300,
    margin: "10px 0 20px 30px",
    borderRadius: 12,
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "10px 0",
  },
  profileRow: {
    paddingLeft: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#4F4E4E",
  },
  divider: {
    color: "#BEBEBE",
  },
  main: {
    padding: "5px 5px 0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  buttons: {
    marginLeft: 8,
    display: "flex",
    justifyContent: "flex-end",
  },

  button: {
    textTransform: "none",
    fontFamily: "DIN NEXT LT Pro",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4) !important",
    paddingTop: 5,
    paddingBottom: 3,
    fontSize: 14,
    margin: 10,
    width: 200,

    "& .MuiButton-startIcon": {
      marginTop: -3,
    },

    "&.MuiButton-text:disabled": {
      boxShadow: "none !important",
      opacity: 0.7,
    },
  },
}));

export default ProfileListItem;
