import React from "react";
import { Backdrop, Modal, Fade, Fab, InputBase } from "@mui/material";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-hls-quality-selector";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import { checkText } from "smile2emoji";
import { generateFullImageSource } from "src/utils/generateImageSet";

const MediaPreview = ({
  submitMediaDialog,
  sendingMedia,
  setSendingMedia,
  userId,
}) => {
  const ref = React.useRef();
  const videoref = React.useRef();
  const { t } = useTranslation();

  const [currentPos, setCurrentPos] = React.useState(0);
  const [player, setPlayer] = React.useState();
  const [aspectRatio, setAspectRatio] = React.useState(16 / 9);
  const [input, setInput] = React.useState("");
  const [descriptions, setDescriptions] = React.useState({});

  const handleMessageChange = (e) => {
    setInput(checkText(e.target.value));
  };

  const [size, setSize] = React.useState({
    width: window.innerWidth - 250,
    height: window.innerHeight - 250,
  });

  //Window resizing
  React.useEffect(() => {
    const handleResize = () =>
      setSize({
        width: window.innerWidth - 250,
        height: window.innerHeight - 250,
      });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Init video player
  React.useEffect(() => {
    setTimeout(() => {
      const p = videojs(videoref.current, {
        autoplay: false,
        controls: true,
        fluid: true,
        sources: [
          {
            src: `${process.env.REACT_APP_STATIC_URL}/${userId}/${sendingMedia[currentPos].id}.m3u8`,
            withCredentials: false,
          },
        ],
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          hls: {
            overrideNative: true,
            withCredentials: false,
          },
        },
      });
      setPlayer(p);
    }, 100);
  }, []);

  //Init descriptions
  React.useEffect(() => {
    let temp = {};
    for (let i = 0; i < sendingMedia.length; i++) {
      if (sendingMedia[i].mediaType !== "audio") temp[i] = "";
    }
    setDescriptions(temp);
  }, []);

  React.useEffect(() => {
    if (sendingMedia[currentPos].mediaType === "audio") {
      setTimeout(() => {
        setAspectRatio(16 / 9);
      }, 200);
    }
    if (sendingMedia[currentPos].mediaType === "video" && player) {
      player.src({
        src: `${process.env.REACT_APP_STATIC_URL}/${userId}/${sendingMedia[currentPos].id}.m3u8`,
      });
      setAspectRatio(player.videoWidth() / player.videoHeight());
    }
  }, [currentPos]);

  const videoWidth = Math.max(
    aspectRatio > size.width / size.height
      ? size.width
      : size.height * aspectRatio,
    285
  );
  const videoHeight =
    aspectRatio > size.width / size.height
      ? size.width / aspectRatio
      : size.height;

  const classes = useStyles({ videoWidth, videoHeight });

  const handleClose = () => {
    if (player)
      setTimeout(() => {
        player.dispose();
        setPlayer();
      }, 500);
    setSendingMedia([]);
  };

  const sendMedia = async () => {
    let newDescriptions = descriptions;
    newDescriptions[currentPos] = input;
    let media = sendingMedia;
    for (let i = 0; i < media.length; i++) {
      media[i].description = newDescriptions[i];
    }
    await submitMediaDialog(media);
    handleClose();
  };

  const incrementPos = () => {
    let newDescriptions = descriptions;
    newDescriptions[currentPos] = input;
    setDescriptions(newDescriptions);
    const newPos = currentPos === sendingMedia.length - 1 ? 0 : currentPos + 1;
    setInput(descriptions[newPos]);
    setCurrentPos(newPos);
  };

  const decrementPos = () => {
    let newDescriptions = descriptions;
    newDescriptions[currentPos] = input;
    setDescriptions(newDescriptions);
    const newPos = currentPos === 0 ? sendingMedia.length - 1 : currentPos - 1;
    setInput(descriptions[newPos]);
    setCurrentPos(newPos);
  };

  const imageSrc = generateFullImageSource(
    userId,
    sendingMedia[currentPos].id,
    false,
    sendingMedia[currentPos].mediaType === "application"
  );

  return (
    <Modal
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop,
      }}
      className={classes.modal}
    >
      <Fade in={true}>
        <div className={classes.contentWrapper}>
          <div
            data-vjs-player
            style={{
              paddingTop: videoHeight,
              backgroundColor: "transparent",
              display:
                sendingMedia[currentPos].mediaType === "video"
                  ? "inherit"
                  : "none",
            }}
          >
            <video ref={videoref} className="video-js"></video>
          </div>
          {["image", "application"].includes(
            sendingMedia[currentPos].mediaType
          ) && (
            <img
              ref={ref}
              src={imageSrc}
              className={classes.photo}
              onLoad={() => {
                setAspectRatio(
                  ref.current.naturalWidth / ref.current.naturalHeight
                );
              }}
            />
          )}
          {sendingMedia[currentPos].mediaType === "audio" && (
            <div className={classes.photo}>
              <audio controls style={{ width: "100%" }}>
                <source
                  src={`${process.env.REACT_APP_STATIC_URL}/${userId}/${sendingMedia[currentPos].id}`}
                />
              </audio>
            </div>
          )}
          <Fab
            onClick={handleClose}
            className={classes.closeButton}
            size="small"
          >
            <CloseIcon />
          </Fab>
          <Fab onClick={decrementPos} className={classes.previous}>
            <SkipPreviousIcon />
          </Fab>
          <Fab onClick={incrementPos} className={classes.next}>
            <SkipNextIcon />
          </Fab>
          <div className={classes.inputBar}>
            <InputBase
              variant="filled"
              classes={{
                root: classes.chatField,
                input: classes.chatFieldInput,
              }}
              style={{
                opacity: sendingMedia[currentPos].mediaType === "audio" ? 0 : 1,
              }}
              multiline
              fullWidth
              value={input}
              onChange={handleMessageChange}
              disabled={sendingMedia[currentPos].mediaType === "audio"}
              placeholder={t("party.writeMessage")}
              autoFocus
            />
            <Fab onClick={sendMedia} className={classes.send} color="primary">
              <SendIcon />
            </Fab>
            <div className={classes.badge}>{sendingMedia.length}</div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "relative",
    width: 36,
    height: 36,
    left: (props) => props.videoWidth - 18,
    top: (props) => -props.videoHeight - 17,
  },
  previous: {
    position: "relative",
    width: 36,
    height: 36,
    left: -128,
    top: (props) => -props.videoHeight / 2 - 17,
  },
  next: {
    position: "relative",
    width: 36,
    height: 36,
    left: (props) => props.videoWidth - 18,
    top: (props) => -props.videoHeight / 2 - 17,
  },
  inputRoot: {
    width: (props) => props.videoWidth - 200,
    height: 30,
    marginLeft: 50,
  },
  contentWrapper: {
    outline: "none",
    width: (props) => props.videoWidth,
    height: (props) => props.videoHeight,
    position: "relative",
    zIndex: 1400,
  },
  photo: {
    width: (props) => props.videoWidth,
    height: (props) => props.videoHeight,
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputBar: {
    display: "flex",
    flexDirection: "row",
  },
  chatField: {
    backgroundColor: "#EBEBEB",
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: theme.spacing(3),
  },
  chatFieldInput: {
    lineHeight: "1.5em",
    fontSize: "1em",
  },
  send: {
    width: 48,
    height: 48,
    marginLeft: 10,
  },
  badge: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    height: 22,
    borderRadius: 11,
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 2,
    marginLeft: -16,
    marginTop: -4,
    zIndex: 1600,
  },
}));

export default MediaPreview;
