import React from "react";
import { Container, CssBaseline, Typography } from "@mui/material";
import {
  AppBar,
  ActionBar,
  NavMenu,
  TemplateDialog,
  ShareTemplateDialog,
} from "components";

import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import shareTemplateDialog from "src/components/shareTemplateDialog";

const TemplateRemove = (props) => {
  const templates = useSelector((state) => state.templates);
  const { GetTemplates, AddTemplate, ShareTemplate, RevokeSharedTemplate } =
    useActions();

  const { t } = useTranslation();

  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <>
      <Container className={classes.screen}>
        <CssBaseline />
        {/* <AppBar
          open={menuOpen}
          onOpen={() => setMenuOpen(true)}
          // searchChange={setSearch}
        /> */}
        {/* <ActionBar title={t("templates.title")} open={menuOpen} /> */}
        <NavMenu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="templates"
        />
        <Typography>{t("templates.unavailable")}</Typography>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
    marginTop: 130,
    [theme.breakpoints.down("sm")]: {
      marginTop: 120,
      paddingRight: 20,
    },
  },
}));

export default TemplateRemove;
