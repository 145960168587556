import {
  GET_CHATS,
  ARCHIVE_CHAT,
  UNARCHIVE_CHAT,
  MUTE_CHAT,
  UNMUTE_CHAT,
  BLOCK,
  UNBLOCK,
  LOGOUT,
  CHAT_LIST,
} from "../types";
import _ from "lodash";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHATS: {
      let chats = action.payload;
      state = chats;
      return state;
    }

    case ARCHIVE_CHAT: {
      const { chatId } = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        archived: true,
      };
      return newState;
    }

    case MUTE_CHAT: {
      const chatId = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      const newState = [...state];
      newState[index].mute = true;
      return newState;
    }
    case UNMUTE_CHAT: {
      const chatId = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      const newState = [...state];
      newState[index].mute = false;
      return newState;
    }

    case UNARCHIVE_CHAT: {
      const { chatId } = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        archived: false,
      };
      return newState;
    }

    case BLOCK: {
      const { id: userId } = action.payload;
      const index = state.findIndex((c) => c.otherUserId === userId);
      const newState = [...state];
      newState[index].blockedByMe = true;
      return newState;
    }

    case UNBLOCK: {
      const userId = action.payload;
      const index = state.findIndex((c) => c.otherUserId === userId);
      const newState = [...state];
      delete newState[index].blockedByMe;
      return newState;
    }

    case CHAT_LIST: {
      const chatList = action.payload;
      const newState = [];
      for (const chatItem of chatList) {
        if (chatItem.type === "direct") {
          newState.push(chatItem);
        }
      }
      const chatWithMessages = _.orderBy(
        _.filter(newState, (item) => item.latestMessage || item.created),
        (item) => item.latestMessage?.timestamp || item.created,
        "desc"
      );

      const chatWithoutMessages = _.filter(
        newState,
        (item) => !item.latestMessage && !item.created
      );
      return [...chatWithMessages, ...chatWithoutMessages];
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
