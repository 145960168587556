import React from "react";
import { CssBaseline } from "@mui/material";
import {
  NavMenu,
  TemplateDialog,
  ShareTemplateDialog,
  FolderDialog,
  MoveToDialog,
  EditMediaDialog,
  DeleteDialog,
  HeaderBar,
  MediaBreadCrumbs,
} from "components";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import TemplateTable from "./table";
import { useHistory } from "react-router-dom";
import short from "short-uuid";
const translator = short();

const Templates = (props) => {
  const session = useSelector((state) => state.session);
  const templates = useSelector((state) => state.templates);
  const templateTrash = useSelector((state) => state.templateTrash);
  const {
    GetTemplate,
    GetTemplates,
    AddTemplate,
    ShareTemplate,
    RevokeSharedTemplate,
    CreateTemplateFolder,
    TemplateAddToFolder,
    RemoveTemplate,
    EditTemplate,
    TemplateTrash,
    RestoreTemplate,
    SharedTemplateAddToFolder,
    RemoveSharedTemplate,
    RestoreSharedTemplate,
  } = useActions();

  const [path, setPath] = React.useState(
    !window.location.hash
      ? []
      : window.location.hash
          .substr(1)
          .split("/")
          .map((p) => (p === "trash" ? p : translator.toUUID(p)))
  );

  const history = useHistory();

  React.useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH" || history.action === "POP") {
        setPath(
          location.hash
            ? location.hash
                .substring(1)
                .split("/")
                .map((p) => translator.toUUID(p))
            : []
        );
      }
    });
  }, []);

  const [visible, setVisible] = React.useState();

  const newFolderDialog = React.useRef();
  const showNewFolderDialog = () => {
    newFolderDialog.current.open();
  };
  const submitNewFolderDialog = async (title) => {
    await CreateTemplateFolder(title, path.join("/"));
    await GetTemplates();
  };

  /* recursively move files and folders */
  const moveFileOrFolder = async (templateId, newPath) => {
    const m = templates.find((m) => m.id === templateId);
    if (!m.isFolder) {
      if (m.sharedBy) await SharedTemplateAddToFolder(m.shareToken, newPath);
      else await TemplateAddToFolder(templateId, newPath);
      return;
    }
    const oldPath = !m.path ? templateId : `${m.path}/${templateId}`;
    const folderContents = templates.filter((m) => m.path === oldPath);
    // move the folder itself
    const { error } = await TemplateAddToFolder(templateId, newPath);
    if (error) return; // catch if moving was prevented for whatever reason
    for (const folderItem of folderContents) {
      // move all folder contents
      await moveFileOrFolder(
        folderItem.id,
        !newPath ? templateId : `${newPath}/${templateId}`
      );
    }
  };

  const moveToDialog = React.useRef();
  const showMoveToDialog = (templateId) => {
    moveToDialog.current.open(templates, templateId);
  };
  const submitMoveToDialog = async (templateId, folderId) => {
    let newPath = [];
    if (folderId !== "home") {
      const folder = templates.find((m) => m.id === folderId);
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
    }

    if (Array.isArray(templateId)) {
      for (let i = 0; i < templateId.length; i++) {
        await moveFileOrFolder(templateId[i], newPath.join("/"));
      }
    } else {
      await moveFileOrFolder(templateId, newPath.join("/"));
    }
    await GetTemplates();
  };

  const openFolder = (folder) => {
    window.location.hash = [
      ...(folder.path ? folder.path.split("/") : []),
      folder.id,
    ]
      .map((p) => translator.fromUUID(p))
      .join("/");
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };
  const openTrash = () => {
    window.location.hash = "trash";
    setPath(["trash"]);
    TemplateTrash();
  };

  const editFolderDialog = React.useRef();
  const [editItem, setEditItem] = React.useState();
  const editFolder = (m) => {
    setEditItem(m);
    let name = m.title;
    editFolderDialog.current.open(name);
  };
  const submitEditDialog = async (name) => {
    await EditTemplate(editItem.id, name);
    await GetTemplates();
  };

  const deleteDialog = React.useRef();
  const [deleteItem, setDeleteItem] = React.useState();
  const removeFolder = (m) => {
    setDeleteItem(m);
    deleteDialog.current.open();
  };

  const submitRemove = async () => {
    if (Array.isArray(deleteItem)) {
      for (let i = 0; i < deleteItem.length; i++) {
        if (deleteItem[i]) {
          if (deleteItem[i].userId === session?.userId) {
            if (deleteItem[i].shareToken)
              await RevokeSharedTemplate(deleteItem[i].id);
            await RemoveTemplate(deleteItem[i].id);
          } else await RemoveSharedTemplate(deleteItem[i].shareToken);
        }
      }
    } else {
      if (deleteItem) {
        if (deleteItem.userId === session?.userId) {
          if (deleteItem.shareToken) await RevokeSharedTemplate(deleteItem.id);
          await RemoveTemplate(deleteItem.id);
        } else await RemoveSharedTemplate(deleteItem.shareToken);
      }
    }
    await TemplateTrash();
    await GetTemplates();
  };

  const handleRestoreTemplate = async (template) => {
    if (template.userId !== session?.userId) {
      if (template.shareToken) await RestoreSharedTemplate(template.shareToken);
    } else {
      await RestoreTemplate(template.id);
    }
    await TemplateTrash();
    await GetTemplates();
  };

  const { t } = useTranslation();

  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const init = async () => {
    await GetTemplates();
    await TemplateTrash();
  };
  React.useEffect(() => {
    init();
    document.title = `${t("templates.title")} - proWIN Messenger`;
  }, []);

  const templateDialog = React.useRef();
  const handleAdd = () => {
    templateDialog.current.open();
  };

  const submitTemplateDialog = async (title) => {
    let { response } = await AddTemplate(title);
    if (path.length > 0 && response?.data)
      await TemplateAddToFolder(response.data.id, path.join("/"));
    await GetTemplates();
    if (response && response.data)
      history.push(`/templates/${response.data.id}`);
  };

  const shareTemplateDialog = React.useRef();
  const handleShare = async (shareTemplate) => {
    const templateId = shareTemplate.id;
    const { response, error } = await ShareTemplate(templateId);
    if (error) {
      alert(t("general.tryAgainLater"));
      return;
    }
    const { shareToken } = response.data;
    const template = templates.find((t) => t.id === templateId);
    shareTemplateDialog.current.open(template.title, shareToken, templateId);
    GetTemplates();
  };
  const revokeTemplateSharing = async (templateId) => {
    await RevokeSharedTemplate(templateId);
    await GetTemplate(templateId);
  };

  const getTemplatesInPath = () => {
    if (!path || path.length === 0) {
      setVisible(templates.filter((m) => !m.path));
    } else {
      if (path[0] === "trash") setVisible(templateTrash);
      else setVisible(templates.filter((m) => m.path === path.join("/")));
    }
  };
  React.useEffect(() => {
    getTemplatesInPath();
  }, [path, templates]);

  const handleSetPath = (folderId) => {
    setSearch();
    if (folderId === "home" || !folderId) {
      history.replace(window.location.pathname);
      setPath([]);
      return;
    }
    const folder = templates.find((m) => m.id === folderId);
    window.location.hash = [
      ...(folder.path ? folder.path.split("/") : []),
      folder.id,
    ]
      .map((p) => translator.fromUUID(p))
      .join("/");
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };

  const [search, setSearch] = React.useState("");

  const applySearch = (item) => {
    const searchVal = search.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result = result && searchTerm.test(item.title.toLowerCase());
    }
    return result;
  };

  React.useEffect(() => {
    if (!search) {
      getTemplatesInPath();
      return;
    }
    setVisible(templates.filter((t) => !t.isFolder).filter(applySearch));
  }, [search]);

  const headerButtons = [
    {
      label: t("templates.newTemplate"),
      icon: <AddIcon />,
      onClick: handleAdd,
    },
    {
      label: t("media.folderDialogTitle"),
      icon: <CreateNewFolderIcon />,
      onClick: showNewFolderDialog,
    },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="templates"
        setPath={setPath}
      />
      <div className={classes.screen}>
        <CssBaseline />
        <HeaderBar
          title={t("templates.title")}
          buttons={headerButtons}
          searchChange={setSearch}
          search={search}
        />
        <MediaBreadCrumbs
          path={path}
          media={templates}
          setNewPath={handleSetPath}
          pathHomeLabel={t("templates.homeTitle")}
        />
        <div className={classes.main}>
          {!!visible && (
            <TemplateTable
              templates={visible}
              searchValue={search}
              onShare={handleShare}
              onMoveTo={showMoveToDialog}
              removeFolder={removeFolder}
              editFolder={editFolder}
              openFolder={openFolder}
              openTrash={path.length === 0 ? openTrash : undefined}
              isTrash={path?.[0] === "trash"}
              restoreTemplate={handleRestoreTemplate}
            />
          )}
        </div>
        <TemplateDialog ref={templateDialog} onSubmit={submitTemplateDialog} />
        <EditMediaDialog ref={editFolderDialog} onSubmit={submitEditDialog} />
        <DeleteDialog ref={deleteDialog} type="media" onSubmit={submitRemove} />
        <FolderDialog ref={newFolderDialog} onSubmit={submitNewFolderDialog} />
        <MoveToDialog ref={moveToDialog} onSubmit={submitMoveToDialog} />
        <MoveToDialog
          ref={moveToDialog}
          onSubmit={submitMoveToDialog}
          pathHomeLabel={t("templates.homeTitle")}
        />
        <ShareTemplateDialog
          ref={shareTemplateDialog}
          onRevoke={revokeTemplateSharing}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  main: {
    flex: 1,
    padding: 20,
    paddingTop: 10,
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
}));

export default Templates;
