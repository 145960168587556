import React from "react";
import { makeStyles } from "@mui/styles";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const TemplateInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.templateHeaderWrapper}>
      <div className={classes.iconName}>
        <FolderOpenIcon style={{ marginRight: 10 }} />
        <Typography>Badezimmer</Typography>
      </div>
      <div className={classes.iconShareMore}>
        <div className={classes.iconShare}>
          <Tooltip title="Share Template">
            <ShareIcon style={{ marginRight: 10 }} />
          </Tooltip>
        </div>
        <div className={classes.iconMore}>
          <Tooltip title="More options">
            <MoreVertIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  templateHeaderWrapper: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  iconName: {
    display: "flex",
    margin: 10,
  },
  iconShareMore: {
    margin: 10,
    display: "flex",
  },

  iconShare: {
    "&:hover": {
      cursor: "pointer",
      color: "#1E92CD",
      transition: "all 0.5s easy",
    },
  },
  iconMore: {
    "&:hover": {
      color: "red",
      cursor: "pointer",
      color: "#1E92CD",
      transition: "all 0.5s easy",
    },
  },
}));

export default TemplateInfo;
