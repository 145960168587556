import React from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import {
  Container,
  Paper,
  Typography,
  Grid,
  CssBaseline,
  Button,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "assets/logo-prowin-messenger.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import GroupsIcon from "@mui/icons-material/Groups";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const AddGroup = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { JoinGroupChatPreflight, JoinGroupChat, GetChatList } = useActions();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2).toLowerCase();
  const langButton = React.useRef();
  const [langOpen, setLangOpen] = React.useState(false);
  const [linkExpired, setLinkExpired] = React.useState(false);
  const [groupChatInfo, setGroupChatInfo] = React.useState();

  const history = useHistory();

  const selectLang = async (lang) => {
    await i18n.changeLanguage(lang);
    setLangOpen(false);
  };

  const loadGroupChatInfo = async () => {
    let { response, error } = await JoinGroupChatPreflight(id);
    if (error) {
      setLinkExpired(true);
    } else {
      setGroupChatInfo(response?.data);
    }
  };

  React.useEffect(() => {
    loadGroupChatInfo();
  }, [id]);

  const joinGuest = async () => {
    await JoinGroupChat(id);
    await GetChatList();
    history.push(`/chats/g/${groupChatInfo.id}`);
  };

  const { src, srcSet } = generateImageSourceSet(
    groupChatInfo?.id,
    groupChatInfo?.image,
    120
  );

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Grid item xs={3}>
            <Link to="/">
              <img src={logo} alt="Prowin Messenger" />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="inherit"
              onClick={() => setLangOpen(!langOpen)}
              ref={langButton}
              className={classes.langButton}
            >
              {lang === "en"
                ? "English 🇬🇧"
                : lang === "nl"
                ? "Nederlands 🇳🇱"
                : lang === "fr"
                ? "Français 🇫🇷"
                : "Deutsch 🇩🇪"}
            </Button>
            <Menu
              anchorEl={langButton.current}
              keepMounted={true}
              open={langOpen}
              onClose={() => setLangOpen(false)}
            >
              <MenuItem onClick={() => selectLang("de")}>Deutsch 🇩🇪</MenuItem>
              <MenuItem onClick={() => selectLang("en")}>English 🇬🇧</MenuItem>
              <MenuItem onClick={() => selectLang("nl")}>
                Nederlands 🇳🇱
              </MenuItem>
              <MenuItem onClick={() => selectLang("fr")}>Français 🇫🇷</MenuItem>
            </Menu>
          </Grid>
        </Grid>

        {!linkExpired ? (
          <Container maxWidth="sm">
            <Paper className={classes.profileCard} justifyContent="center">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Avatar src={src} srcSet={srcSet} className={classes.avatar}>
                    <GroupsIcon />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography align="center" variant="h5">
                    {groupChatInfo?.title.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Typography variant="h6" align="center">
              {t("chats.joinGroupDescription")}
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.buttonCancel}
                color="inherit"
                onClick={() => history.goBack()}
              >
                {t("general.cancel")}
              </Button>
              <Button
                className={classes.buttonCreate}
                type="submit"
                color="primary"
                variant="contained"
                style={{ textTransform: "lowecase" }}
                onClick={() => joinGuest()}
              >
                {t("chats.joinGroup")}
              </Button>
            </div>
          </Container>
        ) : (
          <Container maxWidth="sm">
            <Typography variant="h6" align="center">
              {t("chats.linkExpired")}
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.buttonCreate}
                type="submit"
                color="primary"
                variant="contained"
                style={{ textTransform: "lowecase" }}
                onClick={() => history.push("/")}
              >
                {t("general.back")}
              </Button>
            </div>
          </Container>
        )}
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  langButton: {
    textTransform: "none",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  profileCard: {
    marginBottom: theme.spacing(4),
  },
  cardContent: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonCancel: {
    margin: 10,
    width: 270,
  },
  buttonCreate: {
    width: 270,
    background: "#1E92CD",
  },
}));

export default AddGroup;
