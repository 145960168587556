import React from "react";

const linkRegex =
  /((?:http(?:s)?:\/\/.)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*))/;

const sanitize = (string) => {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
  };
  const reg = /[&<>"']/gi;
  if (typeof string === "string") {
    return string.replace(reg, (match) => map[match]);
  } else {
    return string.toString().replace(reg, (match) => map[match]);
  }
};

const unsanitize = (string) => {
  return string
    .split("&amp;")
    .join("&")
    .split("&lt;")
    .join("<")
    .split("&gt;")
    .join(">")
    .split("&quot;")
    .join('"')
    .split("&#x27;")
    .join("'");
};

const insertFormatting = (string) => insertBold(sanitize(string));
const insertBold = (string) => {
  const collectedStringParts = [];
  const stringLinkSeparated = string.split(linkRegex);
  for (const part of stringLinkSeparated) {
    if (!linkRegex.test(part)) {
      const stringParts = part.split(/\*([^*]+)\*/gu);
      const stringPartsFormatted = stringParts.map((s, index) =>
        index % 2 === 1 ? (
          <b key={String(index)}>{unsanitize(s)}</b>
        ) : (
          insertItalic(s)
        )
      );
      for (const formattedPart of stringPartsFormatted) {
        collectedStringParts.push(formattedPart);
      }
    } else {
      collectedStringParts.push(part);
    }
  }

  return collectedStringParts;
};
const insertItalic = (string) => {
  const stringParts = string.split(/_([^*]+)_/gu);
  return stringParts.map((s, index) =>
    index % 2 === 1 ? <i>{unsanitize(s)}</i> : insertStrike(s)
  );
};
const insertStrike = (string) => {
  const stringParts = string.split(/~([^*]+)~/gu);
  return stringParts.map((s, index) =>
    index % 2 === 1 ? <strike>{unsanitize(s)}</strike> : unsanitize(s)
  );
};

export { insertFormatting };
