import React from "react";
import { Container, CssBaseline, Typography } from "@mui/material";
import { NavMenu } from "components";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import PageNotFound from "./pageNotFound";

const NotFound = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const { t } = useTranslation();

  return (
    <Container>
      <CssBaseline />
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      />
      <main className={classes.main}>
        <PageNotFound />
        <Typography variant="h5">{t("general.notFound")}</Typography>
      </main>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    color: "red",
  },
  main: {
    marginTop: theme.spacing(15),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default NotFound;
