import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FolderIcon from "@mui/icons-material/Folder";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Ellipsis from "react-lines-ellipsis";
import { useTranslation } from "react-i18next";

const MoveToDialog = (props, ref) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { onSubmit, pathHomeLabel = t("media.homeTitle") } = props;
  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = React.useState([]);
  const [mediaId, setMediaId] = React.useState();
  const [selectedFolder, setSelectedFolder] = React.useState("home");
  const [path, setPath] = React.useState([]);
  const [visibleFolders, setVisibleFolders] = React.useState([]);

  const sortABC = (a, b) => {
    if (a.title === undefined) {
      a.title = "";
    }
    return a.title.localeCompare(
      b.title,
      navigator.languages[0] || navigator.language,
      {
        numeric: true,
        ignorePunctuation: true,
      }
    );
  };

  const getMediaInPath = () => {
    if (!path || path.length === 0)
      setVisibleFolders(
        media.filter((m) => (m.mediaType === "folder" || m.isFolder) && !m.path)
      );
    else
      setVisibleFolders(
        media.filter(
          (m) =>
            (m.mediaType === "folder" || m.isFolder) &&
            m.path === path.join("/")
        )
      );
  };
  React.useEffect(() => {
    getMediaInPath();
  }, [path]);
  const handleSetPath = (folderId) => {
    setSelectedFolder(folderId);
    if (folderId === "home") {
      setPath([]);
      return;
    }
    const folder = media.find((m) => m.id === folderId);
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };

  React.useImperativeHandle(ref, () => ({
    open: (media, mediaId) => {
      setOpen(true);
      setMedia(media.filter((m) => m.id !== mediaId));
      setMediaId(mediaId);
      setSelectedFolder("home");
      setPath([]);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(mediaId, selectedFolder);
  };

  const renderFolder = (folder) => {
    return (
      <div
        className={classes.folder}
        key={folder.id}
        onClick={() => handleSetPath(folder.id)}
      >
        <FolderIcon className={classes.folderIcon} />
        <div className={classes.folderName}>
          <Ellipsis lines={1} text={folder.title} basedOn="letters" />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("media.moveToDialogTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("media.moveToDialog")}</DialogContentText>
          <div className={classes.breadcrumbs}>
            <Button
              size="small"
              classes={{
                root: classes.pathButton,
                label: classes.pathButtonLabel,
              }}
              onClick={() => handleSetPath("home")}
            >
              {pathHomeLabel}
            </Button>
            {path.length > 0 && (
              <ChevronRight
                fontSize="small"
                className={classes.pathSeparator}
              />
            )}
            {path.length > 2 && (
              <>
                <Button
                  size="small"
                  classes={{
                    root: classes.pathButton,
                    label: classes.pathButtonLabel,
                  }}
                  disabled
                >
                  ...
                </Button>
                <ChevronRight
                  fontSize="small"
                  className={classes.pathSeparator}
                />
              </>
            )}
            {path.slice(-2).map((p, index) => (
              <React.Fragment key={p}>
                <Button
                  size="small"
                  classes={{
                    root: classes.pathButton,
                    label: classes.pathButtonLabel,
                  }}
                  onClick={() => handleSetPath(p)}
                >
                  {media.find((m) => m.id === p).title}
                </Button>
                {index < path.slice(-2).length - 1 && (
                  <ChevronRight
                    fontSize="small"
                    className={classes.pathSeparator}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
          <div className={classes.mediaArea}>
            {visibleFolders.sort(sortABC).map(renderFolder)}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ width: 300, background: "#D8D8D8" }}
            onClick={handleClose}
            color="inherit"
          >
            {t("general.cancel")}
          </Button>
          <Button
            style={{ width: 300, background: "#1E92CD" }}
            type="submit"
            color="primary"
            variant="contained"
          >
            {t("media.moveToDialogSubmit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(2)})`,
  },
  breadcrumbs: {
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pathButton: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "#333",
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 30,
    "& span": {
      pointerEvents: "none",
    },
    justifyContent: "flex-start",
  },
  pathButtonLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  pathSeparator: {
    color: "#999",
    marginLeft: -5,
    marginRight: -5,
  },
  mediaArea: {
    maxHeight: "calc(100vh - 205px)",
    overflowY: "auto",
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    marginLeft: -theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
    ...theme.scrollbars,
  },
  folder: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  folderIcon: {
    color: "#666",
  },
  folderName: {
    flex: 1,
    fontSize: 17,
    textJustify: "baseline",
    paddingLeft: 5,
    marginBottom: -3,
    color: "#444",
  },
}));

export default React.forwardRef(MoveToDialog);
