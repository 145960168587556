import React from "react";
import { useState } from "react";
import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ViewCompactOutlinedIcon from "@mui/icons-material/ViewCompactOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import Tooltip from "@mui/material/Tooltip";

function WriteTemplate() {
  const classes = useStyles();

  const [val, setVal] = useState();

  return (
    <div className={classes.mainWriteMessageBox}>
      <Divider className={classes.divider} />
      <Tooltip title="Send">
        <ArrowForwardIosOutlinedIcon
          onClick={() => setVal(() => "")}
          className={classes.chevronRight}
        />
      </Tooltip>
      <div className={classes.icons}>
        <Tooltip title="Edit">
          <EditOutlinedIcon className={classes.iconsInt} />
        </Tooltip>
        {/* <Tooltip title="Edit">
        <ViewHeadlineOutlinedIcon className={classes.iconsInt} /></Tooltip> */}
        <Tooltip title="Folder">
          <FolderOpenOutlinedIcon className={classes.iconsInt} />
        </Tooltip>
        <Tooltip title="Template">
          <ViewCompactOutlinedIcon className={classes.iconsInt} />
        </Tooltip>
        <Tooltip title="Media">
          <ImageOutlinedIcon className={classes.iconsInt} />
        </Tooltip>
        <Tooltip title="Record">
          <KeyboardVoiceOutlinedIcon className={classes.iconsInt} />
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteOutlinedIcon className={classes.iconsInt} />
        </Tooltip>
      </div>
      <div className={classes.textArea}>
        <textarea
          className={classes.textArea}
          type="text"
          value={val}
          placeholder="Schreibe deine Nachricht"
          onChange={(e) => setVal(e.target.value)}
          style={{ fontSize: "15px", fontFamily: "DIN NEXT LT Pro" }}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  mainWriteMessageBox: {
    height: 125,
    position: "relative",
    backgroundColor: "#FFF",
    color: "#919191",
    borderRadius: "12px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  textArea: {
    width: "97%",
    padding: 10,
    border: "none",
    resize: "none",
  },
  divider: {
    width: "100%",
    color: "#919191",
    position: "absolute",
    bottom: 50,
  },
  icons: {
    position: "absolute",
    bottom: 10,
    right: 10,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "space-around",
    gap: 23,
  },
  iconsInt: {
    "&:hover": {
      color: "#1E92CD",
      cursor: "pointer",
      transition: "all 0.5s easy",
    },
  },
  chevronRight: {
    position: "absolute",
    bottom: 70,
    right: 10,
    "&:hover": {
      color: "#333E48",
      cursor: "pointer",
      transition: "all 0.5s easy",
    },
  },
}));
export default WriteTemplate;
