import React from "react";
import { Avatar, InputBase, Button, TextField } from "@mui/material";
import { HeaderBar, NavMenu, PartyLoading, DeleteDialog } from "components";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import { DesktopDatePicker } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/it";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ProfileListItem from "./ProfileListItem";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const Profile = (props) => {
  const { profileId } = useParams();
  const history = useHistory();
  const session = useSelector((state) => state.session);
  const profiles = useSelector((state) => state.profile?.profiles || []);
  const guestId = useSelector((state) => state.session.id);
  const profile = useSelector((state) =>
    (state.profile?.profiles || [])?.find((p) => p.id === profileId)
  );
  const selectedProfile = useSelector(
    (state) => state.settings?.[state.session?.userId]?.selectedProfile || ""
  );
  const [loading, setLoading] = React.useState(true);
  const [saveState, setSaveState] = React.useState("saved");
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [biography, setBiography] = React.useState("");
  const [profilePic, setProfilePic] = React.useState();
  const [shopLink, setShopLink] = React.useState();
  const [selectedFile, setSelectedFile] = React.useState();
  const [deleteProfilePic, setDeleteProfilePic] = React.useState(false);
  // const [hasChanged, setHasChanged] = React.useState(false);
  const [guestProfile, setGuestProfile] = React.useState();

  const cancelDisabled = saveState !== "changed";
  const deleteDisabled = saveState === "saving";
  const saveDisabled = saveState !== "changed" || !firstName || !lastName;

  const changeField = (handler, value) => {
    setSaveState("changed");
    handler(value);
  };

  const {
    UpdateProfile,
    AddProfile,
    GetGuest,
    UpdateGuestProfile,
    GetProfiles,
    DeleteProfile,
    SelectProfile,
    SetMainProfile,
  } = useActions();

  React.useEffect(() => {
    if (profileId || guestId) return;
    if (!profiles) {
      GetProfiles();
      return;
    }
    if (selectedProfile && profiles.find((p) => p.id === selectedProfile))
      history.push(`/profiles/${selectedProfile}`);
    else history.push(`/profiles/${profiles[0].id}`);
    return;
  }, [profiles, profileId]);

  React.useEffect(() => {
    document.title = `${t("profiles.title")} - proWIN Messenger`;
  }, []);

  const setFields = (profile) => {
    setFirstName(profile?.firstName || "");
    setLastName(profile?.lastName || "");
    setBirthday(profile?.birthday ? moment(profile.birthday) : null);
    setPhoneNo(profile?.phoneNo || "");
    setEmail(profile?.email || "");
    setWebsite(profile?.website || "");
    setBiography(profile?.biography || "");
    setShopLink(profile?.shopLink || "");
    setProfilePic();
    setSelectedFile();
    setLoading(false);
    setSaveState("saved");
  };

  const handleAdd = () => {
    history.push("/profiles/new");
    setFields();
  };

  const handleSelect = (id) => {
    SelectProfile(id);
  };

  const handleMainProfileChange = async (id) => {
    await SetMainProfile(id);
    window.location.reload();
  };

  const [deleteProfileId, setDeleteProfileId] = React.useState();
  const deleteDialog = React.useRef();
  const handleDelete = () => {
    setDeleteProfileId(profileId);
    deleteDialog.current.open();
  };
  const submitDeleteDialog = async () => {
    DeleteProfile(deleteProfileId);
    history.push(`/profiles`);
  };

  React.useEffect(() => {
    if (!profileId || profileId === "new") return;
    if (!profile) {
      GetProfiles().then(({ response }) => {
        const profile = response?.data?.find((p) => p.id === profileId);
        if (!profile) history.push("/profiles");
        else setFields(profile);
      });
      return;
    }
    setFields(profile);
  }, [profileId]);

  React.useEffect(() => {
    if (!guestId) return;
    GetGuest(guestId).then(({ response }) => {
      setFields(response?.data);
      setGuestProfile(response?.data);
    });
  }, [guestId]);

  const cancel = () => {
    setFields(guestId ? guestProfile : profile);
    setDeleteProfilePic(false);
  };

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  React.useEffect(() => {
    document.title = `${firstName} ${lastName} - proWIN Messenger`;
  }, [firstName, lastName]);

  /* selecting files from hard drive */
  const selectFiles = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = false;
    input.accept = ["image/*"];
    input.onchange = ({ target }) => {
      const { files } = target;
      setSaveState("changed");
      setSelectedFile(files[0]);
      document.body.removeChild(input);
    };
    input.style.display = "none";
    document.body.append(input);
    input.click();
  };
  React.useEffect(() => {
    if (selectedFile) {
      setProfilePic(URL.createObjectURL(selectedFile));
      setDeleteProfilePic(false);
    }
  }, [selectedFile]);

  const handleDeleteProfilePic = () => {
    setSaveState("changed");
    setProfilePic();
    if (guestProfile.profilePic || profile.profilePic)
      setDeleteProfilePic(true);
    if (selectedFile) setSelectedFile();
  };

  const handleSave = async () => {
    setSaveState("saving");
    if (profileId === "new") {
      let { response, error } = await AddProfile(
        {
          firstName,
          lastName,
          birthday,
          phoneNo,
          email,
          website,
          biography,
          shopLink,
          newProfilePicType: profilePic ? selectedFile.type : undefined,
          deleteProfilePic,
        },
        profilePic ? selectedFile : null
      );
      if (error) {
        setSaveState("changed");
        return;
      }
      history.push(
        !response?.data?.profile?.id
          ? "/profiles"
          : `/profiles/${response.data.profile.id}`
      );
      return;
    }
    if (!guestId) {
      let { error } = await UpdateProfile(
        profileId,
        {
          firstName,
          lastName,
          birthday,
          phoneNo,
          email,
          website,
          biography,
          shopLink,
          newProfilePicType: profilePic ? selectedFile.type : undefined,
          deleteProfilePic,
        },
        profilePic ? selectedFile : null
      );
      if (error) {
        setSaveState("changed");
        return;
      }
      GetProfiles();
      setSaveState("saved");
      return;
    }
    let { error } = await UpdateGuestProfile(
      {
        firstName,
        lastName,
        birthday,
        phoneNo,
        email,
        website,
        biography,
        newProfilePicType: profilePic ? selectedFile.type : undefined,
        deleteProfilePic,
      },
      profilePic ? selectedFile : null
    );
    if (error) {
      setSaveState("changed");
      return;
    }
    const { response } = await GetGuest(guestId);
    setFields(response?.data);
    setGuestProfile(response?.data);
    setSaveState("saved");
  };

  const headerButtons = guestId
    ? []
    : [
        {
          label: `${t("profiles.newProfile")}`,
          icon: <AddIcon />,
          onClick: handleAdd,
        },
      ];

  const [loadDatePicker, setLoadDatePicker] = React.useState(false);
  React.useEffect(() => {
    setLoadDatePicker(true);
    setTimeout(setLoadDatePicker, 100, false);
  }, [profileId]);

  const { src, srcSet } = generateImageSourceSet(
    guestProfile?.id || session?.userId,
    guestProfile?.profilePic || profile?.profilePic,
    100
  );

  if (loading) return <PartyLoading />;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="profiles"
      />
      <div className={classes.screen}>
        <HeaderBar
          title={guestProfile ? t("profiles.titleGuest") : t("profiles.title")}
          buttons={headerButtons}
        />
        <div style={{ flex: 1, display: "flex" }}>
          {!guestId && (
            <div className={classes.sideMenu}>
              {profiles.map((item) => (
                <ProfileListItem
                  item={item}
                  handleMainProfileChange={handleMainProfileChange}
                  handleSelect={handleSelect}
                />
              ))}
            </div>
          )}
          <div className={classes.profileWrapper}>
            <div className={classes.picWrapper}>
              <Avatar
                onClick={selectFiles}
                src={
                  deleteProfilePic ? undefined : profilePic ? profilePic : src
                }
                srcSet={
                  deleteProfilePic ? undefined : profilePic ? undefined : srcSet
                }
                className={classes.avatar}
              />
              <div className={classes.addAction}>
                <CameraAltIcon
                  onClick={selectFiles}
                  sx={{ color: "#F3F9FC", fontSize: 15, margin: "auto" }}
                />
              </div>
              <div className={classes.deleteAction}>
                <DeleteIcon
                  onClick={handleDeleteProfilePic}
                  size="large"
                  sx={{ color: "#F3F9FC", fontSize: 15, margin: "auto" }}
                />
              </div>
            </div>
            <div className={classes.fieldGroup}>
              <InputBase
                placeholder={`${t("profiles.firstName")}*`}
                type="text"
                value={firstName}
                onChange={(e) => changeField(setFirstName, e.target.value)}
                className={classes.field}
                required
                disabled={saveState === "saving"}
              />
              <InputBase
                placeholder={`${t("profiles.lastName")}*`}
                type="text"
                value={lastName}
                onChange={(e) => changeField(setLastName, e.target.value)}
                className={classes.field}
                required
                disabled={saveState === "saving"}
              />
            </div>
            <div className={classes.fieldGroup}>
              {!!loadDatePicker ? (
                <InputBase
                  placeholder={t("profiles.birthday")}
                  type="text"
                  value={birthday?.format(t("general.dateFormat"))}
                  className={classes.field}
                  disabled={saveState === "saving"}
                />
              ) : (
                <DesktopDatePicker
                  inputFormat={t("general.dateFormat")}
                  mask={t("general.dateFormat")
                    .replace("DD", "__")
                    .replace("MM", "__")
                    .replace("yyyy", "____")}
                  value={birthday}
                  onChange={(val) => changeField(setBirthday, val)}
                  style={{ marginRight: 5 }}
                  disabled={saveState === "saving"}
                  renderInput={(params) => (
                    <>
                      <TextField
                        variant="standard"
                        className={classes.textFieldInput}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("profiles.birthday"),
                          style: {
                            fontSize: 13,
                            paddingLeft: 10,
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        style={{
                          ...params.style,
                          color: "#333E48",
                          flex: 1,
                          svgIcon: {
                            padding: 5,
                          },
                          justifyContent: "center",
                        }}
                        sx={{
                          svg: { marginRight: "10px", marginTop: "5px" },
                        }}
                      />
                    </>
                  )}
                />
              )}
              <InputBase
                placeholder={t("profiles.phoneNo")}
                type="tel"
                value={phoneNo}
                onChange={(e) => changeField(setPhoneNo, e.target.value)}
                className={classes.field}
                disabled={saveState === "saving"}
              />
            </div>
            <div className={classes.fieldGroup}>
              <InputBase
                placeholder={t("profiles.email")}
                type="email"
                value={email}
                onChange={(e) => changeField(setEmail, e.target.value)}
                className={classes.field}
                disabled={saveState === "saving"}
              />
              <InputBase
                placeholder={t("profiles.website")}
                type="url"
                value={website}
                onChange={(e) => changeField(setWebsite, e.target.value)}
                className={classes.field}
                disabled={saveState === "saving"}
              />
            </div>

            {!guestId && (
              <div className={classes.fieldGroup}>
                <InputBase
                  placeholder={t("profiles.shopLink")}
                  type="url"
                  value={shopLink}
                  onChange={(e) => changeField(setShopLink, e.target.value)}
                  className={classes.field}
                  disabled={saveState === "saving"}
                />
              </div>
            )}
            <InputBase
              placeholder={
                biography.length > 0
                  ? `${t("profiles.biography")} (${biography.length}/300)`
                  : t("profiles.biography")
              }
              type="text"
              value={biography}
              onChange={(e) => changeField(setBiography, e.target.value)}
              multiline
              rows={3}
              className={classes.bioField}
              disabled={saveState === "saving"}
            />
            <div className={classes.buttonRegister}>
              <Button
                disabled={cancelDisabled}
                onClick={cancel}
                className={classes.button}
                style={{
                  color: "#555",
                }}
                startIcon={<HighlightOffIcon />}
                color="white"
                variant={cancelDisabled ? "text" : "contained"}
              >
                {t("general.cancel")}
              </Button>
              <Button
                className={classes.button}
                startIcon={<CheckIcon />}
                disabled={saveDisabled}
                onClick={handleSave}
                color="primary"
                variant="contained"
              >
                {saveState === "saving"
                  ? t("general.saving")
                  : saveState === "saved"
                  ? t("general.saved")
                  : t("general.save")}
              </Button>
              {!guestId &&
                profileId !== "new" &&
                !profile.main &&
                profileId !== selectedProfile && (
                  <Button
                    disabled={deleteDisabled}
                    className={classes.button}
                    style={{
                      color: "#F74F0B",
                    }}
                    startIcon={<DeleteOutlineIcon />}
                    onClick={handleDelete}
                    color="white"
                    variant={deleteDisabled ? "text" : "contained"}
                  >
                    {t("profiles.deleteTitle")}
                  </Button>
                )}
              <DeleteDialog
                ref={deleteDialog}
                type="profile"
                onSubmit={submitDeleteDialog}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  profileWrapper: {
    margin: "10px auto 0",
    [theme.breakpoints.down("xs")]: {
      marginTop: 120,
    },
    maxWidth: 600,
    // background: "pink",
  },
  companySwitch: {
    marginBottom: 20,
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 10,
  },
  addAction: {
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "rgba(51,62,72,0.7)",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#fff",
    marginTop: "-1rem",
    zIndex: "2",
  },
  deleteAction: {
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "rgba(51,62,72,0.7)",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#fff",
    marginLeft: "4rem",
    marginTop: "-1.45rem",
    zIndex: "2",
  },
  avatar: {
    width: 100,
    height: 100,
    zIndex: "1",
  },
  profilesList: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  profileThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  selected: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#00BDF2",
  },
  title: {
    marginBottom: 0,
  },
  fieldGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginTop: "20px",
    "& > :first-child": {
      marginRight: 10,
    },
    "& > :last-child": {
      marginRight: "0 !important",
    },
  },
  field: {
    flex: 1,
    fontSize: "13px",
    color: "#000",
    height: 40,
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    "& input": {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  textFieldInput: {
    color: "#000",
    width: "48%",
    // marginBottom: 20,
    height: 40,
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  bioField: {
    width: "100%",
    fontSize: "13px",
    color: "#000",
    // marginBottom: 20,
    marginTop: 20,
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  buttonRegister: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  dateField: {
    outline: "none",
  },
  sideMenu: {
    width: 300,
    margin: "10px 0 20px 30px",
    borderRadius: 12,
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 1)",
    padding: "10px 0",
  },
  profileRow: {
    paddingLeft: 10,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#4F4E4E",
  },
  divider: {
    color: "#BEBEBE",
  },
  main: {
    padding: "5px 5px 0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  buttons: {
    marginLeft: 8,
    display: "flex",
    justifyContent: "flex-end",
  },

  button: {
    textTransform: "none",
    fontFamily: "DIN NEXT LT Pro",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4) !important",
    paddingTop: 5,
    paddingBottom: 3,
    fontSize: 14,
    margin: 10,
    width: 200,

    "& .MuiButton-startIcon": {
      marginTop: -3,
    },

    "&.MuiButton-text:disabled": {
      boxShadow: "none !important",
      opacity: 0.7,
    },
  },
}));

export default Profile;
