import React from "react";
import {
  Avatar,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import { SelectProfileDialog } from "components";
import { LanguageDialog } from "components";
import { useHistory } from "react-router-dom";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const NavMenu = (props) => {
  const classes = useStyles();
  const session = useSelector((state) => state.session);
  const settings = useSelector((state) => state.settings);
  const guestId = useSelector((state) => state.session.id);
  const profiles = useSelector((state) => state.profile?.profiles || []);
  const profile = useSelector(
    (state) =>
      state.profile?.profiles?.find(
        (p) => p.id === state.settings?.[state.session?.userId]?.selectedProfile
      ) || {}
  );
  const { items = [], selected } = props;
  const [avatarMenuOpen, setAvatarMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const [guestProfile, setGuestProfile] = React.useState();

  const name = session.secretKey
    ? `${session.firstName?.trim() || ""} ${session.lastName?.trim() || ""}`
    : `${profile?.firstName?.trim() || ""} ${profile?.lastName?.trim() || ""}`;

  React.useEffect(async () => {
    if (session.accessToken) {
      await GetUser();
    }
  }, []);

  const { t } = useTranslation();

  const { Logout, SelectProfile, GetUser, GetGuest } = useActions();

  const selectProfileDialog = React.useRef();

  React.useEffect(() => {
    if (
      settings?.[session.userId]?.selectedProfile ||
      !profiles ||
      profiles.length === 0
    ) {
      return;
    }
    if (profiles.length === 1) {
      SelectProfile(profiles[0].id);
      return;
    }
    if (!session.accessToken) {
      return;
    }
    selectProfileDialog.current.open();
  }, [profiles, settings, session.userId]);

  React.useEffect(() => {
    if (!guestId) return;
    GetGuest(guestId).then(({ response }) => {
      setGuestProfile(response?.data);
    });
  }, [guestId]);

  const submitSelectProfileDialog = (profileId) => {
    SelectProfile(profileId);
  };

  const handleViewProfile = () => {
    setAvatarMenuOpen(false);
    if (!session.secretKey) {
      window.location.href = `/profiles/${
        settings[session.userId].selectedProfile
      }`;
    } else {
      window.location.href = `/profiles`;
    }
  };

  const handleChangeEmail = () => {
    history.push("/claim");
  };

  const handleLogout = () => {
    setAvatarMenuOpen(false);
    Logout();
  };

  const languageDialog = React.useRef();
  const showLanguageDialog = () => {
    setAvatarMenuOpen(false);
    languageDialog.current.open();
  };

  const handleAvatarMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setAvatarMenuOpen(!avatarMenuOpen);
  };

  const history = useHistory();

  const { src, srcSet } = generateImageSourceSet(
    session?.userId || session?.id,
    profile?.profilePic || guestProfile?.profilePic,
    40
  );

  return (
    <div className={classes.navWrapper}>
      <div className={classes.logoWrapper}>
        <Link to={"/"}>
          <img
            src="https://prowin.net/files/prowin-data/img/logos/prowin/prowin-blume-farbig.svg"
            alt="alternative"
            width="29px"
            height="34px"
          />
        </Link>
      </div>
      <div className={classes.groupIcon}>
        {items.map((item) => (
          <Tooltip title={t(item.displayName)} placement="right" key={item.id}>
            <Button
              className={classes.menuItem}
              variant={selected === item.id ? "contained" : "text"}
              style={{ color: selected === item.id ? undefined : "#333" }}
              onClick={() => {
                if (props?.setPath) props.setPath([]);
                history.push(item.link);
              }}
            >
              {item.icon}
            </Button>
          </Tooltip>
        ))}
      </div>

      <div className={classes.avatarWrapper}>
        <Avatar
          src={src}
          srcSet={srcSet}
          className={classes.avatar}
          onClick={(event) => handleAvatarMenuOpen(event)}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={avatarMenuOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAvatarMenuOpen(false)}
        style={{ marginLeft: 10 }}
      >
        <MenuItem disabled className={classes.name}>
          {name}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleViewProfile} disabled={!name}>
          {t("general.viewProfile")}
        </MenuItem>
        {!!session.secretKey && (
          <MenuItem onClick={handleChangeEmail}>
            {t("general.changeEmail")}
          </MenuItem>
        )}
        <MenuItem onClick={showLanguageDialog}>
          {t("general.changeLanguage")}
        </MenuItem>
        <MenuItem onClick={handleLogout} target="proWinLogOutFrame">
          {t("general.logout")}
        </MenuItem>
      </Menu>
      <LanguageDialog ref={languageDialog} />
      <SelectProfileDialog
        ref={selectProfileDialog}
        onSubmit={submitSelectProfileDialog}
        profiles={profiles}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    padding: "29px 0 10px 15px",
  },
  navWrapper: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: 75,
    height: "100vh",
  },
  groupIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: "10px 0",
  },

  avatarWrapper: {
    margin: "20px auto",
  },
  avatar: {
    cursor: "pointer",
    // border: "solid 2px #F7920B",
  },
  menuItem: {
    height: 40,
    borderRadius: 0,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    boxShadow: "none !important",
    marginTop: 5,
    marginBottom: 5,
  },
  name: {
    fontWeight: "500",
    opacity: "1 !important",
  },
}));

export default NavMenu;
