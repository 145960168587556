import React, { useEffect } from "react";
import { usePageVisibility } from "react-page-visibility";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const SocketContext = React.createContext();

export const useSocket = () => React.useContext(SocketContext)?.current;

export const SocketProvider = ({ children }) => {
  const socket = React.useRef();
  const isVisible = usePageVisibility();

  const userId = useSelector((state) =>
    state.session?.secretKey ? state.session.id : state.session.userId
  );

  const startConnection = () => {
    socket.current = io(process.env.REACT_APP_API_URL, {
      transports: ["websocket"],
      reconnection: true, // whether to reconnect automatically
      reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
      reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
      reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
      randomizationFactor: 0.5,
    });
    socket.current.heartbeatTimeout = 3000;
    socket.current.on("connect_error", () => {
      setTimeout(() => {
        clearConnection();
        startConnection();
      }, 500);
    });
    socket.current.on("connect_failed", () => {
      setTimeout(() => {
        clearConnection();
        startConnection();
      }, 500);
    });
    socket.current.emit("connectUser", { userId });
  };
  const clearConnection = () => {
    if (socket.current) socket.current.close();
  };

  React.useEffect(() => {
    startConnection();
    return clearConnection;
  }, []);

  React.useEffect(() => {
    if (isVisible && !socket.current?.connected) {
      clearConnection();
      startConnection();
    }
  }, [isVisible]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocketInitialized = () => {
  const socket = useSocket();
  return !!socket;
};

export const useEmit = () => {
  const socket = useSocket();
  if (!socket) return () => {};
  return (...args) => socket.emit(...args);
};

export const useConnect = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("connect", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useUserJoined = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("userJoined", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useIAmOnline = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("iAmOnline", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useUserLeft = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("userLeft", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useGuestUpdate = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("guestUpdate", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useNewMessage = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("newMessage", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useUpdatedMessage = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("updatedMessage", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useUpdatedSeen = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("updatedSeen", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useUpdateRestriction = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("updateRestriction", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useReloadMessages = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("reloadMessages", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const usePartyDeleted = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("partyDeleted", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useGuestRemoved = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("deleted", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};

export const useDirectChatStarted = (handler) => {
  const handlerRef = React.useRef();
  const socket = useSocket();
  useEffect(() => {
    handlerRef.current = handler;
    return () => (handlerRef.current = undefined);
  }, [handler]);
  useEffect(() => {
    if (!socket) return;
    socket.on("directChatStarted", (...args) => handlerRef.current?.(...args));
  }, [socket]);
};
