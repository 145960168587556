import React from "react";
import { makeStyles } from "@mui/styles";
import Guest from "./guest";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const GuestList = ({
  guests,
  onlineUsers,
  guestSearch,
  determineRole = () => {},
  menuItems = [],
  filterMenuItems = () => true,
}) => {
  const classes = useStyles();

  const renderGuest = (item, index) => {
    const id = item.userId || item.id;
    const mainProfile = item.profiles?.find((p) => p.main);
    const name = mainProfile
      ? `${mainProfile.firstName?.trim() || ""} ${
          mainProfile.lastName?.trim() || ""
        }`.trim()
      : `${item.firstName?.trim() || ""} ${item.lastName?.trim() || ""}`.trim();
    const names = name.split(" ");
    const initials = `${names[0]?.[0]}${names[1]?.[0]}`.trim();
    const role = determineRole(item);
    const { src, srcSet } = generateImageSourceSet(
      id,
      mainProfile?.profilePic || item.profilePic,
      40
    );
    const isOnline =
      onlineUsers &&
      Array.isArray(onlineUsers[id]) &&
      onlineUsers[id].length > 0;
    return (
      <Guest
        key={index.toString()}
        id={id}
        initials={initials}
        name={name}
        role={role}
        src={src}
        srcSet={srcSet}
        online={isOnline}
        menuItems={menuItems.filter((menuItem) =>
          filterMenuItems(menuItem, item)
        )}
      />
    );
  };

  return (
    <div className={classes.guests}>
      {guestSearch ? guestSearch.map(renderGuest) : guests?.map(renderGuest)}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    height: 50,
    paddingLeft: 20,
    fontWeight: "bold",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    borderBottom: "1px solid #D8D8D8",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  guest: {
    height: 36,
    borderWidth: 0,
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  managerGuest: {
    fontWeight: "bold",
    // marginTop: 15,
  },
  guests: {
    flex: 1,
    paddingTop: 0,
    borderRadius: 0,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
  onlineStatus: {
    "&::before": {
      content: '""',
      display: "block",
      width: 10,
      height: 10,
      borderRadius: 5,
      marginRight: 10,
    },
  },
  onlineStatusOffline: {
    "&::before": {
      backgroundColor: "#DADADA",
    },
  },
  onlineStatusOnline: {
    "&::before": {
      backgroundColor: "#4DB85C",
    },
  },
  grow: {
    flexGrow: 1,
    minWidth: 10,
  },
}));

export default GuestList;
