import {
  CHAT_LIST,
  LOGOUT,
  MUTE_GROUP,
  UNMUTE_GROUP,
  MUTE_PARTY,
  UNMUTE_PARTY,
  REMOVE_PARTY,
  REMOVE_GROUP_CHAT,
  MUTE_CHAT,
  UNMUTE_CHAT,
  ARCHIVE_CHAT,
  UNARCHIVE_CHAT,
  BLOCK,
  UNBLOCK,
  DIRECT_CHAT_TIMESTAMP,
  GROUP_CHAT_TIMESTAMP,
  PARTY_CHAT_TIMESTAMP,
  HANDLE_GROUP_RESTRICTED,
  HANDLE_PARTY_RESTRICTED,
} from "../types";
import _ from "lodash";
import moment from "moment";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case CHAT_LIST: {
      const chatList = action.payload;
      const chatWithMessages = _.orderBy(
        _.filter(chatList, (item) => item.latestMessage || item.created),
        (item) => item.latestMessage?.timestamp || item.created,
        "desc"
      );
      const chatWithoutMessages = _.filter(
        chatList,
        (item) => !item.latestMessage && !item.created
      );

      return [...chatWithMessages, ...chatWithoutMessages];
    }

    case MUTE_GROUP: {
      const groupId = action.payload;
      const index = state.findIndex((c) => c.id === groupId);
      const newState = [...state];
      newState[index].mute = true;
      return newState;
    }
    case UNMUTE_GROUP: {
      const groupId = action.payload;
      const index = state.findIndex((c) => c.id === groupId);
      const newState = [...state];
      newState[index].mute = false;
      return newState;
    }
    case MUTE_CHAT: {
      const chatId = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      const newState = [...state];
      newState[index].mute = true;
      return newState;
    }
    case UNMUTE_CHAT: {
      const chatId = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      const newState = [...state];
      newState[index].mute = false;
      return newState;
    }

    case MUTE_PARTY: {
      const partyId = action.payload;
      const index = state.findIndex((c) => c.id === partyId);
      const newState = [...state];
      newState[index].mute = true;
      return newState;
    }

    case UNMUTE_PARTY: {
      const partyId = action.payload;
      const index = state.findIndex((c) => c.id === partyId);
      const newState = [...state];
      newState[index].mute = false;
      return newState;
    }

    case ARCHIVE_CHAT: {
      const { chatId } = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        archived: true,
      };
      return newState;
    }

    case UNARCHIVE_CHAT: {
      const { chatId } = action.payload;
      const index = state.findIndex((c) => c.chatId === chatId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index] = {
        ...newState[index],
        archived: false,
      };
      return newState;
    }

    case BLOCK: {
      const { id: userId } = action.payload;
      const index = state.findIndex((c) => c.otherUserId === userId);
      const newState = [...state];
      newState[index].blockedByMe = true;
      return newState;
    }

    case UNBLOCK: {
      const userId = action.payload;
      const index = state.findIndex((c) => c.otherUserId === userId);
      const newState = [...state];
      delete newState[index].blockedByMe;
      return newState;
    }

    case REMOVE_PARTY: {
      const partyId = action.payload;
      const newState = state.filter((m) => m.id !== partyId);
      return newState;
    }

    case REMOVE_GROUP_CHAT: {
      const groupId = action.payload;
      const newState = state.filter((m) => m.id !== groupId);
      return newState;
    }

    case DIRECT_CHAT_TIMESTAMP: {
      const { chatId } = action.payload;
      const index = state.findIndex((chat) => chat.chatId === chatId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index].seen = moment().toISOString();
      return newState;
    }

    case GROUP_CHAT_TIMESTAMP: {
      const { groupId } = action.payload;
      const index = state.findIndex((chat) => chat.id === groupId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index].seen = moment().toISOString();
      return newState;
    }

    case PARTY_CHAT_TIMESTAMP: {
      const { partyId } = action.payload;
      const index = state.findIndex((chat) => chat.shortId === partyId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index].seen = moment().toISOString();
      return newState;
    }

    case HANDLE_GROUP_RESTRICTED: {
      const { groupId, restricted } = action.payload;
      const index = state.findIndex((chat) => chat.id === groupId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index].restrictedMessaging = restricted;
      return newState;
    }

    case HANDLE_PARTY_RESTRICTED: {
      const { partyId, restricted } = action.payload;
      const index = state.findIndex((chat) => chat.id === partyId);
      if (index === -1) return state;
      const newState = [...state];
      newState[index].restrictedMessaging = restricted;
      return newState;
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
