import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputBase,
  alpha,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FolderIcon from "@mui/icons-material/Folder";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import { useActions } from "actions";
import clsx from "clsx";

const MoveToTemplateDialog = ({ onSubmit }, ref) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const classes = useStyles({ windowWidth });
  const { DuplicateFile, AddTemplateMessage, AddTemplate } = useActions();
  const { t } = useTranslation();

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [open, setOpen] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [path, setPath] = React.useState([]);
  const [selected, select] = React.useState();
  const [searchValue, setSearchValue] = React.useState("");
  const [visibleFolders, setVisibleFolders] = React.useState([]);
  const [visibleFiles, setVisibleFiles] = React.useState([]);

  const [newTemplateOpen, setNewTemplateOpen] = React.useState(false);
  const [newTemplateName, setNewTemplateName] = React.useState("");

  const getTemplatesInPath = () => {
    if (!path || path.length === 0) {
      setVisibleFolders(templates.filter((m) => m.isFolder && !m.path));
      setVisibleFiles(
        templates.filter((m) => !m.isFolder && !m.path && !m.sharedBy)
      );
    } else {
      setVisibleFolders(
        templates.filter((m) => m.isFolder && m.path === path.join("/"))
      );
      setVisibleFiles(
        templates.filter(
          (m) => !m.isFolder && m.path === path.join("/") && !m.sharedBy
        )
      );
    }
  };

  React.useEffect(() => {
    getTemplatesInPath();
  }, [path]);

  const handleSetPath = (folderId) => {
    if (folderId === "") {
      setPath([]);
      return;
    }
    const folder = templates.find((m) => m.id === folderId);
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };

  React.useImperativeHandle(ref, () => ({
    open: (templates, messages) => {
      setOpen(true);
      setTemplates(templates);
      setMessages(messages);
      setPath([]);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewTemplateClose = () => {
    setNewTemplateOpen(false);
  };

  const sendMessages = async (selected) => {
    for (const msg of messages) {
      let newFile = {};
      if (
        msg.mediaAttachmentType ||
        (msg.messageType !== "text" && !msg.messageType.startsWith("heading"))
      ) {
        const { response } = await DuplicateFile(
          msg.mediaAttachment
            ? `${msg.mediaAttachmentOwner || msg.sender}/${msg.mediaAttachment}`
            : msg.partyId
            ? `${msg.partyId}/${msg.message}`
            : msg.groupId
            ? `${msg.groupId}/${msg.message}`
            : `${msg.templateId}/${msg.message}`,
          !msg.mediaAttachmentType
            ? msg.messageType === "photo"
              ? "image"
              : "audio"
            : msg.mediaAttachmentType,
          false
        );
        if (!response?.data?.id) continue;
        newFile = {
          mediaId: response.data.id,
          mediaAttachmentType: response.data.mediaType,
          description: msg.description,
          messageType: "text",
        };
      }
      await AddTemplateMessage(selected, {
        selected,
        sender: msg.sender,
        messageType: msg.messageType,
        message: msg.message,
        ...newFile,
      });
    }
  };

  const handleNewTemplate = () => {
    setOpen(false);
    setNewTemplateName("");
    setNewTemplateOpen(true);
  };

  const handleNewTemplateSubmit = async (e) => {
    e.preventDefault();
    setNewTemplateOpen(false);

    const { response } = await AddTemplate(newTemplateName);

    if (!response?.data) return;

    await sendMessages(response.data.id);
    onSubmit();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(false);
    await sendMessages(selected);
    onSubmit();
  };

  const applySearch = (item) => {
    const searchVal = searchValue.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result = result && searchTerm.test(item.title.toLowerCase());
    }
    return result;
  };

  const renderFolder = (folder) => {
    return (
      <div
        className={classes.folder}
        key={folder.id}
        onClick={() => handleSetPath(folder.id)}
      >
        <FolderIcon className={classes.folderIcon} />
        <div className={classes.folderName}>{folder.title}</div>
      </div>
    );
  };

  const renderTemplate = (template) => {
    return (
      <div
        className={clsx(classes.template, {
          [classes.selected]: template.id === selected,
        })}
        key={template.id}
        onClick={() => select(template.id)}
      >
        <Typography className={classes.templateName}>
          &nbsp;{template.title}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t("party.applyTemplateDialogTitle")}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ margin: 10 }}>
              {t("chats.moveToTemplateDialog")}
            </DialogContentText>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                classes={{
                  root: classes.searchField,
                  input: classes.searchInput,
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            {!searchValue.trim() && (
              <div className={classes.breadcrumbs}>
                <Button
                  size="small"
                  classes={{
                    root: classes.pathButton,
                    label: classes.pathButtonLabel,
                  }}
                  onClick={() => handleSetPath("")}
                >
                  {t("templates.homeTitle")}
                </Button>
                {path.length > 0 && (
                  <ChevronRight
                    fontSize="small"
                    className={classes.pathSeparator}
                  />
                )}
                {path.length > 2 && (
                  <>
                    <Button
                      size="small"
                      classes={{
                        root: classes.pathButton,
                        label: classes.pathButtonLabel,
                      }}
                      disabled
                    >
                      ...
                    </Button>
                    <ChevronRight
                      fontSize="small"
                      className={classes.pathSeparator}
                    />
                  </>
                )}
                {path.slice(-2).map((p, index) => (
                  <React.Fragment key={p}>
                    <Button
                      size="small"
                      classes={{
                        root: classes.pathButton,
                        label: classes.pathButtonLabel,
                      }}
                      onClick={() => handleSetPath(p)}
                    >
                      {templates.find((m) => m.id === p)?.title}
                    </Button>
                    {index < path.slice(-2).length - 1 && (
                      <ChevronRight
                        fontSize="small"
                        className={classes.pathSeparator}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
            <div className={classes.mediaArea}>
              {!searchValue.trim() &&
                _.sortBy(visibleFolders, ["title"]).map(renderFolder)}
              {!searchValue.trim() &&
                _.sortBy(visibleFiles, ["title"]).map(renderTemplate)}
              {!!searchValue.trim() &&
                _.sortBy(templates.filter(applySearch), ["title"]).map(
                  renderTemplate
                )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ width: 300 }}
              onClick={handleNewTemplate}
              color="primary"
            >
              {t("templates.newTemplate")}
            </Button>
            <Button
              style={{ width: 300 }}
              type="submit"
              color="primary"
              variant="contained"
              disabled={!selected}
            >
              {t("general.forward")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={newTemplateOpen} onClose={handleNewTemplateClose}>
        <div className={classes.modalWrapper}>
          <form onSubmit={handleNewTemplateSubmit}>
            <DialogTitle>
              {t("templates.createTemplateDialogTitle")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("templates.createTemplateDialog")}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label={t("templates.templateName")}
                type="text"
                fullWidth
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                className={classes.field}
                required
              />
            </DialogContent>
            <div className={classes.spacer} />
            <DialogActions>
              <Button
                className={classes.cancelButton}
                onClick={handleNewTemplateClose}
                color="inherit"
              >
                {t("general.cancel")}
              </Button>
              <Button
                className={classes.createButton}
                type="submit"
                color="primary"
                variant="contained"
                disabled={!newTemplateName?.trim()}
              >
                {t("general.create")}
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("rgb(180, 180, 180)", 0.15),
    "&:hover": {
      backgroundColor: alpha("rgb(180, 180, 180)", 0.25),
    },
    width: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  searchIcon: {
    height: "100%",
    width: theme.spacing(5),
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
  },
  searchField: {
    color: "inherit",
  },
  searchInput: {
    padding: theme.spacing(1, 1, 1, 0),
    width: "100%",
  },
  breadcrumbs: {
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pathButton: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "#333",
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 30,
    "& span": {
      pointerEvents: "none",
    },
    justifyContent: "flex-start",
  },
  pathButtonLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  pathSeparator: {
    color: "#999",
    marginLeft: -5,
    marginRight: -5,
  },
  template: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 550,
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  selected: {
    backgroundColor: "#ddd",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
  mediaArea: {
    maxHeight: "calc(100vh - 350px)",
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
  },
  templateName: {
    flex: 1,
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  folder: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 550,
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  folderIcon: {
    color: "#666",
  },
  folderName: {
    flex: 1,
    fontSize: 17,
    textJustify: "baseline",
    paddingLeft: 5,
    marginBottom: -3,
    color: "#444",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  modalWrapper: {
    width: 500,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    justifyContent: "center",
    display: "flex",
    width: 500,
    gap: 10,
  },
  cancelButton: {
    width: 230,
  },
  createButton: {
    width: 230,
  },
}));

export default React.forwardRef(MoveToTemplateDialog);
