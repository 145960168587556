import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/styles";
import { Button } from "@mui/material";
import GuestAccount from "./GuestAccount";
import RegisterGuest from "./RegisterGuest";
import { useLocation } from "react-router-dom";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import CodeVerification from "../codeVerification";
import PrivacyDialog from "../privacyDialog";

const LoginComponent = (props) => {
  const { target } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const [isCodeSent, setIsCodeSent] = React.useState(false);
  const [guestEmail, setGuestEmail] = React.useState(false);
  const loginPage = "login";
  const { SetCallbackLocation } = useActions();

  const handleProwinLogin = () => {
    if (target !== location.pathname) SetCallbackLocation(target);
    else SetCallbackLocation();
    const params = {
      client_id:
        "f1eb7a3f-0cce-4e7b-b971-c86986eef92b_890ygdla7xs8sc84g0s4g8gsg8gwsck4ocw0s8ko0g0sow8o",
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      response_type: "code",
      scope: "openid",
    };
    const paramsString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    window.location.href = `https://accounts.prowin.net/oauth2/authorize?${paramsString}`;
  };

  const handleCodeSent = () => {
    setIsCodeSent(true);
  };

  const handleCodeSubmitted = () => {
    setIsCodeSent(false);
  };

  const handleGuestEmail = (email) => {
    setGuestEmail(email);
  };

  const privacyDialog = React.useRef();
  const openPrivacyDialog = () => {
    privacyDialog.current.open();
  };

  return (
    <>
      {!isCodeSent && (
        <div className={classes.wrapper}>
          <h1 className={classes.anmeldungTitle}>{t("login.login")}</h1>
          <p className={classes.text}>{t("login.haveGuestAccount")}:</p>
          <GuestAccount
            guestEmail={handleGuestEmail}
            verificationCodeSent={handleCodeSent}
          />
          <p className={classes.text}>{t("login.guestRegistration")}:</p>
          <RegisterGuest target={target} />
          <div className={classes.proWinButton}>
            <Button
              style={{
                textTransform: "none",
                background: "#FFF",
                width: "100%",
                color: theme.palette.primary.main,
                boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
                padding: "8px 20px 5px 20px",
                fontSize: 15,
                fontFamily: theme.typography.h1.fontFamily,
              }}
              onClick={openPrivacyDialog}
            >
              <img src="logo-prowin-square.png" alt="img" height="30" />
              {t("login.withProwin")}
            </Button>
          </div>
        </div>
      )}
      {isCodeSent && (
        <CodeVerification
          verificationCallback={handleCodeSubmitted}
          guestEmail={guestEmail}
          from={loginPage}
        />
      )}
      <PrivacyDialog ref={privacyDialog} onSubmit={handleProwinLogin} />
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100vh",
  },

  proWinButton: {
    marginTop: 30,
    width: 380,
  },
  registerText: {
    display: "flex",
    justifyContent: "flex-start",
  },
  anmeldungTitle: {
    fontSize: 20,
    color: theme.palette.darkGrey.main,
    fontFamily: theme.typography.h1.fontFamily,
  },
  text: {
    fontSize: 15,
    color: theme.palette.darkGrey.main,
    marginTop: 10,
    marginBottom: 0,
    width: "100%",
    maxWidth: 350,
  },
}));
export default LoginComponent;
