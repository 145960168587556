import React from "react";
import { makeStyles } from "@mui/styles";
import ClaimAccount from "src/components/claimAccount";
import { CodeVerification, LoginLeft } from "components";

const LoginPage = () => {
  const classes = useStyles();
  const [emailSubmitted, setEmailSubmitted] = React.useState(false);
  const claimPage = "claim";

  const handleEmailSubmitted = () => {
    setEmailSubmitted(true);
  };

  return (
    <div className={classes.wrapper}>
      <LoginLeft />
      {!emailSubmitted && (
        <div className={classes.loginComponent}>
          <ClaimAccount emailSubmitted={handleEmailSubmitted} />
        </div>
      )}
      {emailSubmitted && <CodeVerification from={claimPage} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    height: "100vh",
    backgroundColor: "#F3F9FC",
    flex: 1,
  },
  loginComponent: {
    flex: 1,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default LoginPage;
