import React from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import {
  Container,
  Paper,
  Typography,
  Grid,
  CssBaseline,
  Button,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import short from "short-uuid";
import logo from "assets/logo-prowin-messenger.svg";
import { useHistory } from "react-router-dom";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const AddVIP = () => {
  const translator = short();
  const classes = useStyles();
  const { id } = useParams();
  const profileId = translator.toUUID(id);
  const { GetDirectProfile } = useActions();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2).toLowerCase();
  const langButton = React.useRef();
  const [langOpen, setLangOpen] = React.useState(false);
  const [profile, setProfile] = React.useState();

  const history = useHistory();

  const { AddChat } = useActions();

  const selectLang = async (lang) => {
    await i18n.changeLanguage(lang);
    setLangOpen(false);
  };

  const loadProfile = async () => {
    let { response } = await GetDirectProfile(profileId);
    setProfile(response.data);
  };

  React.useEffect(() => {
    loadProfile();
  }, []);

  const joinGuest = async () => {
    if (!profile) return;
    const { response } = await AddChat(profile.userId);
    if (!response) return;
    history.push(`/chats/c/${response.data.chatId}`);
  };

  const { src, srcSet } = generateImageSourceSet(
    profile?.userId,
    profile?.profilePic,
    120
  );

  return (
    <div className={classes.screen}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Grid item xs={3}>
            <Link to="/">
              <img src={logo} alt="Prowin Messenger" />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="inherit"
              onClick={() => setLangOpen(!langOpen)}
              ref={langButton}
              className={classes.langButton}
            >
              {lang === "en"
                ? "English 🇬🇧"
                : lang === "nl"
                ? "Nederlands 🇳🇱"
                : lang === "fr"
                ? "Français 🇫🇷"
                : "Deutsch 🇩🇪"}
            </Button>
            <Menu
              anchorEl={langButton.current}
              keepMounted={true}
              open={langOpen}
              onClose={() => setLangOpen(false)}
            >
              <MenuItem onClick={() => selectLang("de")}>Deutsch 🇩🇪</MenuItem>
              <MenuItem onClick={() => selectLang("en")}>English 🇬🇧</MenuItem>
              <MenuItem onClick={() => selectLang("nl")}>
                Nederlands 🇳🇱
              </MenuItem>
              <MenuItem onClick={() => selectLang("fr")}>Français 🇫🇷</MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Container maxWidth="sm">
          <Typography align="center" variant="h4" className={classes.title}>
            {t("addVIP.title")}
          </Typography>
          <div className={classes.profileCard} justifyContent="center">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Avatar src={src} srcSet={srcSet} className={classes.avatar} />
              </Grid>
              <Grid item>
                <Typography align="center" variant="h5">
                  {profile?.firstName.toUpperCase()}{" "}
                  {profile?.lastName.toUpperCase()}
                </Typography>
              </Grid>
              {!!profile?.phoneNo && (
                <Grid item xs={8}>
                  <Typography align="center" variant="h6">
                    <b>{t("profiles.phoneNo")}:</b>{" "}
                    <a href={`tel:${profile.phoneNo}`}>{profile.phoneNo}</a>
                  </Typography>
                </Grid>
              )}
              {!!profile?.email && (
                <Grid item>
                  <Typography align="center" variant="h6">
                    <b>{t("profiles.email")}:</b>{" "}
                    <a href={`mailto:${profile.email}`}>{profile.email}</a>
                  </Typography>
                </Grid>
              )}
              {!!profile?.email && (
                <Grid item>
                  <Typography align="center" variant="h6">
                    <b>{t("profiles.website")}:</b>{" "}
                    <a href={profile.website}>{profile.website}</a>
                  </Typography>
                </Grid>
              )}
              {!!profile?.biography && (
                <>
                  <Grid item>
                    <Typography align="center" variant="h6">
                      <b>{t("profiles.biography")}:</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      align="center"
                      className={classes.cardContent}
                    >
                      {profile.biography}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </div>

          <Typography variant="h6" align="center">
            {`${profile?.firstName} ${profile?.lastName} ${t(
              "chats.directChatInvitationText"
            )}`}
          </Typography>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.buttonCreate}
              type="submit"
              color="primary"
              variant="contained"
              style={{ textTransform: "lowecase" }}
              onClick={joinGuest}
            >
              {t("chats.joinChat")}
            </Button>
            <Button
              className={classes.buttonCancel}
              color="inherit"
              onClick={() => history.push("/")}
            >
              {t("general.cancel")}
            </Button>
          </div>
        </Container>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#F3F9FC",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  langButton: {
    textTransform: "none",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main,
  },
  profileCard: {
    marginBottom: theme.spacing(4),
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
    padding: 10,
  },
  cardContent: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonCancel: {
    margin: 10,
    width: 270,
  },
  buttonCreate: {
    width: 270,
    background: "#1E92CD",
  },
}));

export default AddVIP;
