import {
  GET_GROUP_CHATS,
  GET_GROUP_CHAT,
  ADD_GROUP_CHATS,
  UPDATE_GROUP_CHATS,
  MUTE_GROUP,
  UNMUTE_GROUP,
  // REMOVE_PARTY,
  HANDLE_GROUP_RESTRICTED,
  CHAT_LIST,
  REMOVE_GROUP_CHAT,
  LOGOUT,
} from "../types";
import _ from "lodash";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_CHATS: {
      let groupchats = action.payload;
      const newState = {};
      for (const groupchat of groupchats) {
        newState[groupchat.id] = groupchat;
      }

      const chatWithMessages = _.orderBy(
        _.filter(newState, (item) => item.latestMessage || item.created),
        (item) => item.latestMessage?.timestamp || item.created,
        "desc"
      );

      const chatWithoutMessages = _.filter(
        newState,
        (item) => !item.latestMessage && !item.created
      );
      return [...chatWithMessages, ...chatWithoutMessages];
    }

    case GET_GROUP_CHAT: {
      let groupchat = action.payload;
      const index = state.findIndex((c) => c.id === groupchat);
      const newState = [...state];
      newState[index] = groupchat;
      return newState;
    }

    case UPDATE_GROUP_CHATS:
    case ADD_GROUP_CHATS: {
      let groupchat = action.payload;
      return {
        ...state,
        [groupchat.id]: {
          ...(state[groupchat.id] || {}),
          groupchat,
        },
      };
    }

    case MUTE_GROUP: {
      const groupId = action.payload;
      const index = state.findIndex((c) => c.id === groupId);
      const newState = [...state];
      newState[index].mute = true;
      return newState;
    }
    case UNMUTE_GROUP: {
      const groupId = action.payload;
      const index = state.findIndex((c) => c.id === groupId);
      const newState = [...state];
      newState[index].mute = false;
      return newState;
    }

    case HANDLE_GROUP_RESTRICTED: {
      const { groupId, restricted } = action.payload;
      return {
        ...state,
        [groupId]: {
          ...(state[groupId] || {}),
          restrictedMessaging: restricted,
        },
      };
    }

    case REMOVE_GROUP_CHAT: {
      const groupId = action.payload;
      const newState = state.filter((m) => m.id !== groupId);
      return newState;
    }

    case CHAT_LIST: {
      const chatList = action.payload;
      const newState = [];
      for (const chatItem of chatList) {
        if (chatItem.type === "group") {
          newState.push(chatItem);
        }
      }
      const chatWithMessages = _.orderBy(
        _.filter(newState, (item) => item.latestMessage || item.created),
        (item) => item.latestMessage?.timestamp || item.created,
        "desc"
      );
      const chatWithoutMessages = _.filter(
        newState,
        (item) => !item.latestMessage && !item.created
      );
      return [...chatWithMessages, ...chatWithoutMessages];
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
