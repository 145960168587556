import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const LoginLeft = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <img src="/logo-prowin-messenger.svg" className={classes.logo} />
      <h2 className={classes.title}>
        {t("login.title")}
        <br />
        {t("login.subtitle")}
      </h2>
      <img src="/chats-preview.svg" className={classes.preview} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    background: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 825px)": {
      display: "none",
    },
  },
  logo: {
    width: "50%",
    maxWidth: 300,
    marginBottom: 20,
  },
  title: {
    fontWeight: "500",
    color: "#555",
    textAlign: "center",
    marginLeft: 20,
    marginRight: 20,
  },
  preview: {
    width: 400,
    minWidth: "50%",
    borderRadius: 12,
    boxShadow:
      "0 0 10px 0 rgb(160 160 160 / 20%), 0 0.5px 1px 0 rgba(160,160,160,0.4)",
    marginLeft: 20,
    marginRight: 20,
  },
}));

export default LoginLeft;
