import React from "react";
import {
  Media,
  Chats,
  ReleaseNotes,
  Templates,
  FAQ,
  Profile,
  Parties,
} from "screens";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import WidgetsIcon from "@mui/icons-material/Widgets";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { SocketProvider } from "src/context/socket";
import { CalendarMonth } from "@mui/icons-material";

export const routes = [
  {
    id: "chats",
    screen: (props) => (
      <SocketProvider>
        <Chats {...props} />
      </SocketProvider>
    ),
    path: [
      "/chats/p/:partyId",
      "/chats/g/:partyId",
      "/chats/c/:partyId",
      "/chats",
    ],
    link: "/chats",
    displayName: "chats.title",
    icon: <ChatBubbleOutlineOutlinedIcon />,
  },
  {
    id: "parties",
    screen: (props) => (
      <SocketProvider>
        <Parties {...props} />
      </SocketProvider>
    ),
    path: ["/parties/:partyId", "/parties"],
    link: "/parties",
    displayName: "parties.title",
    icon: <CalendarMonth />,
  },
  {
    id: "media",
    screen: (props) => <Media {...props} />,
    path: "/media",
    link: "/media",
    displayName: "media.library",
    icon: <ImageOutlinedIcon />,
  },
  {
    id: "templates",
    screen: (props) => <Templates {...props} />,
    path: "/templates",
    link: "/templates",
    displayName: "templates.title",
    icon: <WidgetsIcon />,
  },
  {
    id: "profiles",
    screen: (props) => <Profile {...props} />,
    path: "/profiles/",
    link: "/profiles/",
    displayName: "profiles.title",
    icon: <PersonIcon />,
  },
  {
    id: "releaseNotes",
    screen: (props) => <ReleaseNotes {...props} />,
    path: "/releaseNotes",
    link: "/releaseNotes",
    displayName: "general.releaseNotes",
    icon: <NewReleasesIcon />,
  },
  {
    id: "faq",
    screen: (props) => <FAQ {...props} />,
    path: ["/faq/topic/:slug", "/faq"],
    link: "/faq",
    displayName: "faq.menuLabel",
    icon: <HelpIcon />,
  },
];

export const guestRoutes = [
  {
    id: "chats",
    screen: (props) => (
      <SocketProvider>
        <Chats {...props} />
      </SocketProvider>
    ),
    path: [
      "/chats/p/:partyId",
      "/chats/g/:partyId",
      "/chats/c/:partyId",
      "/chats",
    ],
    link: "/chats",
    displayName: "chats.title",
    icon: <ChatBubbleOutlineOutlinedIcon />,
  },
  {
    id: "profiles",
    screen: (props) => <Profile {...props} />,
    path: "/profiles",
    link: "/profiles",
    displayName: "profiles.titleGuest",
    icon: <PersonIcon />,
  },
  {
    id: "faq",
    screen: (props) => <FAQ {...props} />,
    path: ["/faq/topic/:slug", "/faq"],
    link: "/faq",
    displayName: "faq.menuLabel",
    icon: <HelpIcon />,
  },
];
