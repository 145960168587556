import React from "react";
import { makeStyles } from "@mui/styles";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, CssBaseline, Paper, Typography } from "@mui/material";
import { NavMenu, HeaderBar, PartyLoading } from "components";

const AcceptShare = (props) => {
  const { AcceptShare, AcceptSharePreflight, GetTemplates } = useActions();
  const { shareToken } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [info, setInfo] = React.useState();
  const [error, setError] = React.useState();

  const init = async () => {
    const { response: templatesResponse } = await GetTemplates();
    const existingTemplate = templatesResponse?.data?.find(
      (t) => t.shareToken === shareToken
    );
    if (existingTemplate) {
      history.push(`/templates/${existingTemplate.id}`);
    }
    let { response, error } = await AcceptSharePreflight(shareToken);
    if (error) setError(t("templates.invalidShareLink"));
    else setInfo(response.data);
    setLoading(false);
  };

  React.useEffect(() => {
    init();
    document.title = t("templates.addTemplate") + " - proWIN Messenger";
  }, []);

  const submit = async () => {
    let { error } = await AcceptShare(shareToken);
    if (error) {
      setError(t("general.tryAgainLater"));
      return;
    }
    await GetTemplates();
    history.push(`/templates`);
  };
  const cancel = () => {
    history.push(`/templates`);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="templates"
      />
      <div className={classes.screen}>
        <CssBaseline />
        <HeaderBar title={t("templates.title")} />
        {loading ? (
          <PartyLoading />
        ) : !info || error ? (
          <Paper className={classes.card}>
            <Typography variant="body1">
              {error || t("general.tryAgainLater")}
            </Typography>
          </Paper>
        ) : (
          <Paper className={classes.card}>
            <Typography variant="h1">
              {info.title}
              <span
                style={{
                  fontWeight: "normal",
                  opacity: 0.6,
                  fontSize: "0.8em",
                }}
              >
                &nbsp;{t("templates.sharedBy")} {info.name}
              </span>
            </Typography>
            <Typography variant="body1" style={{ color: "#333", marginTop: 5 }}>
              {t("templates.acceptTemplateShare")}
            </Typography>
            <div className={classes.buttonWrapper}>
              <Button color="inherit" onClick={cancel}>
                {t("general.cancel")}
              </Button>
              <Button variant="contained" color="primary" onClick={submit}>
                {t("templates.addTemplate")}
              </Button>
            </div>
          </Paper>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  card: {
    padding: theme.spacing(2),
    maxWidth: 650,
    marginLeft: 20,
  },
  buttonWrapper: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default AcceptShare;
