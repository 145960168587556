import React from "react";
import HeaderButton from "./HeaderButton";
import { makeStyles } from "@mui/styles";
import { Divider, IconButton, InputBase } from "@mui/material";
import { ClaimPrompt } from "components";
import { useActions } from "actions";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const HeaderBar = ({
  title,
  buttons,
  onBack,
  showBackButton,
  from,
  selectMode,
  dotMenuClicked,
  dotMenuClosed,
  searchPlaceholder,
  searchChange,
  search,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const guestId = useSelector((state) => state.session.id);

  const { GetGuest } = useActions();

  const handleHide = () => {
    setShowPrompt(false);
  };

  React.useEffect(() => {
    checkClaimedGuestAccount();
  }, []);

  const checkClaimedGuestAccount = async () => {
    const { response } = await GetGuest(guestId);
    if (guestId && !response?.data.accountStatus) {
      setShowPrompt(true);
    } else if (response?.data.accountStatus) {
      setShowPrompt(false);
    }
  };

  React.useEffect(() => {
    if (!search) {
      setSearchValue("");
    }
  }, [search]);

  const [searchValue, setSearchValue] = React.useState("");
  const onSearchChange = (val) => {
    setSearchValue(val);
    searchChange(val);
  };

  return (
    <div className={classes.headerWrapper}>
      {showPrompt && <ClaimPrompt hide={handleHide} />}
      <div className={classes.main}>
        <div className={classes.wrapperHeaderText}>
          <h1 className={classes.headerText}>{title}</h1>
        </div>
        <div style={{ flex: 1 }}>
          {!!searchChange && (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={searchPlaceholder}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchValue}
                onChange={(e) => onSearchChange(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className={classes.buttons}>
          {buttons?.map((button, index) => (
            <HeaderButton
              key={index.toString()}
              label={button.label}
              icon={button.icon}
              onClick={button.onClick}
            />
          ))}
          {from === "template" && (
            <div>
              {!selectMode && (
                <IconButton size="small" onClick={dotMenuClicked}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </div>
          )}
          {from === "template" && (
            <div>
              {selectMode && (
                <IconButton size="small" onClick={dotMenuClosed}>
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          )}
        </div>
      </div>
      {showBackButton && (
        <div onClick={onBack} className={classes.goBack}>
          <ArrowBackIosIcon style={{ color: "#bebebe", fontSize: 18 }} />
          <span style={{ paddingTop: 2 }}>{t("chats.backToList")}</span>
        </div>
      )}
      <Divider className={classes.divider} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },
  headerText: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#4F4E4E",
    fontWeight: "400",
    fontStyle: "normal",
    transform: "translateY(1px)",
  },
  divider: {
    color: "#BEBEBE",
  },
  main: {
    // padding: "5px 5px 0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconArrowBack: {
    display: "flex",
    alignItems: "center",
    marginTop: 18,
    color: "#BEBEBE",
  },
  wrapperHeaderText: {
    marginLeft: 25,
  },
  buttons: {
    marginLeft: 8,
    display: "flex",
    justifyContent: "flex-end",
  },
  goBack: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: -30,
    height: 30,
    marginLeft: 20,
    marginRight: 30,
    color: "#bebebe",
  },
  search: {
    position: "relative",
    borderRadius: 5,
    backgroundColor: "white",
    width: "auto",
    maxWidth: 300,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgb(160 160 160 / 40%) !important",
    marginLeft: 20,
  },
  searchIcon: {
    height: "100%",
    width: theme.spacing(5),
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    width: "100%",
  },
}));
export default HeaderBar;
