import React from "react";
import { Backdrop, Modal, Fade, Fab, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LeftIcon from "@mui/icons-material/ArrowBack";
import RightIcon from "@mui/icons-material/ArrowForward";
import { makeStyles } from "@mui/styles";
import { Navigation, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import videojs from "video.js";
import contribQualityLevels from "videojs-contrib-quality-levels";
import "video.js/dist/video-js.css";
import "videojs-hls-quality-selector";
import "swiper/swiper-bundle.min.css";
import { generateFullImageSource } from "src/utils/generateImageSet";
videojs.registerPlugin("qualityLevels", contribQualityLevels);

const ImageCarousel = ({
  open,
  onClose,
  media,
  currentIndex,
  party,
  chatType,
  setPhotoIndex,
}) => {
  const [size, setSize] = React.useState({
    width: window.innerWidth - 132,
    height: window.innerHeight - 90,
  });
  React.useEffect(() => {
    const handleResize = () =>
      setSize({
        width: window.innerWidth - 132,
        height: window.innerHeight - 90,
      });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [aspectRatio, setAspectRatio] = React.useState(16 / 9);
  const videoWidth = Math.max(
    aspectRatio > size.width / size.height
      ? size.width
      : size.height * aspectRatio,
    285
  );
  const videoHeight =
    aspectRatio > size.width / size.height
      ? size.width / aspectRatio
      : size.height;

  const classes = useStyles({ videoWidth, videoHeight });

  const getPhotoSource = (m) => {
    const folder =
      m.messageType !== "photo"
        ? m.mediaAttachmentOwner || m.sender || party.userId
        : chatType === "direct"
        ? party.chatId
        : party.id;
    const file = m.messageType !== "photo" ? m.mediaAttachment : m.message;
    if (m.mediaAttachmentType === "video")
      return `${process.env.REACT_APP_STATIC_URL}/${folder}/${file}`;
    return generateFullImageSource(
      folder,
      file,
      m.mediaAttachmentType === "application"
    );
  };

  const [videoReady, setVideoReady] = React.useState(false);
  const player = React.useRef();
  const disposePlayer = () => {
    setVideoReady(false);
    if (player.current) {
      try {
        player.current.dispose();
      } catch {}
      player.current = null;
    }
  };

  const videoPlayer = React.useRef();
  React.useEffect(() => {
    if (!open) {
      disposePlayer();
      return;
    }
    const m = media[currentIndex];
    if (m.mediaAttachmentType !== "video") {
      disposePlayer();
      return;
    }
    setTimeout(() => {
      if (!videoPlayer.current) return;
      let p = videojs(videoPlayer.current, {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: `${getPhotoSource(m)}.m3u8`,
            withCredentials: false,
          },
        ],
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          hls: {
            overrideNative: true,
            withCredentials: false,
          },
        },
      });
      p.hlsQualitySelector({
        displayCurrentQuality: false,
      });
      player.current = p;
      setTimeout(() => {
        setAspectRatio(
          player.current.videoWidth() / player.current.videoHeight()
        );
        setVideoReady(true);
      }, 200);
    }, 100);
  }, [open, currentIndex]);

  const handleClose = () => {
    onClose();
  };

  const prevElRef = React.useRef();
  const nextElRef = React.useRef();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        className: classes.backdrop,
      }}
    >
      <Fade in={open}>
        <div className={classes.screen}>
          <div className={classes.header}>
            <span className={classes.title}>
              {currentIndex + 1}/{media.length}
            </span>
            <IconButton
              style={{ color: "rgba(255,255,255,0.8)" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.content}>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              onSlideChange={(swiper) => setPhotoIndex(swiper.activeIndex)}
              onSwiper={(swiper) => setPhotoIndex(swiper.activeIndex)}
              style={{ height: "100%" }}
              modules={[Navigation, Lazy]}
              navigation={{
                prevEl: prevElRef,
                nextEl: nextElRef,
              }}
              lazy={true}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = prevElRef.current;
                swiper.params.navigation.nextEl = nextElRef.current;
              }}
              initialSlide={currentIndex}
            >
              {media.map((m, index) => {
                const mediaType =
                  m.messageType === "photo" ? "image" : m.mediaAttachmentType;
                return (
                  <SwiperSlide className={classes.slide} key={index.toString()}>
                    {mediaType === "image" && (
                      <>
                        <img
                          data-src={getPhotoSource(m)}
                          className={`${classes.photo} swiper-lazy`}
                        />
                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                      </>
                    )}
                    {mediaType === "application" && currentIndex === index && (
                      <object
                        className={classes.pdfViewer}
                        type="application/pdf"
                        data={getPhotoSource(m)}
                      >
                        <a
                          href={getPhotoSource(m)}
                          target="_blank"
                          style={{
                            cursor: "pointer",
                            border: 0,
                          }}
                        >
                          <img
                            src={`${getPhotoSource(m)}.jpg`}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </a>
                      </object>
                    )}
                    {mediaType === "video" && currentIndex === index && (
                      <div className={classes.videoPlayer}>
                        <div
                          data-vjs-player
                          style={{
                            paddingTop: videoHeight,
                            backgroundColor: "transparent",
                            opacity: !videoReady ? 0 : 1,
                            transition: "opacity .2s ease",
                          }}
                        >
                          <video ref={videoPlayer} className="video-js"></video>
                        </div>
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
              <IconButton
                ref={prevElRef}
                className={classes.leftButton}
                disabled={currentIndex === 0}
              >
                <LeftIcon />
              </IconButton>
              <IconButton
                ref={nextElRef}
                className={classes.rightButton}
                disabled={currentIndex === media.length - 1}
              >
                <RightIcon />
              </IconButton>
            </Swiper>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  header: {
    minHeight: 50,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 8,
    paddingRight: 8,
    zIndex: 2,
  },
  title: {
    color: "rgba(255,255,255,0.8)",
    paddingLeft: 8,
    paddingTop: 2,
  },
  content: {
    flex: 1,
    overflow: "hidden",
  },
  leftButton: {
    position: "absolute",
    top: "calc(50% - 40px)",
    left: 0,
    zIndex: 2,
    color: "rgba(255,255,255,0.9)",
    borderRadius: 0,
    padding: "32px 16px 32px 16px",
  },
  rightButton: {
    position: "absolute",
    top: "calc(50% - 40px)",
    right: 0,
    zIndex: 2,
    color: "rgba(255,255,255,0.9)",
    borderRadius: 0,
    padding: "32px 16px 32px 16px",
  },
  slide: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  photo: {
    maxWidth: "calc(100% - 132px)",
    maxHeight: "calc(100% - 40px)",
  },
  pdfViewer: {
    height: "100%",
    width: 800,
    maxWidth: "calc(100% - 112px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoPlayer: {
    width: (props) => props.videoWidth,
    height: (props) => props.videoHeight,
  },
}));

export default ImageCarousel;
