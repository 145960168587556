import React from "react";
import { makeStyles } from "@mui/styles";
import { LoginComponent, LoginLeft } from "components";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const LoginPage = (props) => {
  const { target } = props;
  const classes = useStyles();
  const session = useSelector((state) => state.session);

  if (session.accessToken || session.secretKey) {
    return <Redirect to={target === "/login" ? "/" : target || "/"} />;
  }

  return (
    <div className={classes.wrapper}>
      <LoginLeft />
      <div className={classes.loginComponent}>
        <LoginComponent target={target} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    height: "100vh",
    backgroundColor: "#F3F9FC",
    flex: 1,
  },
  loginComponent: {
    flex: 1,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default LoginPage;
