const removeFormatting = (string) => string?.replace(/[_*~]/g, "") || "";

export const lastMessage = (item, session, t) => {
  return lastMessageWithSender(item, session, t);
};

export const lastMessageWithSender = (item, session, t, senderName) => {
  if (!item) return;
  let subtitle = removeFormatting(item.latestMessage?.message);
  let description = removeFormatting(item.latestMessage?.description);
  let isMe;
  const userId = session.userId || session.id;
  if (!subtitle && !description && !item.latestMessage?.mediaAttachment) {
    isMe = true;
    subtitle = `${t("chats.noMessages")}`;
  } else {
    if (subtitle === "59953f60-7f65-4119-9374-34ab02e43a9d") {
      subtitle = t("party.newGuest", senderName);
      isMe = true;
    }
    if (subtitle === "8b3c857c-b40f-471b-bc66-a134f5924a83")
      subtitle = t("party.deletedPlaceholder");
    if (
      item.latestMessage?.messageType === "photo" ||
      item.latestMessage?.mediaAttachmentType === "image"
    )
      subtitle = "📷";
    if (item.latestMessage?.mediaAttachmentType === "video") subtitle = "🎞";
    if (
      item.latestMessage?.messageType === "voice" ||
      item.latestMessage?.mediaAttachmentType === "audio" ||
      item.latestMessage?.mediaAttachmentType === "voice"
    )
      subtitle = "🎙";
    if (item.latestMessage?.mediaAttachmentType === "application")
      subtitle = "📄";
    if (item.latestMessage?.messageType === "profile") subtitle = "👤";
    if (item.latestMessage?.sender === userId) {
      subtitle = `${t("party.me")}: ${subtitle}`;
      isMe = true;
    }
  }
  if (isMe === true || !senderName) return subtitle;
  return `${senderName}: ${subtitle}`;
};
