import React from "react";
import { Snackbar, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import { useTranslation } from "react-i18next";

const NewMessageButton = ({ show, handleClick, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Snackbar
      className={classes.newMessageButton}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={show}
      onClose={handleClose}
    >
      <Button
        color="primary"
        variant="contained"
        endIcon={<ArrowDownIcon />}
        onClick={() => handleClick()}
        className={classes.messageIndicator}
      >
        {t("party.newMessages")}
      </Button>
    </Snackbar>
  );
};

const useStyles = makeStyles((theme) => ({
  messageIndicator: {
    borderRadius: theme.spacing(3),
    fontWeight: "bold",
    textTransform: "none",
    background: "#1E92CD",
    fontSize: 15,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  newMessageButton: {
    marginLeft: 200,
    marginBottom: 130,
  },
}));

export default NewMessageButton;
