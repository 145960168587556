import React from "react";
import { InputBase, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import PhotoIcon from "@mui/icons-material/Photo";
import HeaderIcon from "@mui/icons-material/Toc";
import MediaIcon from "@mui/icons-material/VideoLibrary";
import MicIcon from "@mui/icons-material/Mic";
import CloseIcon from "@mui/icons-material/Close";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import ProfileIcon from "@mui/icons-material/AssignmentInd";
import "react-image-lightbox/style.css";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { useTranslation } from "react-i18next";
import { checkText } from "smile2emoji";

const ChatBar = (
  {
    isManager,
    sending,
    expired,
    editing,
    autoFocus,
    showEmojiKeyboard,
    showMediaDialog,
    showHeadingDialog,
    selectFiles,
    showVoiceDialog,
    showProfileDialog,
    showApplyTemplateDialog,
    onEnter,
    onSendText,
    onFinishEditing,
    onCancelEditing,
    chatType,
    restricted,
  },
  ref
) => {
  const classes = useStyles({ isManager });
  const { t } = useTranslation();

  const [message, setMessage] = React.useState("");
  const handleMessageChange = (e) => {
    setMessage(checkText(e.target.value));
  };

  const handleSpeedDial = (action) => {
    action();
  };

  const inputRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    setMessage: (message) => {
      setMessage(message);
    },
    focus: () => inputRef?.current?.focus?.(),
    setSelectionRange: (...args) =>
      inputRef?.current?.setSelectionRange?.(...args),
  }));

  const readOnly = expired || restricted;

  return (
    <>
      <div className={classes.chatBar}>
        <div style={{ display: "flex", width: "100%" }}>
          <InputBase
            id="chatInput"
            inputRef={inputRef}
            variant="filled"
            classes={{
              root: classes.chatField,
              input: classes.chatFieldInput,
            }}
            multiline
            fullWidth
            value={readOnly ? "" : message}
            onChange={handleMessageChange}
            disabled={sending}
            placeholder={
              expired
                ? t("party.expired")
                : restricted
                ? t("party.partyRestricted")
                : t("party.writeMessage")
            }
            onKeyPress={(e) => onEnter(e, message)}
            autoFocus={autoFocus}
            readOnly={readOnly}
          />
          {!editing && (
            <IconButton
              onClick={() => onSendText(message)}
              disabled={!message || sending || readOnly}
              className={classes.sendButton}
            >
              <SendIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.actionsWrapper}>
          <IconButton
            disabled={sending || readOnly}
            onClick={showEmojiKeyboard}
            className={classes.emojiButton}
            size="small"
          >
            <EmojiEmotionsIcon />
          </IconButton>
          {!editing && (
            <IconButton
              disabled={sending || readOnly}
              onClick={showVoiceDialog}
              className={classes.sendButton}
            >
              <MicIcon />
            </IconButton>
          )}
          {editing && (
            <IconButton
              onClick={onCancelEditing}
              disabled={sending || readOnly}
              className={classes.sendButton}
            >
              <CloseIcon />
            </IconButton>
          )}
          {editing && (
            <Tooltip title={t("party.sendAudio")}>
              <IconButton
                onClick={() => onFinishEditing(message)}
                disabled={!message || sending || readOnly}
                className={classes.sendButton}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          )}
          {!editing && (
            <Tooltip title={t("party.sendPhotoTitle")}>
              <IconButton
                disabled={sending || readOnly}
                onClick={() => handleSpeedDial(selectFiles)}
              >
                <PhotoIcon />
              </IconButton>
            </Tooltip>
          )}
          {isManager &&
            !editing &&
            (chatType === "party" || chatType === "template") && (
              <Tooltip title={t("party.sendHeadingTitle")}>
                <IconButton
                  disabled={sending || readOnly}
                  onClick={() => handleSpeedDial(showHeadingDialog)}
                >
                  <HeaderIcon />
                </IconButton>
              </Tooltip>
            )}
          {isManager &&
            !editing &&
            (chatType === "party" || chatType === "template") && (
              <Tooltip title={t("party.templateImportTitle")}>
                <IconButton
                  disabled={sending || readOnly}
                  onClick={() => handleSpeedDial(showApplyTemplateDialog)}
                >
                  <ViewCompactIcon />
                </IconButton>
              </Tooltip>
            )}
          {isManager && !editing && (
            <Tooltip title={t("party.sendMediaTitle")}>
              <IconButton
                disabled={sending || readOnly}
                onClick={() => handleSpeedDial(showMediaDialog)}
              >
                <MediaIcon />
              </IconButton>
            </Tooltip>
          )}
          {isManager && !editing && chatType === "party" && (
            <Tooltip title={t("party.sendProfileTitle")}>
              <IconButton
                disabled={sending || readOnly}
                onClick={() => handleSpeedDial(showProfileDialog)}
              >
                <ProfileIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  chatBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "10px",
    height: "auto",
    borderRadius: 12,
    backgroundColor: "#fff",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    boxShadow: "0px 0.5px 1px rgba(160,160,160, 0.4)",
  },
  chatField: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    borderBottom: "1px solid #D8D8D8",
  },
  chatFieldInput: {
    lineHeight: "1.5em",
    fontSize: "1em",
  },
  actionsWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

export default React.forwardRef(ChatBar);
