import React from "react";
import { StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import reduxStore from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import Router from "./router";
import ReactNotifications from "react-notifications-component";
import { SWRConfig } from "swr";
import { fetcher } from "api";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useTranslation } from "react-i18next";

const { store, persistor } = reduxStore();

const theme = createTheme({
  palette: {
    primary: {
      main: "#1E92CD",
      contrastText: "#fff",
    },
    secondary: {
      main: "#4DB85C",
      contrastText: "#fff",
    },
    lightBlue: {
      main: "#F3F9FC",
      contrastText: "#333E48",
    },
    darkGrey: {
      main: "#333E48",
      contrastText: "#FFF",
    },
    grey: {
      main: "#919191",
      contrastText: "#FFF",
    },
    error: {
      main: "#cc0000",
      contrastText: "#FFF",
    },
    white: {
      main: "#ffffff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    h1: {
      fontFamily: "DIN NEXT LT Pro, sans-serif",
      fontWeight: "500",
      fontSize: "1.5em",
    },
    h4: {
      fontFamily: "DIN NEXT LT Pro, sans-serif",
    },
    h5: {
      fontFamily: "DIN NEXT LT Pro, sans-serif",
      fontWeight: "500",
    },
    h6: {
      fontFamily: "DIN NEXT LT Pro, sans-serif",
      fontWeight: "500",
    },
    body1: {
      fontFamily: "DIN NEXT LT Pro, sans-serif",
    },
    body2: {
      fontFamily: "DIN NEXT LT Pro, sans-serif",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: "DIN NEXT LT Pro, sans-serif",
      },
    },
    MuiButton: {
      text: {
        fontFamily: "DIN NEXT LT Pro, sans-serif",
        fontWeight: "500",
        fontSize: "1.2em",
      },
      contained: {
        fontFamily: "DIN NEXT LT Pro, sans-serif",
        fontWeight: "500",
        fontSize: "1.2em",
      },
      containedSizeSmall: {
        fontSize: "1.1em",
      },
    },
    MuiTab: {
      textColorPrimary: {
        fontFamily: "DIN NEXT LT Pro, sans-serif",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  scrollbars: {
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(0,0,0,0.2) rgba(255,255,255,0.1)",
    "&::-webkit-scrollbar": {
      width: "6px !important",
      height: "6px !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgba(255,255,255,0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,0.2)",
    },
  },
});

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

function App() {
  const { i18n } = useTranslation();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <StyledEngineProvider injectFirst> */}
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                locale={i18n.language}
              >
                <SWRConfig value={{ fetcher }}>
                  <ReactNotifications />
                  <Router />
                </SWRConfig>
              </LocalizationProvider>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
        {/* </StyledEngineProvider> */}
      </PersistGate>
    </Provider>
  );
}

export default App;
