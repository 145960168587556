import React from "react";
import { Grid, Button } from "@mui/material";
import { LanguageButton } from "src/components";
import logo from "assets/logo-prowin-messenger.svg";

const LandingPageHeader = (props) => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={5} md={3}>
        <img src={logo} alt="Prowin Messenger" />
      </Grid>
      <Grid item xs={6} md={2}>
        <div>
          <LanguageButton lang={props.lang} />
          <Button href="/login" variant="contained" color="primary">
            Login
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default LandingPageHeader;
