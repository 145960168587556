import React from "react";
import { makeStyles } from "@mui/styles";
import WriteTemplate from "./WriteTemplate";
import TemplateInfo from "./TemplateInfo";

const TemplateCreate = () => {
  const classes = useStyles();
  return (
    <div className={classes.templateCreateWrapper}>
      <TemplateInfo />
      <WriteTemplate />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  templateCreateWrapper: {
    maxWidth: "100%",
    flex: 1,
    margin: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export default TemplateCreate;
