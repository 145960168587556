import React from "react";
import { Button, InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import { Redirect } from "react-router-dom";
import { guestRoutes } from "./../../router/allRoutes";
import { useHistory } from "react-router-dom";
import LoginLeft from "../loginLeft";

const CodeVerification = (props) => {
  const { guestEmail, from } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { VerifyGuestLogin, VerifyAccountClaim } = useActions();

  const history = useHistory();

  const handleSubmitVerification = async (e) => {
    e.preventDefault();
    if (from === "login") {
      const { response, error } = await VerifyGuestLogin(
        guestEmail,
        verificationCode
      );
      if (error) {
        handleError(error);
      }
      if (!error) {
        setTimeout(() => history.push("/"), 500);
      }
    } else if (from === "claim") {
      const { response, error } = await VerifyAccountClaim(verificationCode);
      if (error) {
        handleError(error);
      }
      if (!error) {
        setRedirect(true);
      }
    }
  };

  const handleError = (error) => {
    const errorArray = Object.keys(error).map((key) => [
      Number(key),
      error[key],
    ]);
    const errorMessage = (errorArray[1][1]?.response)
      .split(":")[1]
      .replace("}", "")
      .replace(/"/g, "");
    setErrorMessage(errorMessage);
  };

  return (
    <div className={classes.loginComponent}>
      <p className={classes.title}>{t("login.insertCode")}</p>

      <div className={classes.inputSendCode}>
        <InputBase
          placeholder={t("login.verificationCode")}
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          style={{
            fontSize: "13px",
            color: "#000",
            width: 350,
          }}
        />
      </div>
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      <div className={classes.buttonSendCode}>
        <Button
          style={{
            textTransform: "none",
          }}
          onClick={() => history.goBack()}
        >
          {t("general.back")}
        </Button>
        <Button
          style={{
            textTransform: "none",
            fontFamily: "DIN NEXT LT Pro",
            background: "rgba(30, 146, 205, 100)",
            color: "#FFF",
            boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
            padding: "5px 20px",
          }}
          onClick={handleSubmitVerification}
        >
          {t("login.verify")}
        </Button>
        {redirect && <Redirect to={guestRoutes[0].path} />}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    fontSize: 15,
    color: theme.palette.error.main,
    margin: "10px 0 0 25px",
    width: "100%",
    maxWidth: 350,
  },
  wrapper: {
    display: "flex",
    flex: 1,
    height: "100vh",
    backgroundColor: "#F3F9FC",
    overflow: "hidden",
  },
  loginComponent: {
    flex: 1,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  inputSendCode: {
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    height: 30,
    margin: "10px 20px",
    padding: 5,
  },
  buttonSendCode: {
    marginTop: 10,
    width: "100%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    color: "#333E48",
    margin: 0,
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: 350,
  },
}));

export default CodeVerification;
