/* eslint-disable no-useless-computed-key */
/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { isIOS, isAndroid } from "react-device-detect";
import {
  IconButton,
  Card,
  Button,
  useMediaQuery,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  Grid,
  Alert,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/CloudUpload";
import SendingIcon from "@mui/icons-material/Schedule";
import FailedIcon from "@mui/icons-material/ErrorOutline";
import {
  NameDialog,
  HeadingDialog,
  VoiceDialog,
  PhotoDialog,
  MediaDialog,
  SendProfileDialog,
  HostDialog,
  PartyLoading,
  PartyExpired,
  PartyNotFound,
  PartyRemoved,
  RemoveDialog,
  MoveToDialog,
  MoveToTemplateDialog,
  MoveToChatDialog,
  RemoveMessageDialog,
  ForwardMessagesDialog,
  ApplyTemplateDialog,
  OverwriteTemplateDialog,
  ReadReceiptDialog,
  ChatBar,
  UploadProgress,
  NewMessageButton,
  ImageCarousel,
  TemplateMessages,
  MediaPreview,
  MobilePopUp,
  preventSimultaneousPlayback,
  ChatListInfo,
  PartyDialog,
  DeleteDialog,
  ForbiddenToJoin,
  ShareLinkDialog,
} from "components";
import { insertFormatting } from "src/utils/insertFormatting";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import clsx from "clsx";
import moment from "moment";
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";
import Linkify from "react-linkify";
import LinkifyIt from "linkify-it";
import tlds from "tlds";
import { ReactTinyLink } from "react-tiny-link";
import { subscribeUser } from "./notifications";
import EmojiPicker from "emoji-picker-react";
import "draft-js/dist/Draft.css";
import { usePageVisibility } from "react-page-visibility";
import Ellipsis from "react-lines-ellipsis";
import MediaInfo from "mediainfo.js";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import Snackbar from "@mui/material/Snackbar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Checkbox from "@mui/material/Checkbox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  useEmit,
  useGuestRemoved,
  useGuestUpdate,
  useIAmOnline,
  useNewMessage,
  usePartyDeleted,
  useReloadMessages,
  useSocket,
  useUpdatedMessage,
  useUpdatedSeen,
  useUpdateRestriction,
  useUserJoined,
  useUserLeft,
} from "./../../context/socket";
import { useHandleTimestamp } from "src/utils/handleTimestamp.util";
import useForwardMessages from "./useForwardMessage";
import { useHistory } from "react-router-dom";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const linkify = new LinkifyIt();
linkify.tlds(tlds);

const PartyChatView = (props) => {
  const { t, i18n } = useTranslation();
  const {
    showChatInfo,
    setShowChatInfo,
    headerBackButton,
    refreshList,
    unreadMessages,
    startDirectChat,
  } = props;
  const classes = useStyles();
  const isVisible = usePageVisibility();
  const favicon = document.getElementById("favicon");

  const { partyId } = useParams();

  const handleTimestamp = useHandleTimestamp();

  const {
    GetMedia,
    AddMedia,
    AddTemplateMessage,
    ConvertMedia,
    PartyInfo,
    JoinParty,
    CopyToFolder,
    DuplicateFile,
    PartyGuests,
    LatestChat,
    ChangeName,
    UpdateProfile,
    Subscribe,
    HandleMessage,
    UpdateSeen,
    RemoveGuest,
    SendMessage,
    SendMessageAgain,
    PartyHost,
    HandleUpdatedMessage,
    HandleUpdatedSeen,
    EditMessage,
    RemoveMessage,
    RemoveMessages,
    GetTemplates,
    GetAppliedTemplate,
    GetProfile,
    DeleteTemplateMessage,
    ChatTimestamp,
    RemoveParty,
    UpdateParty,
    SendGroupMessage,
    SendDirectMessage,
    HandlePartyRestricted,
    GetChatList,
  } = useActions();

  //Selectors

  const session = useSelector((state) => state.session);

  const profile = useSelector(
    (state) => state.joinedParties[partyId]?.profile || {}
  );
  const media = useSelector((state) => state.media || []);
  const templates = useSelector((state) => state.templates);

  const allChatTypes = useSelector((state) => state.allChatTypes);

  const me = useSelector((state) => state.me[partyId] || {});

  const userId = useSelector((state) =>
    state.session.secretKey ? state.session.id : state.session.userId
  );

  const userType = useSelector((state) =>
    state.session.secretKey ? "guest" : "user"
  );

  const party = useSelector(
    (state) => state.joinedParties[partyId]?.info || {}
  );

  const sortedMessages = useSelector(
    (state) => state.joinedParties[partyId]?.messages || []
  );

  const guests = useSelector(
    (state) => state.joinedParties[partyId]?.guests || []
  );

  const admin = me.guestId === party.userId ? true : false;

  const appliedTemplate = useSelector(
    (state) => state.appliedTemplates?.[partyId] || {}
  );

  const templateMessages = useSelector((state) => {
    const appliedTemplate = state.appliedTemplates?.[partyId] || {};
    if (!appliedTemplate) return [];
    const messages = state.templateMessages[appliedTemplate.templateId] || [];
    if (!messages) return [];
    return (
      messages.filter(
        (m) => !appliedTemplate.hiddenMessages.find((id) => id === m.id)
      ) || []
    );
  });

  const headings = useSelector(
    (state) =>
      state.joinedParties[partyId]?.messages?.filter((m) =>
        m?.messageType.startsWith("heading")
      ) || []
  );

  const mediaMessages = useSelector(
    (state) =>
      state.joinedParties[partyId]?.messages?.filter(
        (m) =>
          m?.messageType === "photo" ||
          (m.mediaAttachmentType?.trim() && m.mediaAttachmentType !== "audio")
      ) || []
  );

  const socketEmit = useEmit();

  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(!party?.title);
  React.useEffect(async () => {
    if (!loading) return;
    const { response } = await GetChatList();
    const thisChat = response?.data?.find((c) => c.shortId === partyId);
    if (!thisChat)
      history.push(
        location.pathname.startsWith("/parties") ? "/parties" : "/chats"
      );
    setLoading(false);
  }, [loading, partyId]);

  //Chat info
  const handleOpenChatInfo = () => {
    setShowChatInfo(true);
  };
  const handleCloseChatInfo = () => {
    setShowChatInfo(false);
  };

  preventSimultaneousPlayback(); //For Audio files

  const [appDeclined, setAppDeclined] = React.useState(false);

  const [showEmojis, setShowEmojis] = React.useState(false);
  const onEmojiClick = (event, { emoji }) => {
    let {
      selectionStart,
      selectionEnd,
      value: oldMessage,
    } = document.getElementById("chatInput");
    var newMessage =
      oldMessage.slice(0, selectionStart) +
      emoji +
      oldMessage.slice(selectionEnd);
    inputRef.current.setMessage(newMessage);
    setTimeout(() => {
      inputRef.current.setSelectionRange(
        selectionStart + emoji.length,
        selectionStart + emoji.length
      );
    }, 100);
  };
  const { acceptedFiles, getRootProps, getInputProps, isDragReject } =
    useDropzone({
      noDragEventsBubbling: true,
      accept:
        me.guestId === party.userId
          ? ["image/*", "video/*", "audio/*", "application/pdf"]
          : ["image/*"],
      maxFiles: 10,
    });

  const inputRef = React.useRef();
  /* load party on start */
  const [partyState, setPartyState] = React.useState("loading");
  const [messagesLoading, setMessagesLoading] = React.useState(true);
  const [seen, setSeen] = React.useState("");

  const loadParty = async () => {
    setMessagesLoading(true);
    let { response, error } = await PartyInfo(partyId);
    if (error?.response?.data?.error === "This party is over.") {
      setMessagesLoading(false);
      setPartyState("expired");
      return;
    }
    if (!response || !response.data || !partyId) {
      setPartyState("error");
      setMessagesLoading(false);
      return;
    }
    setExpired(moment(response?.data?.expiration).isBefore(moment()));
    setSeen(response?.data?.seen);
    ChatTimestamp(partyId);
    await initParty(response?.data);
    setMessagesLoading(false);
    setPartyState("loaded");
    handleMessageIndicator();
  };

  const updatePartyChat = async () => {
    const lastMessageTimestamp = _.last(sortedMessages)?.timestamp;
    await LatestChat(null, partyId, lastMessageTimestamp);
  };

  React.useEffect(() => {
    loadParty();
    setShowEmojis(false);
  }, [partyId]);

  /* load manager profile */
  React.useEffect(() => {
    if (party.manager) GetProfile(partyId, party.manager);
  }, [party.manager]);

  const shareLinkDialog = React.useRef();
  const handleShareLink = () => {
    handleGlobalChatMenuClose();
    const inviteLink = `${process.env.REACT_APP_SITE_URL}/joinParty/${partyId}`;
    shareLinkDialog.current.open(inviteLink);
  };

  // Edit party
  const editPartyDialog = React.useRef();

  const handleEdit = () => {
    setGlobalChatMenuOpen(false);
    editPartyDialog.current.open(
      party.title,
      party.expiration,
      party.start,
      party.host,
      party.manager,
      party.password,
      party.restrictedMessaging,
      !party.partyPic
        ? undefined
        : `${process.env.REACT_APP_STATIC_URL}/${party.id}/${party.partyPic}`
    );
  };

  const submitEditPartyDialog = async (
    title,
    start,
    expiration,
    host,
    manager,
    password,
    restrictedMessaging,
    selectedFile,
    deleteProfilePic
  ) => {
    await UpdateParty(
      partyId,
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging,
      selectedFile,
      deleteProfilePic
    );

    socketEmit("updateRestriction", {
      partyId,
      restrictedMessaging,
    });
    // refreshList();
    loadParty();
  };

  // Delete party
  const deleteDialog = React.useRef();
  // const [deleteItem, setDeleteItem] = React.useState();
  const handleRemove = () => {
    setGlobalChatMenuOpen(false);
    deleteDialog.current.open();
  };
  const submitRemove = async () => {
    history.push(
      location.pathname.startsWith("/parties") ? "/parties" : "/chats"
    );
    await RemoveParty(partyId);
    socketEmit("deleteParty", {
      partyId,
    });
    refreshList();
  };

  /* new message indicator */
  const messageList = React.useRef();
  let dividerRef = React.useRef(null);
  const [showMessageIndicator, setShowMessageIndicator] = React.useState(false);
  const handleMessageIndicator = (message = null) => {
    if (!me.guestId) return;
    if (!messageList.current) return;
    if (dividerRef.current) dividerRef.current.scrollIntoView();
    else {
      messageList.current.scrollTo({
        top: messageList.current.scrollHeight,
        behaviour: "smooth",
      });
      setShowMessageIndicator(false);
    }

    if (message) updateSeen(message);
    else if (templateMessages.length > 0) updateSeen(_.last(templateMessages));
  };

  const updateSeen = async (message) => {
    if (!me.guestId) {
      setTimeout(updateSeen, 2000, message); // try again in 2 seconds
      return;
    }
    if (me.guestId === party.userId) return; // Don't update for messages sent by the manager
    const timestamp = message?.timestamp || message?.messageInfo?.timestamp;
    if (timestamp) await UpdateSeen(partyId, me.guestId, timestamp);
    socketEmit("updateSeen", {
      partyId,
      guestId: me.guestId,
      timestamp: timestamp,
    });
  };

  const handleScroll = () => {
    if (
      messageList.current.scrollTop >=
      messageList.current.scrollHeight - messageList.current.clientHeight
    ) {
      if (templateMessages.length > 0) updateSeen(_.last(templateMessages));
    }
  };

  /* bulk menu */
  const [globalAnchorEl, setGlobalAnchorEl] = React.useState();
  const [globalChatMenuOpen, setGlobalChatMenuOpen] = React.useState(false);
  const [bulkDelete, setBulkDelete] = React.useState(false);
  const [bulkForward, setBulkForward] = React.useState(false);
  const [bulkForwardTemplate, setBulkForwardTemplate] = React.useState(false);
  const [selectMode, setSelectMode] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [mediaSelected, setMediaSelected] = React.useState(false);
  const [forwardStatus, setForwardStatus] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  /* message context menu */
  const [anchorEl, setAnchorEl] = React.useState();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedMessage, setSelectedMessage] = React.useState();
  const [selectedReply, setSelectedReply] = React.useState();
  const [sendingMedia, setSendingMedia] = React.useState();
  const [editing, setEditing] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState();
  const [expired, setExpired] = React.useState(true);
  const [hovered, setHovered] = React.useState(false);

  /* selecting files from hard drive */
  React.useEffect(() => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept =
      session?.userId === party.userId
        ? ["image/*", "video/*", "audio/*", "application/pdf"]
        : ["image/*"];
    input.onchange = ({ target }) => {
      const { files } = target;
      let filesResult = Object.keys(files).map(
        (key, index) => files[(key, index)]
      );
      setSelectedFiles(filesResult);
    };
    input.id = "fileSelector";
    input.style.display = "none";
    document.body.append(input);
    return () => {
      document.body.removeChild(input);
    };
  }, []);

  const selectFiles = () => {
    setShowEmojis(false);
    document.getElementById("fileSelector").click();
  };
  const cancelPhotoPreview = () => {
    setSelectedFiles();
    document.getElementById("fileSelector").value = "";
  };

  React.useEffect(() => {
    setHovered(false);
    if (acceptedFiles.length > 0) setSelectedFiles(acceptedFiles);
  }, [acceptedFiles]);

  React.useEffect(() => {
    if (selectedFiles) {
      showPhotoDialog();
    }
  }, [selectedFiles]);

  const handleMenuOpen = (event, message) => {
    if (!message?.id) return;
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setSelectedMessage(message);
  };
  const handleMenuClose = () => {
    setAnchorEl();
    setMenuOpen(false);
    setSelectedMessage();
  };
  const startEditMessage = () => {
    if (!selectedMessage) return;
    setEditing(true);
    setMenuOpen(false);
    setTimeout(() => {
      inputRef.current.focus();
      if (
        selectedMessage?.messageType === "photo" ||
        selectedMessage?.mediaAttachmentType === "application" ||
        selectedMessage?.mediaAttachmentType === "video" ||
        selectedMessage?.mediaAttachmentType === "image"
      )
        inputRef.current.setMessage(selectedMessage?.description);
      else inputRef.current.setMessage(selectedMessage?.message);
    }, 100);
  };
  const cancelEditing = () => {
    if (sending || !me.guestId) return;
    setEditing(false);
    inputRef.current.setMessage("");
    handleMenuClose();
    setShowEmojis(false);
  };
  const finishEditing = async (message) => {
    if (sending || !me.guestId || !selectedMessage?.id) return;
    setSending(true);
    let { response, error } = await EditMessage(
      session?.userId === party?.userId ? null : me.guestId,
      partyId,
      selectedMessage.id,
      message
    );
    if (error) {
      setSending(false);
      return;
    }
    socketEmit("updateMessage", { partyId, message: response.data });
    setEditing(false);
    inputRef.current.setMessage("");
    setSending(false);
    handleMenuClose();
    setShowEmojis(false);
    inputRef.current.focus();
  };

  const startReplyMessage = () => {
    if (!selectedMessage) return;
    setSelectedReply(selectedMessage);
    setMenuOpen(false);
  };
  const cancelReply = () => {
    if (sending || !me.guestId) return;
    setSelectedReply();
  };
  const cancelMediaSending = () => {
    if (sending || !me.guestId) return;
    setSendingMedia();
  };

  /* bulk select operations*/

  const handleGlobalChatMenuOpen = (event) => {
    setShowEmojis(false);
    setGlobalAnchorEl(event.currentTarget);
    setGlobalChatMenuOpen(true);
  };
  const handleGlobalChatMenuClose = () => {
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleForwardMessage = () => {
    setBulkForward(true);
    setSelectMode(true);
    setSelectedIds([selectedMessage?.id]);
    setSelectedMessages([selectedMessage]);
    handleMenuClose();
  };

  const handleBulkDelete = () => {
    setBulkDelete(true);
    setSelectMode(true);
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleBulkForward = () => {
    setBulkForward(true);
    setSelectMode(true);
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleCancelBulk = () => {
    setBulkDelete(false);
    setBulkForward(false);
    setSelectMode(false);
    setSelectedIds([]);
    setSelectedMessages([]);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const [selectedMessages, setSelectedMessages] = React.useState([]);

  const onMessageSelect = (item, e) => {
    if (selectedIds.includes(item.id))
      setSelectedIds((selectedIds) =>
        selectedIds.filter((id) => id !== item.id)
      );
    else setSelectedIds((selectedIds) => [...selectedIds, item.id]);

    if (selectedMessages.includes(item)) {
      setSelectedMessages((selectedMessages) =>
        selectedMessages.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      setSelectedMessages((selectedMessages) => [...selectedMessages, item]);
    }
  };

  const handleForwardToTemplate = () => {
    const myMessages = sortedMessages.filter(
      (m) => m.sender === session.userId && m.messageType !== "profile"
    );
    setBulkForward(true);
    setBulkForwardTemplate(true);
    showCopyToTemplateDialog(myMessages);
    setGlobalChatMenuOpen(false);
  };

  /* forward messages */
  const forwardMessagesDialog = React.useRef();
  const showForwardMessagesDialog = () => {
    let nonMediaMessages = [];
    for (const message of selectedMessages) {
      if (
        !message.mediaAttachmentType &&
        message.messageType !== "photo" &&
        message.messageType !== "video" &&
        message.messageType !== "audio"
      ) {
        nonMediaMessages.push(message);
      }
    }
    if (nonMediaMessages.length > 0) {
      setMediaSelected(false);
    } else {
      setMediaSelected(true);
    }
    setMenuOpen(false);
    forwardMessagesDialog.current.open();
  };

  const submitMoveToLibrary = async () => {
    showCopyToDialog(selectedMessages);
  };
  const submitMoveToTemplate = async () => {
    showCopyToTemplateDialog(selectedMessages);
  };
  const submitMoveToChat = async () => {
    showCopyToChatDialog(selectedMessages);
  };

  // Dialog to select a CHAT where a message should be copied
  // TODO: move into a cutstom hook?
  const copyToChatDialog = React.useRef();

  const showCopyToChatDialog = (mediaItems) => {
    let allChats = [];
    for (const item of mediaItems) {
      if (item.messageType.includes("heading")) {
        allChats = allChatTypes.filter((m) => m.type === "party");
      } else {
        allChats = allChatTypes;
      }
    }
    copyToChatDialog.current.open(allChats, mediaItems);
  };

  const forwardMessages = useForwardMessages();

  const submitCopyToChatDialog = async (mediaItems, selectedChat, chatType) => {
    await forwardMessages(mediaItems, selectedChat, chatType);
    refreshList();
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setSelectMode(false);
  };

  // Dialog to select a TEMPLATE where a message should be copied
  const copyToTemplateDialog = React.useRef();
  const showCopyToTemplateDialog = (mediaItems) => {
    copyToTemplateDialog.current.open(templates, mediaItems);
  };
  const submitCopyToTemplateDialog = () => {
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setBulkForwardTemplate(false);
    setSelectMode(false);
  };

  // Dialog to select a MEDIA FOLDER where an attachment should be copied
  const copyToDialog = React.useRef();
  const showCopyToDialog = (mediaItems) => {
    copyToDialog.current.open(media, mediaItems);
  };
  const submitCopyToDialog = async (mediaItems, folderId) => {
    let newPath = [];
    const folder = media.find((m) => m.id === folderId);
    for (let i = 0; i < mediaItems.length; i++) {
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
      await copyFiles(mediaItems[i], newPath.join("/"));
    }
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setSelectMode(false);
  };

  const copyFiles = async (mediaItem, newPath) => {
    let { response, error } = await CopyToFolder(
      mediaItem.fromTemplate
        ? `${session.userId}/${mediaItem.mediaAttachment}`
        : `${mediaItem.partyId}/${mediaItem.message}`,
      mediaItem.mediaAttachmentType
        ? mediaItem.mediaAttachmentType
        : mediaItem.messageType,
      newPath,
      mediaItem.title ? mediaItem.title : null
    );
    if (!error) {
      setForwardStatus("Success!");
      setSnackBarOpen(true);
    }
  };

  /* delete message */
  const removeMessageDialog = React.useRef();
  const showRemoveMessageDialog = () => {
    setMenuOpen(false);
    removeMessageDialog.current.open();
  };
  const submitRemoveMessageDialog = async () => {
    if (deleteTemplateMessage) {
      submitDeleteTemplateMessage();
      return;
    }
    if (bulkDelete) {
      let { response, error } = await RemoveMessages(
        session?.userId === party?.userId ? null : me.guestId,
        partyId,
        selectedIds
      );
      if (!error)
        for (const msg of response.data) {
          socketEmit("updateMessage", { partyId, message: msg });
        }
      setBulkDelete(false);
      setSelectMode(false);
      setSelectedMessages([]);
      setSelectedIds([]);
    } else {
      if (!selectedMessage?.id) return;
      if (selectedMessage.messageType.startsWith("heading"))
        selectedMessage.deleted = true;
      let { response, error } = await RemoveMessage(
        session?.userId === party?.userId ? null : me.guestId,
        partyId,
        selectedMessage.id,
        selectedMessage.deleted
      );
      if (!error)
        socketEmit("updateMessage", { partyId, message: response.data });
    }
    handleMenuClose();
  };

  /* initialize party */
  const [removed, setRemoved] = React.useState(false);
  const [otherConsultant, setOtherConsultant] = React.useState(false);

  const initParty = async (party) => {
    setOtherConsultant(false);
    if (messageList.current)
      messageList.current.scrollTo({
        top: messageList.current.scrollHeight,
        behaviour: "auto",
      });
    if (session && session.userId && session.userId === party.userId) {
      await GetMedia();
      await GetTemplates();
      await GetAppliedTemplate(partyId);
      await PartyGuests(partyId, null);
      const last = _.last(sortedMessages.filter((m) => !!m.timestamp));
      let { response } = await LatestChat(
        null,
        partyId,
        last ? last.timestamp : undefined,
        null
      );
      if (response?.data?.length > 0) setShowMessageIndicator(true);
    }
    if (session.userId && session.userId !== party.userId) {
      setOtherConsultant(true);
      return;
    }
    if (!me.guestId) {
      let myProfile = profile;
      if (!myProfile.firstName) {
        let { response } = await GetProfile(partyId, party.manager);
        myProfile = response?.data || {};
      }
      await JoinParty(partyId, myProfile.firstName, myProfile.lastName);
    } else {
      const { error: partyError } = await PartyGuests(
        partyId,
        session.userId === party.userId ? null : me.guestId
      );
      const last = _.last(sortedMessages.filter((m) => !!m.timestamp));
      const { error: chatError, response } = await LatestChat(
        session?.userId === party?.userId ? null : me?.guestId,
        partyId,
        last ? last.timestamp : undefined,
        null
      );
      if (partyError || chatError) setRemoved(true);
      if (response?.data?.length > 0) {
        setShowMessageIndicator(true);
        updateSeen(response?.data[response?.data.length - 1]);
      }
    }
  };

  /* Play sound when new message arrives and not in focus */
  const [newMessages, dispatchNewMessages] = React.useReducer(
    (state, action) => (action.type === "inc" ? state + 1 : 0),
    0
  );
  React.useEffect(() => {
    if (!isVisible) return;
    if (newMessages > 0) ChatTimestamp(partyId);
    dispatchNewMessages({ type: "reset" });
    updatePartyChat();
  }, [isVisible, newMessages]);

  React.useEffect(() => {
    if (!party.title) return;
    document.title =
      (unreadMessages > 0 ? `(${unreadMessages}) ` : "") +
      party.title +
      (party.host ? ` ${party.host}` : "") +
      (party.start
        ? ` ${moment(party.start).format(t("general.dateFormat"))}`
        : "");
  }, [unreadMessages, party.title, party.host]);

  /* socket functions */
  const [onlineUsers, setOnlineUsers] = React.useState({});
  const registerPush = async () => {
    const subscription = await subscribeUser();
    if (subscription) await Subscribe(partyId, me.guestId, subscription);
  };
  React.useEffect(() => {
    if (!me.guestId) return;
    registerPush();
  }, [me.guestId]);

  const socket = useSocket();

  React.useEffect(() => {
    if (!socket?.connected || !me.guestId) return;
    socketEmit("join", {
      partyId,
      guestId: me.guestId,
    });
  }, [socket?.connected, me.guestId]);

  useUserJoined(({ partyId: pId, guestId, socketId }) => {
    if (partyId !== pId) return;
    setOnlineUsers((onlineUsers) => {
      if (!Array.isArray(onlineUsers[guestId])) onlineUsers[guestId] = [];
      onlineUsers[guestId].push(socketId);
      return { ...onlineUsers };
    });
    socketEmit("iAmOnline", {
      recipientId: socketId,
      partyId,
      guestId: me.guestId,
    });
    if (me.guestId) {
      const { error } = PartyGuests(
        partyId,
        session?.userId === party.userId ? null : me.guestId
      );
    }
  });

  useIAmOnline(({ partyId: pId, guestId, socketId }) => {
    if (pId !== partyId) return;
    setOnlineUsers((onlineUsers) => {
      if (!Array.isArray(onlineUsers[guestId])) onlineUsers[guestId] = [];
      onlineUsers[guestId].push(socketId);
      return { ...onlineUsers };
    });
  });

  useUserLeft(({ partyId: pId, socketId }) => {
    if (pId !== partyId) return;
    setOnlineUsers((onlineUsers) => {
      for (const guestId in onlineUsers) {
        if (!Array.isArray(onlineUsers[guestId])) onlineUsers[guestId] = [];
        onlineUsers[guestId] = onlineUsers[guestId].filter(
          (sid) => sid !== socketId
        );
      }
      return { ...onlineUsers };
    });
  });

  const updatePartySeen = React.useCallback(() => {
    if (isVisible) ChatTimestamp(partyId);
  }, [isVisible]);

  useNewMessage((message) => {
    if (message?.partyId !== partyId) return;
    refreshList().then(updatePartySeen);
    // TODO: handle notification
    const myMessage = message.messageInfo.sender === me;
    const showIndicator =
      !myMessage &&
      messageList?.current?.scrollTop <
        messageList?.current?.scrollHeight - messageList?.current?.clientHeight;
    HandleMessage(partyId, message.messageInfo);
    if (showIndicator) setShowMessageIndicator(true);
    else if (!myMessage) setTimeout(() => handleMessageIndicator(message), 200);
    if (!isVisible) {
      dispatchNewMessages({ type: "inc" });
    }
    setSeen(null);
  });

  useUpdatedMessage((message) => {
    if (message?.partyId !== partyId) return;
    HandleUpdatedMessage(partyId, message);
  });

  useUpdatedSeen(({ partyId: pId, guestId, timestamp }) => {
    if (pId !== partyId) return;
    ChatTimestamp(partyId);
    HandleUpdatedSeen(partyId, { guestId, timestamp });
  });

  useUpdateRestriction(({ partyId: pId, restrictedMessaging }) => {
    if (pId !== partyId) return;
    HandlePartyRestricted(partyId, restrictedMessaging);
  });

  useReloadMessages(async ({ partyId: pId }) => {
    if (pId !== partyId) return;
    const last = _.last(sortedMessages.filter((m) => !!m.timestamp));
    await LatestChat(null, partyId, last ? last.timestamp : undefined, null);
    if (session.userId !== party.userId) setShowMessageIndicator(true);
  });

  useGuestRemoved(({ partyId: pId, guestId }) => {
    if (pId !== partyId) return;
    if (guestId === me.guestId) {
      history.push("/chats");
      alert(t("party.partyRemoved"));
    }
  });

  useGuestUpdate(({ partyId: pId }) => {
    if (pId !== partyId) return;
    PartyGuests(partyId);
  });

  usePartyDeleted(({ partyId: pId }) => {
    if (pId !== partyId) return;
    history.push("/chats");
    alert(t("party.partyDeleted"));
  });

  const allAudio = (mediaArray) => {
    for (let i = 0; i < mediaArray.length; i++) {
      if (mediaArray[i].mediaType !== "audio") return false;
    }
    return true;
  };

  const handleMediaDescriptions = async (mediaArray) => {
    if (allAudio(mediaArray)) await submitMediaDialog(mediaArray);
    else setSendingMedia(mediaArray);
  };

  /* remove user dialog */
  const removeDialog = React.useRef();
  const handleRemoveGuest = (guestId) => {
    const guest = guests.find((guest) => guest.id === guestId) || {};
    removeDialog.current.open(
      guestId,
      `${guest.firstName?.trim() || ""} ${guest.lastName?.trim() || ""}`.trim()
    );
  };
  const submitRemoveDialog = async (guestId) => {
    const { error, response } = await RemoveGuest(partyId, guestId);
    if (response)
      PartyGuests(
        partyId,
        session?.userId === party?.userId ? null : me.guestId
      ); // update guest list
    if (error) return;
    socketEmit("guestUpdate", { partyId });
  };
  const handleLeave = async () => {
    // TODO: show confirm dialog
    await RemoveGuest(partyId, me.guestId);
    socketEmit("guestUpdate", { partyId });
  };

  /* name dialog */

  const submitNameDialog = async (firstName, lastName, password) => {
    if (me.guestId) {
      // user has changed their name
      if (session && session.userId === party.userId)
        await UpdateProfile(firstName, lastName);
      const { error } = await ChangeName(
        partyId,
        me.guestId,
        firstName,
        lastName
      );
      if (!error) socketEmit("guestUpdate", { partyId });
      return;
    }
    // user wants to join
    let { response: r, error } = await JoinParty(
      partyId,
      firstName,
      lastName,
      password
    );
    if (error) {
      // nameDialog.current.open(firstName, lastName, false, true, error);
      return;
    }
    const guestId = r.data.id;
    sendNewGuestMessage(guestId);
    await PartyGuests(partyId, guestId);
    const last = _.last(sortedMessages.filter((m) => !!m.timestamp));
    let { response } = await LatestChat(
      guestId,
      partyId,
      last ? last.timestamp : undefined,
      null
    );
    if (response?.data?.length > 0) setShowMessageIndicator(true);
  };

  /* voice message dialog */
  const voiceDialog = React.useRef();
  const showVoiceDialog = () => {
    setShowEmojis(false);
    voiceDialog.current.open();
  };
  const submitVoiceDialog = async (audioBlob) => {
    if (sending || !me.guestId) return;
    setSending(true);
    let replyingTo;
    if (selectedReply) replyingTo = selectedReply.id;
    const { response, error } = await SendMessage(
      session?.userId === party?.userId ? null : me.guestId,
      partyId,
      false,
      replyingTo,
      me.guestId,
      "voice",
      audioBlob,
      undefined,
      undefined,
      audioBlob.type
    );
    if (error) {
      setSending(false);
      return;
    }
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
    setSeen(null);
    setSending(false);
    setSelectedReply();
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  /* photo message dialog */
  const photoDialog = React.useRef();
  const showPhotoDialog = () => {
    photoDialog.current.open();
  };
  const submitPhotoDialogChoice = (submission) => {
    if (submission.saveToLibrary) submitUploadDialog(submission.files);
    else submitPhotoDialog(submission.files);
  };
  const submitPhotoDialog = async (files) => {
    if (sending || !me.guestId) return;
    setSending(true);
    for (const file of files) {
      if (me.guestId && !file.type.startsWith("image")) {
        setShowUpload(true);
        setUploadProgress(-1);
        setUploadDescription(`${file.name} ist kein Bild.`);
        setTimeout(() => {
          setShowUpload(false);
          setUploadProgress(0);
          setUploadDescription("");
        }, 3000);
        continue;
      }
      let replyingTo;
      if (selectedReply) replyingTo = selectedReply.id;
      const { response, error } = await SendMessage(
        session?.userId === party?.userId ? null : me.guestId,
        partyId,
        false,
        replyingTo,
        me.guestId,
        file.type.startsWith("audio") ? "voice" : "photo",
        file,
        file.type
      );
      if (error) {
        setSending(false);
        return;
      }
      socketEmit("sendMessage", {
        partyId,
        message: { messageInfo: response.data, senderName: me.firstName },
      });
      setSeen(null);
    }
    setSending(false);
    setSelectedReply();
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  /* make host dialog */
  const hostDialog = React.useRef();
  const handleHostDialog = (guestId) => {
    const guest = guests.find((guest) => guest.id === guestId) || {};
    hostDialog.current.open(
      guestId,
      `${guest.firstName?.trim() || ""} ${guest.lastName?.trim() || ""}`.trim()
    );
  };
  const submitHostDialog = async (guestId) => {
    const { error } = await PartyHost(partyId, guestId);
    if (error) return;
    socketEmit("guestUpdate", { partyId });
  };

  /* apply template dialog */
  const applyTemplateDialog = React.useRef();
  const showApplyTemplateDialog = async () => {
    setShowEmojis(false);
    await GetTemplates();
    applyTemplateDialog.current.open();
  };
  /* overwrite template dialog */
  const overwriteTemplateDialog = React.useRef();
  const showOverwriteTemplateDialog = (templateId) => {
    overwriteTemplateDialog.current.open(templateId);
  };

  /* read receipt dialog */
  const readReceiptDialog = React.useRef();
  const showReadReceiptDialog = (guests) => {
    readReceiptDialog.current.open(guests);
  };

  /* heading message dialog */
  const headingDialog = React.useRef();
  const showHeadingDialog = () => {
    setShowEmojis(false);
    headingDialog.current.open();
  };
  const submitHeadingDialog = async (heading, messageType) => {
    if (sending || !me.guestId) return;
    setSending(true);
    let replyingTo;
    if (selectedReply) replyingTo = selectedReply.id;
    let { response, error } = await SendMessage(
      session?.userId === party?.userId ? null : me.guestId,
      partyId,
      false,
      replyingTo,
      me.guestId,
      messageType,
      heading
    );
    if (error) {
      setSending(false);
      return;
    }
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
    setSeen(null);
    setSending(false);
    setSelectedReply();
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  /* new guest message */
  const sendNewGuestMessage = async (guestId) => {
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  const [showUpload, setShowUpload] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadDescription, setUploadDescription] = React.useState("");

  const submitUploadDialog = async (files) => {
    let len = files.length;
    let mediaFiles = [];
    setShowUpload(true);
    for (var i = 0; i < len; i++) {
      let mediaType = files[i].type.split("/")[0];
      let mediaName = files[i].name.split(".").slice(0, -1).join(".");
      let mediaExtension = /(?:\.([^.]+))?$/.exec(files[i].name)[1];
      setUploadProgress(0);
      setUploadDescription(`${mediaName} wird hochgeladen...`);
      let isPortrait = false;
      let noAudio;
      let videoLength = 0;
      if (mediaType === "video" || mediaType === "image")
        mediaExtension = undefined;
      if (mediaType === "video") {
        let mediaInfo = await MediaInfo();
        const getSize = () => files[i].size;
        const readChunk = (chunkSize, offset) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              if (event.target.error) reject(event.target.error);
              resolve(new Uint8Array(event.target.result));
            };
            reader.readAsArrayBuffer(
              files[i].slice(offset, offset + chunkSize)
            );
          });
        let info = await mediaInfo.analyzeData(getSize, readChunk);
        noAudio =
          (info?.media?.track || []).filter((t) => t["@type"] === "Audio")
            .length < 1;
        let general = (info?.media?.track || []).filter(
          (t) => t["@type"] === "General"
        )[0] || { Duration: "0" };
        videoLength = parseInt(general.Duration);
        let videoTrack = (info?.media?.track || []).filter(
          (t) => t["@type"] === "Video"
        )[0] || { Width: "0", Height: "0" };
        isPortrait = parseInt(videoTrack.Height) > parseInt(videoTrack.Width);
        if (
          parseInt(videoTrack.Rotation) === 90 ||
          parseInt(videoTrack.Rotation) === 270
        )
          isPortrait = !isPortrait;
      }
      try {
        let mediaId = await new Promise((resolve, reject) => {
          let fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const { response, error } = await AddMedia(
              files[i].type,
              e.target.result,
              mediaName,
              null,
              mediaType,
              mediaExtension,
              (e) => setUploadProgress((100 / e.total) * e.loaded)
            );
            error ? reject(error) : resolve(response);
          };
          fileReader.readAsArrayBuffer(files[i]);
        });
        if (mediaType === "video" || mediaType === "application") {
          setUploadDescription(`${mediaName} wird konvertiert...`);
          await ConvertMedia(mediaId, isPortrait, noAudio);
          await new Promise((resolve) =>
            setTimeout(resolve, videoLength > 0 ? videoLength * 500 : 1000)
          );
        }
        setUploadDescription(`${mediaName} erfolgreich hochgeladen!`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
        let m = {
          id: mediaId,
          title: mediaName,
          mediaType: mediaType,
          mediaExtension: mediaExtension,
        };
        mediaFiles = [...(mediaFiles || []), m];
      } catch (e) {
        setUploadDescription(`${mediaName}: Hochladen fehlgeschlagen.`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
      }
    }
    handleMediaDescriptions(mediaFiles);
  };

  /* media message dialog */
  const mediaDialog = React.useRef();
  const showMediaDialog = async () => {
    setShowEmojis(false);
    await GetMedia();
    mediaDialog.current.open();
  };
  const submitMediaDialog = async (mediaArray) => {
    if (sending || !me.guestId) return;
    setSending(true);
    for (let m of mediaArray) {
      let replyingTo;
      if (selectedReply) replyingTo = selectedReply.id;
      let { response, error } = await SendMessage(
        session?.userId === party?.userId ? null : me.guestId,
        partyId,
        false,
        replyingTo,
        me.guestId,
        "text",
        m.title,
        m.id,
        m.mediaType,
        m.mediaExtension,
        undefined,
        undefined,
        m.description
      );
      if (error) {
        setSending(false);
        return;
      }
      socketEmit("sendMessage", {
        partyId,
        message: { messageInfo: response.data, senderName: me.firstName },
      });
      setSeen(null);
    }
    setSending(false);
    setSelectedReply();
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  /* profile message dialog */
  const sendProfileDialog = React.useRef();
  const showProfileDialog = () => {
    setShowEmojis(false);
    sendProfileDialog.current.open();
  };
  const submitProfileDialog = async () => {
    if (sending || !me.guestId) return;
    setSending(true);
    let { response, error } = await SendMessage(
      session?.userId === party?.userId ? null : me.guestId,
      partyId,
      false,
      null,
      me.guestId,
      "profile",
      profile.id
    );
    if (error) {
      setSending(false);
      return;
    }
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
    setSeen(null);
    setSending(false);
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);

  /* send text message */
  const [sending, setSending] = React.useState(false);
  const sendTextMessage = async (message) => {
    if (sending || !me.guestId || !message) return;
    let replyingTo;
    if (selectedReply) replyingTo = selectedReply.id;
    inputRef.current.setMessage("");
    if (selectedReply) setSelectedReply();
    if (showEmojis) setShowEmojis(false);
    inputRef.current.focus();
    let { response, error } = await SendMessage(
      session?.userId === party?.userId ? null : me.guestId,
      partyId,
      false,
      replyingTo,
      me.guestId,
      "text",
      message
    );
    if (error) return;
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
    setSeen(null);
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  const sendAgain = async (message) => {
    let { response, error } = await SendMessageAgain({ ...message, partyId });
    if (error) return;
    socketEmit("sendMessage", {
      partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
    setSeen(null);
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "auto",
    });
  };

  /* chat input behaviour */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMessageSubmit = (e, message) => {
    if (e.which === 13 && !e.shiftKey && !isMobile && !editing) {
      e.preventDefault();
      sendTextMessage(message);
    } else if (e.which == 13 && !e.shiftKey && !isMobile && editing) {
      e.preventDefault();
      finishEditing(message);
    }
  };

  /* delete template message */
  const [deleteTemplateMessage, setDeleteTemplateMessage] = React.useState();
  const submitDeleteTemplateMessage = async () => {
    DeleteTemplateMessage(partyId, deleteTemplateMessage.id);
    setDeleteTemplateMessage();
  };

  const getImageSourceSet = (item, isReply) => {
    const folder =
      item.messageType === "profile"
        ? party.userId
        : item.messageType === "photo"
        ? party.id
        : item.mediaAttachmentOwner || party.userId;
    const file =
      item.messageType === "profile"
        ? profile.profilePic
        : item.messageType === "photo"
        ? item.message
        : item.mediaAttachmentType === "video"
        ? `${item.mediaAttachment}.0000001.jpg`
        : item.mediaAttachment;
    const size = item.messageType === "profile" ? 80 : isReply ? 120 : 300;
    return generateImageSourceSet(
      folder,
      file,
      size,
      item.mediaAttachmentType === "application"
    );
  };

  const renderReply = (item, isPreview, fromTemplate) => {
    const myMessage = item.sender === me.guestId;
    const senderName = fromTemplate
      ? t("party.nextFromTemplate")
      : myMessage
      ? t("party.me")
      : item.sender === party.userId
      ? `${party.managerName} (${t("party.manager")})`
      : `${guests.find((g) => g.id === item.sender)?.firstName || ""} ${
          guests.find((g) => g.id === item.sender)?.lastName || ""
        }`;
    const goToMessage = () => {
      document.getElementById(item.id).scrollIntoView();
    };

    const { src, srcSet } = getImageSourceSet(item, true);

    return (
      <div
        className={
          isPreview
            ? classes.replyPreview
            : clsx(classes.replyQuote, { [classes.myReplyQuote]: myMessage })
        }
        onClick={isPreview ? null : goToMessage}
      >
        <strong>
          {senderName}
          <br />
        </strong>
        {item?.messageType.startsWith("heading") && (
          <Ellipsis
            lines={1}
            text={`${t("party.heading")}: ${item.message}`}
            basedOn="letters"
          />
        )}
        {item?.messageType === "profile" && (
          <Ellipsis
            lines={1}
            text={`${profile.firstName} ${profile.lastName}`}
            basedOn="letters"
          />
        )}
        {item?.messageType === "text" && !item.mediaAttachment && (
          <Ellipsis
            lines={1}
            style={item.deleted && { fontStyle: "italic" }}
            text={item.deleted ? t("party.deletedPlaceholder") : item.message}
            basedOn="letters"
          />
        )}
        {(item?.messageType === "voice" ||
          item.mediaAttachmentType === "audio") && (
          <span style={{ fontStyle: "italic" }}>{t("party.voiceMessage")}</span>
        )}
        {(item?.messageType === "photo" ||
          (!!item.mediaAttachment &&
            item?.messageType === "text" &&
            item.mediaAttachmentType !== "audio")) && (
          <img
            src={src}
            srcSet={srcSet}
            className={classes.replyPhotoMessage}
          />
        )}
        {item.mediaAttachmentType === "video" && (
          <PlayIcon className={classes.replyIcon} />
        )}
        {item.mediaAttachmentType === "application" && (
          <PdfIcon className={classes.replyIcon} />
        )}
      </div>
    );
  };

  const renderMessage = (item) => {
    if (item.message === "2bgd418k-a72n-532s-hu07-z582p3581d46") return;
    const timestampPreviousMessage =
      sortedMessages[sortedMessages.indexOf(item) - 1]?.timestamp;
    const showNewMessagesDivider =
      timestampPreviousMessage &&
      seen &&
      item.timestamp > seen &&
      timestampPreviousMessage < seen;

    const seenByGuests = guests.filter(
      (g) => g.lastSeen && moment(g.lastSeen).isSameOrAfter(item.timestamp)
    );
    let myMessage =
      item.sender === me.guestId &&
      item.message !== "59953f60-7f65-4119-9374-34ab02e43a9d";
    let deleted = item.message === "8b3c857c-b40f-471b-bc66-a134f5924a83";
    if (deleted) item.deleted = true;
    if (!item) return;
    let senderName = myMessage
      ? t("party.me")
      : item.sender === party.userId
      ? `${party.managerName} (${t("party.manager")})`
      : `${guests.find((g) => g.id === item.sender)?.firstName || ""} ${
          guests.find((g) => g.id === item.sender)?.lastName || ""
        }`;
    if (party.managerName && item.sender === party.userId && !myMessage) {
      senderName = party.managerName;
    }
    if (senderName.includes("undefined")) {
      senderName = `[${t("party.guestRemoved")}]`;
    }
    const senderIsPresent =
      guests.some((g) => g.id === item.sender) ||
      item.senderType === "manager" ||
      myMessage;
    if (!senderIsPresent) {
      senderName = `[${t("party.guestRemoved")}]`;
    }
    if (item.message === "59953f60-7f65-4119-9374-34ab02e43a9d") {
      if (!senderIsPresent) return;
      return (
        <div className={classes.newUserMessage} key={item.id} id={item.id}>
          <span>{t("party.newGuest", { name: senderName })}</span>
        </div>
      );
    }
    if (item?.messageType.startsWith("heading"))
      return (
        <div
          className={clsx([classes.headingMessage, classes[item.messageType]])}
          key={item.id}
          id={item.id}
        >
          {selectMode && (
            <div className={classes.headingCheckbox}>
              <Checkbox
                checked={selectedIds.includes(item.id)}
                onChange={() => onMessageSelect(item)}
                style={{ color: "#1E92CD" }}
              />
            </div>
          )}
          <span>{item.message}</span>
          {!expired && session?.userId === party.userId && (
            <IconButton
              size="small"
              style={{ marginLeft: 10, marginTop: -4 }}
              onClick={(event) => handleMenuOpen(event, item)}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </div>
      );

    const { src, srcSet } = getImageSourceSet(item);

    let messageLines;
    if (item?.messageType === "profile") {
      messageLines = (
        <div>
          <div className={classes.picWrapper}>
            <Avatar src={src} srcSet={srcSet} className={classes.avatar} />
            <p className={classes.name}>
              {profile.firstName} {profile.lastName}
            </p>
          </div>
          <p className={classes.profileMessage}>
            {!!profile.birthday && (
              <>
                <b>{t("profiles.birthday")}: </b>
                {moment(profile.birthday).format(t("general.dateFormat"))}
                <br />
              </>
            )}
            {!!profile.phoneNo && (
              <>
                <b>{t("profiles.phoneNo")}: </b>
                <a
                  className={classes.linkColor}
                  href={`tel:${profile.phoneNo}`}
                >
                  {profile.phoneNo}
                </a>
                <br />
              </>
            )}
            {!!profile.email && (
              <>
                <b>{t("profiles.email")}: </b>
                <a
                  className={classes.linkColor}
                  href={`mailto:${profile.email}`}
                >
                  {profile.email}
                </a>
                <br />
              </>
            )}
            {!!profile.website && (
              <>
                <b>{t("profiles.website")}: </b>
                <a
                  className={classes.linkColor}
                  href={
                    profile.website.indexOf("http") === -1
                      ? "https://" + profile.website
                      : profile.website
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {profile.website}
                </a>
                <br />
              </>
            )}
            {!!profile.shopLink && (
              <>
                <b>{t("profiles.shopLink")}: </b>
                <a
                  className={classes.linkColor}
                  href={
                    profile.shopLink.indexOf("http") === -1
                      ? "https://" + profile.shopLink
                      : profile.shopLink
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {profile.shopLink}
                </a>
                <br />
              </>
            )}
            {!!profile.biography && (
              <>
                <b>{t("profiles.biography")}:</b>
                <br />
                {profile.biography}
              </>
            )}
          </p>
        </div>
      );
    }
    if (item.mediaAttachment && item.mediaAttachmentType === "application") {
      const viewFile = () => {
        let i = mediaMessages.findIndex((p) => p.id === item.id);
        if (i !== -1) {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }
      };

      messageLines = (
        <div>
          <div className={classes.pdfMessageWrapper}>
            <img
              src={src}
              srcSet={srcSet}
              className={classes.photoMessage}
              onClick={viewFile}
            />
            <div className={classes.pdfTitleWrapper}>
              <Typography className={classes.pdfTitle}>
                <PdfIcon style={{ marginBottom: -6 }} />
                &nbsp;{item.message}
              </Typography>
            </div>
          </div>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target="blank">
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }

    if (item.mediaAttachmentType === "video") {
      const viewVideo = () => {
        let i = mediaMessages.findIndex((p) => p.id === item.id);
        if (i !== -1) {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }
      };
      messageLines = (
        <div>
          <div className={classes.pdfMessageWrapper}>
            <img
              src={src}
              srcSet={srcSet}
              className={classes.photoMessage}
              onClick={viewVideo}
            />
            <div className={classes.iconWrapper}>
              <PlayIcon className={classes.videoMessagePlay} />
            </div>
          </div>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a
                className={classes.linkColor}
                href={href}
                key={key}
                target="blank"
              >
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }
    if (item?.messageType === "text" && !item.mediaAttachment) {
      const links = linkify.match(item.message);

      messageLines = (
        <>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a
                className={classes.linkColor}
                href={href}
                key={key}
                target="blank"
              >
                {text}
              </a>
            )}
          >
            <div
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                fontStyle: deleted ? "italic" : undefined,
              }}
            >
              {deleted
                ? t("party.deletedPlaceholder")
                : insertFormatting(item.message)}
            </div>
          </Linkify>
          {links && links[0] && (
            <>
              <div style={{ height: 5 }} />
              <ReactTinyLink
                cardSize="small"
                showGraphic={true}
                maxLine={2}
                minLine={1}
                url={links[0].url}
              />
              <div style={{ height: 5 }} />
            </>
          )}
        </>
      );
    }
    if (
      item?.messageType === "voice" ||
      item.mediaAttachmentType === "voice" ||
      item.mediaAttachmentType === "audio"
    )
      messageLines = (
        <div className={classes.voiceMessage}>
          <audio controls>
            <source
              src={
                item?.messageType === "voice"
                  ? `${process.env.REACT_APP_STATIC_URL}/${party.id}/${item.message}`
                  : `${process.env.REACT_APP_STATIC_URL}/${
                      item.mediaAttachmentOwner || party.userId
                    }/${item.mediaAttachment}`
              }
            />
          </audio>
        </div>
      );
    if (item?.messageType === "photo" || item.mediaAttachmentType === "image") {
      const viewPhoto = () => {
        let i = mediaMessages.findIndex((p) => p.id === item.id);
        if (i !== -1) {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }
      };
      messageLines = (
        <div>
          <img
            src={src}
            srcSet={srcSet}
            className={classes.photoMessage}
            onClick={viewPhoto}
          />
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target="_blank">
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }
    const replyMessage = !item.replyingTo
      ? undefined
      : sortedMessages.find((m) => m.id === item.replyingTo);
    return (
      <React.Fragment key={item.id}>
        {showNewMessagesDivider && (
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Divider ref={dividerRef} />
            </Grid>
            <Grid item>{t("party.newMessages")}</Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        )}

        <div className={clsx(classes.primaryMessageWrapper)} id={item.id}>
          {selectMode && (item.messageType !== "profile" || !bulkForward) && (
            <div className={clsx(classes.messageCheckbox)}>
              <Checkbox
                checked={selectedIds.includes(item.id)}
                onChange={() => onMessageSelect(item)}
                style={{ color: "#1E92CD" }}
              />
            </div>
          )}
          <div
            className={clsx(
              myMessage ? classes.messageWrapperOut : classes.messageWrapperIn,
              !selectMode
                ? classes.fullWidthMessage
                : classes.noneFullWidthMessage
            )}
          >
            <Card
              elevation={0}
              className={clsx(classes.chatMessage, {
                [classes.chatMessageOut]: myMessage,
              })}
            >
              <div className={classes.messageHeader}>
                {!!item.tempId && (
                  <span
                    style={{
                      transform: "translateX(-50px)",
                      color: item.failed ? "red" : "rgb(160,160,160)",
                      width: 0,
                      height: 0,
                      cursor: item.failed && "pointer",
                    }}
                    onClick={item.failed && (() => sendAgain(item))}
                  >
                    {item.failed ? (
                      <FailedIcon size="small" />
                    ) : (
                      <SendingIcon size="small" />
                    )}
                  </span>
                )}
                <span className={classes.chatSender}>{senderName}</span>
                <span className={classes.grow} />
                <span className={classes.chatTimestamp}>
                  {handleTimestamp(item.timestamp)}
                </span>
                {(session?.userId === party.userId ||
                  (!deleted && !party.restrictedMessaging)) && (
                  <IconButton
                    className={classes.iconMessageSendPartyChatView}
                    size="small"
                    style={{ marginTop: -7, marginRight: -5 }}
                    onClick={(event) => handleMenuOpen(event, item)}
                    disabled={expired || !!item.tempId}
                  >
                    <MoreHorizIcon
                      className={
                        myMessage
                          ? classes.iconMessageGroupChatViewReceived
                          : classes.otherIconMessageGroupChatView
                      }
                    />
                  </IconButton>
                )}
              </div>
              {!!replyMessage && !item.deleted && renderReply(replyMessage)}
              {messageLines}
            </Card>
            {myMessage && me.guestId === party.userId && (
              <span
                className={classes.readReceipt}
                onClick={() => showReadReceiptDialog(seenByGuests)}
              >
                {t("party.seenBy", {
                  seen: seenByGuests.length,
                  total: guests.length,
                })}
              </span>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const { src: avatarSrc, srcSet: avatarSrcSet } = generateImageSourceSet(
    party.id,
    party.partyPic,
    40
  );

  if (otherConsultant) {
    return <ForbiddenToJoin />;
  }

  if (
    (isIOS || isAndroid) &&
    !appDeclined &&
    session.userId !== party.userId &&
    !me.guestId
  )
    return <MobilePopUp setAppDeclined={setAppDeclined} />;
  if (loading || messagesLoading || partyState === "loading")
    return <PartyLoading />;
  if (partyState === "expired") return <PartyExpired />;
  if (partyState === "error") return <PartyNotFound />;
  if (removed) return <PartyRemoved />;

  return (
    <div className={classes.screen} onDragEnter={() => setHovered(true)}>
      <audio className="audio-element">
        <source src={require("assets/message-alert.mp3")}></source>
      </audio>
      {hovered && (
        <div
          {...getRootProps({
            className: clsx("dropzone", classes.dropzone, {
              [classes.dropzoneReject]: isDragReject,
            }),
            onDragLeave: () => setHovered(false),
          })}
        >
          <UploadIcon />
          <span>{t("general.uploadDrop")}</span>
          <input {...getInputProps()} />
        </div>
      )}
      <div className={classes.chat}>
        <div
          className={classes.chatHeader}
          onClick={() => handleOpenChatInfo()}
        >
          <Avatar
            className={classes.headerAvatar}
            src={avatarSrc}
            srcSet={avatarSrcSet}
          >
            <ShoppingCartIcon />
          </Avatar>
          <div style={{ flex: 1 }}>{party.title}</div>
          <div>
            {selectMode && (
              <Button
                style={{ color: "#1E92CD" }}
                variant="text"
                size="small"
                disabled={selectedIds.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  if (bulkDelete && !bulkForward) showRemoveMessageDialog();
                  else if (!bulkDelete && bulkForward)
                    showForwardMessagesDialog();
                }}
              >
                {bulkDelete && !bulkForward
                  ? t("party.massDeleteMessageLabel")
                  : bulkForward && !bulkDelete
                  ? t("party.massForwardMessageLabel")
                  : null}
              </Button>
            )}
            {!selectMode && (
              <IconButton
                className={classes.moreInfoMessage}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleGlobalChatMenuOpen(event);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            {selectMode && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancelBulk();
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div
          className={classes.chatView}
          ref={messageList}
          onScroll={handleScroll}
        >
          <div className={classes.allMessages}>
            {sortedMessages.map(renderMessage)}
          </div>
        </div>
        {showEmojis && (
          <div className={classes.emojiKeyboard}>
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              groupNames={{
                smileys_people: t("emojis.smileys_people"),
                animals_nature: t("emojis.animals_nature"),
                food_drink: t("emojis.food_drink"),
                travel_places: t("emojis.travel_places"),
                activities: t("emojis.activities"),
                objects: t("emojis.objects"),
                symbols: t("emojis.symbols"),
                flags: t("emojis.flags"),
                recently_used: t("emojis.recently_used"),
              }}
              disableSearchBar={true}
            />
          </div>
        )}
        {!!selectedReply && (
          <div className={classes.reply}>
            <IconButton onClick={cancelReply} disabled={sending}>
              <CloseIcon />
            </IconButton>
            {renderReply(selectedReply, true)}
          </div>
        )}
        {Array.isArray(sendingMedia) && sendingMedia.length > 0 && (
          <MediaPreview
            submitMediaDialog={submitMediaDialog}
            sendingMedia={sendingMedia}
            setSendingMedia={setSendingMedia}
            userId={party?.userId}
          />
        )}
        {!selectedReply &&
          !!templateMessages && //Template Messages
          templateMessages.length > 0 &&
          appliedTemplate.open === true && (
            <TemplateMessages
              socket={socket}
              sending={sending}
              inputRef={inputRef}
              renderReply={renderReply}
              partyId={partyId}
              userId={party?.userId}
            />
          )}
        {!bulkDelete && !bulkForward && (
          <ChatBar
            isManager={userId === party.userId}
            sending={sending}
            expired={expired}
            editing={editing}
            autoFocus={!!me.guestId}
            showEmojiKeyboard={() => {
              setShowEmojis(!showEmojis);
            }}
            showMediaDialog={showMediaDialog}
            showHeadingDialog={showHeadingDialog}
            selectFiles={selectFiles}
            showVoiceDialog={showVoiceDialog}
            showProfileDialog={showProfileDialog}
            showApplyTemplateDialog={showApplyTemplateDialog}
            onEnter={handleMessageSubmit}
            onSendText={sendTextMessage}
            onFinishEditing={finishEditing}
            onCancelEditing={cancelEditing}
            ref={inputRef}
            chatType="party"
            restricted={userId !== party.userId && party.restrictedMessaging}
          />
        )}
      </div>

      {showChatInfo && headerBackButton && (
        <ChatListInfo
          hideChatInfo={() => handleCloseChatInfo()}
          party={party}
          guests={[
            {
              ...profile,
              id: party.userId,
              isManager: true,
            },
            ...guests,
          ]}
          onlineUsers={onlineUsers}
          headings={headings}
          messageList={messageList}
          media={mediaMessages}
          chatType="party"
          determineRole={(guest) => {
            if (guest.isManager) return t("party.manager");
            if (guest.isHost === "true") return t("party.host");
            return t("party.guest");
          }}
          menuItems={[
            {
              id: "startChat",
              label: "Direktchat starten",
              action: startDirectChat,
            },
            {
              id: "makeHost",
              label: "Zum Gastgeber ernennen",
              action: handleHostDialog,
            },
            {
              id: "remove",
              label: "Aus Party entfernen",
              action: handleRemoveGuest,
            },
          ]}
          filterMenuItems={(item, guest) => {
            switch (item.id) {
              case "startChat": {
                if (userType !== "user" && !guest.isManager) return false;
                if (guest.id === userId) return false;
                if (!guest.isManager && !guest.isAppUser) return false;
                return true;
              }
              case "makeHost": {
                if (userType !== "user" || guest.isHost === "true")
                  return false;
                if (guest.id === userId) return false;
                return true;
              }
              case "remove": {
                if (userType !== "user") return false;
                if (guest.id === userId) return false;
                return true;
              }
            }
            return true;
          }}
        />
      )}
      <Menu
        anchorEl={globalAnchorEl}
        keepMounted={true}
        open={globalChatMenuOpen}
        onClose={handleGlobalChatMenuClose}
      >
        {admin && (
          <MenuItem onClick={handleEdit}>{t("chats.editParty")}</MenuItem>
        )}
        {admin && (
          <MenuItem onClick={handleRemove}>{t("chats.deleteParty")}</MenuItem>
        )}
        {admin && (
          <MenuItem onClick={handleForwardToTemplate}>
            {t("chats.partyAsTemplate")}
          </MenuItem>
        )}
        {admin && (
          <MenuItem onClick={handleBulkDelete}>
            {t("party.massDeleteMessageLabel")}
          </MenuItem>
        )}
        {admin && (
          <MenuItem onClick={handleBulkForward}>
            {t("party.massForwardMessageLabel")}
          </MenuItem>
        )}
        <MenuItem onClick={handleShareLink}>{t("chats.inviteGuests")}</MenuItem>
        {!admin && (
          <MenuItem onClick={handleLeave}>{t("party.leave")}</MenuItem>
        )}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {userType === "user" && selectedMessage?.messageType !== "profile" && (
          <MenuItem onClick={handleForwardMessage}>
            {t("general.forward")}
          </MenuItem>
        )}
        {(userId === selectedMessage?.sender || userId === party.userId) && (
          <MenuItem onClick={showRemoveMessageDialog}>
            {t("party.deleteMessageLabel")}
          </MenuItem>
        )}
        {selectedMessage?.mediaAttachmentType !== "audio" &&
          selectedMessage?.messageType !== "voice" &&
          selectedMessage?.messageType !== "profile" &&
          me.guestId === selectedMessage?.sender &&
          !selectedMessage?.deleted && (
            <MenuItem onClick={startEditMessage}>
              {t("party.editMessageLabel")}
            </MenuItem>
          )}
        {!selectedMessage?.deleted &&
          !selectedMessage?.messageType?.startsWith?.("heading") && (
            <MenuItem onClick={startReplyMessage}>
              {t("party.replyMessageLabel")}
            </MenuItem>
          )}
      </Menu>
      <PartyDialog ref={editPartyDialog} onSubmit={submitEditPartyDialog} />
      <DeleteDialog ref={deleteDialog} type="party" onSubmit={submitRemove} />
      <RemoveDialog ref={removeDialog} onSubmit={submitRemoveDialog} />
      <RemoveMessageDialog
        ref={removeMessageDialog}
        onSubmit={submitRemoveMessageDialog}
        bulk={bulkDelete}
      />
      <ForwardMessagesDialog
        ref={forwardMessagesDialog}
        onMoveToLibrary={submitMoveToLibrary}
        onMoveToTemplate={submitMoveToTemplate}
        onMoveToChat={submitMoveToChat}
        isMedia={mediaSelected}
      />
      <MoveToDialog ref={copyToDialog} onSubmit={submitCopyToDialog} />
      <MoveToTemplateDialog
        ref={copyToTemplateDialog}
        onSubmit={submitCopyToTemplateDialog}
      />
      <MoveToChatDialog
        ref={copyToChatDialog}
        onSubmit={submitCopyToChatDialog}
      />
      <VoiceDialog ref={voiceDialog} onSubmit={submitVoiceDialog} />
      <PhotoDialog
        ref={photoDialog}
        onSubmit={submitPhotoDialogChoice}
        onCancel={cancelPhotoPreview}
        selectedFiles={selectedFiles}
        libraryEnabled={session?.userId === party.userId}
      />
      <HeadingDialog ref={headingDialog} onSubmit={submitHeadingDialog} />
      <MediaDialog
        ref={mediaDialog}
        onSubmit={handleMediaDescriptions}
        media={media}
        userId={session.userId}
      />
      <SendProfileDialog
        ref={sendProfileDialog}
        onSubmit={submitProfileDialog}
        userId={session.userId}
        profile={profile}
      />
      <HostDialog ref={hostDialog} onSubmit={submitHostDialog} />
      <ApplyTemplateDialog
        ref={applyTemplateDialog}
        socket={socket}
        showOverwriteTemplateDialog={showOverwriteTemplateDialog}
        partyId={partyId}
        appliedTemplate={appliedTemplate}
        templates={templates}
        setSending={setSending}
        setShowUpload={setShowUpload}
        setUploadDescription={setUploadDescription}
      />
      <OverwriteTemplateDialog
        ref={overwriteTemplateDialog}
        partyId={partyId}
      />
      <ReadReceiptDialog ref={readReceiptDialog} />
      <ShareLinkDialog
        ref={shareLinkDialog}
        title={t("chats.createLink")}
        description={t("chats.createPartyLinkDescription")}
      />
      <ImageCarousel
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        media={mediaMessages}
        currentIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        party={party}
        chatType="party"
      />
      <NewMessageButton
        show={showMessageIndicator}
        handleClick={handleMessageIndicator}
        handleClose={() => setShowMessageIndicator(false)}
      />
      <UploadProgress
        showUpload={showUpload}
        progress={uploadProgress}
        description={uploadDescription}
      />
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
        anchorOriginTopRight
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {forwardStatus}
        </Alert>
      </Snackbar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    padding: 0,
    height: "100%",
  },
  title: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    flex: 1,
    textOverflow: "ellipsis",
  },
  copyLinkButton: {
    color: "#000",
    textTransform: "none",
    fontWeight: "bold",
  },
  mobileDotMenu: {
    justifyContent: "right",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tabs: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  tab: {
    textTransform: "capitalize",
    minWidth: 0,
    marginRight: 30,
    fontWeight: "bold",
    flex: 1,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  chat: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    maxHeight: "100%",
  },
  chatView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
    scrollBehavior: "smooth",
    padding: "10px 0",
    margin: "0 10px",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  allMessages: {
    display: "block",
    // paddingBottom: 10,
  },
  primaryMessageWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  messageWrapperIn: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "8px",
    alignItems: "flex-start",
  },
  messageWrapperOut: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  fullWidthMessage: {
    width: "100%",
  },
  noneFullWidthMessage: {
    width: "90%",
  },
  linkColor: {
    color: "white",
  },
  messageCheckbox: {
    display: "flex",
    alignItems: "center",
    marginLeft: "5rem",
    width: "10%",
  },
  headingCheckbox: {
    display: "flex",
    alignItems: "center",
    width: "10%",
  },
  messageWrapperReply: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  headingMessage: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingTop: 30,
    paddingBottom: 10,
    flexShrink: 0,
    fontWeight: "bold",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    "& span": {
      maxWidth: "calc(100% - 20px)",
    },
  },
  heading: {
    fontSize: "1.5em",
    borderColor: "rgba(160,160,160,0.6)",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 0.5,
  },
  heading2: {
    fontSize: "1.4em",
  },
  heading3: {
    fontWeight: "500",
    fontSize: "1.3em",
    paddingTop: 20,
  },
  newUserMessage: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    paddingTop: 15,
    paddingBottom: 10,
    fontSize: "1em",
    color: "rgba(160,160,160,0.6)",
    flexShrink: 0,
  },
  previewHeading: {
    display: "flex",
    width: "78%",
    justifyContent: "center",
    fontWeight: "bold",
    borderColor: "rgba(160,160,160,0.6)",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 0.5,
    flexShrink: 0,

    fontSize: 20,
    fontWeight: 10,
    marginTop: 10,
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderLeft: "solid",
    borderWidth: 1,
    backgroundColor: "rgb(240,240,240)",
  },
  emojiKeyboard: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 8,
    marginTop: -328,
    zIndex: 1500,
  },
  voiceMessage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "italic",
    paddingRight: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  photoMessage: {
    width: 300,
    maxWidth: "calc(100% + 10px)",
    aspectRatio: 1,
    margin: -5,
    marginTop: 5,
    borderRadius: 3,
    cursor: "pointer",
  },
  pdfMessageWrapper: {
    paddingBottom: 5,
  },
  pdfTitleWrapper: {
    height: 50,
    margin: -5,
    marginBottom: -45,
    transform: "translateY(-45px)",
    backgroundColor: "rgba(0,0,0,0.5)",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 300,
  },
  pdfTitle: {
    flex: 1,
    color: "white",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  iconWrapper: {
    width: 300,
    height: 300,
    margin: -5,
    marginTop: -305,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoMessagePlay: {
    fontSize: 60,
    pointerEvents: "none",
    color: "white",
  },
  onlineIcon: {
    paddingRight: 10,
  },

  managerVoiceMessageIcon: {
    color: "white",
  },
  moreIcon: {
    display: "none",
  },
  chatMessage: {
    display: "block",
    marginTop: 10,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "20%",
    maxWidth: "70%",
    backgroundColor: "rgb(240,240,240)",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    overflow: "visible",
    backgroundColor: "#fff",
    color: "#4E4E4E",
  },
  mediaDescription: {
    marginTop: 5,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    maxWidth: 280,
  },
  readReceipt: {
    marginTop: 5,
    fontSize: "0.9em",
    color: "rgba(160,160,160,0.6)",
    textAlign: "right",
    cursor: "pointer",
  },
  replyMessage: {
    display: "block",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: "20%",
    maxWidth: "100%",
    opacity: 0.8,
    border: "solid",
    borderWidth: 1,
    backgroundColor: "rgb(240,240,240)",
  },
  reply: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    paddingLeft: 5,
  },
  replyPreview: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    marginLeft: 5,
    padding: 10,
    borderLeft: "solid",
    borderWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    backgroundColor: "#eee",
  },
  replyQuote: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 48,
    marginLeft: -5,
    marginRight: -5,
    marginBottom: 5,
    padding: 10,
    borderLeft: "solid",
    borderWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
  },
  myReplyQuote: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  replyPhotoMessage: {
    width: 120,
    height: 120,
  },
  replyIcon: {
    fontSize: 40,
    color: "white",
    marginTop: -80,
    marginBottom: 40,
    marginLeft: 40,
  },
  // eslint-disable-next-line no-dupe-keys
  replyMessage: {
    fontSize: 20,
    fontWeight: 10,
  },
  chatMessageOut: {
    backgroundColor: theme.palette.darkGrey.main,
    color: "#fff",
    borderRadius: 12,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    // width: "50%",
    "&:a": { color: "#fff" },
  },
  messageHeader: {
    display: "flex",
    flexDirection: "row",
  },
  chatSender: {
    fontSize: ".9em",
    fontWeight: "bold",
  },
  chatTimestamp: {
    fontSize: ".9em",
    opacity: 0.7,
  },
  mediaContent: {
    width: "100%",
    flex: 1,
    marginBottom: 20,
  },
  contentWrapper: {
    display: "none",
    paddingTop: 0,
    [theme.breakpoints.up("md")]: {
      display: "block",
      flex: 1,
      padding: 10,
      paddingBottom: 20,
    },
  },
  chatHeader: {
    boxShadow: "0px 0.5px 1px rgba(160,160,160, 0.4)",
    height: 50,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    fontWeight: "bold",
    backgroundColor: "#fff",
    borderRadius: 5,
    alignItems: "center",
    cursor: "pointer",
  },
  mobileButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  tabletButton: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
    minWidth: 10,
  },
  messageIndicator: {
    borderRadius: theme.spacing(3),
    fontWeight: "bold",
    textTransform: "none",
  },

  allTemplateMessages: {
    display: "block",
    paddingBottom: 10,
  },
  dropzone: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "dashed",
    alignItems: "center",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    opacity: "80%",
    zIndex: 10000,
    pointerEvents: "auto",
  },
  dropzoneReject: {
    backgroundColor: "rgb(255, 180, 180)",
    borderColor: "red",
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  name: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: "bold",
  },
  profileMessage: {
    fontSize: 15,
    marginLeft: 20,
    marginRight: 20,
  },
  moreInfoMessage: {
    "&:hover": {
      cursor: "pointer",
      color: "#333E48",
    },
  },
  iconMessageGroupChatViewReceived: {
    color: "white",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  otherIconMessageGroupChatView: {
    color: theme.palette.darkGrey.main,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  headerAvatar: {
    marginRight: 10,
  },
}));

export default PartyChatView;
