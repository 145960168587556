import React from "react";
import {
  CssBaseline,
  Button,
  Avatar,
  ListItemText,
  Divider,
  Skeleton,
  Box,
  ListItemAvatar,
  ListItem,
} from "@mui/material";
import {
  HeaderBar,
  NavMenu,
  PartyDialog,
  NewChatDialog,
  GroupDialog,
  JoinChatDialog,
  ShareLinkDialog,
} from "components";
import PartyChatView from "./partyChatView";
import DirectChatView from "./directChatView";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmailIcon from "@mui/icons-material/Email";
import GroupsIcon from "@mui/icons-material/Groups";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import {
  useDirectChatStarted,
  useEmit,
  useGuestRemoved,
  useNewMessage,
  usePartyDeleted,
  useReloadMessages,
  useSocketInitialized,
  useUpdatedMessage,
  useUserJoined,
} from "./../../context/socket";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { Switch, Route } from "react-router-dom";
import GroupChatView from "./groupChatView";
import { lastMessageWithSender, lastMessage } from "./latestMessage";
import { useHandleTimestamp } from "src/utils/handleTimestamp.util";
import moment from "moment";
import short from "short-uuid";
import { generateImageSourceSet } from "src/utils/generateImageSet";
const translation = short();

const sortByLatestMessage = (a, b) => {
  if (!a.latestMessage?.timestamp) return 1;
  if (!b.latestMessage?.timestamp) return -1;
  return (
    new Date(b.latestMessage.timestamp) - new Date(a.latestMessage.timestamp)
  );
};

const Chats = (props) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [allActiveChats, setAllActiveChats] = React.useState([]);
  const [groupChats, setGroupChats] = React.useState([]);
  const [directChats, setDirectChats] = React.useState([]);
  const [partyChats, setPartyChats] = React.useState([]);
  const [archivedChats, setArchivedChats] = React.useState([]);
  const [chatListLoading, setChatListLoading] = React.useState(true);

  const handleTimestamp = useHandleTimestamp();

  const session = useSelector((state) => state.session);

  const userId = session.secretKey ? session.id : session.userId;

  const settings = useSelector((state) => state.settings);

  const allChats = useSelector((state) => state.allChatTypes || []);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState();

  const { partyId: selectedId } = useParams();

  const history = useHistory();

  const socketInitialized = useSocketInitialized();
  const socketEmit = useEmit();

  const { t } = useTranslation();

  const { AddParty, GetChatList, AddChat, AddGroupChat, JoinGroupChat } =
    useActions();

  const chips = [
    {
      title: `${t("chats.all")}`,
      list: allActiveChats,
    },
    {
      title: `${t("chats.groups")}`,
      list: groupChats,
      onlyVip: true,
    },
    {
      title: `${t("chats.direct")}`,
      list: directChats,
      onlyVip: true,
    },
    {
      title: `${t("chats.parties")}`,
      list: partyChats,
      onlyVip: true,
    },
    {
      title: `${t("chats.archive")}`,
      list: archivedChats,
    },
  ];

  const applySearch = (item, query) => {
    const searchVal = query.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result =
        result &&
        item?.title &&
        searchTerm.test(item.title?.toLowerCase() || item.title);
    }
    return result;
  };

  const handleSearch = (query, selectedChip) => {
    setSearchQuery(query);
    if (!query.trim()) {
      return;
    }
    setSearchResults(
      chips[selectedChip].list.filter((item) => applySearch(item, query))
    );
  };

  const handlePartySelect = async (party) => {
    if (!party) return;
    if (party.shortId) {
      history.push(`/chats/p/${party.shortId}`);
    } else if (party.chatId) {
      history.push(`/chats/c/${party.chatId}`);
    } else {
      history.push(`/chats/g/${party.id}`);
    }
  };

  const [selectedChip, setSelectedChip] = React.useState(0);
  const changeChip = (chip) => {
    setSelectedChip(chip);
    handleSearch(searchQuery, chip);
  };

  const [unreadMessages, setUnreadMessages] = React.useState(0);

  const loadChatList = async () => {
    setChatListLoading(true);
    await GetChatList();
    setChatListLoading(false);
  };
  React.useEffect(() => {
    if (!allChats) return;
    setAllActiveChats(
      allChats.filter((m) => !m.archived).sort(sortByLatestMessage)
    );
    setGroupChats(
      allChats.filter((m) => m.type === "group").sort(sortByLatestMessage)
    );
    setDirectChats(
      allChats
        .filter((m) => m.type === "direct" && !m.archived)
        .sort(sortByLatestMessage)
    );
    setPartyChats(
      allChats.filter((m) => m.type === "party").sort(sortByLatestMessage)
    );
    const archivedChats = allChats
      .filter((m) => m.archived)
      .sort(sortByLatestMessage);
    setArchivedChats(archivedChats);
    let unreadMessages = 0;
    if (allChats)
      unreadMessages = allChats.filter((item) => !isSeen(item)).length;
    setUnreadMessages(unreadMessages);
    if (!selectedId)
      document.title =
        (unreadMessages > 0 ? `(${unreadMessages}) ` : "") +
        `${t("chats.title")} - proWIN Messenger`;
    if (session.secretKey && archivedChats.length === 0) setSelectedChip(0);
  }, [allChats]);

  React.useEffect(() => {
    loadChatList();
    if (selectedId) return;
    document.title =
      (unreadMessages > 0 ? `(${unreadMessages}) ` : "") +
      `${t("chats.title")} - proWIN Messenger`;
    setShowChatInfo(false);
  }, [selectedId]);

  React.useEffect(() => {
    if (!allChats || !socketInitialized) return;
    for (const item of allChats) {
      socketEmit("join", {
        partyId: item.chatId || item.shortId || item.id,
        guestId: userId,
      });
    }
  }, [allChats, socketInitialized]);

  const partyDialog = React.useRef();
  const groupDialog = React.useRef();
  const newChatDialog = React.useRef();
  const joinChatDialog = React.useRef();
  const shareLinkDialog = React.useRef();

  const [showChatInfo, setShowChatInfo] = React.useState(false);
  const handleBack = () => setShowChatInfo(false);

  const handleAdd = () => {
    partyDialog.current.open();
  };

  const submitNewChatDialog = async (chatId) => {
    const { response } = await AddChat(chatId);
    if (!response) return;
    loadChatList();
    history.push(`/chats/c/${response?.data.chatId}`);
  };

  const handleMessage = () => {
    newChatDialog.current.open();
  };
  const handleShareLink = () => {
    let shortProfileId = translation.fromUUID(
      settings[session.userId]?.selectedProfile
    );
    const inviteLink = `${process.env.REACT_APP_SITE_URL}/addVIP/${shortProfileId}`;
    shareLinkDialog.current.open(inviteLink);
  };

  const handleNewGroup = () => {
    groupDialog.current.open();
  };

  const submitNewGroupDialog = async (
    title,
    restrictedMessaging,
    selectedFile
  ) => {
    const { response, error } = await AddGroupChat(
      title,
      restrictedMessaging,
      selectedFile
    );
    if (error) return;
    const groupId = response?.data?.id;
    changeChip(1);
    await loadChatList();
    if (groupId) history.push(`/chats/g/${groupId}`);
  };

  const submitPartyDialog = async (
    title,
    start,
    expiration,
    host,
    manager,
    password,
    restrictedMessaging,
    selectedFile
  ) => {
    const { response, error } = await AddParty(
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging,
      selectedFile
    );
    if (error) return;
    changeChip(3);
    history.push(`/chats/p/${response?.data.shortId}`);
    loadChatList();
  };

  const handleGuestJoinParty = () => {
    joinChatDialog.current.open("party");
  };
  const handleGuestJoinChat = () => {
    joinChatDialog.current.open("direct");
  };
  const handleGuestJoinGroup = () => {
    joinChatDialog.current.open("group");
  };

  const submitJoinChatDialog = (chatURL) => {
    window.location.href = chatURL;
  };

  useUserJoined(({ partyId, socketId }) => {
    socketEmit("iAmOnline", {
      recipientId: socketId,
      partyId,
      guestId: userId,
    });
  });

  useNewMessage((message) => {
    if (!selectedId) loadChatList();
    if (message?.messageInfo?.sender !== userId) {
      const audioEl = document.getElementsByClassName("audio-element")?.[0];
      if (audioEl?.play) audioEl.play();
    }
  });

  useUpdatedMessage(() => {
    loadChatList();
  });

  useReloadMessages(() => {
    loadChatList();
  });

  usePartyDeleted(() => {
    loadChatList();
  });

  useGuestRemoved(() => {
    loadChatList();
  });

  useDirectChatStarted(() => {
    loadChatList();
  });

  const isSeen = (item) => {
    if (!item.latestMessage?.timestamp) return true;
    if (item.latestMessage.sender === userId) return true;
    if (item.archived) return true;
    if (!item.seen) return false;
    return moment(item.latestMessage.timestamp).isSameOrBefore(item.seen);
  };

  const renderItem = (item, index, list) => {
    const seen = isSeen(item);
    const TextWrapper = seen
      ? ({ children, ...props }) => <span {...props}>{children}</span>
      : ({ children, ...props }) => <strong {...props}>{children}</strong>;

    const folder = item.type === "direct" ? item.otherUserId : item.id;
    const file =
      item.type === "party"
        ? item.partyPic
        : item.type === "direct"
        ? item.profilePic
        : item.type === "group"
        ? item.image
        : undefined;
    const { src, srcSet } = generateImageSourceSet(folder, file, 45);
    return (
      <div key={index}>
        <ListItem
          className={
            !!selectedId &&
            (item.shortId === selectedId ||
              item.id === selectedId ||
              item.chatId === selectedId)
              ? classes.listItemSelected
              : classes.listItem
          }
          onClick={() => handlePartySelect(item)}
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar} src={src} srcSet={srcSet}>
              {item.type === "party" && <ShoppingCartIcon />}
              {item.type === "group" && <GroupsIcon />}
              {item.type === "direct" && item.initials?.toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            key={index}
            className={classes.mainListItemText}
            primary={<TextWrapper>{item?.title}</TextWrapper>}
            secondary={
              <TextWrapper>
                {item.shortId
                  ? lastMessageWithSender(
                      item,
                      session,
                      t,
                      item?.latestMessage?.senderName
                    )
                  : !item.chatId && item.latestMessage?.senderName
                  ? lastMessageWithSender(
                      item,
                      session,
                      t,
                      item?.latestMessage?.senderName
                    )
                  : lastMessage(item, session, t)}
              </TextWrapper>
            }
          />
          <div className={classes.timestamp}>
            <span style={{ whiteSpace: "nowrap" }}>
              <TextWrapper>
                {handleTimestamp(item.latestMessage?.timestamp)}
              </TextWrapper>
              {!seen && <span className={classes.unreadDot} />}
            </span>
            <div
              className={classes.chatType}
              style={{
                backgroundColor:
                  item.type === "group"
                    ? "#cce2cb"
                    : item.type === "party"
                    ? "#f6eac2"
                    : "transparent",
              }}
            >
              {item.type === "group"
                ? t("chats.group")
                : item.type === "party"
                ? t("chats.party")
                : " "}
            </div>
          </div>
        </ListItem>
        {index < list.length - 1 && (
          <Divider
            style={{
              width: "calc(100% - 80px)",
              marginLeft: 70,
            }}
          />
        )}
      </div>
    );
  };

  const chatList = chips[selectedChip].list;

  const headerButtons = session.accessToken
    ? [
        {
          label: t("chats.newParty"),
          icon: <CalendarMonthIcon />,
          onClick: handleAdd,
        },
        {
          label: t("chats.newMessage"),
          icon: <EmailIcon />,
          onClick: handleMessage,
        },
        {
          label: t("chats.newGroup"),
          icon: <GroupsIcon />,
          onClick: handleNewGroup,
        },
      ]
    : [
        {
          label: t("chats.joinParty"),
          icon: <CalendarMonthIcon />,
          onClick: handleGuestJoinParty,
        },
        {
          label: t("chats.joinChat"),
          icon: <EmailIcon />,
          onClick: handleGuestJoinChat,
        },
        {
          label: t("chats.joinGroup"),
          icon: <GroupsIcon />,
          onClick: handleGuestJoinGroup,
        },
      ];

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <NavMenu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="chats"
      />
      <div className={classes.screen} data-testid="screen">
        <HeaderBar
          title={t("chats.title")}
          buttons={headerButtons}
          onBack={handleBack}
          showBackButton={showChatInfo}
        />
        <CssBaseline />
        <div style={{ minHeight: 0, flex: 1, display: "flex" }}>
          {!showChatInfo && (
            <div className={classes.chatList}>
              <div className={classes.wrapperSearch}>
                <div className={classes.search}>
                  <InputBase
                    className={classes.inputBase}
                    placeholder={t("chats.searchChat")}
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value, selectedChip)}
                    style={{
                      fontSize: "13px",
                      color: "#000",
                      width: 280,
                    }}
                  />
                  <IconButton
                    className={classes.searchBtn}
                    type="submit"
                    aria-label="search"
                    disableRipple
                    color="primary"
                  >
                    <div>
                      {searchQuery.length === 0 ? (
                        <SearchIcon />
                      ) : (
                        <CloseIcon onClick={() => setSearchQuery(() => "")} />
                      )}
                    </div>
                  </IconButton>
                </div>
              </div>
              {/* Chat tabs */}
              <div className={classes.chatTab}>
                {(!!session.accessToken || archivedChats.length > 0) &&
                  chips.map((chip, index) =>
                    !session.accessToken && chip.onlyVip ? null : (
                      <Button
                        key={index.toString()}
                        className={classes.chip}
                        onClick={() => changeChip(index)}
                        style={
                          selectedChip === index
                            ? { backgroundColor: "#333E48", color: "#fff" }
                            : null
                        }
                      >
                        {chip.title}
                      </Button>
                    )
                  )}
              </div>
              <div className={classes.sideMenu}>
                {/* Search input */}
                {!chatList?.length && chatListLoading ? (
                  <>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                    <Box className={classes.skeletonWrapper}>
                      <Skeleton variant="circular" width={45} height={45} />
                      <Skeleton
                        variant="rectangular"
                        style={{ marginLeft: 10 }}
                        width={"80%"}
                        height={70}
                      />
                    </Box>
                  </>
                ) : !chatList?.length ? (
                  <p
                    style={{
                      margin: "20px auto",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    {t("chats.noChats")}
                  </p>
                ) : !searchQuery?.length ? (
                  chatList.map(renderItem)
                ) : searchResults.length ? (
                  searchResults.map(renderItem)
                ) : (
                  <p
                    style={{
                      margin: "20px auto",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    {t("chats.noResults")}
                  </p>
                )}
              </div>
            </div>
          )}

          {/* Chat view */}

          <main className={classes.main}>
            <Switch>
              <Route exact path="/chats/p/:partyId">
                <PartyChatView
                  chatType="party"
                  showChatInfo={showChatInfo}
                  setShowChatInfo={setShowChatInfo}
                  headerBackButton={showChatInfo}
                  refreshList={loadChatList}
                  unreadMessages={unreadMessages}
                  startDirectChat={submitNewChatDialog}
                />
              </Route>
              <Route exact path="/chats/c/:partyId">
                <DirectChatView
                  chatType="directChat"
                  showChatInfo={showChatInfo}
                  setShowChatInfo={setShowChatInfo}
                  headerBackButton={showChatInfo}
                  refreshList={loadChatList}
                  unreadMessages={unreadMessages}
                />
              </Route>
              <Route exact path="/chats/g/:partyId">
                <GroupChatView
                  chatType="groupChat"
                  showChatInfo={showChatInfo}
                  setShowChatInfo={setShowChatInfo}
                  headerBackButton={showChatInfo}
                  refreshList={loadChatList}
                  unreadMessages={unreadMessages}
                  startDirectChat={submitNewChatDialog}
                />
              </Route>
              <Route path="*">
                <div className={classes.noChats}>
                  <img src="/chats.svg" />
                  <p>{t("chats.selectChat")}</p>
                </div>
              </Route>
            </Switch>
          </main>
        </div>
        <PartyDialog ref={partyDialog} onSubmit={submitPartyDialog} />
        <GroupDialog ref={groupDialog} onSubmit={submitNewGroupDialog} />
        {session.accessToken && (
          <>
            <NewChatDialog
              ref={newChatDialog}
              selectedProfile={settings[session.userId]?.selectedProfile}
              onCopy={handleShareLink}
              onSubmit={submitNewChatDialog}
            />
            <ShareLinkDialog
              ref={shareLinkDialog}
              title={t("chats.createLink")}
              description={t("chats.createDirectLinkDescription")}
            />
          </>
        )}
        <JoinChatDialog ref={joinChatDialog} onSubmit={submitJoinChatDialog} />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    margin: "10px 0",
    backgroundColor: "#F3F9FC",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    height: "calc(100vh - 20px)",
    overflow: "hidden",
  },
  main: {
    height: "calc(100% - 20px)",
    margin: "0px 20px 20px 20px",
    flex: 2,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  chatList: {
    flex: 1,
    minHeight: 0,
    height: "calc(100% - 20px)",
    display: "flex",
    flexDirection: "column",
    maxWidth: 350,
    marginLeft: 30,
    marginBottom: 20,
    marginRight: -10,
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
    overflow: "hidden",
  },
  sideMenu: {
    flex: 1,
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
    fontFamily: "DIN NEXT LT Pro, sans-serif",
  },
  search: {
    background: "#F3F9FC",
    borderRadius: 10,
    margin: "20px 10px",
    width: "100%",
    height: 30,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  wrapperSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: "0 10px",
    maxWidth: "97%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  inputBase: {
    marginLeft: "10px",
  },
  searchBtn: {
    position: "relative",
    top: 3,
    fontSize: "1.2rem",
  },
  chatTab: {
    display: "flex",
    padding: 10,
    paddingTop: 0,
    flexWrap: "no-wrap",
    flexDirection: "row",
    overflowX: "scroll",
    overflowY: "hidden",
    ...theme.scrollbars,
  },
  chip: {
    marginRight: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    padding: "5px 10px",
    color: "#333E48",
    backgroundColor: "#fff",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    textTransform: "none",
    fontSize: "12px",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(250,250,250)",
      transition: "all 0.1s easy",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 12px 0 16px",
    height: 80,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(160,160,160,0.2)",
    },
  },
  listItemSelected: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 12px 0 16px",
    height: 80,
    backgroundColor: "#E8E8E8",
    cursor: "pointer",
  },
  mainListItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "20px 0",
    overflow: "hidden",
    wordWrap: "normal",
    width: "100%",

    "& .MuiListItemText-primary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },

    "& .MuiListItemText-secondary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  avatar: {
    width: 45,
    height: 45,
    fontWeight: "bold",
  },
  skeletonWrapper: {
    display: "flex",
    padding: "0 12px 0 16px",
    height: 80,
    marginTop: 20,
  },
  chatInfoIcon: {
    color: "#333E48",
    "&:hover": {
      cursor: "pointer",
      color: "#BEBEBE",
    },
  },
  initials: {
    marginRight: 20,
    width: 40,
    height: 40,
    color: "#fff",
    backgroundColor: "#bdbdbd",
    borderRadius: "50%",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timestamp: {
    fontSize: "0.8rem",
    color: "#bdbdbd",
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  chatType: {
    paddingLeft: 7,
    paddingRight: 7,
    height: 20,
    borderRadius: 10,
    justifyContent: "center",
    color: "#333",
    fontSize: 12,
    fontWeight: "500",
    paddingTop: 1,
  },
  noChats: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: "50%",
    },

    "& p": {
      marginTop: 0,
      color: "rgb(120,120,120)",
    },
  },
  unreadDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    display: "inline-block",
    marginLeft: 5,
    marginRight: -15,
  },
}));

export default Chats;
