import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const NameDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit, registered, passwordRequired } = props;
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [canClose, setCanClose] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [restrictedMessaging, setRestrictedMessaging] = React.useState(false);
  const [error, setError] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: (firstName, lastName, canClose, restrictedMessaging, error) => {
      setOpen(true);
      setFirstName(firstName || "");
      setLastName(lastName || "");
      setCanClose(canClose || false);
      setPassword("");
      setRestrictedMessaging(restrictedMessaging || false);
      setError(!!error);
    },
  }));

  const handleClose = () => {
    if (canClose) setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(firstName, lastName, password);
  };

  const submitEnabled = !!firstName.trim() && !!lastName.trim();
  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.nameDialogTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("party.nameDialog")}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t("party.firstName")}
            type="text"
            fullWidth
            value={firstName ? firstName : ""}
            onChange={(e) => setFirstName(e.target.value)}
            className={classes.field}
            required
          />
          <TextField
            margin="dense"
            label={t("party.lastName")}
            type="text"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className={classes.field}
            required
          />
          {passwordRequired ? (
            <TextField
              margin="dense"
              label={t("party.password")}
              type="text"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={classes.field}
              error={error ? true : false}
              required
            />
          ) : (
            <></>
          )}
          {!registered && (
            <DialogContentText>
              <Trans i18nKey="party.privacyNotice">
                <Link to="/privacy" target="_blank"></Link>
              </Trans>
            </DialogContentText>
          )}
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          {canClose && (
            <Button onClick={handleClose} color="inherit">
              {t("general.cancel")}
            </Button>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!submitEnabled}
          >
            {t("general.confirm")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(NameDialog);
