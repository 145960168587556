import * as React from "react";
import List from "@mui/material/List";
import Search from "./Search";
import { makeStyles, useTheme } from "@mui/styles";
import TemplateContent from "./TemplateContent";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import HomeIcon from "@mui/icons-material/Home";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import FolderIcon from "@mui/icons-material/Folder";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";

const TemplateList = () => {
  const theme = useTheme();
  const messages = [
    {
      id: "8122ed76-086b-419a-b644-e396bf90549f",
      name: "Badezimmer",
    },
    {
      id: "8122ed76-086b-419a-b644-e396bf90549f",
      name: "New test",
    },
  ];
  const classes = useStyles();

  const handleClick = (value) => {
    // alert("TemplateList clicked");
  };

  function handleClickBC(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  return (
    <List className={classes.mainTemplateList}>
      <div className={classes.search}>
        <Search />
        <Tooltip title="New Folder">
          <CreateNewFolderIcon className={classes.folderIcon} />
        </Tooltip>
      </div>

      <div
        className={classes.breadcrumbsWrapper}
        role="presentation"
        onClick={handleClickBC}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            {/* <ViewQuiltIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
            Template
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href="/material-ui/getting-started/installation/"
          >
            {/* <FolderIcon sx={{ mr: 0.5 }} fontSize="inherit" /> */}
            folder*
          </Link>
          <Typography color="text.primary">content*</Typography>
        </Breadcrumbs>
      </div>
      {messages.map((item, index) => {
        return (
          <div>
            <TemplateContent
              profileUrl={item.profileUrl}
              name={item.name}
              handleClick={() => handleClick(item.id)}
              newMessage={item.newMessage}
              message={item.message}
              key={index}
            />

            {/* <FolderDialog
              ref={newFolderDialog}
              onSubmit={submitNewFolderDialog}
            /> */}
          </div>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles(() => ({
  mainTemplateList: {
    width: 350,
    height: "78vh",
    margin: "10px 0 10px 30px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 0.5px 1px rgba(165,164,164, 0.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    overflow: "hidden",
  },
  search: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "5px 0 5px 10px",
  },
  folderIcon: {
    color: "#333E48",
    marginRight: 20,
    "&:hover": {
      cursor: "pointer",
      color: "#1E92CD",
    },
  },

  breadcrumbsWrapper: {
    margin: 20,
    display: "flex",
  },
}));

export default TemplateList;
