import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Avatar,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/it";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";

const GroupDialog = (props, ref) => {
  const classes = useStyles();
  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState("create");
  const [selectedFile, setSelectedFile] = React.useState();
  const [originalProfilePic, setOriginalProfilePic] = React.useState();
  const [profilePic, setProfilePic] = React.useState();
  const [deleteProfilePic, setDeleteProfilePic] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [restrictedMessaging, setRestrictedMessaging] = React.useState(false);

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  React.useImperativeHandle(ref, () => ({
    open: (title, restrictedMessaging, profilePic) => {
      setOpen(true);
      setTitle(title || "");
      setMode(title ? "edit" : "create");
      setRestrictedMessaging(restrictedMessaging || false);
      setOriginalProfilePic(profilePic);
      setProfilePic(profilePic);
      setSelectedFile();
      setDeleteProfilePic(false);
    },
  }));

  const handleDeleteProfilePic = () => {
    setProfilePic();
    setSelectedFile();
    if (originalProfilePic) setDeleteProfilePic(true);
  };

  const selectFile = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = false;
    input.accept = ["image/*"];
    input.onchange = ({ target }) => {
      const { files } = target;
      // setSaveState("changed");
      setSelectedFile(files[0]);
      setDeleteProfilePic(false);
      document.body.removeChild(input);
    };
    input.style.display = "none";
    document.body.append(input);
    input.click();
  };

  React.useEffect(() => {
    if (selectedFile) {
      setProfilePic(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(title, restrictedMessaging, selectedFile, deleteProfilePic);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {mode === "create" ? t("chats.createGroup") : t("chats.editGroup")}
        </DialogTitle>
        <DialogContent>
          <div className={classes.picWrapper}>
            <Avatar
              onClick={selectFile}
              src={profilePic}
              className={classes.avatar}
            >
              <GroupsIcon />
            </Avatar>
            <div className={classes.addAction}>
              <CameraAltIcon
                onClick={selectFile}
                sx={{ color: "#F3F9FC", fontSize: 15, margin: "auto" }}
              />
            </div>
            <div className={classes.deleteAction}>
              <DeleteIcon
                onClick={handleDeleteProfilePic}
                size="large"
                sx={{ color: "#F3F9FC", fontSize: 15, margin: "auto" }}
              />
            </div>
          </div>
          <TextField
            autoFocus
            margin="dense"
            label={t("chats.groupTitle")}
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={classes.field}
            required
            placeholder={t("chats.newGroupPlaceholder")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={restrictedMessaging}
                onChange={(e) =>
                  setRestrictedMessaging(
                    restrictedMessaging === true ? false : true
                  )
                }
                size="medium"
                name="checkedB"
              />
            }
            label={t("parties.restrictedMessaging")}
          />
        </DialogContent>
        <div className={classes.spacer} />
        <div className={classes.buttonWrapper}>
          <DialogActions>
            <Button
              style={{ width: 270 }}
              className={classes.buttonCancel}
              onClick={handleClose}
              color="inherit"
            >
              {t("general.cancel")}
            </Button>
            <Button
              className={classes.buttonCreate}
              type="submit"
              color="primary"
              variant="contained"
              style={{ width: 270 }}
            >
              {mode === "create" ? t("general.create") : t("general.save")}
            </Button>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
    width: 550,
    borderRadius: 10,
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonCreate: {
    margin: 10,
    width: 200,
    background: "#1E92CD",
  },
  buttonCancel: {
    margin: 10,
    width: 200,
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    zIndex: "1",
    "& svg": {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  addAction: {
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "rgba(51,62,72,0.7)",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#fff",
    marginTop: "-1rem",
    zIndex: "2",
  },
  deleteAction: {
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "rgba(51,62,72,0.7)",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#fff",
    marginLeft: "4rem",
    marginTop: "-1.45rem",
    zIndex: "2",
  },
}));

export default React.forwardRef(GroupDialog);
