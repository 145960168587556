import React from "react";
import { Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

const TrashCard = ({ onView }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Card id={"trash"} className={classes.card} onClick={onView}>
        <DeleteIcon className={classes.folderIcon} />
        <Typography variant="h5" component="span" className={classes.title}>
          {t("templates.trash")}
        </Typography>
      </Card>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 50,
    width: 250,
    cursor: "pointer",
    marginLeft: 10,
    marginTop: 10,
    padding: 10,
    "& *": {
      pointerEvents: "none",
    },
    maxWidth: "calc(100% - 20px)",
    backgroundColor: "#f17a65",
  },
  folderIcon: {
    color: "#FFF",
  },
  title: {
    flex: 1,
    fontSize: 17,
    textJustify: "baseline",
    paddingLeft: 5,
    marginBottom: -3,
    color: "#FFF",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default TrashCard;
