import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const Search = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const { guestList, guestResults } = props;

  const handleSearch = (query) => {
    setSearchValue(query);
    if (!query.trim()) {
      guestResults();
      return;
    }
    const results = guestList.filter((item) => applySearch(item, query));
    guestResults(results);
  };

  React.useEffect(() => {
    if (searchValue.length === 0) {
      guestResults();
    }
  }, [searchValue]);

  const applySearch = (item, query) => {
    const searchVal = query.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = false;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result =
        result ||
        (item?.firstName && searchTerm.test(item.firstName?.toLowerCase())) ||
        (item?.lastName && searchTerm.test(item.lastName?.toLowerCase()));
    }
    return result;
  };

  return (
    <div className={classes.rootBlue}>
      <InputBase
        className={classes.inputBase}
        placeholder={t("chats.searchGuest")}
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <IconButton
        className={classes.searchBtn}
        type="submit"
        aria-label="search"
        disableRipple
        color="primary"
      >
        <div>
          {searchValue.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon onClick={() => setSearchValue(() => "")} />
          )}
        </div>
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  rootBlue: {
    background: "#F3F9FC",
    borderRadius: 10,
    height: 30,
    border: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    flex: 1,
    marginLeft: 8,
  },
  inputBase: {
    marginLeft: 10,
    fontSize: "13px",
    color: "#000",
    padding: "5px 10px 5px 10px",
    fontFamily: "DIN NEXT LT Pro, sans-serif",
    flex: 1,
    "& .MuiInputBase-input": {
      paddingTop: 7,
    },
  },
  searchBtn: {
    position: "relative",
    top: 3,
    fontSize: "1.2rem",
  },
}));

export default Search;
