import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ListItem,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import GroupsIcon from "@mui/icons-material/Groups";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  lastMessageWithSender,
  lastMessage,
} from "./../../screens/chats/latestMessage";
import { useHandleTimestamp } from "src/utils/handleTimestamp.util";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const MoveToChatDialog = (props, ref) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [chats, setChats] = React.useState([]);
  const [chatId, setChatId] = React.useState();
  const [selectedChat, setSelectedChat] = React.useState();
  const [chatType, setChatType] = React.useState();

  const session = useSelector((state) => state.session);

  const handleTimestamp = useHandleTimestamp();

  const select = (item) => {
    setSelectedChat(item.shortId || item.id || item.chatId);
    setChatType(item.shortId ? "party" : item.chatId ? "direct" : "group");
  };

  React.useImperativeHandle(ref, () => ({
    open: (allChatTypes, partyId) => {
      setOpen(true);
      let tidyChatList = [];
      for (const singleChat of allChatTypes) {
        if (!singleChat.expiration || !singleChat.archived) {
          tidyChatList.push(singleChat);
        }
      }
      setChats(tidyChatList.filter((m) => m.id !== partyId));
      setChatId(partyId);
      setSelectedChat();
      setChatType();
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(chatId, selectedChat, chatType);
  };

  const renderItem = (item, index, list) => {
    const id = item.shortId || item.id || item.chatId;
    const { src, srcSet } = generateImageSourceSet(
      item.type === "direct" ? item.otherUserId : item.id,
      item.type === "party"
        ? item.partyPic
        : item.type === "direct"
        ? item.profilePic
        : item.image,
      45
    );
    return (
      <div key={index}>
        <ListItem
          className={
            selectedChat !== id ? classes.listItem : classes.listItemSelected
          }
          onClick={() => select(item)}
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar} src={src} srcSet={srcSet}>
              {item.type === "party" && <ShoppingCartIcon />}
              {item.type === "group" && <GroupsIcon />}
              {item.type === "direct" && item.initials?.toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            key={index}
            className={classes.mainListItemText}
            primary={item?.title}
            secondary={
              item.shortId
                ? lastMessageWithSender(
                    item,
                    session,
                    t,
                    item?.latestMessage?.senderName
                  )
                : !item.chatId && item.latestMessage?.senderName
                ? lastMessageWithSender(
                    item,
                    session,
                    t,
                    item?.latestMessage?.senderName
                  )
                : lastMessage(item, session, t)
            }
          />
          <div className={classes.timestamp}>
            <span>{handleTimestamp(item.latestMessage?.timestamp)}</span>
            {item.type !== "direct" && (
              <div
                className={classes.chatType}
                style={{
                  backgroundColor:
                    item.type === "group" ? "#cce2cb" : "#f6eac2",
                }}
              >
                {item.type === "group" ? t("chats.group") : t("chats.party")}
              </div>
            )}
          </div>
        </ListItem>
        {index < list.length - 1 && (
          <Divider
            style={{
              width: "calc(100% - 80px)",
              marginLeft: 70,
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("chats.moveToChats")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("chats.moveToChatSubheading")}
          </DialogContentText>
          <div className={classes.mediaArea}>{chats.map(renderItem)}</div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ width: 300, background: "#D8D8D8" }}
            onClick={handleClose}
            color="inherit"
          >
            {t("general.cancel")}
          </Button>
          <Button
            style={{ width: 300, background: "#1E92CD" }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={!selectedChat}
          >
            {t("chats.moveToDialogSubmit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
  },
  mediaArea: {
    height: "60vh",
    overflowX: "hidden",
    overflowY: "scroll",
    ...theme.scrollbars,
    margin: "auto",
  },
  listItemSelected: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 12px 0 16px",
    height: 80,
    backgroundColor: "#E8E8E8",
    cursor: "pointer",
  },
  mainListItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: "20px 0",
    overflow: "hidden",
    wordWrap: "normal",
    width: "100%",

    "& .MuiListItemText-primary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },

    "& .MuiListItemText-secondary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  timestamp: {
    fontSize: "0.8rem",
    color: "#bdbdbd",
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  chatType: {
    paddingLeft: 7,
    paddingRight: 7,
    height: 20,
    borderRadius: 10,
    justifyContent: "center",
    color: "#333",
    fontSize: 12,
    fontWeight: "500",
    paddingTop: 1,
  },
  avatar: {
    width: 45,
    height: 45,
    fontWeight: "bold",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 12px 0 16px",
    height: 80,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(160,160,160,0.2)",
    },
  },
}));

export default React.forwardRef(MoveToChatDialog);
