import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PrivacyDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("login.withProwin")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={accepted}
                  onChange={(e) => setAccepted(e.target.checked)}
                  required
                />
              }
              label={
                <Trans i18nKey="login.privacyNotice">
                  <Link to="/privacy" target="_blank"></Link>
                </Trans>
              }
              style={{ marginTop: 10 }}
            />
          </DialogContentText>
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button style={{ width: 300 }} onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button
            style={{ width: 300 }}
            type="submit"
            color="primary"
            variant="contained"
            disabled={!accepted}
          >
            {t("login.login")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(PrivacyDialog);
