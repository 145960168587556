import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const ForwardMessagesDialog = (props, ref) => {
  const classes = useStyles();

  const { onMoveToLibrary, onMoveToTemplate, onMoveToChat, isMedia } = props;
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleMoveToLibrary = (e) => {
    e.preventDefault();
    setOpen(false);
    onMoveToLibrary();
  };
  const handleMoveToTemplate = (e) => {
    e.preventDefault();
    setOpen(false);
    onMoveToTemplate();
  };
  const handleMoveToChat = (e) => {
    e.preventDefault();
    setOpen(false);
    onMoveToChat();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("party.massForwardMessageLabel")}</DialogTitle>
      <DialogContent style={{ color: "#1E92CD" }}>
        <div className={classes.forwardOptionsWrapper}>
          {isMedia && (
            <Paper
              className={classes.forwardOptions}
              elevation={3}
              onClick={handleMoveToLibrary}
            >
              {t("forwarding.forwardLibrary")}
            </Paper>
          )}
          <Paper
            className={classes.forwardOptions}
            elevation={3}
            onClick={handleMoveToTemplate}
          >
            {t("forwarding.forwardTemplate")}
          </Paper>
          <Paper
            className={classes.forwardOptions}
            elevation={3}
            onClick={handleMoveToChat}
          >
            {t("forwarding.forwardChat")}
          </Paper>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            width: 200,
            margin: 10,
            background: "#D8D8D8",
          }}
          onClick={handleClose}
          color="inherit"
        >
          {t("general.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
  forwardOptionsWrapper: {
    display: "flex",
    flexDirection: "row",
    wrap: "no-wrap",
  },
  forwardOptions: {
    padding: "1rem",
    cursor: "pointer",
    margin: "0.5rem",
    width: 200,
    display: "flex",
    justifyContent: "center",
  },
}));

export default React.forwardRef(ForwardMessagesDialog);
