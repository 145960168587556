import React from "react";
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import { generateImageSourceSet } from "src/utils/generateImageSet";

const DirectChatProfileInfo = (props) => {
  const classes = useStyles();

  const { party } = props;
  const [guestInfo, setGuestInfo] = React.useState();
  const [guestBirthday, setGuestBirthday] = React.useState();

  const { t, i18n } = useTranslation();

  const { GetUserProfile } = useActions();

  React.useEffect(async () => {
    const { response } = await GetUserProfile(party.otherUserId);
    setGuestInfo(response?.data);
    if (response?.data.birthday) {
      const guestBirthday = new Date(response?.data.birthday);
      setGuestBirthday(guestBirthday.toLocaleDateString(`${i18n.language}`));
    }
  }, [party.otherUserId]);

  const { src, srcSet } = generateImageSourceSet(
    guestInfo?.id,
    guestInfo?.profilePic,
    100
  );

  return (
    <div className={classes.profileWrapper}>
      {" "}
      <Avatar className={classes.avatar} src={src} srcSet={srcSet} />
      <p style={{ fontWeight: "600" }}>
        {guestInfo?.firstName} {guestInfo?.lastName}
      </p>
      {!!guestInfo?.birthday && (
        <span>
          {t("profiles.birthday")}: <strong>{guestBirthday}</strong>
        </span>
      )}
      {!!guestInfo?.phoneNo && (
        <div>
          {t("profiles.phoneNo")}:
          <a href={`tel:${guestInfo.phoneNo}`}>
            <strong>{guestInfo?.phoneNo}</strong>
          </a>
        </div>
      )}
      {!!guestInfo?.email && (
        <div>
          {t("profiles.email")}:{" "}
          <a href={`mailto:${guestInfo?.email}`}>
            <strong>{guestInfo?.email}</strong>
          </a>
        </div>
      )}
      {!!guestInfo?.website && (
        <div>
          {t("profiles.website")}:{" "}
          <a
            href={
              guestInfo?.website.startsWith("http")
                ? guestInfo?.website
                : `https://${guestInfo?.website}`
            }
          >
            <strong>{guestInfo?.website}</strong>
          </a>
        </div>
      )}
      {!!guestInfo?.biography && (
        <div>
          {t("profiles.biography")}: <strong>{guestInfo?.biography}</strong>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  profileWrapper: {
    margin: "10px 20px",
    padding: "10px",
    backgroundColor: "#fff",
    borderRadius: 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    width: 100,
    height: 100,
  },
}));

export default DirectChatProfileInfo;
